import React, { useEffect, useState } from "react";
import TabButtons from "../../../components/Tabs/TabButton";
import TabContent from "../../../components/Tabs/TabContent";
import CardSetting from "../../ClevaCards/CardSetting";
import SendPinConfirmation from "./Security/modals/SendPinConfirmation";
import { useAppDispatch } from "../../../app/hooks";
import { setTitle } from "../../../features/Nav/NavSlice";
import BackButton from "../../../components/Buttons/BackButton";

const Settings = () => {
  const [activeTab, setActiveTab] = useState<string>("security");

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setTitle("Settings"));
  }, []);

  return (
    <>
      <div className="flex py-8 items-center">
        <BackButton />
        <p className="text-lg font-bold ml-3">Security</p>
      </div>
      <div className="pt-6 md:pt-2 md:w-4/5 xl:w-3/4 md:px-4 xl:px-12 mx-auto">
        <div className="z-2 w-full md:mb-8 md:w-1/2 order-0 md:order-0 pt-5 mb-5 mx-auto">
          <ul className="grid grid-cols-2 gap-4 bg-[#EBF0F0] rounded-[10px] px-2 py-1.5">
            <TabButtons
              name="Security PIN"
              id="security"
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
            <TabButtons
              name="Card Settings"
              id="card"
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          </ul>
        </div>

        <div className="md:mt-4">
          <TabContent id="security" activeTab={activeTab}>
            <SendPinConfirmation />
          </TabContent>
          <TabContent id="card" activeTab={activeTab}>
            <CardSetting />
          </TabContent>
        </div>
      </div>
    </>
  );
};

export default Settings;
