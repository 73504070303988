import { useEffect } from "react";
import { DocumentGuide, Timeline } from "./components/Features";
import BeneficialOwners from "./pages/beneficiaryOwners";
import BusinessDetails from "./pages/businessDetails";
import ReviewKyc from "./pages/reviewKyc";
import UploadDocuments from "./pages/uploadDocuments";
import CompletedKyc from "./pages/completedKyc";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  setBusinesskyc,
  setStep,
  setIndex,
  openForm,
  setCompletedSteps,
} from "../../features/Kyc/kycSlice";
import { setTitle } from "../../features/Nav/NavSlice";
import { ToastContainer, toast } from "react-toastify";

function BusinessKYC() {
  const { BusinessKyc, step, index, newOrEdit, completedSteps, KYCState } =
    useAppSelector((state) => state.kycInfo);
  const dispatch = useAppDispatch();

  const changeStep = (next: number) => {
    if (next > step) {
      setCompletedSteps([...completedSteps, step]);
      dispatch(setStep(next));
    } else {
      setCompletedSteps(
        completedSteps.filter((completedStep) => completedStep !== step)
      );
      dispatch(setStep(next));
    }
    window.scrollTo(0, 0);
  };

  const setItemIndex = (index: any) => {
    dispatch(setIndex(index));
  };

  const setOpenForm = (action: any) => {
    dispatch(openForm(action));
  };

  const saveForLater = () => {
    toast.success("saved successfully!");
    localStorage.setItem("BusinessKyc", JSON.stringify(BusinessKyc));
  };

  useEffect(() => {
    dispatch(setTitle("KYC Verification - Business"));
    const item = localStorage.getItem("BusinessKyc");
    if (item) {
      const businessKyc: any = JSON.parse(item);
      dispatch(setBusinesskyc(businessKyc));
    }
    window.scrollTo(0, 0);
  }, [KYCState, dispatch]);

  return (
    <div className="flex my-20">
      {step < 4 && <Timeline step={step} completed={completedSteps} />}
      {step === 4 && <DocumentGuide />}

      {step === 1 && (
        <BusinessDetails currentStep={step} nextStep={changeStep} />
      )}
      {step === 2 && (
        <BeneficialOwners
          opened={newOrEdit}
          openForm={setOpenForm}
          currentStep={step}
          nextStep={changeStep}
          index={index}
          setIndex={setItemIndex}
        />
      )}
      {step === 3 && (
        <ReviewKyc
          saveForLater={saveForLater}
          currentStep={step}
          openForm={setOpenForm}
          nextStep={changeStep}
          setIndex={setItemIndex}
        />
      )}
      {step === 4 && (
        <UploadDocuments
          saveForLater={saveForLater}
          currentStep={step}
          nextStep={changeStep}
        />
      )}
      {step === 5 && <CompletedKyc />}

      <ToastContainer />
    </div>
  );
}

export default BusinessKYC;
