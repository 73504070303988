import { ReactNode } from "react";

interface IModalTemplate {
  modalState: boolean;
  parentStyle?: string;
  childStyle?: string;
  extraStyle?: string;
  children: ReactNode;
  setModalState?: any;
}

const ModalTemplate = ({
  modalState,
  setModalState,
  parentStyle,
  childStyle,
  extraStyle,
  children,
}: IModalTemplate) => {
  const handleClose = () => {
    if (setModalState) {
      setModalState(false);
    }
  };

  return (
    <>
      {modalState && (
        <div
          onClick={handleClose}
          className={`${parentStyle} fixed inset-0 z-50 flex items-center justify-center backdrop-filter bg-black bg-opacity-70`}
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className={`${childStyle} rounded-xl w-[90%] mx-auto absolute bg-white`}
          >
            <div className={`${extraStyle}`}>{children}</div>
          </div>
        </div>
      )}
    </>
  );
};

export default ModalTemplate;
