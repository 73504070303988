import { createSlice, PayloadAction, Middleware } from "@reduxjs/toolkit";

interface AnniversaryState {
  displayBanner: boolean;
}

// Function to load state from local storage
const loadState = (): AnniversaryState => {
  try {
    const serializedState = localStorage.getItem("anniversaryState");
    if (serializedState === null) {
      return { displayBanner: true };
    }
    return JSON.parse(serializedState);
  } catch {
    return { displayBanner: true };
  }
};

// Function to save state to local storage
const saveState = (state: AnniversaryState) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("anniversaryState", serializedState);
  } catch {
    // Ignore write errors
  }
};

const initialState: AnniversaryState = loadState();

const anniversarySlice = createSlice({
  name: "anniversary",
  initialState,
  reducers: {
    setAnniversaryBanner: (state, action: PayloadAction<boolean>) => {
      state.displayBanner = action.payload;
    },
  },
});

export const { setAnniversaryBanner } = anniversarySlice.actions;

export default anniversarySlice.reducer;

// Custom middleware to save state to local storage
export const localStorageMiddleware: Middleware =
  (store) => (next) => (action) => {
    const result = next(action);
    if (setAnniversaryBanner.match(action)) {
      saveState(store.getState().anniversary);
    }
    return result;
  };
