import React, { useState, useEffect } from "react";
import DashboardCard from "../../../components/Layout/DashboardCard";

const Carousel = ({
  KYCState,
  referralBonusEarned,
  hasAccounts,
  navigate,
  GoToTagTransfer,
  ReferralBG,
  CardBG,
  TagBG,
  DealBG,
}: any) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [itemsPerSlide, setItemsPerSlide] = useState(1);
  const [peekAmount, setPeekAmount] = useState(16);

  const items = [
    <div className="order-first lg:order-none" key="referral">
      <DashboardCard
        disabled={KYCState !== "VERIFIED" && KYCState !== "BUSINESS_VERIFIED"}
        active={KYCState === "VERIFIED" || KYCState === "BUSINESS_VERIFIED"}
        handleClick={() => navigate("/referrals")}
        cardClass={true}
        title={`Earn $${referralBonusEarned} for each referral`}
        backgroundImage={ReferralBG}
        additionalStyle="text-base"
      />
    </div>,
    <div key="cards">
      <DashboardCard
        data-test="cards-box"
        disabled={KYCState !== "VERIFIED" && KYCState !== "BUSINESS_VERIFIED"}
        active={KYCState === "VERIFIED" || KYCState === "BUSINESS_VERIFIED"}
        handleClick={() => navigate("/cards")}
        title="USD card that works"
        backgroundImage={CardBG}
        additionalStyle="text-base"
      />
    </div>,
    <div key="transfers">
      {hasAccounts ? (
        <DashboardCard
          disabled={KYCState !== "VERIFIED" && KYCState !== "BUSINESS_VERIFIED"}
          active={KYCState === "VERIFIED" || KYCState === "BUSINESS_VERIFIED"}
          handleClick={GoToTagTransfer}
          title="Free C2C transfers to other Cleva users"
          additionalStyle="text-base text-[#111111]"
          backgroundImage={TagBG}
        />
      ) : (
        <DashboardCard
          disabled={KYCState !== "VERIFIED" && KYCState !== "BUSINESS_VERIFIED"}
          active={KYCState === "VERIFIED" || KYCState === "BUSINESS_VERIFIED"}
          title="Free C2C transfers to other Cleva users"
          additionalStyle="text-base text-[#111111]"
          backgroundImage={TagBG}
        />
      )}
    </div>,
    <div key="deals">
      {hasAccounts ? (
        <DashboardCard
          disabled={KYCState !== "VERIFIED" && KYCState !== "BUSINESS_VERIFIED"}
          active={KYCState === "VERIFIED" || KYCState === "BUSINESS_VERIFIED"}
          handleClick={() => navigate("/deals")}
          title="Exclusive deals for you"
          additionalStyle="text-base text-[#111111]"
          backgroundImage={DealBG}
        />
      ) : (
        <DashboardCard
          disabled={KYCState !== "VERIFIED" && KYCState !== "BUSINESS_VERIFIED"}
          active={KYCState === "VERIFIED" || KYCState === "BUSINESS_VERIFIED"}
          title="Exclusive deals for you"
          additionalStyle="text-base text-[#111111]"
          backgroundImage={DealBG}
        />
      )}
    </div>,
  ];

  const totalSlides = Math.ceil(items.length / itemsPerSlide);

  // Handle window resize and adjust items per slide
  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth < 640;
      setItemsPerSlide(isMobile ? 1 : 3);
      setPeekAmount(isMobile ? 24 : 12);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Automatic slide change every 5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % totalSlides);
    }, 5000);

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [totalSlides]);

  // const handleNext = () => {
  //   if (currentSlide < totalSlides - 1) {
  //     setCurrentSlide(currentSlide + 1);
  //   } else {
  //     setCurrentSlide(0);
  //   }
  // };

  // const handlePrevious = () => {
  //   if (currentSlide > 0) {
  //     setCurrentSlide(currentSlide - 1);
  //   } else {
  //     setCurrentSlide(totalSlides - 1);
  //   }
  // };

  const goToSlide = (index: number) => {
    setCurrentSlide(index);
  };

  return (
    <div className="relative">
      <div className="overflow-hidden px-4">
        <div
          className="flex transition-transform duration-300 ease-in-out"
          style={{
            transform: `translateX(-${currentSlide * (100 / itemsPerSlide)}%)`,
            gap: `${peekAmount}px`,
          }}
        >
          {items.map((item, index) => (
            <div
              key={index}
              className={`${
                itemsPerSlide === 1 ? "w-[90%]" : `w-[30%]`
              } flex-shrink-0 mx-1`}
            >
              {item}
            </div>
          ))}
        </div>
      </div>

      {/* Carousel Indicators */}
      <div className="absolute inset-x-0 bottom-[-1.5rem] flex justify-center space-x-2">
        {Array(totalSlides)
          .fill(0)
          .map((_, index) => (
            <button
              key={index}
              onClick={() => goToSlide(index)}
              className={`w-6 h-1 ${
                currentSlide === index ? "bg-cleva-gold" : "bg-gray-300"
              }`}
              aria-label={`Go to slide ${index + 1}`}
            ></button>
          ))}
      </div>
    </div>
  );
};

export default Carousel;
