import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import VirtualAccountDetails, {
  statementAccountDetails,
} from "./VirtualAccountDetails";
import TransactionList from "./TransactionList";
import { IVirtualAccount } from "../../../../features/VirtualAccount/VirtualAccountSlice";
import Logo from "../../../../asset/logo.png";
import { TransactionListItem } from "../../../../features/Transanctions/TransanctionSlice";
import BackgroundImage from "../../../../asset/images/watermark.png";

function GenerateStatement({
  month,
  year,
  transactions,
  user,
}: {
  month: string;
  year: string;
  transactions: TransactionListItem[];
  user: IVirtualAccount;
}) {
  // const year = '2023'
  // const month= '09'
  const months = Array.from({ length: 12 }, (val, index) => {
    return new Date(0, index + 1, 0).toLocaleDateString("en", {
      month: "long",
    });
  });
  const monthNumber = months.indexOf(month) + 1;
  const monthNumberString =
    `${monthNumber}`.length === 1 ? `0${monthNumber}` : `${monthNumber}`;
  const selectedDate = `${year}-${monthNumberString}-01`;
  let moneyIn = 0;
  let moneyOut: number = 0;

  let allTransfer = transactions.filter((transaction) => {
    const isTagDebit =
      transaction.TransactionType === "TAG_TRANSFER" &&
      transaction.TransactionState === "COMPLETED";
    const isTagCredit =
      transaction.TransactionType === "TAG_CREDIT" &&
      transaction.TransactionState === "COMPLETED";
    if (
      new Date(transaction.CreatedAt?.slice(0, 7)).toDateString() ===
      new Date(selectedDate).toDateString()
    ) {
      if (
        (transaction.TransactionType === "MAKE_PAYMENT" &&
          transaction.TransactionState !== "FAILED") ||
        transaction.TransactionType === "CARD_FUNDING" ||
        transaction.TransactionType === "CARD_CREATION" ||
        isTagDebit
      ) {
        moneyOut += transaction.TotalAmount;
        return transaction;
      }
      if (
        transaction.TransactionType === "USD_CREDIT" ||
        transaction.TransactionType === "REFERRAL_BONUS" ||
        isTagCredit
      ) {
        moneyIn += transaction.TotalAmount;
        return transaction;
      }
    }
  });
  allTransfer.sort(
    (a, b) => new Date(b.CreatedAt).getTime() - new Date(a.CreatedAt).getTime()
  );
  const accountDetails: statementAccountDetails = {
    ...user,
    month,
    year,
    moneyIn,
    moneyOut,
  };

  const styles = StyleSheet.create({
    page: {
      color: "black",
      fontFamily: "Inter",
      fontSize: 8,
      fontWeight: "thin",
      paddingVertical: 40,
    },
    section: {
      //margin: 10,
      padding: 10,
    },
    header: {
      backgroundColor: "black",
      paddingVertical: 15,
      paddingRight: 20,
      paddingleft: 5,
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      color: "white",
      marginTop: -30,
      marginHorizontal: 10,
      borderRadius: 3,
    },
    body: {
      padding: 10,
      paddingHorizontal: 20,
      margin: 10,
    },
    pageBackground: {
      width: "100%",
      position: "absolute",
    },
    test: {
      marginTop: -20,
    },
  });
  Font.register({
    family: "Inter",
    fonts: [
      {
        fontWeight: 400,
        src: "https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfMZhrib2Bg-4.ttf",
      },
      {
        fontWeight: 500,
        src: "https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuI6fMZhrib2Bg-4.ttf",
      },
      {
        fontWeight: 600,
        src: "https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuGKYMZhrib2Bg-4.ttf",
      },
      {
        fontWeight: 700,
        src: "https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuFuYMZhrib2Bg-4.ttf",
      },
      {
        fontWeight: 800,
        src: "https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuDyYMZhrib2Bg-4.ttf",
      },
      {
        fontWeight: 900,
        src: "https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuBWYMZhrib2Bg-4.ttf",
      },
    ],
  });
  const MyDocument = () => (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={{}}>
          <View style={styles.header}>
            <Image src={Logo} style={{ height: 33 }} />
            <Text style={{ fontWeight: 600, fontSize: 13 }}>
              STATEMENT OF ACCOUNT
            </Text>
          </View>
        </View>

        <View style={styles.body}>
          <VirtualAccountDetails userAccountDetails={accountDetails} />
        </View>
        <View style={{ position: "relative" }}>
          <View style={styles.test}>
            <Image src={BackgroundImage} style={styles.pageBackground} />
          </View>
          <View style={styles.body}>
            <TransactionList transactions={allTransfer} />
          </View>
        </View>
      </Page>
    </Document>
  );

  return <MyDocument />;
}
export default GenerateStatement;
