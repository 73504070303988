import { useState } from "react";
import {
  SaveForLaterLong,
  UploadDocument,
} from "../../../components/Buttons/KycButtons";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { getKyc, updateKyc } from "../../../api";
import { BeneficiaryDocument } from "../components/BeneficiaryDocument";
import Loader from "../../../components/PopUps/Loader";
import { BusinessDocument } from "../components/BusinessDocuments";
import { setStep, setBusinesskyc } from "../../../features/Kyc/kycSlice";
import { AWS_CREDS } from "../../../features/services/AmazonService";

interface ISteps {
  currentStep?: number;
  nextStep?: any;
  saveForLater?: any;
}

function UploadProgress(progress: any[]) {
  const IN_PROGRESS = progress.some((doc) => doc.status === "IN_PROGRESS");
  const FAILED = progress.some((doc) => doc.status === "FAILED");
  const CORRUPT = progress.some((doc) => doc.status === "CORRUPT");

  if (IN_PROGRESS) {
    return "IN_PROGRESS";
  } else if (FAILED || CORRUPT) {
    return "FAILED";
  } else {
    return "SUCCESSFUL";
  }
}

export function UploadDocuments(props: ISteps) {
  const { BusinessKyc } = useAppSelector((state) => state.kycInfo);
  const [loading, setLoader] = useState(false);
  const dispatch = useAppDispatch();

  const isDisabled =
    BusinessKyc?.BeneficialOwners?.every(
      (owner) => owner.Document?.data === undefined || null
    ) ||
    BusinessKyc?.BusinessDocuments?.every(
      (doc) => doc?.data === undefined || null
    );

  const handleSave = () => {
    props?.saveForLater();
    setTimeout(() => {
      window.history.back();
    }, 2000);

    setTimeout(() => {
      dispatch(setStep(1));
    }, 2000);
  };

  const handleSubmit = async () => {
    const KYCIdentifier: any = localStorage.getItem("KYCIdentifier");
    setLoader(true);
    if (AWS_CREDS.STAGE === "qa") {
      console.log("Final data:", BusinessKyc);
    }
    if (KYCIdentifier) {
      updateKyc(KYCIdentifier, { BusinessKyc: BusinessKyc })
        .then(() => {
          if (AWS_CREDS.STAGE === "qa") {
            console.log("check progress for: ", KYCIdentifier);
          }
          checkProgress(KYCIdentifier);
        })
        .catch(() => {
          setLoader(false);
        });
    } else {
      if (AWS_CREDS.STAGE === "qa") {
        console.log("Kyc Identifier missing....");
      }
    }
  };

  function checkProgress(KYCIdentifier: any) {
    let intervalCount = 0;
    const interval = setInterval(() => {
      getKyc(KYCIdentifier)
        .then((response: any) => {
          if (AWS_CREDS.STAGE === "qa") {
            console.log("check upload progress.....");
          }
          if (response?.data?.BusinessKyc?.AdditionalDetails?.UploadProgress) {
            const status = UploadProgress(
              response.data.BusinessKyc.AdditionalDetails.UploadProgress
            );
            if (AWS_CREDS.STAGE === "qa") {
              console.log("upload status : ", status);
            }
            if (status === "SUCCESSFUL" || status === "FAILED") {
              setLoader(false);
              clearInterval(interval);
              dispatch(setBusinesskyc(response.data.BusinessKyc));
              localStorage.setItem(
                "BusinessKyc",
                JSON.stringify(response.data.BusinessKyc)
              );
              if (props.currentStep) {
                props.nextStep(props.currentStep + 1);
              }
            }
          } else {
            if (AWS_CREDS.STAGE === "qa") {
              console.log("no server response:", response);
            }
          }
        })
        .catch((error: any) => {
          if (AWS_CREDS.STAGE === "qa") {
            console.error("Error occurred during API call:", error);
          }
        });
      intervalCount++;
      if (intervalCount >= 12) {
        setLoader(false);
        clearInterval(interval);
        if (AWS_CREDS.STAGE === "qa") {
          console.log("Interval ended after 60 seconds.");
        }
      }
    }, 5000);
  }

  // function handleDocStatus(docStatus: string, kycResponse: any) {
  //   if (docStatus in ["SUCCESSFUL", "FAILED"]) {
  //     localStorage.setItem(
  //       "BusinessKyc",
  //       JSON.stringify(kycResponse.data.BusinessKyc)
  //     );
  //     if (props.currentStep) {
  //       props.nextStep(props.currentStep + 1);
  //     }
  //     return true;
  //   }
  //   return false;
  // }

  return (
    <>
      <div className="md:w-[70%] h-[120vh] sm:w-[60%] ml-16">
        <div className="w-[60%]">
          <h3 className="font-semibold  text-[18px] py-5 ">
            Additional documents required
          </h3>
          <p className="text-[14px] text-[#747A80] mb-10 sm:mb-5 ">
            To complete your KYC verification, please submit a document for
            review.
          </p>

          <form className="mt-6 ">
            <h3 className="text-[14px] font-medium">Owner Document</h3>
            {BusinessKyc?.BeneficialOwners?.map((owner, index) => {
              return <BeneficiaryDocument key={index} index={index} />;
            })}

            <h3 className="text-[14px] font-medium">Business Document</h3>
            {BusinessKyc?.BusinessDocuments?.map((doc, index) => {
              return <BusinessDocument key={index} index={index} />;
            })}

            <div className="w-full">
              <UploadDocument
                action={handleSubmit}
                isButtonDisabled={isDisabled}
                loading={loading}
              />
              <SaveForLaterLong action={handleSave} />
            </div>
          </form>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />

      {loading && <Loader />}
    </>
  );
}

export default UploadDocuments;
