import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ITiers, IUser } from "../../types";
import { getTiers } from "./UserApi";

interface UserState {
  userData: IUser | null;
  displayReferModal: boolean;
  displayBalance: boolean;
  userTag: string;
  displayCreateTagModal: boolean;
  loading: boolean;
  error: string | null;
  userTier: ITiers;
  userhash: string;
  tnxLimit: number;
  dailyLimit: number;
  weeklyLimit: number;
  monthlyLimit: number;
  cardLimit: number;
}

const initialState: UserState = {
  displayReferModal: false,
  tnxLimit: 0,
  dailyLimit: 0,
  weeklyLimit: 0,
  monthlyLimit: 0,
  cardLimit: 0,
  userhash: "",
  userData: localStorage.getItem("storageUser")
    ? JSON.parse(localStorage.getItem("storageUser")!)
    : null,
  displayBalance: localStorage.getItem("displayBalance")
    ? JSON.parse(localStorage.getItem("displayBalance")!)
    : true,
  userTag: localStorage.getItem("userTag")
    ? JSON.parse(localStorage.getItem("userTag")!)
    : null,
  displayCreateTagModal: false,
  loading: false,
  error: null,
  userTier: {
    Tier: "Custom",
    Limits: {
      USD: {
        perTransactionLimitValue: 0,
        daily: {
          dailyVolume: 0,
          dailyLimitValue: 0,
        },
        weekly: {
          weeklyVolume: 0,
          weeklyLimitValue: 0,
        },
        monthly: {
          monthlyVolume: 0,
          monthlyLimitValue: 0,
        },
        cardFunding: {
          cardFundingDailyVolume: 0,
          cardFundingDailyLimitValue: 0,
        },
      },
    },
    TiersInfo: {},
  },
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateReferralModalStatus: (state, action) => {
      state.displayReferModal = action.payload;
    },
    changeBalanceDisplay: (state, action) => {
      state.displayBalance = action.payload;
      localStorage.setItem("displayBalance", action.payload);
    },
    setUser: (state, action: PayloadAction<UserState["userData"]>) => {
      state.userData = action.payload;
    },
    setUserHash: (state, action) => {
      state.userhash = action.payload;
    },
    setTnxLimit: (state, action) => {
      state.tnxLimit = action.payload;
    },
    setDailyLimit: (state, action) => {
      state.dailyLimit = action.payload;
    },
    setWeeklyLimit: (state, action) => {
      state.weeklyLimit = action.payload;
    },
    setMonthlyLimit: (state, action) => {
      state.monthlyLimit = action.payload;
    },
    setCardLimit: (state, action) => {
      state.cardLimit = action.payload;
    },
    clearUser: (state) => {
      state.userData = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTiers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getTiers.fulfilled, (state, action) => {
        state.loading = false;
        state.userTier = action.payload;
      })
      .addCase(getTiers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error as string;
      });
  },
});

export const {
  setUser,
  setUserHash,
  clearUser,
  updateReferralModalStatus,
  changeBalanceDisplay,
  setTnxLimit,
  setDailyLimit,
  setWeeklyLimit,
  setMonthlyLimit,
  setCardLimit,
} = userSlice.actions;

export default userSlice.reducer;
