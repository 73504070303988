/* eslint-disable react-hooks/rules-of-hooks */
import axios from "./features/services/AxiosInstance";
import { IUser } from "./types";
import { AWS_CREDS } from "./features/services/AmazonService";
import { Auth } from "aws-amplify";

export interface IPatchUserBody {
  ActionType: "PAYOUT_LIMIT";
}

export const removeAuthTokens = () => {
  localStorage.clear();
  sessionStorage.clear();
};

export const getUser = async (
  userId: string,
  idToken?: string
): Promise<IUser> => {
  let updatedReferralCode;
  const headers = idToken
    ? {
        Authorization: idToken,
      }
    : undefined;

  try {
    const result = await axios.get(
      `${AWS_CREDS.APIGATEWAY_URL}/users/${userId}`,
      { headers }
    );

    if (!result.data.ReferralCode) {
      const generateReferralCode = await axios.post(
        `${AWS_CREDS.APIGATEWAY_URL}/referral`,
        {},
        { headers }
      );
      updatedReferralCode = generateReferralCode.data.ReferralCode;
      result.data.ReferralCode = updatedReferralCode;
    }

    localStorage.setItem("storageUser", JSON.stringify(result.data));
    return result.data;
  } catch (error) {
    console.log(error);

    throw Error;
  }
};

export const PatchUser = async (
  userId: string,
  body: IPatchUserBody,
  idToken?: string
): Promise<IUser> => {
  const headers = idToken
    ? {
        Authorization: idToken,
      }
    : undefined;

  const result = await axios.patch(
    `${AWS_CREDS.APIGATEWAY_URL}/users/${userId}`,
    body,
    { headers }
  );
  return result.data;
};

export const getUserIdWithAccessToken = async () => {
  let userId = "";
  let kycId = "";
  const user: any = await Auth.currentAuthenticatedUser({ bypassCache: true });
  // await Auth.currentUserInfo()
  for (const key in user.attributes) {
    if (key === "custom:id") {
      userId = user.attributes[key];
    }

    if (key === "custom:kycIdentifier") {
      kycId = user.attributes[key];
      localStorage.setItem("KYCIdentifier", kycId);
    }
  }
  return userId;
};

export const getReturningUser = async () => {
  try {
    // const [session, userID] = await Promise.all([Auth.currentSession(), getUserIdWithAccessToken()])
    // const user = await Auth.currentAuthenticatedUser()

    const userID = await getUserIdWithAccessToken();
    // console.log(user, userID, '>>>>>>>>>> session, userID')
    const session = await Auth.currentSession();
    // if(userID){
    // }
    // console.log(session, userID, '>>>>>>>>>> session, userID')

    return getUser(userID, session.getIdToken().getJwtToken());
  } catch (err) {
    if (AWS_CREDS.STAGE === "qa") {
      console.log(`Failed to fetch current user: ${JSON.stringify(err)}`);
    }
    // throw errz
  }
};
