import { useEffect, useRef, useState } from "react";
import BackButton from "../../components/Buttons/BackButton";
import Select from "../../components/Layout/inputs/Select";
import GenerateStatement from "./components/AccountStatement";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../app/hooks";
import { setTitle } from "../../features/Nav/NavSlice";
import { fetchTransactions } from "../../features/Transanctions/transactionApi";
import Loader from "../../components/PopUps/Loader";
import { useNavigate } from "react-router-dom";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { pdf } from "@react-pdf/renderer";
import { RootState } from "../../app/store";
import SuccessModal from "../../components/PopUps/SuccessModal";
import { fetchAccount } from "../../features/VirtualAccount/VirtualAccountApi";

const AccountStatement = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const selectedYearValue = useRef<string>("");
  const [months, setMonths] = useState<string[]>([]);
  const [checkedState, setCheckedState] = useState<boolean[]>([]);
  const [selectedMonths, setSelectedMonths] = useState<string[]>([]);
  const [statementStatus, setStatementStatus] = useState(false);
  let { allTransfer } = useSelector((state: RootState) => state.transaction);
  const { allAccount } = useSelector(
    (state: RootState) => state.virtualAccount
  );
  const getCurrentYear = () => String(new Date().getFullYear());
  const navigate = useNavigate();
  const getValidMonthList = (year: string) => {
    const monthNumber =
      year === String(new Date().getFullYear())
        ? new Date().getMonth() + 1
        : 12;
    if (year === "2023") {
      let offMonth = 7;
      let monthDiff = monthNumber - offMonth;
      monthDiff = monthDiff > 5 ? (monthDiff = 5) : monthDiff;
      let months = Array.from({ length: monthDiff }, (val, index) => {
        return new Date(0, index + 1 + offMonth, 0).toLocaleDateString("en", {
          month: "long",
        });
      }).reverse();
      return months;
    }
    const months = Array.from({ length: monthNumber }, (val, index) => {
      return new Date(0, index + 1, 0).toLocaleDateString("en", {
        month: "long",
      });
    }).reverse();
    return months;
  };
  const getYearsOptionLabel = () => {
    const year = new Date().getFullYear();
    const minYear = 2023;
    return Array.from(new Array(year - minYear + 1), (val, index) => {
      return {
        label: `${year - index}`,
        value: `${year - index}`,
      };
    });
  };
  const handleYearChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const year = event.target.value;
    selectedYearValue.current = year;
    setMonths(getValidMonthList(year));
    setCheckedState(
      new Array<boolean>(getValidMonthList(year).length).fill(false)
    );
  };
  const handleMonthCheck = (index: number) => {
    const updatedCheckedState = checkedState.map((val, i) =>
      i === index ? !val : val
    );
    setCheckedState(updatedCheckedState);
    const pickedMonth: string[] = [];
    updatedCheckedState.forEach((state, i) => {
      if (state) {
        pickedMonth.push(months[i]);
      }
    });
    setSelectedMonths(pickedMonth);
  };
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    setLoading(true);
    event.preventDefault();
    const zip = new JSZip();
    if (selectedMonths.length > 1) {
      selectedMonths.forEach((month) => {
        zip.file(
          `cleva_statement_${month}_${selectedYearValue.current}.pdf`,
          pdf(
            <GenerateStatement
              transactions={allTransfer}
              user={allAccount!}
              month={month}
              year={selectedYearValue.current}
            />
          ).toBlob()
        );
      });
      const blob = await zip.generateAsync({ type: "blob" });
      saveAs(blob, "cleva-account-statements.zip");
    } else {
      const blob = await pdf(
        <GenerateStatement
          transactions={allTransfer}
          user={allAccount!}
          month={selectedMonths[0]}
          year={selectedYearValue.current}
        />
      ).toBlob();
      saveAs(
        blob,
        `cleva_statement_${selectedMonths[0]}_${selectedYearValue.current}.pdf`
      );
    }
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setLoading(false);
    setStatementStatus(true);
  };
  const handleSuccessModalClose = () => {
    setStatementStatus(false);
  };
  const setDefault = async () => {
    setCheckedState(new Array<boolean>(months?.length).fill(false));
    setMonths(getValidMonthList(getCurrentYear()));
    selectedYearValue.current = getCurrentYear();
  };
  useEffect(() => {
    if (!allTransfer.length) {
      dispatch(fetchTransactions());
    }
    dispatch(setTitle("Accounts"));
    if (!checkedState.length) {
      setDefault();
    }
    if (!allAccount) {
      dispatch(fetchAccount());
    }
  }, [checkedState, allAccount, allTransfer, months]);

  return (
    <>
      <div className="min-w-full  mt-auto ">
        <div className=" min-h-full  gap-4 items-end justify-center p-4 sm:items-center sm:p-0">
          <div className="flex py-8 items-center">
            <BackButton />
            <p className="text-lg font-bold ml-3">Account Statement</p>
          </div>
          <div className="flex justify-center w-full  items-center p-3 ">
            <div className="sm:w-[60%] md:w-[50%] xl:w-[50%]  rounded-lg ">
              <form
                onSubmit={handleSubmit}
                className=" [&>*:not(:last-child)]:mb-10 "
              >
                <div>
                  <Select
                    title={"Year"}
                    arr={getYearsOptionLabel()}
                    fn={handleYearChange}
                    err={""}
                    xtstyles={""}
                  />
                </div>
                <div className="flex">
                  <fieldset>
                    <div className="space-y-1">
                      {months.map((month, index) => (
                        <div key={month} className="relative flex items-start">
                          <div className="flex h-6 items-center">
                            <input
                              id={month}
                              aria-describedby={month}
                              name={month}
                              type="checkbox"
                              value={month}
                              checked={checkedState[index] || false}
                              onChange={() => handleMonthCheck(index)}
                              className="h-4 w-4 rounded border-gray-300 text-cleva-gold focus:ring-cleva-gold"
                            />
                          </div>
                          <div className="ml-3 text-sm leading-6">
                            <label htmlFor={month} className="text-black-soft">
                              {month}, {selectedYearValue?.current}
                            </label>
                          </div>
                        </div>
                      ))}
                    </div>
                  </fieldset>
                </div>
                <div className=" flex gap-28">
                  <button
                    onClick={() => {
                      navigate("/accounts");
                    }}
                    className={
                      " bg-cleva-gold w-[40%] text-black text-center px-6 bg-opacity-60 p-4 rounded-lg "
                    }
                  >
                    Back
                  </button>
                  <button
                    disabled={!selectedMonths.length}
                    className={
                      selectedMonths.length
                        ? "login-active w-[50%] items-center"
                        : "login-disabled items-center w-[50%]"
                    }
                  >
                    Get Statement
                  </button>
                </div>
              </form>
            </div>
          </div>
          {loading && <Loader text="Generating Statement..." />}
          {statementStatus && (
            <SuccessModal
              onClose={handleSuccessModalClose}
              secText={"Your account statement has been generated successfully"}
              text={"Account Statement Generated!"}
              btnText={"Okay"}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default AccountStatement;
