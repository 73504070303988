import React, { useEffect } from "react";
import tipImage from "../../../asset/dashboard/tipImg.svg";
import { useAppDispatch } from "../../../app/hooks";
import { setTitle } from "../../../features/Nav/NavSlice";
import BackButton from "../../../components/Buttons/BackButton";

const Tips = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setTitle("Settings"));
  }, []);
  return (
    <>
      <div className="flex py-8 items-center">
        <BackButton />
        <p className="text-lg font-bold ml-3">Tips for your account</p>
      </div>

      <main className="md:w-4/5 xl:w-3/4 md:px-4 xl:px-12 mx-auto">
        <section className="flex justify-center items-center pt-6 md:pt-6 mx-auto">
          <div className="text-[#111111] bg-white  p-4 md:px-10 md:pt-4 md:pb-8 rounded-xl">
            <div className="flex justify-between items-center">
              <div className="md:w-[55%] w-[80%] 2xl:w-[50%]">
                <p className="md:text-2xl text-xl font-bold">
                  How to Maintain a Healthy Cleva Account
                </p>
              </div>

              <img
                src={tipImage}
                className="w-24 h-24 md:w-40 md:h-40"
                alt=""
              />
            </div>

            <p>
              <ul className="space-y-4 list-decimal list-inside">
                <li className="font-bold">
                  <span className="">Link your own external accounts</span>
                  <p className="font-normal">
                    If you want to link your Cleva account to an external
                    account (e.g. PayPal, Upwork), ensure the external account
                    carries your real name and identity - the same name on your
                    Cleva account and on your BVN.
                  </p>
                </li>
                <li className="font-bold">
                  <span className="">Receive funds meant for you</span>
                  <p className="font-normal">
                    Ensure the funds being transferred to your Cleva account are
                    for you only (do not receive funds on behalf of others).
                  </p>
                </li>
                <li className="font-bold">
                  <span className="">Do not purchase accounts</span>
                  <p className="font-normal">
                    Do not purchase Cleva accounts from others, this is
                    completely unacceptable and against our terms of use.
                    Financial losses due to this are at your own risk.
                  </p>
                </li>
                <li className="font-bold">
                  <span className="">Avoid exchanging funds</span>
                  <p className="font-normal">
                    Do not use Cleva as a means to "exchange" funds on behalf of
                    other people, we do not support this.
                  </p>
                </li>

                <li className="font-bold">
                  <span className="">Use a strong password and PIN</span>
                  <p className="font-normal">
                    Ensure you have a strong password and PIN in order to
                    protect your account.
                  </p>
                </li>

                <li className="font-bold">
                  <span className="">No fraudulent transactions</span>
                  <p className="font-normal">
                    Ensure that no fraudulent or questionable funds are
                    transferred into your Cleva account. This is completely
                    unacceptable and will result in major consequences.
                  </p>
                </li>
              </ul>
            </p>
          </div>
        </section>
      </main>
    </>
  );
};

export default Tips;
