import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setTitle } from "../../features/Nav/NavSlice";
import { IoCloseOutline } from "react-icons/io5";
import freeze from "../../asset/cards/freeze.svg";
import rightArr from "../../asset/cards/rightArr.svg";
import { Link, useNavigate } from "react-router-dom";
import CardModal from "./CardModal";
import {
  fetchCard,
  fetchCardById,
  freezeCard,
} from "../../features/ClevaCards/ClevaCardsApi";
import { setCardIdentifier } from "../../features/ClevaCards/ClevaCardsSlice";

import Spinner from "../../components/PopUps/Spinner";
import { toast } from "react-toastify";
import PinLoader from "../Security/modals/PinLoader";

const CardSetting = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [frozenCard, setfrozenCard] = useState(false);
  const [freezeModal, setFreezeModal] = useState(false);
  // const [unfreezeModal, setUnfreezeModal] = useState(false);
  const [deleteModal, setdeleteModal] = useState(false);
  const [cardFetched, setCardFetched] = useState(false);
  const [cardData, setCardData] = useState(false);
  // const [isLoading, setisLoading] = useState(false);
  const [freezingCard, setFreezingCard] = useState(false);

  const [alert, setAlert] = useState(false);

  const cards = useAppSelector((state) => state.clevaCards.cards);
  const card = useAppSelector((state) => state.clevaCards.card);

  const cardIdentifier = useAppSelector(
    (state) => state.clevaCards.cardIdentifier
  );

  //card pin and otp
  // const [openTransactionPin, setTransactionPin] = useState(false);

  const handleFreezeModal = () => {
    if (card) {
      if (card.Status === "FROZEN") {
        navigate("/cards");
      } else {
        setFreezeModal(true);
      }
      setAlert(false);
    } else {
      setAlert(true); // Alert is set to true when card doesn't exist
    }
  };

  const handleFreezeCard = async () => {
    try {
      setFreezeModal(false);
      setFreezingCard(true);
      const response = await dispatch(
        freezeCard({
          card: { RequestType: "FREEZE" },
          CardIdentifier: cardIdentifier,
        })
      );
      if (response?.payload) {
        setFreezingCard(false);
        setfrozenCard(true);
        navigate("/cards");
      } else {
        setFreezingCard(false);
        toast.error("Please try again later");
      }
    } catch (error: any) {
      setFreezingCard(false);
      toast.error(error.message);
    }
  };

  // const handleUnFreezeCard = () => {
  //     setisLoading(true);
  //     try {
  //         setUnfreezeModal(false);
  //         setTransactionPin(true);
  //     } catch (error: any) {
  //         setisLoading(false);
  //     }
  // };

  // const handleDeleteModal = () => {
  //     setdeleteModal(true)
  // }

  const handleDeleteCard = () => {
    navigate("/cards");
    localStorage.setItem("deleteCard", "true");
  };

  useEffect(() => {
    // Check if cards and CardIdentifier are available

    if (cards && (cards as any)?.length > 0) {
      const activeCard = (cards as any).find((card: any) =>
        ["ACTIVE", "PENDING", "FROZEN"].includes(card.Status)
      );
      if (activeCard) {
        if (activeCard.Status === "FROZEN") {
          setfrozenCard(true);
        } else {
          setfrozenCard(false);
        }
        const { CardIdentifier } = activeCard;
        dispatch(setCardIdentifier(CardIdentifier));
        dispatch(fetchCardById(CardIdentifier))
          .then(() => setCardData(true))
          .catch(() => setCardData(false));
      }
    }
  }, [dispatch, cardIdentifier, cards, navigate]);

  useEffect(() => {
    dispatch(setTitle("Settings"));
    // setAlert(false)
    dispatch(fetchCard())
      .then(() => setCardFetched(true))
      .catch(() => setCardFetched(false));
  }, [dispatch]);

  if (!cardData && !cardFetched) {
    return <Spinner />;
  }
  return (
    <>
      <div className="relative flex-1">
        <div className="pt-3">
          {/* <GoBackComponent text="Card Settings" /> */}

          <div className="bg-[#FFF] shadow-sm rounded-2xl py-[2.28125rem] px-4 md:px-[3.8125rem]">
            {/* <section onClick={() => navigate('/cards/low-balance')} className='cursor-pointer flex justify-between items-center border-b border-b-[#DADADA] pb-[1.88rem]'>
                            <div className='flex items-center justify-center gap-4 '>
                                <img src={lowBal} alt="" />
                                <div className="">
                                    <h1 className='text-[#1D1D1D] text-sm font-medium'>Low balance alert</h1>
                                    <p className="text-[#898989] text-[0.8125rem] leading-4 font-normal">Set reminder when your card balance reaches a set amount to avoid deactivation.</p>
                                </div>
                            </div>
                            <div>
                                <img src={rightArr} alt="" />
                            </div>
                        </section> */}
            <section
              onClick={handleFreezeModal}
              className="cursor-pointer flex justify-between items-center border-b border-b-[#DADADA] py-[1.88rem]"
            >
              <div className="flex items-center justify-center gap-4 ">
                <img src={freeze} alt="" />
                <div className="">
                  <h1 className="text-[#1D1D1D] text-sm font-medium">
                    {frozenCard ? "Unfreeze Card" : "Freeze Card"}{" "}
                  </h1>
                  {!frozenCard && (
                    <p className="text-[#898989] text-[0.8125rem] leading-4 font-normal">
                      Freezing your card will result in all attempted
                      transaction being declined
                    </p>
                  )}
                  {alert && (
                    <Link
                      to="/cards"
                      className="underline text-xs pt-2 text-[#D31D1D]"
                    >
                      Create a virtual USD Card
                    </Link>
                  )}
                </div>
              </div>
              <div>
                <img src={rightArr} alt="" />
              </div>
            </section>
            {/* <section onClick={handleDeleteModal} className='cursor-pointer flex justify-between items-center  py-[1.88rem]'>
                            <div className='flex items-center justify-center gap-4 '>
                                <img src={delItem} alt="" />
                                <div className="">
                                    <h1 className='text-[#1D1D1D] text-sm font-medium'>Delete Card</h1>
                                    <p className="text-[#898989] text-[0.8125rem] leading-4 font-normal">Delete your card permanently and return funds to your wallet.</p>
                                </div>
                            </div>
                            <div>
                                <img src={rightArr} alt="" />
                            </div>
                        </section> */}
          </div>
        </div>
      </div>

      {deleteModal && (
        <CardModal width="md:w-[33rem]" shadow="rounded-[1.25rem]">
          <div className="">
            <div className="rounded-t-[1.25rem] bg-[#EBF0F0] flex items-center justify-between px-[1.8rem] py-2">
              <h1 className="text-black font-medium text-base">Delete Modal</h1>
              <IoCloseOutline
                onClick={() => setdeleteModal(false)}
                className="text-xl cursor-pointer"
              />
            </div>
            <div className="px-[1.8rem] py-[2.6rem]">
              <div className="h-[50vh] lg:h-auto overflow-scroll">
                <h2 className="text-[#232323] font-semibold text-xl pb-[2.6rem]">
                  Are you sure you want to delete this virtual USD card?
                </h2>
                <div className="text-[#767676] text-sm leading-[1.375rem] font-normal">
                  <p>
                    Deleting this card will initiate a "cooling down" period to
                    ensure all pending transactions are processed correctly.
                    During this period:
                  </p>
                  <ul className="list-disc pb-[2.6rem] px-[2rem]">
                    <li>
                      The card will be disabled and frozen and new payments will
                      not be authorized.
                    </li>
                    <li>
                      If there are pending payments, we’ll finalize these
                      payments before deleting the card. Any remaining funds on
                      the card will be transferred to your Cleva USD account.
                    </li>
                    <li>
                      If there are no pending payments, the card deletion will
                      be completed after the cooling down period. Any remaining
                      funds will be transferred to your Cleva USD account.
                    </li>
                  </ul>

                  <p>
                    Please review this information carefully before proceeding.
                  </p>
                </div>
              </div>
              <div className="flex justify-between items-center pt-[3.04rem]">
                <button
                  className="bg-[#F4C952] py-[0.63rem] px-8 md:px-[2.87rem] rounded-lg hover:brightness-90 text-black font-bold  text-sm"
                  onClick={() => setdeleteModal(false)}
                >
                  Cancel
                </button>
                <button
                  className="bg-[#FFBD59] py-[0.63rem] px-8 md:px-[2.87rem] rounded-lg hover:brightness-90 text-black font-bold  text-sm"
                  onClick={handleDeleteCard}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </CardModal>
      )}
      {freezeModal && (
        <CardModal width="md:w-[33rem]" shadow="rounded-[1.25rem]">
          <div className="">
            <div className="rounded-t-[1.25rem] bg-[#EBF0F0] flex items-center justify-between px-[1.8rem] py-4">
              <h1 className="text-black font-medium text-base">Freeze Card</h1>
              <IoCloseOutline
                onClick={() => setFreezeModal(false)}
                className="text-xl cursor-pointer"
              />
            </div>
            <div className="px-[1.8rem] py-4">
              <div className="">
                <p className="text-[#404040] text-base font-normal">
                  Freezing your card will automatically decline all future
                  transactions made on the card.
                </p>
                <p className="text-[#232323] text-base font-normal pt-8">
                  Are you sure you want to continue?
                </p>
              </div>
              <div className="flex justify-between items-center pt-[3.04rem]">
                <button
                  className="bg-[#F4C952] py-[0.63rem] px-8 md:px-[2.87rem] rounded-lg hover:brightness-90 text-black font-bold  text-sm"
                  onClick={() => setFreezeModal(false)}
                >
                  Cancel
                </button>
                <button
                  className="bg-[#FFBD59] py-[0.63rem] px-8 md:px-[2.87rem] rounded-lg hover:brightness-90 text-black font-bold  text-sm"
                  onClick={handleFreezeCard}
                >
                  Freeze
                </button>
              </div>
            </div>
          </div>
        </CardModal>
      )}

      {freezingCard && <PinLoader text="Freezing Card..." />}
    </>
  );
};

export default CardSetting;
