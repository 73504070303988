import React, { useState, useEffect } from "react";
import TabButtons from "../../components/Tabs/TabButton";
import TabContent from "../../components/Tabs/TabContent";
import Table from "../../components/Table/CardDataTable";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../app/store";
import { ToastContainer } from "react-toastify";
import Spinner from "../../components/PopUps/Spinner";
import { setTitle } from "../../features/Nav/NavSlice";
import ViewCardTransfer from "../Transfers/M2/modals/ViewCardTransfer";
import GoBackComponent from "./utils/GoBackComponent";
import { CardColumn } from "../../components/Table/CardColumn";
import {
  fetchCard,
  fetchCardTransactions,
} from "../../features/ClevaCards/ClevaCardsApi";
import { setCardIdentifier } from "../../features/ClevaCards/ClevaCardsSlice";
import { CardIncomingColumn } from "../../components/Table/CardIncomingColumn";

const CardTransactions = () => {
  // const loading = useSelector((state: RootState) => state.clevaCards.loading);
  const [activeTab, setActiveTab] = useState<string>("all");
  const [modal, setModal] = useState(false);
  const [selectedTransfer, setSelectedTransfer] = useState<any>(null);
  const dispatch = useDispatch<AppDispatch>();
  const cards = useSelector((state: RootState) => state.clevaCards.cards);
  const cardIdentifier = useSelector(
    (state: RootState) => state.clevaCards.cardIdentifier
  );
  const allTransfer = useSelector(
    (state: RootState) => state.clevaCards.cardTransaction
  );
  const filteredTransfer = (allTransfer as any)?.filter(
    (transfer: any) => transfer?.NetAmount != 0
  );

  function CardModal(transferId: any) {
    const transfer = (filteredTransfer as any)?.find(
      (transfer: any) => transfer?.TransactionID === transferId.TransactionID
    );
    setSelectedTransfer(transfer);
    setModal(!modal);
  }

  const closeModal = () => {
    setSelectedTransfer(null);
    setModal(false);
  };

  const OutgoingTrans = (filteredTransfer as any)?.filter((transfer: any) => {
    return transfer.Entry === "DEBIT";
  });
  const IncomingTrans = (filteredTransfer as any)?.filter((transfer: any) => {
    return transfer.Entry === "CREDIT";
  });

  useEffect(() => {
    dispatch(setTitle("Card"));
    dispatch(fetchCard());
  }, [dispatch]);

  useEffect(() => {
    // Check if cards and CardIdentifier are available

    if (cards && (cards as any)?.length > 0) {
      const activeCard = (cards as any).find((card: any) =>
        ["ACTIVE", "PENDING", "FROZEN"].includes(card.Status)
      );
      if (activeCard) {
        const { CardIdentifier } = activeCard;
        dispatch(setCardIdentifier(CardIdentifier));
        dispatch(fetchCardTransactions(CardIdentifier));
      }
    }
  }, [dispatch, cardIdentifier, cards]);

  if (!cards) {
    return <Spinner />;
  }

  return (
    <>
      <div className="w-full md:pt-8 pt-4 mb-4 md:mb-0 md:w-[40%] order-2 md:order-1">
        <GoBackComponent text="Card Transactions" />
        {/* tab section  */}
        <ul className="grid grid-cols-2 gap-4 bg-[#EBF0F0] rounded-[10px] px-2 py-1.5">
          <TabButtons
            name="Outgoing"
            id="all"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
          <TabButtons
            name="Incoming"
            id="failedTab"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </ul>
      </div>
      <div className="mt-4">
        <TabContent id="all" activeTab={activeTab}>
          {/* {loading ? (
            <Spinner /> // Show the spinner when loading is true
          ) : ( */}
          <Table
            data={OutgoingTrans}
            TableColumns={CardColumn}
            title="Outgoing Transactions"
            searchPlaceholder="Search transactions"
            onClickTable={CardModal}
          />
          {/* )} */}
        </TabContent>
        {/* <TabContent id="successfulTab" activeTab={activeTab}>
          {loading ? (
            <Spinner /> // Show the spinner when loading is true
          ) : (
            <Table
              data={OutgoingTrans}
              TableColumns={CardColumn}
              title="Outgoing Transactions"
              searchPlaceholder="Search transactions"
              onClickTable={CardModal}
            />
          )}
        </TabContent> */}
        <TabContent id="failedTab" activeTab={activeTab}>
          {/* {loading ? (
            <Spinner /> // Show the spinner when loading is true
          ) : ( */}
          <Table
            data={IncomingTrans}
            TableColumns={CardIncomingColumn}
            title="Incoming Transactions"
            searchPlaceholder="Search transactions"
            onClickTable={CardModal}
          />
          {/* )} */}
        </TabContent>
      </div>
      {modal && (
        <ViewCardTransfer props={selectedTransfer} closeModal={closeModal} />
      )}
      <ToastContainer />
    </>
  );
};

export default CardTransactions;
