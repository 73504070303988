import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import transactionReducer from "../features/Transanctions/TransanctionSlice";
import kycSlice from "../features/Kyc/kycSlice";
import userReducer from "../features/User/UserSlice";
import NavSlice from "../features/Nav/NavSlice";
import { setupListeners } from "@reduxjs/toolkit/query";
import RecipientSlice from "../features/Recipients/RecipientSlice";
import VirtualAccountSlice from "../features/VirtualAccount/VirtualAccountSlice";
import ReferralSlice from "../features/Referrals/ReferralSlice";
import ClevaCardsSlice from "../features/ClevaCards/ClevaCardsSlice";
import TagsSlice from "../features/Tags/TagsSlice";
import AnniversarySlice, {
  localStorageMiddleware,
} from "../features/Anniversary/AnniversarySlice";
import DealsSlice from "../features/Deals/DealsSlice";

export const store = configureStore({
  reducer: {
    transaction: transactionReducer,
    kycInfo: kycSlice,
    user: userReducer,
    navbar: NavSlice,
    recipient: RecipientSlice,
    virtualAccount: VirtualAccountSlice,
    referral: ReferralSlice,
    clevaCards: ClevaCardsSlice,
    tags: TagsSlice,
    anniversary: AnniversarySlice,
    deals: DealsSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(localStorageMiddleware),
});

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
