import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../app/hooks";
import { setTitle } from "../../features/Nav/NavSlice";
import ModalTemplate from "../../components/PopUps/ModalTemplate";
import { IoCloseOutline } from "react-icons/io5";
import Img from "../../asset/deals.jpg";
import GoBackComponent from "../ClevaCards/utils/GoBackComponent";
import Spinner from "../../components/PopUps/smallSpinner";
import { updateDeal } from "../../features/services/DashboardServices";
import { DealsData, setSelectedDeal } from "../../features/Deals/DealsSlice";
import { getDeals } from "../../features/Deals/DealsApi";

const DealDetails = () => {
  const [loader, setLoader] = useState(false);
  const dispatch = useAppDispatch();
  const [redeem, setRedeem] = useState(false);
  const selectedDeal = useSelector(
    (state: RootState) => state.deals.selectedDeal
  );

  const redeemDiscount = async (deal: DealsData) => {
    setLoader(true);
    try {
      const response = await updateDeal(deal.DealID, deal.Code);
      if (response.status === 200) {
        dispatch(setSelectedDeal(response.data));
        window.open(deal.Link);
      }
      setLoader(false);
      setRedeem(false);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  useEffect(() => {
    dispatch(setTitle("Deals"));
    dispatch(getDeals());
  }, [dispatch]);

  return (
    <>
      <div>
        <div className="pt-6">
          <GoBackComponent textStyle="text-lg font-bold" text="Deals details" />
        </div>

        {!selectedDeal.DealID || loader ? (
          <Spinner />
        ) : (
          <div className="bg-[#FFFFFF] mt-7 md:w-[500px] mx-auto rounded-md">
            <div className="">
              <img
                className="rounded h-[200px] w-full object-cover"
                src={selectedDeal.Image}
                alt="Deals details"
              />
              <div className="px-8 pt-4 pb-8">
                <h1 className="font-bold text-lg ">{selectedDeal.Title}</h1>
                <p className="font-base text-base py-4">
                  {selectedDeal.Description}
                </p>

                <h2 className="font-bold text-lg pb-3">How to redeem</h2>
                <ul className="font-base text-base list-decimal ml-7">
                  {selectedDeal.Guide.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>

                <div className="flex items-center justify-center mt-5">
                  {selectedDeal.Redeemed == true ||
                  selectedDeal.RedeemedDate ? (
                    <button
                      className="bg-cleva-gold py-4 rounded-md w-[60%] text-black text-sm font-bold"
                      onClick={() => window.open(selectedDeal.Link)}
                    >
                      View Details
                    </button>
                  ) : (
                    <button
                      className="bg-cleva-gold py-4 rounded-md w-[60%] text-black text-sm font-bold"
                      onClick={() => setRedeem(true)}
                    >
                      Redeem now
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <ModalTemplate childStyle="md:w-[400px]" modalState={redeem}>
        <div>
          <header className="bg-[#EBF0F0] px-8 py-4 rounded-lg flex justify-between items-center">
            <p>Redeem Discount</p>
            <IoCloseOutline
              onClick={() => setRedeem(false)}
              className="cursor-pointer"
            />
          </header>
          <div className="flex flex-col justify-center w-[90%] mx-auto items-center py-5">
            <div>
              <img src={Img} alt="Redeem Discount" />
            </div>
            <p className="text-lg text-center font-semibold text-black">
              Are you sure you want to redeem this discount?
            </p>
            <div className="flex justify-center gap-4 w-full mt-5">
              <button
                onClick={() => setRedeem(false)}
                className="bg-[#ffecd1] w-2/4 text-black px-10 py-2 rounded-md"
              >
                No
              </button>
              <button
                onClick={() => redeemDiscount(selectedDeal)}
                className="bg-cleva-gold w-2/4 text-black px-10 py-2 rounded-md"
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </ModalTemplate>
    </>
  );
};

export default DealDetails;
