import { useEffect, useState } from "react";
import { setTitle } from "../../features/Nav/NavSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getDeals } from "../../features/Deals/DealsApi";
import Spinner from "../../components/PopUps/Spinner";
import { DealsData, setSelectedDeal } from "../../features/Deals/DealsSlice";
import { MdCalendarMonth } from "react-icons/md";
import { formatDate } from "../../utils/FormatDateString";
import { useNavigate } from "react-router-dom";
import Img from "../../asset/deals.jpg";
import { KycStatus } from "../Kyc/components/KycStatus";
import parse from "html-react-parser";

const Deals = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("Active");
  const [currData, setCurrData] = useState([]);
  const { loading, error, data } = useAppSelector((state: any) => state.deals);
  const { KYCState } = useAppSelector((state) => state.kycInfo);

  const showRedeemed = () => {
    setCurrData(data?.filter((item: DealsData) => item.Redeemed === true));
    setActiveTab("Redeemed");
  };

  const showActive = () => {
    setCurrData(data?.filter((item: DealsData) => item.Active === true));
    setActiveTab("Active");
  };

  useEffect(() => {
    if (activeTab === "Active") {
      setCurrData(data?.filter((item: DealsData) => item.Active === true));
    } else {
      setCurrData(data?.filter((item: DealsData) => item.Redeemed === true));
    }
  }, [data, activeTab]);

  useEffect(() => {
    dispatch(setTitle("Deals"));
    dispatch(getDeals());
  }, [dispatch]);

  if (KYCState !== "VERIFIED" && KYCState !== "BUSINESS_VERIFIED") {
    return (
      <>
        <KycStatus />
        <DefaultView />
      </>
    );
  }

  const handleNavigation = (item: any) => {
    dispatch(setSelectedDeal(item));
    navigate("/deals/details");
  };

  return (
    <>
      <div className="pt-8">
        <h1 className="text-lg text-black font-semibold">
          Exclusive deals for you
        </h1>
        <p className="text-sm text-[#6F6F6F] pt-2 ">
          Generous offers and deals from our partner brands, curated with you in
          mind.
        </p>

        <div className="py-8 ">
          <div className="bg-[#EBF0F0] rounded text-black flex items-center justify-between min-[375px]:w-[20rem] p-2 ">
            <button
              onClick={() => showActive()}
              className={`${
                activeTab === "Active"
                  ? "bg-black text-white"
                  : "bg-transparent text-black"
              } px-4 py-2 w-2/4  rounded`}
            >
              Active
            </button>
            <button
              onClick={() => showRedeemed()}
              className={`${
                activeTab === "Redeemed"
                  ? "bg-black text-white"
                  : "bg-transparent text-black"
              } px-4 py-2 w-2/4 rounded`}
            >
              Redeemed
            </button>
          </div>
        </div>

        {loading ? (
          <Spinner />
        ) : error || !data ? (
          <>
            <p>An error occured. Please try again.</p>
          </>
        ) : (
          <div>
            {currData && currData.length > 0 ? (
              <div
                className={`grid md:grid-cols-2 min-[1400px]:grid-cols-3 gap-4 flex-wrap`}
              >
                {currData.map((item: DealsData) => (
                  <div
                    className="bg-[#FFFFFF] border rounded border-[#E8E8E8] flex flex-col justify-between flex-grow"
                    key={item.DealID}
                  >
                    <div>
                      <img
                        className={`rounded h-[200px] w-full object-cover ${
                          item.Redeemed && "opacity-60"
                        }`}
                        src={item.Image}
                        alt={item.Name}
                      />
                    </div>
                    <div className="p-4">
                      <h3 className="text-lg font-semibold">{item.Name}</h3>
                      <p className="mt-3 text-[#525252] text-sm min-h-[50px]">
                        {parse(item.SubTitle)}
                      </p>

                      <div className="flex items-center justify-between mt-4">
                        <p className="text-[#383838] text-sm flex items-center gap-1">
                          <span>
                            <MdCalendarMonth className="inline text-2xl" />
                          </span>
                          <span className="mt-1">
                            {item.Redeemed === true
                              ? "Redeemed on"
                              : "Valid until"}
                          </span>
                          <span className="mt-1">
                            {item.Redeemed === true
                              ? formatDate(item.RedeemedDate)
                              : formatDate(item.ExpiryDate)}
                          </span>
                        </p>
                        <button
                          onClick={() => handleNavigation(item)}
                          className="bg-cleva-gold px-4 py-2 rounded-lg text-sm"
                        >
                          View
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="flex items-center justify-center h-[80vh] lg:h-[60vh]">
                <div className="md:w-[500px] mx-auto bg-[#FFFFFF] border rounded-md shadow-md border-[#dddddd] flex flex-col items-center justify-center py-7">
                  <img src={Img} alt="complete Kyc" />
                  <p className="text-black text-xl font-semibold w-[70%] text-center">
                    {activeTab === "Active"
                      ? "No deals available"
                      : "You haven't redeemed any deals yet"}
                  </p>
                  <p className="text-black text-base font-medium w-[70%] text-center">
                    {activeTab === "Active"
                      ? "No deals available at this time, Please check back later."
                      : "Check out our deals and start redeeming."}
                  </p>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

const DefaultView = () => {
  return (
    <div className="flex items-center justify-center h-[80vh] lg:h-[60vh]">
      <div className="w-[500px] bg-[#FFFFFF] border rounded-md shadow-md border-[#dddddd] flex flex-col items-center justify-center py-7">
        <img src={Img} alt=" complete Kyc" />
        <p className="text-black text-xl font-semibold w-[70%] text-center">
          Complete your KYC to unlock deals
        </p>
        <p className="text-black text-base font-medium w-[70%] text-center">
          To redeem our exclusive deals, please complete your KYC verification.{" "}
        </p>
      </div>
    </div>
  );
};

export default Deals;
