import React from "react";
import { Link } from "react-router-dom";
import progressIcon from "../../../images/progress-check.svg";

const KycPending = () => {
  return (
    <>
      <div className="flex justify-center items-center h-[85vh]">
        <div className="md:w-2/3 mx-auto text-center md:py-12 p-4  rounded-lg bg-gray-50 shadow">
          <div className="flex justify-center">
            <img src={progressIcon} alt="progress" className="w-16 h-16" />
          </div>
          <div>
            <p className="text-base font-medium mt-8 mb-4 text-[#138D24]">
              KYC Verification Pending!
            </p>
            <p className="text-[#747A80] mb-8 ">
              Thank you for submitting your information.
            </p>

            <p className="text-[#747A80] font-bold lg:w-2/3 mx-auto">
              We'll send you a notification within 1-2 days with the status of
              your verification.
            </p>

            <div className="mt-12 mb-12">
              <Link
                to="/"
                className="bg-cleva-gold py-4 px-12 rounded-lg font-medium"
              >
                View Dashboard
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default KycPending;
