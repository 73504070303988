import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import errorImg from "../../../asset/svg/material-symbols_warning.svg";
import OtpField from "react-otp-field";

import { XMarkIcon } from "@heroicons/react/24/outline";
import { Auth } from "aws-amplify";
import { getUser, getUserIdWithAccessToken } from "../../../login";
import { ValidatePin } from "../../../api";
import { setUser } from "../../../features/User/UserSlice";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { Link, useNavigate } from "react-router-dom";
interface ModalProps {
  closeModal: () => void;
  openForgotPinModal: () => void;
  openVerifyOTPModal?: () => void;
  VerifyTransaction: () => Promise<void>;
  sendOTP: () => void;
}
const ValidateUserPin: React.FC<ModalProps> = ({
  closeModal,
  openForgotPinModal,
  VerifyTransaction,
  sendOTP,
}) => {
  const [loading, setLoading] = useState(false);
  const [pin, setPin] = useState("");
  const [invalid, setInvalid] = useState(false);
  const navigate = useNavigate();
  const cancelButtonRef = useRef(null);
  const AppDispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.userData);

  const handleSubmit = async (event: any) => {
    setLoading(true);
    event.preventDefault();
    const userID = await getUserIdWithAccessToken();
    const session = await Auth.currentSession();

    try {
      const response = await ValidatePin(userID, pin);
      if (response?.data?.Token) {
        localStorage.setItem("OTPToken", response.data.Token);

        await VerifyTransaction();
        localStorage.removeItem("OTPToken");
      }
    } catch (err: any) {
      setLoading(false);

      if (err?.response?.status === 403) {
        setInvalid(true);

        const userDetails = await getUser(
          userID,
          session.getIdToken().getJwtToken()
        );
        AppDispatch(setUser(userDetails));

        if (!userDetails?.PinRetriesLeft) {
          sendOTP();
        }
      }
    }
  };

  const handleForgotPin = async (event: any) => {
    event.preventDefault();
    openForgotPinModal();
  };

  useEffect(() => {
    if (user) {
      if (!user?.PinResetDisabled && !user?.PinRetriesLeft) {
        navigate("/create-pin");
      } else if (!user?.PinRetriesLeft) {
        sendOTP();
      }
    }
  }, []);

  return (
    <Transition.Root show={true} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={closeModal}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white pb-8 text-left shadow-xl transition-all sm:my-8 sm:w-full md:max-w-lg  w-[30rem]">
                <header
                  className={`w-full text-left bg-[#EFEFEF] py-4 px-8  flex justify-between`}
                >
                  <Dialog.Title
                    as="h3"
                    className="text-base font-bold leading-6 text-gray-900"
                  >
                    Transaction PIN
                  </Dialog.Title>
                  <button
                    type="button"
                    className="rounded-md text-black hover:text-gray-500 focus:outline-none focus:ring-2 font-bold"
                    onClick={() => closeModal()}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-4 w-4" aria-hidden="true" />
                  </button>
                </header>

                <div className="flex justify-center  w-full items-center p-3 ">
                  <div className="p-2 sm:w-[80%] md:w-[80%] pb-14 sm:pb-8 rounded-lg ">
                    <form>
                      <center className="mb-10 p-2">
                        {invalid && (
                          <div
                            className="w-full px-5 flex font-[500]
                      rounded-lg
                      justify-center text-sm items-center h-[3.75rem] bg-[#FFEAED] "
                          >
                            <img src={errorImg} className="" alt="error" />
                            <span className="text-[#000] pl-2  font-normal">
                              PIN not valid. &nbsp;
                              <span className="text-[#027429] font-semibold">
                                {user?.PinRetriesLeft || 0} retries left
                              </span>
                            </span>
                          </div>
                        )}

                        <p className="py-5 mb-4 mt-4 text-[11px] md:text-[13px] text-[#444444] font-medium">
                          Enter your 4-digit PIN to complete this transaction
                        </p>

                        <div className="w-[20rem] mx-auto mb-5 justify-center">
                          {/* <PinInput hidden={true} pin={pin} setPin={setPin} pinLength={4} error={false}/> */}

                          <OtpField
                            data-test="otp-field"
                            value={pin}
                            onChange={setPin}
                            numInputs={4}
                            autoFocus
                            autoComplete="new-password"
                            onChangeRegex={/^([0-9]{0,})$/}
                            separator={<span> </span>}
                            isTypeNumber={false}
                            inputProps={{
                              className: "otp-field__input",
                              disabled: false,
                              type: "password",
                              autoComplete: "new-password",
                              maxLength: 1,
                              inputMode: "numeric",
                            }}
                          />
                        </div>

                        <Link
                          onClick={(e) => handleForgotPin(e)}
                          to=""
                          className="text-[#606060] text-sm underline underline-offset-2"
                        >
                          {" "}
                          Forgot PIN?
                        </Link>
                      </center>
                      <button
                        data-test="submit-pin"
                        onClick={(e) => handleSubmit(e)}
                        disabled={loading || pin.length < 4}
                        className={
                          loading || pin.length < 4
                            ? " login-disabled"
                            : "login-active"
                        }
                      >
                        {loading ? "Loading ..." : "Submit"}
                      </button>
                    </form>
                  </div>
                </div>

                <footer></footer>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ValidateUserPin;
