import React, { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import UsIcon from "../../../../images/USD.svg";
import { RootState } from "../../../../app/store";
import { useSelector } from "react-redux";
import Spinner from "../../../../components/PopUps/smallSpinner";

type ModalAProps = {
  // transfer: any; // Replace with the appropriate type for your transfer object
  closeModal: () => void;
};

const ViewReversal: React.FC<ModalAProps> = ({ closeModal }) => {
  const [open, setOpen] = useState(true);
  const cancelButtonRef = useRef(null);
  const { singleTransfer, loading } = useSelector(
    (state: RootState) => state.transaction
  );

  const status = singleTransfer
    ? (singleTransfer as any).TransactionState
    : " ";

  const statusResult =
    status === "REVERSAL" ? (
      <span className="px-3 py-1 my-2 rounded-full capitalize bg-[#F0E9DF] text-[#B8730A] font-medium text-xs">
        Reversed
      </span>
    ) : (
      <span className=" px-3 py-1 my-2 font-medium capitalize bg-[#FDE8E8] rounded-full text-[#9B1C1C] text-[10px]">
        {singleTransfer ? (singleTransfer as any).TransactionState : " "}
      </span>
    );

  const transactionID = singleTransfer?.TransactionIdentifier;
  const totalAmount = singleTransfer
    ? Math.abs((singleTransfer as any).TotalAmount)
    : " ";

  let createdDate = singleTransfer ? (singleTransfer as any).CreatedAt : "";

  const convertTime = (myDate: any) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const day = myDate.getDate();
    const month = months[myDate.getMonth()];
    const year = myDate.getFullYear();

    let hour = myDate.getHours();
    let minutes = myDate.getMinutes();
    let period = "am";

    if (hour >= 12) {
      hour -= 12;
      period = "pm";
    }

    if (hour === 0) {
      hour = 12;
    }

    if (minutes < 10) {
      minutes = "0" + minutes;
    }

    const datestring = `${month} ${day}, ${year}  ${hour}:${minutes}${period}`;
    return datestring;
  };

  const displayDate = convertTime(new Date(createdDate));

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white pb-8 text-left shadow-xl transition-all sm:my-8 sm:w-full md:max-w-lg  w-[30rem]">
                <header
                  className={`w-full bg-[#EFEFEF] py-4 px-8  flex justify-between`}
                >
                  <Dialog.Title
                    as="h3"
                    className="text-base font-bold leading-6 text-gray-900"
                  >
                    Transaction Details
                  </Dialog.Title>
                  <button
                    type="button"
                    className="rounded-md text-black hover:text-gray-500 focus:outline-none focus:ring-2 font-bold"
                    onClick={closeModal}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-4 w-4" aria-hidden="true" />
                  </button>
                </header>
                {loading ? (
                  <Spinner />
                ) : (
                  <div className="">
                    <div className="mt-3">
                      <div className="ml-auto md:w-[72%]">
                        <div className="flex justify-between px-6 py-7 items-center gap-4 md:gap-0">
                          <div className="flex items-center">
                            <img src={UsIcon} alt="icon" />
                            <p className="font-bold ml-2">USD Reversal</p>
                          </div>
                          <div>
                            <p className="text-sm">{displayDate}</p>
                          </div>
                        </div>
                      </div>

                      <div className="bg-[#F6F6F6]">
                        <div className="text-center py-6">
                          <p className="text-lg">
                            Reversal of{" "}
                            <span className="font-bold text-xl">
                              -$
                              {totalAmount?.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </span>{" "}
                          </p>

                          <div className="mt-2">
                            {singleTransfer ? statusResult : ""}
                          </div>
                        </div>
                      </div>

                      <div className="mt-4 px-6 md:px-8">
                        <div className="bg-[#FCFCFC] border border-[#F1F1F1] md:px-4 py-3 px-2 rounded-xl mb-3">
                          <>
                            <div className="md:flex justify-between my-3">
                              <p className="text-sm text-[#747A80]">
                                Amount reversed by Sender
                              </p>
                              <p className="text-sm font-medium">
                                -$
                                {totalAmount?.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </p>
                            </div>
                          </>
                          <div className="md:flex justify-between mt-3">
                            <p className="text-sm text-[#747A80]">Status</p>
                            <div className="text-sm">
                              {singleTransfer ? statusResult : ""}
                            </div>
                          </div>
                        </div>

                        <div className="mt-8 md:mt-8">
                          <div className="flex justify-between">
                            <p className="text-sm text-[#6F6F6F]">
                              Transaction ID
                            </p>
                            <p className="select-text">{transactionID}</p>
                          </div>
                        </div>
                        <footer>
                          <div className="pt-4">
                            <button
                              type="button"
                              className="text-sm 
                        py-3
                          rounded-md mt-4 
                          border border-[#35803F] w-[100%] text-[#35803F]"
                              onClick={closeModal}
                            >
                              Done
                            </button>
                          </div>
                        </footer>
                      </div>
                    </div>
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ViewReversal;
