import Gmail from "../../asset/referral/mail.svg";
import Facebook from "../../asset/referral/facebook.svg";
import Twitter from "../../asset/referral/twitter.svg";
import Linkedin from "../../asset/referral/linkedin.svg";
import { AWS_CREDS } from "../../features/services/AmazonService";
import { FacebookShareButton } from "react-share";
import { Auth } from "aws-amplify";

Auth.configure({
  userPoolId: `${AWS_CREDS.CACHED_BUSINESS_USER_POOL_ID}`,
  userPoolWebClientId: `${AWS_CREDS.CACHED_BUSINESS_USER_POOL_CLIENT_ID}`,
});

interface IShareReferrals {
  code?: string;
}

export default function ShareReferrals({ code }: IShareReferrals) {
  const referralCode = code;
  const subject = encodeURIComponent("Check out Cleva Banking!");
  const domainUrl = window.location.origin;
  const referralLink = `${domainUrl}/refer/${referralCode}`;
  const body = `
  Hello [Friend's Name],
  
  Open a free US-based USD account on Cleva to receive and save USD. Cleva Banking is definitely a game changer! 💸🌍
  Sign up using my referral link for exclusive benefits:
  
  ${referralLink}

  `;
  const mailtoLink = `mailto:?subject=${subject}&body=${encodeURIComponent(body)}`;

  const handleTwitterShare = () => {
    const twitterShareLink = `https://twitter.com/intent/tweet?text=${encodeURIComponent(body)}`;
    window.open(twitterShareLink, "_blank", "width=600,height=400");
  };

  const handleLinkedInShare = () => {
    const linkedInShareLink = `https://www.linkedin.com/sharing/share-offsite/?url=https://www.getcleva.com&summary=${body}`;
    window.open(linkedInShareLink, "_blank", "width=600,height=400");
  };

  return (
    <article className="cursor-pointer flex w-[90%] md:w-3/4 mt-8 mx-auto justify-between items-center">
      <a
        className="hover:underline relative h-[65px] flex flex-col items-center"
        href={mailtoLink}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={Gmail} alt="share" />
        <p className="absolute bottom-0 text-sm">Share</p>
      </a>

      <FacebookShareButton url={referralLink} quote={body}>
        <div className="hover:underline relative h-[65px] flex flex-col items-center">
          <img src={Facebook} alt="share" />
          <p className="absolute bottom-0 text-sm">Share</p>
        </div>
      </FacebookShareButton>

      <div
        onClick={handleTwitterShare}
        className="hover:underline relative h-[65px] flex flex-col items-center"
      >
        <img src={Twitter} alt="tweet" />
        <p className="absolute bottom-0 text-sm">Tweet</p>
      </div>

      <div
        onClick={handleLinkedInShare}
        className="hover:underline relative h-[65px] flex flex-col items-center"
      >
        <img src={Linkedin} alt="share" />
        <p className="absolute bottom-0 text-sm">Linkedin</p>
      </div>
    </article>
  );
}
