import errIcon from "../../images/error-icon.svg";

interface Input {
  title: string;
  value: string | number;
  fn: any;
  onBlur?: any;
  type: string;
  err: string;
  readOnly?: boolean;
  placeholder?: string;
  flag: any;
  code: string;
  isBal?: boolean;
  accBalance?: number;
  seeLimit?: boolean;
}

export default function CurrencyInput({
  title,
  type,
  fn,
  onBlur,
  value,
  err,
  readOnly,
  placeholder,
  flag,
  code,
  isBal,
  accBalance,
  seeLimit,
}: Input) {
  return (
    <div className="mt-5">
      {isBal ? (
        <div className="flex justify-between items-center">
          <div>
            <header className="text-[14px] font-semibold text-left">
              {title}{" "}
              {<span className="hidden text-[#D31D1D] font-semibold">*</span>}
            </header>
          </div>
          <button className="bg-[#F0F0F0] px-2 text-sm text-[#338453] rounded-full">
            Bal: $
            {accBalance?.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </button>
        </div>
      ) : (
        <header className="text-[14px] font-semibold text-left">
          {title}{" "}
          {<span className="hidden text-[#D31D1D] font-semibold">*</span>}
        </header>
      )}
      <div className="relative mt-1 rounded-md shadow-sm">
        <input
          type={type}
          onChange={fn}
          onBlur={onBlur}
          placeholder={placeholder}
          readOnly={readOnly}
          value={value}
          className="input-control"
          aria-describedby="price-currency"
        />
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          <p className="text-sm mr-1.5">{code}</p>
          <img src={flag} alt="" srcSet="" />
        </div>
      </div>
      {err ? (
        <div className="flex items-center mb-2 mt-1">
          <div className="flex flex-col md:flex-row">
            <p className="text-[#D31D1D] text-sm ml-1 flex gap-2 items-baseline">
              <img src={errIcon} alt="error" />
              {err}
            </p>
            {seeLimit && (
              <a
                href="/profile/transaction-limits"
                className="text-[#0B69AD] underline font-medium ml-3 text-sm"
              >
                See all limits
              </a>
            )}
          </div>
        </div>
      ) : (
        " "
      )}
    </div>
  );
}
