import { useEffect, useState } from "react";
// @ts-ignore Veriff SDK may lack TypeScript types, but usage is safe here
import { createVeriffFrame, MESSAGES } from "@veriff/incontext-sdk";
import { useNavigate } from "react-router-dom";
import { GetVeriffURL } from "../../../api";

const VeriffComponent = () => {
  const navigate = useNavigate();
  const [veriffURL, setVeriffURL] = useState("");
  const [loading, setLoading] = useState(true);
  const [veriffFrame, setVeriffFrame] = useState<any>(null);

  const handleButtonClick = () => {
    if (!veriffURL) {
      console.error("Veriff URL not provided yet.");
      return;
    }

    const frame = createVeriffFrame({
      url: veriffURL,
      onEvent: function (msg) {
        switch (msg) {
          case MESSAGES.STARTED:
            console.log("Session has started.");
            break;
          case MESSAGES.CANCELED:
            console.log("Session was canceled.");
            break;
          case MESSAGES.FINISHED:
            navigate("/kyc-pending");
            break;
          default:
            console.log("Received message:", msg);
            break;
        }
      },
    });

    setVeriffFrame(frame);
  };

  const getURL = async () => {
    try {
      setLoading(true);
      const res = await GetVeriffURL({
        VerificationType: "VERIFF_IDENTITY",
      });
      if (res?.data?.Url) {
        setVeriffURL(res.data.Url);
      } else {
        console.error("URL not found in response data");
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getURL();

    return () => {
      // Close the Veriff frame if it exists
      if (veriffFrame) {
        veriffFrame.close();
        console.log("Veriff session closed.");
      }
    };
  }, [veriffFrame]);

  return (
    <>
      <button
        onClick={handleButtonClick}
        className={`bg-cleva-gold py-4 w-[100%] rounded-lg font-semibold shadow-lg ${
          loading ? "opacity-60 cursor-not-allowed" : ""
        }`}
        disabled={loading}
        style={{
          opacity: loading ? 0.6 : 1,
        }}
      >
        {loading ? "Loading..." : "Take a picture of my ID"}
      </button>
      {/* <div id="veriff-root"></div> */}
    </>
  );
};

export default VeriffComponent;
