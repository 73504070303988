import { ReactNode, useState } from "react";
import Navbar from "../../Navbar";
import Footer from "../../Footer";
import { useNavigate } from "react-router-dom";
interface IWebLayout {
  onLinkClick?: () => void;
  scrollToProducts?: () => void;
  scrollToFAQ?: () => void;
  children?: ReactNode;
  navbarChild?: ReactNode;
  handleMobileNav?: () => void;
  closeProducts?: () => void;
  isMobile?: boolean;
  anyRef?: React.RefObject<HTMLDivElement>;
  showProductsMobile?: (event: React.MouseEvent) => void;
  showProducts?: (event: React.MouseEvent) => void;
}

export default function WebLayout({ children }: IWebLayout) {
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();
  const [displayProducts, setDisplayProducts] = useState(false);
  const [productsMobile, setProductsMobile] = useState(false);
  const [companyMobile, setCompanyMobile] = useState(false);
  const [displaycompany, setDisplayCompany] = useState(false);

  const scrollToProducts = () => {
    navigate("/");
  };

  const scrollToFAQ = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    navigate("/faq");
  };

  const scrollToBlog = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    window.open("https://blog.getcleva.com/", "_blank");
  };
  const scrollToRefer = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    navigate("/refer-and-earn");
  };

  const showProductsMobile = (event: any) => {
    event.stopPropagation();
    setProductsMobile(!productsMobile);
    setCompanyMobile(false);
  };

  const showCompanyMobile = (event: any) => {
    event.stopPropagation();
    setCompanyMobile(!companyMobile);
    setProductsMobile(false);
  };

  const removeDropdowns = () => {
    setDisplayCompany(false);
    setDisplayProducts(false);
  };

  const showProducts = () => {
    setDisplayProducts(!displayProducts);
    setDisplayCompany(false);
  };

  const closeProducts = () => {
    setDisplayProducts(false);
  };

  const showCompany = () => {
    setDisplayCompany(!displaycompany);
    setDisplayProducts(false);
  };

  const closeCompany = () => {
    setDisplayCompany(false);
    setCompanyMobile(false);
  };
  const handleMobileNav = () => setIsMobile(!isMobile);
  return (
    <div className="font-montserrat">
      <Navbar
        companyMobile={companyMobile}
        showCompanyMobile={showCompanyMobile}
        removeDropdowns={removeDropdowns}
        productsMobile={productsMobile}
        closeCompany={closeCompany}
        displayCompany={displaycompany}
        closeProducts={closeProducts}
        displayProducts={displayProducts}
        showProductsMobile={showProductsMobile}
        showProducts={showProducts}
        isMobile={isMobile}
        handleMobileNav={handleMobileNav}
        scrollToProducts={scrollToProducts}
        scrollToFAQ={scrollToFAQ}
        scrollToBlog={scrollToBlog}
        showCompany={showCompany}
        scrollToRefer={scrollToRefer}
      />
      <div>{children}</div>
      <div className="">
        <Footer />
      </div>
    </div>
  );
}

//faq
