import React, { useState } from "react";
import Button from "../../../components/Layout/buttons/Button";
import Modal from "../../../components/PopUps/Modal";
import { useNavigate } from "react-router-dom";
import { setModalState } from "../../../features/Kyc/kycSlice";
import { useAppDispatch } from "../../../app/hooks";
import axios from "../../../features/services/AxiosInstance";
import { AWS_CREDS } from "../../../features/services/AmazonService";

export default function ConfirmRecipient() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const itemString = localStorage.getItem("recipients");
  const item = itemString !== null ? JSON.parse(itemString) : null;
  const recipientString = localStorage.getItem("verifiedRecipient");
  const verifiedRecipient = recipientString !== null ? recipientString : null;
  const existingRecipient = localStorage.getItem("recipients");
  var parsedData = existingRecipient ? JSON.parse(existingRecipient) : [];
  const [loading, setLoading] = useState(false);

  function handleClose() {
    navigate("/recipients");
    dispatch(setModalState(false));
  }
  function saveRecipient() {
    setLoading(true);
    axios
      .post(`${AWS_CREDS.APIGATEWAY_URL}/recipients`, parsedData)
      .then(() => {
        localStorage.removeItem("recipients");
        localStorage.removeItem("verifiedRecipient");
        navigate("/recipients");
        dispatch(setModalState(false));
        setLoading(false);
      })
      .catch((error) => {
        localStorage.removeItem("recipients");
        localStorage.removeItem("verifiedRecipient");
        if (AWS_CREDS.STAGE === "qa") {
          console.error("Error sending data to Postman:", error);
        }
        navigate("/recipients");
        dispatch(setModalState(false));
      });
  }

  const details = [
    {
      id: 1,
      key: "Bank name",
      value: item.BankName,
    },
    {
      id: 2,
      key: "Account Number",
      value: item.AccountNumber,
    },
    {
      id: 3,
      key: "Account name",
      value: verifiedRecipient,
    },
  ];

  return (
    <Modal
      titlePosition="text-center"
      header="Confirm Recipient"
      closeModal={handleClose}
    >
      <div className="md:px-10 md:pt-8">
        <div
          className="flex
     justify-between
     text-[12px]
     font-bold
     px-6"
        ></div>
        <div className=" pt-3 pb-3 px-6 rounded-xl mt-[1.5em]">
          {details.map((info) => (
            <div
              key={info.id}
              className="flex
              text-left
              items-center
              justify-between
              border-b
              text-[12px]
                last:border-none
              py-3

              "
            >
              <span className=" text-slate-400 font-semibold">{info.key}</span>
              <span className="font-bold text-[14px]]">{info.value}</span>
            </div>
          ))}
        </div>
      </div>

      <div className="px-4 md:px-10 flex justify-between md:pt-6">
        <Button
          fn={() => navigate("/recipients")}
          status={false}
          styles="text-[12px]
          font-bold py-[10px] px-[6%]
          ${btn_bg}
          float-right
          rounded-md mt-4
          bg-[#FFF5D9]"
          // text="Edit"
          text="Cancel"
        />

        <Button
          fn={saveRecipient}
          status={loading}
          styles={`text-[12px]
          font-bold py-[10px] px-[8%]
          float-right
          rounded-md mt-4
          bg-[#FFBD59]
           ${loading ? "opacity-50" : ""}`}
          text={loading ? "Saving..." : "Save Recipient"}
        />
      </div>
    </Modal>
  );
}
