import BusinessKYC from "./BusinessKyc";
import IndividualKYC from "./IndividualKyc";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useEffect, useState } from "react";
import { getKyc } from "../../api";
import {
  setAdditionalDetails,
  setKYCState,
  setStep,
} from "../../features/Kyc/kycSlice";
import Spinner from "../../components/PopUps/Spinner";
import { setTitle } from "../../features/Nav/NavSlice";
import { useNavigate } from "react-router-dom";

const KycInfoPage = () => {
  const { accountType, AdditionalDetails, KYCState } = useAppSelector(
    (state: any) => state.kycInfo
  );
  const KYCIdentifier = localStorage.getItem("KYCIdentifier");
  const [loading, setLoader] = useState(true);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (KYCIdentifier) {
      getKyc(KYCIdentifier)
        .then((res) => {
          if (res?.data?.IndividualKyc) {
            dispatch(setKYCState(res?.data?.IndividualKyc?.KYCState!));
            dispatch(
              setAdditionalDetails(res?.data?.IndividualKyc?.AdditionalDetails)
            );
          } else if (res?.data?.BusinessKyc) {
            dispatch(setKYCState(res?.data?.BusinessKyc?.KYCState!));
            dispatch(
              setAdditionalDetails(res?.data?.BusinessKyc?.AdditionalDetails)
            );
          } else {
            dispatch(setKYCState(""));
          }
          setLoader(false);
        })
        .catch(() => {
          setLoader(false);
          dispatch(setKYCState(""));
        });
    } else {
      setLoader(false);
    }
  }, []);

  useEffect(() => {
    dispatch(setTitle("KYC Verification - Individual"));
    if (KYCState === "VERIFIED") {
      navigate("/");
    } else if (KYCState === "BIOMETRICS") {
      if (AdditionalDetails?.VerificationPending) {
        navigate("/");
      } else {
        dispatch(setStep(2));
      }
    } else if (KYCState === "DOCUMENT_RETRY" || KYCState === "ID_UPLOAD") {
      dispatch(setStep(2));
    } else if (KYCState === "DENIED") {
      navigate("/");
    } else if (KYCState === "SUSPENDED") {
      navigate("/");
    }
  }, []);

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>
          {accountType !== "BusinessUser" ? <IndividualKYC /> : <BusinessKYC />}
        </>
      )}
    </>
  );
};

export default KycInfoPage;
