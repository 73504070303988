import { useState } from "react";
import OtpField from "react-otp-field";
import emailIcon from "../../../../asset/images/email.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { VerifyOTP } from "../../../../api";
import { getUser, getUserIdWithAccessToken } from "../../../../login";
import { Auth } from "aws-amplify";
import { useAppDispatch } from "../../../../app/hooks";
import { setUser } from "../../../../features/User/UserSlice";
import errorImg from "../../../../asset/svg/material-symbols_warning.svg";

const VerifyUserOTP = () => {
  const [otp, setOtp] = useState<string>("");
  // const [formValid, setFormValid] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const AppDispatch = useAppDispatch();

  const [error, setError] = useState(false);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setLoading(true);
    const userID = await getUserIdWithAccessToken();
    const session = await Auth.currentSession();
    try {
      await VerifyOTP(userID, otp)
        .then(async () => {
          setLoading(false);
          const userDetails = await getUser(
            userID,
            session.getIdToken().getJwtToken()
          );
          AppDispatch(setUser(userDetails));
          navigate("/profile/security/update-pin");
        })
        .catch((err: any) => {
          if (err?.response?.status === 403) {
            setError(true);
          } else {
            toast.error(err.message);
          }
          setLoading(false);
        });
    } catch (err: any) {
      setLoading(false);
      toast.error(err.message);
    }
  };

  // useEffect(() => {
  //   if (otp) {
  //     setFormValid(true);
  //   } else {
  //     setFormValid(false);
  //   }
  // }, [otp]);

  return (
    <>
      <div className="relative flex-1 bg-[#FBFBFB]">
        <div className="flex justify-center pt-8 min-h-[100vh] md:items-center md:pt-0">
          <div className="login-card w-full lg:w-[32rem] xl:w-[36rem] py-8 px-8 md:py-16 md:px-20">
            <div className="text-center ">
              <div className="flex justify-center">
                <img src={emailIcon} alt="email" />
              </div>

              <h2 className="text-2xl text-medium text-black-soft mt-6">
                Enter OTP Code
              </h2>
              <p className="text-[#5F5D5D] w-[20rem] mx-auto text-sm mt-4">
                Please enter the 6 digit code sent to your email address{" "}
                {/* <span className="text-[#935B06]">{email}</span> */}
              </p>
              {/* form section  */}
              <form onSubmit={handleSubmit} className=" mt-8">
                <div className="w-[20rem] mx-auto">
                  <OtpField
                    value={otp}
                    onChange={(e: any) => {
                      setOtp(e);
                      setError(false);
                    }}
                    numInputs={6}
                    onChangeRegex={/^([0-9]{0,})$/}
                    autoFocus
                    separator={<span> </span>}
                    isTypeNumber={false}
                    inputProps={{
                      className: `otp-field__input ${error ? "error-box" : ""}`,
                      disabled: false,
                      pattern: "[0-9]*",
                      inputMode: "numeric",
                    }}
                  />
                </div>

                {error && (
                  <div
                    className="w-full px-5 flex font-[500] mb-8 mt-4
                        rounded-lg
                        justify-center text-sm items-center h-[3.75rem] bg-[#FFEAED] "
                  >
                    <img src={errorImg} className="" alt="error" />
                    <span className="text-[#800205] pl-2  font-normal">
                      OTP code not valid
                    </span>
                  </div>
                )}
                <div className="mt-7">
                  <button
                    onClick={(e) => handleSubmit(e)}
                    disabled={loading || otp.length < 6}
                    className={
                      loading || otp.length < 6
                        ? " login-disabled"
                        : "login-active"
                    }
                  >
                    {loading ? "Loading ..." : "Submit"}
                  </button>
                </div>
                {/* <div className="mt-9 text-center">
                    <p className="text-[#8F8F8F] text-sm ">
                      Resend code <span className="text-cleva-gold">50s</span>
                    </p>
                  </div> */}
              </form>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
};

export default VerifyUserOTP;
