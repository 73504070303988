import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchDeals } from "../services/DashboardServices";
import { AWS_CREDS } from "../services/AmazonService";

export const getDeals = createAsyncThunk("deals/getDeals", async () => {
  try {
    const response = await fetchDeals();
    return response.data.DealsSummary;
  } catch (error) {
    if (AWS_CREDS.STAGE === "qa") {
      console.log(error);
    }
  }
});
