import { Navigate, createBrowserRouter } from "react-router-dom";
import { SuccessMsg } from "./components/Message/SuccessMsg";
import { FailureMsg } from "./components/Message/FailureMsg";
import { RetryMsg } from "./components/Message/RetryMail";
import { Error404 } from "./components/error/Error404";
import MainLayout from "./components/Layout/MainLayout";
import Login from "./components/Auth/Login";
import Accounts from "./pages/Accounts";
import ViewAccount from "./pages/Accounts/ViewAccount";
import Transactions from "./pages/Transactions";
import Invoice from "./pages/Invoice";
import ClevaCards from "./pages/ClevaCards";
import VerifyCardBVN from "./pages/ClevaCards/VerifyCardBVN";

import PendingEmailVerification from "./components/Auth/AwaitingEmailVerification";
import ForgotPassword from "./components/Auth/ForgotPassword";
import ResetPassword from "./components/Auth/ResetPassword";
import Register from "./components/Auth/Register";
import VerifyEmail from "./components/Auth/VerifyEmail";
import UnauthenticatedLayout from "./components/Auth/UnauthenticatedLayout";
import EditProfile from "./pages/Profile/EditProfile";
// import ChangePassword from './pages/Profile/ChangePassword';
import Transfers from "./pages/Transfers/M2";
import CreateTransfers from "./pages/Transfers/M2/CreateTransfer";
import ConfirmTransfers from "./pages/Transfers/ConfirmTransfer";
import Recipients from "./pages/Recipients";
import ConfirmRecipient from "./pages/Recipients/modals/ConfirmRecipient";
import ViewTransfer from "./pages/Transfers/M2/ViewTransfer";
import ProfilePage from "./pages/Profile";
import RecipientHistory from "./pages/Recipients/pages/RecipientsHistory";
import ConfirmSelfie from "./pages/Kyc/ConfirmSelfie";
import FailureSelfie from "./pages/Kyc/FailureSelfie";
// const Signup = lazyWithRetry(() => import('./components/Auth/Signup'));
import Home from "./pages/Home/index";

import Referrals from "./pages/Referrals/Index";
import Statistics from "./pages/Referrals/Statistics";

// new kyc flow
import KYC from "./pages/Kyc";
import KycInfo from "./pages/Kyc/KycInfoPage";
import KycPending from "./pages/Kyc/pages/KycPending";

import ReferralsRedirect from "./pages/Referrals/RedirectReferrals";

//Transaction PIN
import CreatePin from "./pages/Security/CreatePin";
import ConfirmPin from "./pages/Security/ConfirmPin";

//profile flow
import ChangePin from "./pages/Profile/pages/Security/Index";
import ProfileLayout from "./components/Layout/ProfileLayout";

import SuccessPin from "./pages/Profile/pages/Security/SuccessPin";
import UpdatePin from "./pages/Profile/pages/Security/UpdatePIN";
import ChangePassword from "./pages/Profile/pages/Security/ChangePassword";
import VerifyUserOTP from "./pages/Profile/pages/Security/VerifyUserOTP";
import FundCard from "./pages/ClevaCards/FundCard";
import CardDeactivation from "./pages/ClevaCards/CardDeactivation";

//cards
import TopUp from "./pages/ClevaCards/Topup";
import Withdraw from "./pages/ClevaCards/Withdraw";
import CardTransactions from "./pages/ClevaCards/CardTransactions";
import CardSetting from "./pages/ClevaCards/CardSetting";
import LowBalance from "./pages/ClevaCards/LowBalance";
//import AccountStatement from "./pages/Accounts/AccountStatement";
import AccountDetails from "./pages/Accounts/MoblieAccountDetails";
import PendingCard from "./pages/ClevaCards/PendingCard";
import CardName from "./pages/ClevaCards/CardName";
import AccountStatement from "./pages/Accounts/AccountStatement";
//tag related pages
import Tags from "./pages/Tags";
import TagComplete from "./pages/Tags/TagComplete";
import AddRecipient from "./pages/Tags/AddRecipient";
import ViewC2CTransfer from "./pages/Tags/ViewC2CTransfer";

//mobile review transfer
import MobileReviewTransfer from "./pages/Transfers/M2/MobileReviewTransfer";
import DeleteAccount from "./pages/Home/DeleteAccount";
import DeleteUserAccount from "./pages/Profile/pages/DeleteAccount";
import ConfirmDeleteAccount from "./pages/Profile/pages/ConfirmDeleteAccount";
import CreateTransfer from "./pages/Tags/CreateTagTransfer";
import CompleteTransfer from "./pages/Tags/CompleteTransfer";
import TransferOption from "./pages/Transfers/M2/TransferOption";
import TagMobileReviewTransfer from "./pages/Tags/MobileReviewTransfer";
import UpdatePinEntry from "./pages/Profile/pages/Security/UpdatePinEntry";
import AccountDeletionOTP from "./pages/Profile/pages/Security/AccountDeletionOTP";
import SendPinConfirmation from "./pages/Profile/pages/Security/modals/SendPinConfirmation";
import SendDeleteConfirmation from "./pages/Profile/pages/Security/modals/SendDeleteConfirmation";
import SecurityPage from "./pages/Profile/pages/Settings";
import SettingsPage from "./pages/Profile/pages/NewSetting";
import TipsPage from "./pages/Profile/pages/Tips";

//NIN KYC

import VerifyNINPage from "./pages/Kyc/components/VerifyNINPage";
import Deals from "./pages/Deals";
import DealDetails from "./pages/Deals/DealDetails";
import ProfileTransactionLimits from "./pages/Profile/pages/ProfileTransactionLimits";
import ProofAccounts from "./pages/Accounts/ProofAccounts";
import { ErrorBoundary } from "react-error-boundary";
import {
  ErrorFallbackComponent,
  TransactionLimitFallbackComponent,
} from "./utils";
import DeleteAccountCheck from "./pages/Profile/pages/DeleteAccountCheck";
import { DashboardStatusFallbackComponent } from "./utils";

const routes = (user: any) => {
  return createBrowserRouter([
    {
      path: "/",
      element: user ? <MainLayout /> : <Login />,
      children: [
        {
          path: "",
          element: (
            <ErrorBoundary FallbackComponent={DashboardStatusFallbackComponent}>
              <Home />
            </ErrorBoundary>
          ),
        },
        {
          path: "/accounts",
          element: (
            <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
              <Accounts />
            </ErrorBoundary>
          ),
        },
        {
          path: "/accounts/view",
          element: (
            <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
              <ViewAccount />
            </ErrorBoundary>
          ),
        },
        {
          path: "/accounts/account-details",
          element: (
            <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
              <AccountDetails />
            </ErrorBoundary>
          ),
        },
        {
          path: "/accounts/statement",
          element: (
            <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
              <AccountStatement />
            </ErrorBoundary>
          ),
        },
        {
          path: "/accounts/proof",
          element: (
            <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
              <ProofAccounts />
            </ErrorBoundary>
          ),
        },
        {
          path: "/transanctions",
          element: (
            <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
              <Transactions />
            </ErrorBoundary>
          ),
        },

        // transfer pages
        {
          path: "/transfers",
          element: (
            <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
              <Transfers />
            </ErrorBoundary>
          ),
        },

        {
          path: "/transfers/create",
          element: (
            <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
              <CreateTransfers />
            </ErrorBoundary>
          ),
        },
        {
          path: "/transfers/confirm",
          element: (
            <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
              <ConfirmTransfers />
            </ErrorBoundary>
          ),
        },
        {
          path: "/transfers/view",
          element: (
            <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
              <ViewTransfer />
            </ErrorBoundary>
          ),
        },

        {
          path: "/transfers/review-transfer/tag",
          element: (
            <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
              <TagMobileReviewTransfer />
            </ErrorBoundary>
          ),
        },

        {
          path: "/transfers/review-transfer",
          element: (
            <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
              <MobileReviewTransfer />
            </ErrorBoundary>
          ),
        },

        {
          path: "/referrals",
          element: (
            <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
              <Referrals />
            </ErrorBoundary>
          ),
        },
        {
          path: "/referrals/statistics",
          element: (
            <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
              <Statistics />
            </ErrorBoundary>
          ),
        },
        {
          path: "/confirm_selfie",
          element: (
            <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
              <ConfirmSelfie />
            </ErrorBoundary>
          ),
        },
        //tags related
        {
          path: "/create-tag",
          element: (
            <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
              <Tags />
            </ErrorBoundary>
          ),
        },
        {
          path: "/tag-complete",
          element: (
            <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
              <TagComplete />
            </ErrorBoundary>
          ),
        },
        {
          path: "/tag-recipient",
          element: (
            <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
              <AddRecipient />
            </ErrorBoundary>
          ),
        },
        {
          path: "/tags/transfers/create",
          element: (
            <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
              <CreateTransfer />
            </ErrorBoundary>
          ),
        },
        {
          path: "/transfers/view/c2c",
          element: (
            <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
              <ViewC2CTransfer />
            </ErrorBoundary>
          ),
        },
        {
          path: "/transfers/options",
          element: (
            <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
              <TransferOption />
            </ErrorBoundary>
          ),
        },
        {
          path: "/tags/transfers/complete",
          element: (
            <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
              <CompleteTransfer />
            </ErrorBoundary>
          ),
        },
        //end of tags related
        {
          path: "/failure_selfie",
          element: (
            <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
              <FailureSelfie />
            </ErrorBoundary>
          ),
        },
        {
          path: "/password",
          element: (
            <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
              <ChangePassword />
            </ErrorBoundary>
          ),
        },

        {
          path: "/confirm_recipient",
          element: (
            <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
              <ConfirmRecipient />
            </ErrorBoundary>
          ),
        },
        {
          path: "/recipient_details",
          element: (
            <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
              <RecipientHistory />
            </ErrorBoundary>
          ),
        },
        {
          path: "/successMsg",
          element: (
            <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
              <SuccessMsg />
            </ErrorBoundary>
          ),
        },
        {
          path: "/failureMsg",
          element: (
            <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
              <FailureMsg />
            </ErrorBoundary>
          ),
        },
        {
          path: "/retryMsg",
          element: (
            <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
              <RetryMsg />
            </ErrorBoundary>
          ),
        },
        {
          path: "/kyc",
          element: (
            <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
              <KYC />
            </ErrorBoundary>
          ),
        },
        {
          path: "/kyc-info",
          element: (
            <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
              <KycInfo />
            </ErrorBoundary>
          ),
        },
        {
          path: "/kyc-pending",
          element: (
            <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
              <KycPending />
            </ErrorBoundary>
          ),
        },
        {
          path: "/kyc/verify-NIN",
          element: (
            <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
              <VerifyNINPage />
            </ErrorBoundary>
          ),
        },
        {
          path: "/recipients",
          element: (
            <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
              <Recipients />
            </ErrorBoundary>
          ),
        },
        {
          path: "/invoices",
          element: (
            <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
              <Invoice />
            </ErrorBoundary>
          ),
        },
        {
          path: "/cards",
          element: (
            <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
              <ClevaCards />
            </ErrorBoundary>
          ),

          //element: <ClevaCards />
        },
        {
          path: "/cards/verify",
          element: (
            <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
              <VerifyCardBVN />
            </ErrorBoundary>
          ),
        },
        {
          path: "/cards/card-name",
          element: (
            <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
              <CardName />
            </ErrorBoundary>
          ),
        },
        {
          path: "/cards/fund",
          element: (
            <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
              <FundCard />
            </ErrorBoundary>
          ),
        },
        {
          path: "/cards/deactivation",
          element: (
            <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
              <CardDeactivation />
            </ErrorBoundary>
          ),
        },
        {
          path: "/cards/topup",
          element: (
            <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
              <TopUp />
            </ErrorBoundary>
          ),
        },
        {
          path: "/cards/withdraw",
          element: (
            <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
              <Withdraw />
            </ErrorBoundary>
          ),
        },
        {
          path: "/cards/transactions",
          element: (
            <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
              <CardTransactions />
            </ErrorBoundary>
          ),
        },
        {
          path: "/cards/settings",
          element: (
            <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
              <CardSetting />
            </ErrorBoundary>
          ),
        },
        {
          path: "/cards/low-balance",
          element: (
            <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
              <LowBalance />
            </ErrorBoundary>
          ),
        },
        {
          path: "/cards/pending",
          element: (
            <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
              <PendingCard />
            </ErrorBoundary>
          ),
        },

        {
          path: "/deals",
          element: (
            <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
              <Deals />
            </ErrorBoundary>
          ),
        },
        {
          path: "/deals/details",
          element: (
            <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
              <DealDetails />
            </ErrorBoundary>
          ),
        },

        //profile
        {
          path: "/profile",
          element: user ? (
            <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
              <ProfileLayout />
            </ErrorBoundary>
          ) : (
            ""
          ),
          children: [
            {
              path: "/profile",
              element: (
                <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
                  <ProfilePage />
                </ErrorBoundary>
              ),
            },
            {
              path: "/profile/view",
              element: (
                <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
                  <EditProfile />
                </ErrorBoundary>
              ),
            },
            {
              path: "/profile/Card",
              element: (
                <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
                  <CardSetting />
                </ErrorBoundary>
              ),
            },
            {
              path: "/profile/security/confirm-change",
              element: (
                <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
                  <SendPinConfirmation />
                </ErrorBoundary>
              ),
            },
            {
              path: "/profile/security/confirm-otp",
              element: (
                <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
                  <UpdatePinEntry />
                </ErrorBoundary>
              ),
            },
            {
              path: "/profile/security/change-pin",
              element:
                !user?.PinRetries && !user?.PinResetDisabled ? (
                  <Navigate to="/create-pin" />
                ) : (
                  <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
                    <ChangePin />
                  </ErrorBoundary>
                ),
            },
            {
              path: "/profile/security/success-pin",
              element: (
                <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
                  <SuccessPin />
                </ErrorBoundary>
              ),
            },
            {
              path: "/profile/security/verify-otp",
              element: (
                <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
                  <VerifyUserOTP />
                </ErrorBoundary>
              ),
            },
            {
              path: "/profile/security/update-pin",
              element: !user?.PinResetDisabled ? (
                <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
                  <UpdatePin />
                </ErrorBoundary>
              ) : (
                <Navigate to="/profile/security/change-pin" />
              ),
            },
            {
              path: "/profile/security/password",
              element: (
                <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
                  <ChangePassword />
                </ErrorBoundary>
              ),
            },
            {
              path: "/profile/transaction-limits",
              element: (
                <ErrorBoundary
                  FallbackComponent={TransactionLimitFallbackComponent}
                >
                  <ProfileTransactionLimits />
                </ErrorBoundary>
              ),
            },
            {
              path: "/profile/confirm-delete",
              element: (
                <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
                  <SendDeleteConfirmation />
                </ErrorBoundary>
              ),
            },
            {
              path: "/profile/confirm-delete-otp",
              element: (
                <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
                  <AccountDeletionOTP />
                </ErrorBoundary>
              ),
            },
            {
              path: "/profile/delete-account",
              element: (
                <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
                  <DeleteUserAccount />
                </ErrorBoundary>
              ),
            },
            {
              path: "/profile/confirm-delete-account",
              element: (
                <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
                  <ConfirmDeleteAccount />
                </ErrorBoundary>
              ),
            },
            {
              path: "/profile/settings",
              element: (
                <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
                  <SettingsPage />
                </ErrorBoundary>
              ),
            },
            {
              path: "/profile/tips",
              element: (
                <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
                  <TipsPage />
                </ErrorBoundary>
              ),
            },
            {
              path: "/profile/security",
              element: (
                <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
                  <SecurityPage />
                </ErrorBoundary>
              ),
            },
            {
              path: "/profile/delete-account/reason",
              element: (
                <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
                  <DeleteAccountCheck />
                </ErrorBoundary>
              ),
            },
          ],
        },
      ],
    },
    // {
    //   path: '/landingpage',
    //   element: <LandingPage />
    // },
    {
      path: "*",
      element: (
        <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
          <Error404 />
        </ErrorBoundary>
      ),
    },
    // {
    //   path: 'faq',
    //   element: <FAQ />
    // },
    // {
    //   path: 'careers',
    //   element: <Jobs />
    // },
    // {
    //   path: 'careers/engineering-lead',
    //   element: <JobDescription />
    // },
    // {
    //   path: 'terms-of-service',
    //   element: <TermsOfService />
    // },
    // {
    //   path: 'privacy-policy',
    //   element: <PrivacyPolicy />
    // },
    // {
    //   path: 'privacy-policy-app',
    //   element: <PrivacyPolicyApp />
    // },
    // {
    //   path: 'referral-terms',
    //   element: <ReferralTerms />
    // },
    // {
    //   path: 'refer-and-earn',
    //   element: <ReferralsWeb />
    // }, //security pin
    {
      path: "/refer/:referral",
      element: (
        <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
          <ReferralsRedirect />
        </ErrorBoundary>
      ),
    },
    {
      path: "/create-pin",
      element: user?.PinResetDisabled ? (
        <Navigate to="/" />
      ) : (
        <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
          <CreatePin />
        </ErrorBoundary>
      ),
    },
    {
      path: "/success-pin",
      element: (
        <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
          <ConfirmPin />
        </ErrorBoundary>
      ),
    },
    {
      path: "/delete-account",
      element: (
        <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
          <DeleteAccount />
        </ErrorBoundary>
      ),
    },
    {
      path: "/auth",
      element: !user ? (
        <UnauthenticatedLayout />
      ) : (
        <Navigate to="/create-pin" />
      ),
      children: [
        { path: "", element: <Navigate to="/" /> },
        // { path: "", element: <Login /> },
        { path: "login", element: <Login /> },
        { path: "register/:referral?", element: <Register /> },
        { path: "forgot-password", element: <ForgotPassword /> },
        { path: "reset-password/:contact/:otp", element: <ResetPassword /> },
        // { path: "reset-password", element: <ResetPassword /> },
        { path: "verify-token/:contact/:otp", element: <VerifyEmail /> },
        { path: "verify-email", element: <PendingEmailVerification /> },
        {
          path: "verify-email/:pending",
          element: <PendingEmailVerification />,
        },
        // { path: "signup", element: <Signup /> },
      ],
    },
    // { path: "usd-account", element: <USDAccount/> },
    // { path: "usd-card", element: <USDCard/> },
  ]);
};

export default routes;
