import { useState, useEffect } from "react";
import { useAppSelector } from "./app/hooks";

import { Tier1, Tier2 } from "./asset";

export const useIsBusinessUser = () => {
  const user = useAppSelector((state) => state.user.userData);
  const [isBusinessUser, setIsBusinessUser] = useState<boolean | null>(null);

  useEffect(() => {
    if (user?.BusinessIdentifier) {
      setIsBusinessUser(true);
    } else {
      setIsBusinessUser(false);
    }
  }, [user]);

  return isBusinessUser;
};

export const getBusinessNameInitials = (value: string) => {
  let word = value.split(" ");
  const firstLetters = word.map((name) => name?.charAt(0)?.toUpperCase());

  const firstItems = firstLetters.splice(0, 2);
  return firstItems;
};

export const KycStatusFallbackComponent = () => {
  return (
    <div className="bg-[#FFEEE6] border p-4 text-[#111111] rounded-md">
      Something went wrong displaying the status of your KYC. Please try again
    </div>
  );
};

export const TransactionLimitFallbackComponent = () => {
  return (
    <div className="bg-[#FFEEE6] border p-4 text-[#111111] rounded-md">
      Something went wrong displaying your transaction limit. Please try again
    </div>
  );
};

export const TierType = (value: string): string => {
  switch (value) {
    case "TIER1_PERSON":
      return "Tier 1";
    case "TIER2_PERSON":
      return "Tier 2";
    case "TIER1_BUSINESS":
      return "Business Tier 1";
    case "CUSTOM":
      return "Custom";
    default:
      return "Tier 1";
  }
};

export const TierTypeImage = (value: string): string | undefined => {
  switch (value) {
    case "TIER1_PERSON":
      return Tier1;
    case "TIER2_PERSON":
      return Tier2;
    case "CUSTOM":
      return;
    default:
      return Tier1;
  }
};

export const formatNumberThousands = (num: number) => {
  return new Intl.NumberFormat(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(num);
};

export const DashboardStatusFallbackComponent = () => {
  return (
    <div className="bg-[#FFEEE6] border p-4 text-[#111111] rounded-md">
      Something went wrong displaying your home page. Please try again
    </div>
  );
};

export const ErrorFallbackComponent = () => {
  return (
    <div className="bg-[#FFEEE6] border p-4 text-[#111111] rounded-md">
      Something went wrong displaying this page. Please refresh your browser or
      try again
    </div>
  );
};

export const capitalizeFirstLetter = (word: string) => {
  if (!word) return "";
  let wordArr = word.split(" ");

  const capitalized = wordArr.map((word) => {
    return word[0].toUpperCase() + word.slice(1).toLowerCase();
  });

  return capitalized.join(" ");
};
