import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import TagModal from "../../Tags/Modals/index";
import { Link, useNavigate } from "react-router-dom";
import { TrashIcon } from "@heroicons/react/24/outline";
import { getTiers } from "../../../features/User/UserApi";
import Spinner from "../../../components/PopUps/Spinner";
import { LiaAngleRightSolid } from "react-icons/lia";
import { TierType, TierTypeImage, useIsBusinessUser } from "../../../utils";
import { getKyc } from "../../../api";
import {
  setAdditionalDetails,
  setKYCState,
} from "../../../features/Kyc/kycSlice";
interface IProfile {
  user: any;
}
export default function Profile(props: IProfile) {
  const [initials, setInitials] = useState("");

  useEffect(() => {
    let nameInitials = "";
    if (props.user?.FullName?.FirstName) {
      nameInitials += props.user?.FullName?.FirstName[0];
    }
    if (props.user?.FullName?.LastName) {
      nameInitials += props.user?.FullName?.LastName[0];
    }
    setInitials(nameInitials);
  }, [props]);

  let [openTag, setOpenTag] = useState(false);
  const userTag = useAppSelector((state) => state.tags.userTag);
  const user = useAppSelector((state) => state.user.userData);
  const tiers = useAppSelector((state) => state.user.userTier);
  const kycState = useAppSelector((state) => state.kycInfo.KYCState);
  const KYCIdentifier = localStorage.getItem("KYCIdentifier");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isBusinessUser = useIsBusinessUser();
  const openTagModal = () => {
    setOpenTag(true);
  };

  const closeTagModal = () => {
    setOpenTag(false);
  };

  useEffect(() => {
    if (isBusinessUser) {
      const businessIdentifier = user?.BusinessIdentifier;
      if (businessIdentifier) {
        dispatch(getTiers(businessIdentifier));
      }
    } else {
      const clevaUserID = user?.ClevaUserID;
      if (clevaUserID) {
        dispatch(getTiers(clevaUserID));
      }
    }

    if (KYCIdentifier) {
      getKyc(KYCIdentifier)
        .then((res) => {
          if (res?.data?.IndividualKyc) {
            dispatch(setKYCState(res?.data?.IndividualKyc?.KYCState!));
            dispatch(
              setAdditionalDetails(res?.data?.IndividualKyc?.AdditionalDetails)
            );
          } else if (res?.data?.BusinessKyc) {
            dispatch(setKYCState(res?.data?.BusinessKyc?.KYCState!));
            dispatch(
              setAdditionalDetails(res?.data?.BusinessKyc?.AdditionalDetails)
            );
          } else {
            dispatch(setKYCState(""));
          }
        })
        .catch(() => {
          dispatch(setKYCState(""));
        });
    } else {
      dispatch(setKYCState(""));
    }
  }, [dispatch, isBusinessUser, user]);

  const allAccount = useAppSelector(
    (state) => state.virtualAccount?.allAccount
  );

  const hasAccounts = allAccount && Object.keys(allAccount).length > 0;

  if (tiers === null) {
    return <Spinner />;
  }

  return (
    <>
      <div className="md:w-4/5 xl:w-3/4 md:px-4 xl:px-12 mx-auto">
        <section>
          <div className="pt-[1em] flex items-center gap-6">
            <span className="rounded-full h-[60px] w-[60px] bg-[#F2F2F2] text-[20px] border-[3px] border-[#cccccc] text-center flex items-center justify-center">
              <b style={{ lineHeight: "70px", width: "100%" }}>{initials}</b>
            </span>
            <p className="text-xl font-medium">
              {props.user?.FullName?.FirstName} {props.user?.FullName?.LastName}
            </p>
            {/* <button
            onClick={() => console.log("")}
            className="border-2 border-[#9a9a9a] py-3 px-8 text-[#787979]  rounded-[8px] "
          >
            Change
          </button> */}
          </div>
        </section>
        <br />
        {(kycState === "VERIFIED" || kycState === "BUSINESS_VERIFIED") && (
          <section
            onClick={() => navigate("/profile/transaction-limits")}
            className="cursor-pointer bg-white border border-[#aaa9a9] md:px-12 px-4 py-4 text-[14px] rounded-xl"
          >
            <div className="flex justify-between">
              <p>Account tier</p>

              <button className="gap-2 flex items-center justify-center">
                {tiers?.Tier !== "CUSTOM" ? (
                  <img src={TierTypeImage(tiers?.Tier)} alt="Account tier" />
                ) : null}
                <p>{TierType(tiers?.Tier)}</p>
                <LiaAngleRightSolid />
              </button>
            </div>
          </section>
        )}

        {hasAccounts ? (
          <section className="bg-white border border-[#aaa9a9] mt-4 md:px-12 px-4 py-8 text-[14px] rounded-xl">
            <div>
              <header className="text-[#4B4B4B] font-[500] text-base">
                Cleva Tag
              </header>

              <div className="md:flex items-start justify-between mt-3">
                <div className="md:w-[80%]">
                  <span className="text-[#747A80] text-[0.875rem] italic font-[400]">
                    Receive money from other Cleva users using your unique tag.
                  </span>
                </div>
                {!userTag ? (
                  <button
                    onClick={() => {
                      navigate("/create-tag");
                    }}
                    className="border border-[#0E7BE0] float-right mt-3 md:mt-0 py-3 px-8 text-[#0E7BE0]  rounded-[8px] "
                  >
                    Add
                  </button>
                ) : (
                  <button
                    onClick={openTagModal}
                    className="border border-[#0E7BE0] float-right mt-3 md:mt-0 py-3 px-8 text-[#0E7BE0]  rounded-[8px] "
                  >
                    Edit
                  </button>
                )}
              </div>

              <TagModal
                open={openTag}
                closeModal={closeTagModal}
                tag={userTag}
              />
            </div>
            <div>
              <p
                className={
                  (userTag ? "text-[#2B2B2B]" : "text-[#D0D0D0]") +
                  " text-[1.25rem] pt-1 font-[700]"
                }
              >
                @{userTag || "yourTag"}
              </p>
            </div>
          </section>
        ) : (
          //unverified user and no usd account
          <section className="bg-white border border-[#aaa9a9] mt-4 md:px-12 px-4 py-8 text-[14px] rounded-xl">
            <div>
              <header className="text-[#4B4B4B] font-[500] text-base">
                Cleva Tag
              </header>

              <div className="md:flex items-start justify-between mt-3">
                <div className="md:w-[80%]">
                  <span className="text-red-500 text-[0.875rem] italic font-[400]">
                    You need to have a USD Account before you can create your
                    tag
                  </span>
                </div>
                <button
                  className="border border-[#0E7BE0] float-right mt-3 md:mt-0 py-3 px-8 text-[#0E7BE0] rounded-[8px] opacity-50 cursor-not-allowed"
                  disabled
                >
                  Add
                </button>
              </div>
            </div>
            <div>
              <p className="text-[#D0D0D0] text-[1.25rem] pt-1 font-[700]">
                @{"yourTag"}
              </p>
            </div>
          </section>
        )}
        <section className="bg-white border border-[#aaa9a9] mt-4 px-4 py-8 text-[14px] rounded-xl ">
          <div>
            <header className="text-[#4B4B4B] font-[500] text-base">
              Personal Information
            </header>
            <p className="text-[#747A80] text-[0.875rem] italic font-[400] mt-3">
              This information has been verified. Any updates will require
              re-verification of your identity.
              <br />
              Email us at{" "}
              <span className="text-[#052B7B] font-[400px]">
                contact@getcleva.com
              </span>{" "}
              to update your identity information.
            </p>
            <div className=" grid md:grid-cols-2 w-[70%] gap-8 mt-8">
              <div className="">
                <p className="text-black text-opacity-90 text-sm font-normal leading-[18px]">
                  First Name
                </p>
                <b>{props.user?.FullName?.FirstName}</b>
              </div>

              <div className="">
                <p className="text-black text-opacity-90 text-sm font-normal leading-[18px]">
                  Last Name
                </p>
                <b className="break-words">{props.user?.FullName?.LastName}</b>
              </div>

              <div className="">
                <p className="text-black text-opacity-90 text-sm font-normal leading-[18px]">
                  Email Address
                </p>
                <b className="break-words">
                  {props.user?.StandardAttributes?.Email}
                </b>
              </div>

              <div className="">
                <p className="text-black text-opacity-90 text-sm font-normal leading-[18px]">
                  Phone Number
                </p>
                <b className="break-words">
                  {props.user?.StandardAttributes?.PhoneNumber}
                </b>
              </div>

              <div className="">
                <p className="text-black text-opacity-90 text-sm font-normal leading-[18px]">
                  Government-Issued ID
                </p>
                <b>{props.user?.StandardAttributes?.Address?.Country}</b>
              </div>
            </div>
          </div>
        </section>

        {/* delete account  */}

        <div className="shadow bg-white mt-8 py-5 text-red-500 rounded-lg">
          <Link to="/profile/confirm-delete">
            <div className="flex justify-center items-center">
              <TrashIcon className="w-4 h-4 font-semibold" />
              <p className="text-base text-center ml-2">Delete Account</p>
            </div>
          </Link>
        </div>
      </div>
    </>
  );
}
