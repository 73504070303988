import { useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { Link, useNavigate } from "react-router-dom";
import { setTitle } from "../../features/Nav/NavSlice";
import kycIcon from "../../asset/images/kyc.svg";
import KycCard from "./components/KycCard";
import { FiChevronRight } from "react-icons/fi";
import {
  setStep,
  setKYCState,
  setAdditionalDetails,
} from "../../features/Kyc/kycSlice";
import { getKyc } from "../../api";

function KYC() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { AdditionalDetails, KYCState } = useAppSelector(
    (state) => state.kycInfo
  );
  const KYCIdentifier = localStorage.getItem("KYCIdentifier");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchKycData = async () => {
      if (KYCIdentifier) {
        try {
          const res = await getKyc(KYCIdentifier);
          const individualKyc =
            res?.data?.IndividualKyc || res?.data?.BusinessKyc;

          if (individualKyc) {
            dispatch(setKYCState(individualKyc.KYCState));
            dispatch(setAdditionalDetails(individualKyc.AdditionalDetails));
          } else {
            dispatch(setKYCState(""));
          }
        } catch (error) {
          dispatch(setKYCState(""));
          console.error(error);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };

    fetchKycData();
  }, [dispatch, KYCIdentifier]);

  useEffect(() => {
    dispatch(setTitle("KYC Verification - Individual"));

    const handleNavigation = () => {
      switch (KYCState) {
        case "VERIFIED":
        case "DENIED":
        case "SUSPENDED":
          navigate("/");
          break;
        case "BIOMETRICS":
          if (AdditionalDetails?.VerificationPending) {
            navigate("/");
          } else {
            dispatch(setStep(2));
          }
          break;
        case "DOCUMENT_RETRY":
          dispatch(setStep(2));
          break;
        default:
          break;
      }
    };

    handleNavigation();
  }, [navigate, KYCState, dispatch, AdditionalDetails]);

  if (loading) {
    return <div>Loading...</div>; // Placeholder for loading state
  }

  return (
    <div className="py-6 lg:py-16 md:w-2/3 mx-auto">
      <div className="bg-gray-50 shadow p-6 xl:px-20 lg:py-16 w-full rounded-xl">
        <div className="text-center">
          <img src={kycIcon} alt="KYC Icon" className="inline-block" />
          <p className="text-xl font-semibold mt-4">
            Let’s verify your identity
          </p>
          <p className="text-xs text-[#545454] mt-1">
            Completing KYC is a required step to unlock access to our exciting
            features.
          </p>
        </div>

        <div>
          <p className="text-center text-[#202020] my-6">
            3 simple steps to complete KYC
          </p>
          {[
            {
              number: 1,
              mainText: "Provide your personal information",
              secText: "Your legal name, date of birth, etc.",
            },
            {
              number: 2,
              mainText: "Upload your ID",
              secText:
                "Passport, voters card, driver’s license, or national ID (NIN)",
            },
            {
              number: 3,
              mainText: "Upload your selfie",
              secText: "Selfie of you holding your ID",
            },
          ].map((step) => (
            <KycCard
              key={step.number}
              number={step.number}
              mainText={step.mainText}
              secText={step.secText}
            />
          ))}
        </div>

        <p className="text-[#747A80] text-sm">
          Ensure your documents are valid and you’ll be verified within 1 day.
        </p>

        <div className="flex justify-end mt-10">
          <Link
            to="/kyc-info"
            className="bg-cleva-gold text-[#111111] font-bold text-sm py-3 px-4 md:px-6 rounded-lg"
          >
            <div className="flex items-center">
              <p>Get Started</p>
              <FiChevronRight className="text-base font-bold ml-3" />
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default KYC;
