import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch } from "../../../app/hooks";
import { NewImageUploadInput } from "./NewImageUploadInput";
import { getKyc, updateKyc } from "../../../api";
import "react-dropzone-uploader/dist/styles.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/PopUps/MainLoader";
import SuccessModal from "../../../components/PopUps/SuccessModal";
import NINImg from "../../../asset/kyc/NIN.png";
import SelfieImg from "../../../asset/kyc/SELFIE.png";
import CameraIcon from "../../../asset/kyc/cameraIcon.svg";
import { HiOutlineX } from "react-icons/hi";

import { ToastContainer, toast } from "react-toastify";
import { validateNINDocument } from "../../../features/Kyc/KycApi";

interface IDoc {
  key: any;
  index?: any;
  currentStep?: number;
  nextStep?: any;
  saveForLater?: any;
}

const NINDocumnent = (props: IDoc) => {
  const [documentUpload, setDocumentUpload] = useState<any>({});
  const [isDocumentUploadButtonDisabled, setDocumentUploadButtonDisabled] =
    useState(true);
  const [isSelfieUploadButtonDisabled, setSelfieUploadButtonDisabled] =
    useState(true);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [fileError, setFileError] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isCameraActive, setIsCameraActive] = useState(false);
  const [selfieImage, setSelfieImage] = useState<any>(null);
  const videoRef = useRef<any>(null);

  const canvasRef = useRef<any>(null);
  const dispatch = useAppDispatch();

  // Activate the camera
  // const activateCamera = async () => {
  //   setIsCameraActive(true);
  //   const stream = await navigator.mediaDevices.getUserMedia({ video: true });
  //   videoRef.current.srcObject = stream;
  //   videoRef.current.play();
  // };

  const activateCamera = async () => {
    setIsCameraActive(true);
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: "user" },
      });
      videoRef.current.srcObject = stream;
      videoRef.current.play();
    } catch (error) {
      console.error("Error accessing the camera:", error);
    }
  };

  const captureSelfieImage = () => {
    const canvas = canvasRef.current;
    const video = videoRef.current;

    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    const ctx = canvas.getContext("2d");
    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
    // Convert the image to a data URL and then to a File object
    canvas.toBlob((blob: any) => {
      if (blob) {
        const file = new File([blob], "selfie.png", {
          type: "image/png",
          lastModified: Date.now(),
        });
        setSelfieImage(file);

        // Stop the video stream
        video.srcObject.getTracks().forEach((track: any) => track.stop());
        setIsCameraActive(false);
        const filename = file?.name;

        setSelfieUploadButtonDisabled(!filename);
      }
    }, "image/png");
  };

  const selfieSize = (selfieImage?.size / 1024).toFixed(2);

  const removeSelfie = () => {
    setSelfieImage(null);
    setIsCameraActive(false);
    setSelfieUploadButtonDisabled(true);
    setIsButtonDisabled(true);
  };

  const handleDocumentInputChange = (field: string, value: any) => {
    const updatedData = { ...documentUpload, [field]: value };
    const filename = updatedData["uploaded image"]?.file?.name;

    setDocumentUpload(updatedData);

    setDocumentUploadButtonDisabled(!filename || fileError);

    // Show the selfieUpload field when an ID type is selected
    // setShowSelfieUpload(!!value);
  };

  const hasFileErrors = () => {
    return fileError;
  };

  const getContentTypeConfig = (contentType: any) => {
    return {
      headers: {
        "Content-Type": contentType,
      },
    };
  };

  const DocContentType = documentUpload["uploaded image"]?.file?.type;
  const SelfieContentType = selfieImage?.type;

  //convert file to base 64
  const fileToBase64 = (file: File): Promise<string | null> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  };

  //remove data prefix from base64 string
  function removeBase64Prefix(base64String: string): string {
    return base64String.replace(/^data:image\/[a-zA-Z]+;base64,/, "");
  }

  // rewrite function for better experience
  const handleUploadButton = async () => {
    if (fileError) return;

    const KYCIdentifier: string | null = localStorage.getItem("KYCIdentifier");
    if (!KYCIdentifier) {
      console.error("No KYC Identifier found.");
      return;
    }

    setLoading(true);

    try {
      const response = await getKyc(KYCIdentifier);
      const responseData = response?.data?.IndividualKyc;
      if (!responseData) {
        console.error("No KYC data found.");
        return;
      }

      const { AdditionalDetails } = responseData;
      const DocFile = documentUpload["uploaded image"].file;
      const DocConfig = getContentTypeConfig(DocContentType);
      const SelfieConfig = getContentTypeConfig(SelfieContentType);
      const base64File = await fileToBase64(DocFile);
      const base64String = removeBase64Prefix(base64File ?? " ");

      const [docUploadUrl, selfieUploadUrl] = [
        AdditionalDetails?.IDUploadUrl?.url,
        AdditionalDetails?.SelfieUploadUrl?.url,
      ];

      if (!docUploadUrl || !selfieUploadUrl) {
        console.error("Missing upload URLs.");
        return;
      }

      // Upload document and selfie

      // Validate for NIN documnent Type
      const isDocTypeValid = await dispatch(
        validateNINDocument({
          imageString: base64String,
          extensionType: DocContentType,
        })
      ).unwrap();

      if (isDocTypeValid) {
        await Promise.all([
          axios.put(docUploadUrl, DocFile, DocConfig),
          axios.put(selfieUploadUrl, selfieImage, SelfieConfig),
        ]);

        const IDDocument = {
          DocumentType: "NATIONAL_ID",
          ContentType: DocConfig.headers["Content-Type"],
          Filename: DocFile.name,
        };

        const SelfieDocument = {
          ContentType: SelfieConfig.headers["Content-Type"],
          Filename: selfieImage?.name,
          Size: selfieImage?.size,
        };

        responseData.IdentificationDocument = IDDocument;
        responseData.ImageUploadItem = SelfieDocument;
        responseData.DocumentVerificationPending = true;

        await updateKyc(KYCIdentifier, { IndividualKyc: responseData });

        setShowSuccessModal(true);
      } else {
        console.error("Invalid document type:", isDocTypeValid?.Message);
        toast.error(isDocTypeValid?.Message || "Document type is not valid.");
        setLoading(false); // Stop loading here as it is not an exception but a handled case
        return;
      }
    } catch (error: any) {
      let errorMessage = "Error updating KYC. Please try again later.";

      if (error?.response?.data) {
        try {
          const errorData = error.response.data;
          // const errorData = JSON.parse(error.response.data); // Parse the JSON string
          // Parse the JSON string
          if (errorData?.Message) {
            errorMessage = errorData.Message;
          }
        } catch (e) {
          console.error("Error parsing response data:", e);
        }
      }

      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  // const handleUploadButton = async () => {
  //   if (fileError) {
  //     return;
  //   }

  //   const KYCIdentifier: any = localStorage.getItem("KYCIdentifier");
  //   setLoading(true);

  //   try {
  //     const response = await getKyc(KYCIdentifier);
  //     const responseData = response?.data?.IndividualKyc;

  //     const DocConfig = getContentTypeConfig(DocContentType);
  //     const SelfieConfig = getContentTypeConfig(SelfieContentType);

  //     const DocUpload = `${responseData.AdditionalDetails.IDUploadUrl.url}`;
  //     const url = DocUpload;
  //     const DocFile = documentUpload["uploaded image"].file;

  //     await axios.put(url, DocFile, DocConfig);

  //     const selfieUrl = `${responseData.AdditionalDetails.SelfieUploadUrl.url}`;
  //     const selfieFile = selfieImage;

  //     await axios.put(selfieUrl, selfieFile, SelfieConfig);

  //     const base64File = await fileToBase64(DocFile);
  //     const base64String = removeBase64Prefix(base64File ?? " ");
  //     console.log("Base64 File:", base64String);

  //     // Check the document type before proceeding
  //     try {
  //       const isDocTypeValid = await dispatch(
  //         validateNINDocument({
  //           imageString: base64String, // Pass base64 string here
  //         })
  //       ).unwrap();
  //       if (isDocTypeValid) {
  //         console.log("validity", isDocTypeValid);
  //         if (responseData) {
  //           const IDDocument = {
  //             DocumentType: "NATIONAL_ID",
  //             DocumentNumber: "",
  //             IssuingCountry: "",
  //             IssueDate: "",
  //             ExpirationDate: "",
  //             ContentType: DocConfig.headers["Content-Type"],
  //             Filename: documentUpload["uploaded image"]?.file?.name,
  //           };
  //           const SelfieDocument = {
  //             ContentType: SelfieConfig.headers["Content-Type"],
  //             Filename: selfieImage?.name,
  //             Path: "",
  //             Size: selfieImage?.size,
  //           };

  //           responseData.IdentificationDocument = IDDocument;
  //           responseData.ImageUploadItem = SelfieDocument;
  //           responseData.DocumentVerificationPending = true;

  //           await updateKyc(KYCIdentifier, { IndividualKyc: responseData });
  //           setLoading(false);
  //           setShowSuccessModal(true);
  //         } else {
  //           if (AWS_CREDS.STAGE === "qa") {
  //             console.error("No KYC data found.");
  //           }
  //         }
  //       }
  //     } catch (error) {
  //       console.error(error);
  //     }

  //     // if (responseData && isDocTypeValid) {
  //     //   const IDDocument = {
  //     //     DocumentType: "NATIONAL_ID",
  //     //     DocumentNumber: "",
  //     //     IssuingCountry: "",
  //     //     IssueDate: "",
  //     //     ExpirationDate: "",
  //     //     ContentType: DocConfig.headers["Content-Type"],
  //     //     Filename: documentUpload["uploaded image"]?.file?.name,
  //     //   };
  //     //   const SelfieDocument = {
  //     //     ContentType: SelfieConfig.headers["Content-Type"],
  //     //     Filename: selfieImage?.name,
  //     //     Path: "",
  //     //     Size: selfieImage?.size,
  //     //   };

  //     //   responseData.IdentificationDocument = IDDocument;
  //     //   responseData.ImageUploadItem = SelfieDocument;
  //     //   responseData.DocumentVerificationPending = true;

  //     //   await updateKyc(KYCIdentifier, { IndividualKyc: responseData });
  //     //   setLoading(false);
  //     //   setShowSuccessModal(true);
  //     // } else {
  //     //   if (AWS_CREDS.STAGE === "qa") {
  //     //     console.error("No KYC data found.");
  //     //   }
  //     // }
  //   } catch (error: any) {
  //     toast.error(error?.response?.data);
  //     if (AWS_CREDS.STAGE === "qa") {
  //       console.error("Error updating KYC:", error);
  //     }
  //
  //   }

  //   setLoading(false);
  // };

  const handleSaveForLater = () => {
    const dataToSave = {
      documentUpload,
      selfieImage,
    };

    // Convert the data to a JSON string and save it to local storage
    localStorage.setItem("kycData", JSON.stringify(dataToSave));

    setShowSaveModal(true);
  };

  const closeSuccessModal = () => {
    navigate("/kyc-pending");
    setShowSuccessModal(false);
  };

  const closeSaveModal = () => {
    navigate("/");
    setShowSuccessModal(false);
  };

  useEffect(() => {
    setIsButtonDisabled(hasFileErrors());
  }, [fileError]);

  // const BVNKYC: boolean =
  //   KYCState === "MISMATCH" && AdditionalDetails.NoOfRetriesLeft === 0;

  return (
    <>
      {loading ? (
        <Loader text="Uploading documents" />
      ) : (
        <div className="md:w-[55%] sm:w-[50%] mb-2">
          <section className="bg-white mt-4 rounded-xl">
            <div className="bg-[#F2F2F2]  px-2 md:px-8 py-2">
              <p className="text-lg font-semibold">
                1. Upload a live selfie (fully clothed)
                <span className="text-[#D31D1D]">*</span>
              </p>
            </div>
            <div className="px-2 md:px-8 py-4">
              {!selfieImage ? (
                <>
                  <input
                    style={{ display: "none" }}
                    type="file"
                    accept="image/*"
                    capture="environment"
                    ref={videoRef}
                    onChange={() => {}}
                  />
                  {isCameraActive ? (
                    <>
                      <video
                        ref={videoRef}
                        autoPlay
                        playsInline
                        muted
                        style={{ width: "100%", height: "auto" }}
                        className="w-full max-w-md"
                      />
                      <button
                        className="btn bg-cleva-gold py-2 px-4 mt-2 rounded-lg w-[100%]"
                        onClick={captureSelfieImage}
                      >
                        Take Selfie
                      </button>
                    </>
                  ) : (
                    <>
                      <p className="text-sm text-[#172323]">
                        Here’s a sample document for your reference:
                      </p>

                      <img className="py-2" alt="sample ID" src={SelfieImg} />

                      <button
                        className="btn border-[#747A80] flex justify-center items-center bg-[#F9FAFA] text-sm  w-[100%] border-[2.5px] border-dotted rounded-[13px] md:p-5 p-3 mt-3"
                        onClick={activateCamera}
                      >
                        <img src={CameraIcon} alt="" />
                        <p className="ml-3 text-[#0B69AD] font-medium text-base">
                          Click to take selfie
                        </p>
                      </button>
                    </>
                  )}
                </>
              ) : (
                <>
                  <div className="bg-[#E8F4FF] border-[2.5px] border-dotted  border-[#747A80] text-sm py-2  rounded-[13px] flex  justify-between px-5 cursor-pointer ">
                    <div className="">
                      <img
                        src={URL.createObjectURL(selfieImage)}
                        alt="Captured selfie"
                        className="max-w-md mt-2 md:h-[13rem] h-[12rem] md:w-[24rem]"
                      />

                      <p className="mt-3 mb-0">{selfieImage.name}</p>
                      <p className="text-[13px] text-[#747A80] font-semibold pt-1w2">
                        {selfieSize} KB
                      </p>
                    </div>
                    <div className="w-[10%]">
                      <button
                        className="btn text-center md:w-[3rem] md:h-[3rem] h-[1.5rem] w-[1.5rem] rounded-full  flex border bg-slate-200 shadow-sm justify-center items-center"
                        onClick={removeSelfie}
                      >
                        <HiOutlineX className="text-[28px] text-[#747A80]" />
                      </button>
                    </div>
                  </div>
                </>
              )}
              <canvas ref={canvasRef} style={{ display: "none" }} />
            </div>
          </section>

          <section className="bg-white rounded-xl mt-6">
            <div className="bg-[#F2F2F2]  px-2 md:px-8 py-2">
              <p className="text-lg font-semibold">
                2. Upload your NIN ID
                <span className="text-[#D31D1D]">*</span>
              </p>
            </div>
            <div className="px-2 md:px-8 py-4">
              <p className="text-sm text-[#172323]">
                Here’s a sample document for your reference:
              </p>
              <img alt="sample ID" src={NINImg} />

              <div className="mt-3">
                <NewImageUploadInput
                  index={props.index}
                  name="ID Documents"
                  show={true}
                  document={documentUpload}
                  fileError={fileError}
                  onChange={(field, value, fileError) => {
                    handleDocumentInputChange(field, value);
                    setFileError(fileError);
                  }}
                />
              </div>
            </div>
          </section>

          <div className="md:mt-16 mt-12">
            <button
              onClick={handleUploadButton}
              className={`${
                isSelfieUploadButtonDisabled ||
                isDocumentUploadButtonDisabled ||
                isButtonDisabled
                  ? "bg-cleva-gold opacity-50 cursor-not-allowed"
                  : "bg-cleva-gold"
              } mb-2 block w-[100%] py-3 rounded-lg text-sm font-bold text-black`}
              disabled={
                isSelfieUploadButtonDisabled ||
                isDocumentUploadButtonDisabled ||
                isButtonDisabled
              }
            >
              {loading ? "Uploading..." : "Upload Document"}
            </button>

            <button
              className="border border-[#ADADADB2] rounded-lg block w-[100%] py-3 text-sm"
              onClick={handleSaveForLater}
            >
              Save for later
            </button>
          </div>
        </div>
      )}

      {showSuccessModal && (
        <SuccessModal
          btnText="Continue"
          text="Documents uploaded successfully!"
          onClose={closeSuccessModal}
        />
      )}

      {showSaveModal && (
        <SuccessModal
          btnText="Continue"
          text="KYC saved for Later"
          onClose={closeSaveModal}
        />
      )}

      <ToastContainer />
    </>
  );
};

export default NINDocumnent;
