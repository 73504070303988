import { useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import Profile_pop from "../PopUps/Profile_pop";
import { useAppSelector } from "../../app/hooks";
import Menu from "../../asset/landing_photos/hamburger.svg";
import Down from "../../asset/svg/arrowDown.svg";
import BackButton from "../Buttons/BackButton";

interface iNav {
  handleClick: () => void;
  isOpen: boolean;
  backButton?: boolean;
}

export default function Nav(props: iNav) {
  const [modal, setModal] = useState(false);
  const user = useAppSelector((state) => state.user.userData);
  const { title } = useAppSelector((state) => state.navbar);
  const { handleClick, backButton } = props;

  const handleModal = () => {
    setModal((prevModal) => !prevModal);
  };
  const closeModal = () => {
    setModal(false);
  };

  // const getNameFromPath = (path: string): string => {
  //   const pathSegments = path.split("/").filter((segment) => segment !== ""); // Split the path into segments
  //   let name = "";

  //   // Determine the name based on the route segments
  //   if (pathSegments.length > 0) {
  //     const lastSegment = pathSegments[pathSegments.length - 1];
  //     const secondLastSegment = pathSegments[pathSegments.length - 2];

  //     if (lastSegment === "view" || lastSegment === "confirm") {
  //       // Special case for /accounts/view or /transfer/view route
  //       name =
  //         secondLastSegment.charAt(0).toUpperCase() +
  //         secondLastSegment.slice(1);
  //     } else if (lastSegment === "create") {
  //       // Special case for /transfer/create route
  //       name =
  //         secondLastSegment.charAt(0).toUpperCase() +
  //         secondLastSegment.slice(1);
  //     } else {
  //       name = lastSegment.charAt(0).toUpperCase() + lastSegment.slice(1);
  //     }
  //   }

  //   return name;
  // };

  // const activeNavLink = location.pathname;

  return (
    <div className={`m-auto `}>
      <section className="flex items-center justify-between">
        <div className="flex items-center justify-evenly gap-4">
          <button
            onClick={handleClick}
            className="flex items-center  w-[2rem] h-[2rem] justify-center bg-[#000] rounded-[50%] lg:hidden"
          >
            <img
              className="w-[1rem]"
              src={Menu}
              alt="Open sidebar Navigation"
            />
          </button>
          <div className="flex items-center">
            {backButton && <BackButton />}

            <b className=" font-extrabold text-lg ml-3">{title}</b>
          </div>
        </div>
        <div className="flex items-center w-[20%] md:w-[12rem] justify-evenly">
          <span className="hidden border-2 items-center gap-1 px-2 rounded-md w-[70%] text-[18px] ">
            <AiOutlineSearch />
            <input
              className="h-9 text-[12px] outline-none border-none "
              type="text"
              placeholder="Type to search ..."
            />
          </span>
          {/* <span className="border-2 p-[10px]  rounded-md ">
            <AiOutlineBell />
          </span> */}

          {/* <span
            onClick={() => handleModal()}
            className="rounded-full cursor-pointer overflow-hidden w-10 h-10 bg-[#F2F2F2] border-2"
          >
            <img src={user_img} className="w-full h-[100%] object-cover" />
          </span> */}

          <div className="flex items-center">
            <span
              onClick={() => handleModal()}
              className=" cursor-pointer rounded-full w-10 h-10 bg-[#F2F2F2] border-2 py-[6px] px-2 flex items-center justify-center"
            >
              <b className="flex justify-center items-center">
                {user?.FullName?.FirstName[0]}
                {user?.FullName?.LastName[0]}
              </b>
            </span>
            <button className="pt-[.5rem]" onClick={() => handleModal()}>
              <img src={Down} alt="View your profile or logout" />
            </button>
          </div>
        </div>
      </section>
      {modal && (
        <Profile_pop handleModal={handleModal} closeModal={closeModal} />
      )}
    </div>
  );
}
