import React, { useEffect, useState } from "react";
import logo from "../../asset/images/logo.svg";
import authImg from "../../asset/images/login-img.svg";
import emailIcon from "../../asset/images/email.svg";
import emailErrorImg from "../../asset/images/email-error.svg";
// import { CognitoUser, Cognito } from "amazon-cognito-identity-js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import AuthServices from "../../features/services/AuthServices";
import Spinner from "../PopUps/Spinner";
import Logo from "../../asset/logo.svg";
import { classNames } from "../Tabs/TabButton";
import { AWS_CREDS } from "../../features/services/AmazonService";

const RenderHTMLTemplate = ({ htmlTemplate }: any) => {
  return (
    <div>
      {/* Render the HTML template */}
      {React.createElement("div", {
        dangerouslySetInnerHTML: { __html: htmlTemplate },
      })}
    </div>
  );
};

export const EMAIL_SENT = "EmailSent";
const VerifyEmail = () => {
  const { contact, otp } = useParams();
  const [otpResponseMessage, setOtpResponseMessage] = useState<string>(
    "About to verify your email"
  );
  const [formValid, setFormValid] = useState<boolean>(false);
  const [loading] = useState<boolean>(false);
  const [isSpinner, setIsSpinner] = useState(true);
  const [messageAlert, setMessageAlert] = useState(contact);
  const [token] = useState(otp);
  const [email] = useState(contact);
  const [error, setError] = useState(false);
  const [confirmed, setConfirmed] = useState(false);

  const navigate = useNavigate();
  const invalidOrMistMatchToken = `<span className="mt-6 text-red-500 text-sm">Invalid Verification link</span>`;
  const aliasUserExist = `<span className="mt-6 text-[#158025] text-sm"> Your email has already been verified</span>`;
  const success = `<span className="mt-6 text-[#158025]  text-sm"> Email verified successfully</span>`;
  const expiredToken = `<span className="mt-6 text-red-500 text-sm"> Verification link has expired</span>`;
  const verificationFailed = `<span className="mt-6 text-red-500 text-sm"> Failed to verify email using token.</span>`;
  const userNotFound = `<span className="mt-6 text-red-500 text-sm"> Email address not found.</span>`;
  const limitExceeded = `<span className="mt-6 text-red-500 text-sm"> Attempt limit exceeded, please try again after some time.</span>`;

  const invalidOrMistMatchTokenContext =
    "The verification link you provided is no longer valid. Please check your email for the most recent verification link";
  const AliasExistsExceptionContext = "Please log in to your cleva account";
  const expiredTokenContext =
    "Please check your email for the most recent verification link.";

  // handle form submit and send params to amanzon cognito
  const handleSubmit = async () => {
    try {
      sessionStorage.setItem(EMAIL_SENT, "true");
      await AuthServices.confirmSignUp(email!, token!);
      setFormValid(true);
      setIsSpinner(false);
      setOtpResponseMessage(success);
      setConfirmed(true);
      // }
    } catch (error: any) {
      setIsSpinner(false);
      if (AWS_CREDS.STAGE === "qa") {
        console.log(error.message);
      }
      if (error.toString().includes("CodeMismatchException")) {
        setOtpResponseMessage(invalidOrMistMatchToken);
        setMessageAlert(invalidOrMistMatchTokenContext);
        setFormValid(false);
        setError(true);
        setConfirmed(false);
      } else if (error.toString().includes("ExpiredCodeException")) {
        AuthServices.resendOTP(email!);
        setOtpResponseMessage(expiredToken);
        setMessageAlert(expiredTokenContext);
        setFormValid(false);
        setError(true);
        setConfirmed(false);
      } else if (error.toString().includes("AliasExistsException")) {
        setOtpResponseMessage(aliasUserExist);
        setMessageAlert(AliasExistsExceptionContext);
        setFormValid(true);
        setConfirmed(true);
        setError(false);
      } else if (error.toString().includes("NotAuthorizedException")) {
        setOtpResponseMessage(aliasUserExist);
        setMessageAlert(AliasExistsExceptionContext);
        setFormValid(true);
        setConfirmed(true);
        setError(false);
      } else if (error.toString().includes("UserNotFoundException")) {
        setOtpResponseMessage(userNotFound);
        setFormValid(false);
        setConfirmed(false);
        setError(true);
      } else if (error.toString().includes("LimitExceededException")) {
        setOtpResponseMessage(limitExceeded);
        setFormValid(false);
        setConfirmed(false);
        setError(true);
      } else {
        setFormValid(false);
        setOtpResponseMessage(verificationFailed);
        setMessageAlert(contact);
        toast.error(error.message);
        setError(true);
        setConfirmed(false);
      }
    }
  };
  useEffect(() => {
    handleSubmit();
  }, []);

  const handleRedirect = (e: any) => {
    e.preventDefault();
    navigate("/auth/login");
  };

  return (
    <>
      <div className="md:flex min-h-full">
        <div className="hidden lg:flex md:flex-col justify-center flex-1 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-20 bg-black text-white ">
          <div className="mx-auto w-full max-w-[24rem] 2xl:max-w-[28rem] lg:w-[30rem] xl:w-[28rem]">
            <div className="flex justify-center pt-20 min-h-[100vh] md:items-center md:pt-0">
              <div className="w-full">
                <div>
                  <Link to="/">
                    <img className="w-auto mt-2" src={logo} alt="logo" />
                  </Link>
                  <h1 className="mt-12 text-4xl font-medium text-white">
                    Verify Your Email
                  </h1>
                </div>

                <img src={authImg} className="mt-20" alt="login" />
              </div>
            </div>
          </div>
        </div>
        <div className="relative flex-1 bg-[#FBFBFB]">
          <div className="flex justify-center min-h-[100vh] md:items-center md:pt-0">
            {isSpinner ? (
              <Spinner />
            ) : (
              <div className="login-card w-full md:w-[32rem] xl:w-[36rem] px-8 py-16 md:px-20">
                <div className="hidden justify-center pb-8">
                  <img src={Logo} alt="Cleva banking logo" />
                </div>

                <div className="text-center ">
                  <div className="flex justify-center">
                    <img src={error ? emailErrorImg : emailIcon} alt="email" />
                  </div>

                  <h2
                    className={classNames(
                      confirmed
                        ? "text-[#158025] font-medium"
                        : "text-black-soft ",
                      "text-2xl text-medium  mt-6"
                    )}
                  >
                    <RenderHTMLTemplate htmlTemplate={otpResponseMessage} />
                  </h2>
                  <p className="text-[#5F5D5D] w-[20rem] mx-auto text-sm mt-4">
                    <span
                      className={classNames(
                        confirmed ? "text-black-soft" : "text-[#935B06]"
                      )}
                    >
                      {messageAlert}
                    </span>
                  </p>
                  <div className="mt-7">
                    <button
                      type="button"
                      onClick={(e) => handleRedirect(e)}
                      disabled={!formValid}
                      className={formValid ? "login-active" : "login-disabled"}
                    >
                      {loading ? "Loading ..." : "Go To Login"}
                    </button>
                  </div>
                  {/* form section  */}
                  {/*<form onSubmit={handleSubmit} className=" mt-8">*/}
                  {/*  <div className="w-[20rem] mx-auto">*/}
                  {/*    <OtpField*/}
                  {/*      value={otp}*/}
                  {/*      onChange={setOtp}*/}
                  {/*      numInputs={6}*/}
                  {/*      onChangeRegex={/^([0-9]{0,})$/}*/}
                  {/*      autoFocus*/}
                  {/*      separator={<span> </span>}*/}
                  {/*      isTypeNumber={false}*/}
                  {/*      inputProps={{*/}
                  {/*        className: "otp-field__input",*/}
                  {/*        disabled: false,*/}
                  {/*      }}*/}
                  {/*    />*/}
                  {/*  </div>*/}

                  {/* <div className="mt-9 text-center">
                    <p className="text-[#8F8F8F] text-sm ">
                      Resend code <span className="text-cleva-gold">50s</span>
                    </p>
                  </div> */}
                  {/*</form>*/}
                </div>
              </div>
            )}
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
};

export default VerifyEmail;
