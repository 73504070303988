import React, { useEffect, useState } from "react";
import AllRecipients from "./pages/AllRecipients";
import NoRecipients from "./pages/NoRecipient";
import Spinner from "../../components/PopUps/Spinner";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setTitle } from "../../features/Nav/NavSlice";
import { fetchRecipients } from "../../features/Recipients/RecipientsApi";
import TabButtons from "../../components/Tabs/TabButton";
import { BsPersonPlusFill } from "react-icons/bs";
import { setModalState } from "../../features/Kyc/kycSlice";
import { ToastContainer } from "react-toastify";
import TabContent from "../../components/Tabs/TabContent";
import AllTags from "./pages/AllTags";
import AddRecipient from "./modals/AddRecipient";
import { useLocation, useNavigate } from "react-router-dom";

export default function Recipients() {
  const { modalState } = useAppSelector((state) => state.kycInfo);
  const { loading, recipients } = useAppSelector((state) => state.recipient);
  const [activeTab, setActiveTab] = useState<string>("BANK");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const currentTabRecipients = recipients?.filter((info: any) => {
    if (activeTab === "BANK")
      return (
        !info.RecipientType ||
        info.RecipientType === activeTab ||
        info.RecipientType === "Recipient"
      );
    return info.RecipientType === activeTab;
  });

  const handleCloseModal = () => {
    dispatch(setModalState(false));
  };

  useEffect(() => {
    dispatch(setTitle("Recipients"));
    dispatch(fetchRecipients());
  }, [dispatch]);

  const addRecipient = () => {
    if (activeTab === "BANK") {
      dispatch(setModalState(true));
    } else if (activeTab === "TAG") {
      navigate("/tag-recipient");
    }
  };

  useEffect(() => {
    if (location.state?.activeTab) {
      setActiveTab(location.state.activeTab);
    }
  }, [location]);

  return (
    <>
      <header
        className="md:flex justify-between items-center
          text-sm  xs:flex-wrap md:flex-nowrap md:mb-4
          "
      >
        <div className="z-2 w-full md:mb-8 md:w-1/2 order-0 md:order-0 pt-5 mb-5">
          <ul className="grid grid-cols-2 gap-4 bg-[#EBF0F0] rounded-[10px] px-2 py-1.5">
            <TabButtons
              name="Local Banks"
              id="BANK"
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
            <TabButtons
              name="Cleva Tags"
              id="TAG"
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          </ul>
        </div>

        <div className="md:w-[70%] order-1 md:order-2 w-100 mb-3 md:mb-0 right">
          {currentTabRecipients! && currentTabRecipients!.length > 0 && (
            <button
              onClick={() => addRecipient()}
              className="btn flex bg-cleva-gold items-center border-[0.5px] border-[#8A8A8A] py-2 px-4 rounded-lg gap-1"
              style={{ float: "right" }}
            >
              <span>
                <BsPersonPlusFill />
              </span>
              <span>Add new recipient</span>
            </button>
          )}
        </div>
      </header>

      {!loading ? (
        <div className="md:mt-4">
          <TabContent id="BANK" activeTab={activeTab}>
            {recipients?.filter((info: any) => info.RecipientType === "BANK")
              ?.length ? (
              <AllRecipients />
            ) : (
              <NoRecipients addRecipient={addRecipient} />
            )}
          </TabContent>
          <TabContent id="TAG" activeTab={activeTab}>
            {recipients?.filter((info: any) => info.RecipientType === "TAG")
              .length ? (
              <AllTags />
            ) : (
              <NoRecipients addRecipient={addRecipient} />
            )}
          </TabContent>
        </div>
      ) : (
        <Spinner />
      )}
      <ToastContainer />
      {modalState && <AddRecipient closeModal={handleCloseModal} />}
    </>
  );
}
