import React, { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import UsIcon from "../../../../images/USD.svg";
import { RootState } from "../../../../app/store";
import { useSelector } from "react-redux";
import Spinner from "../../../../components/PopUps/Spinner";
import CardTransferDetails from "../../../../components/Layout/CardTransferDetails";
import { convertTime } from "../../../Accounts/components/AccountUtils";

type ModalProps = {
  closeModal: () => void;
  props?: any;
};

const ViewCardTransfer: React.FC<ModalProps> = ({ closeModal, props }) => {
  const [open] = useState(true);
  const cancelButtonRef = useRef(null);
  // const [activeTab, setActiveTab] = useState<string>("status");

  const loading = useSelector((state: RootState) => state.clevaCards.loading);

  // const today = new Date().toLocaleString();

  // const dispatch = useDispatch<AppDispatch>();

  let createdDate = props ? (props as any).CreatedAt : "";

  const displayDate = convertTime(new Date(createdDate));

  const CreditCard = props ? (props as any).Entry === "CREDIT" : " ";
  // const CardCreation = props ? (props as any).Entry === "CARD_CREATION" : " ";
  // const DebitCard = props ? (props as any).Entry === "DEBIT" : " ";

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={closeModal}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white pb-8 text-left shadow-xl transition-all sm:my-8 sm:w-full md:max-w-[28rem]  w-[28rem]">
                <header
                  className={`w-full bg-[#EFEFEF] py-4 px-8  flex justify-between`}
                >
                  <Dialog.Title
                    as="h3"
                    className="text-base font-bold leading-6 text-gray-900"
                  >
                    Transaction Details
                  </Dialog.Title>
                  <button
                    type="button"
                    className="rounded-md text-black hover:text-gray-500 focus:outline-none focus:ring-2 font-bold"
                    onClick={closeModal}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-4 w-4" aria-hidden="true" />
                  </button>
                </header>
                {loading ? (
                  <Spinner />
                ) : (
                  <div className="">
                    <div className="mt-3">
                      <div className="flex justify-between px-6 py-9 items-center">
                        <div className="flex items-center">
                          <img src={UsIcon} alt="" />
                          {CreditCard ? (
                            <p className="text-sm font-bold ml-2">
                              Card credit
                            </p>
                          ) : (
                            <p className="text-sm font-bold ml-2">Card debit</p>
                          )}
                        </div>

                        <div>
                          <p className="pl-10 md:pl-0 text-xs">{displayDate}</p>
                        </div>
                      </div>

                      <div className="mt-4 px-6 md:px-8">
                        <div>
                          <CardTransferDetails details={props} />
                        </div>

                        <footer>
                          <div className="pt-4">
                            <button
                              type="button"
                              className="text-sm 
                          py-3
                            rounded-md mt-4 
                            border border-[#35803F] w-[100%] text-[#35803F] bg-[#F9FFFA]"
                              onClick={closeModal}
                            >
                              Done
                            </button>
                          </div>
                        </footer>
                      </div>
                    </div>
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ViewCardTransfer;
