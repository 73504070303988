import React, { useState } from "react";
import { Link } from "react-router-dom";
import deleteIcon from "../../../asset/svg/deleteIcon.svg";
// import { toast } from "react-toastify";
// import { DeleteUser } from "../../../api";
// import { getUserIdWithAccessToken, removeAuthTokens } from "../../../login";
// import { AWS_CREDS } from "../../../features/services/AmazonService";
// import { resetBvnVerified } from "../../../features/ClevaCards/ClevaCardsSlice";
// import { setCompletedSteps, setStep } from "../../../features/Kyc/kycSlice";
// import { setUser } from "../../../features/User/UserSlice";
// import { AuthService } from "../../../features/services/AuthServices";
// import { useAppDispatch } from "../../../app/hooks";
import BackButton from "../../../components/Buttons/BackButton";

const ConfirmDeleteAccount = () => {
  // const [error, setError] = useState(false);
  const [loading] = useState(false);
  // const navigate = useNavigate();
  // const dispatch = useAppDispatch();

  const DeleteThisUser = async () => {
    // const userID = await getUserIdWithAccessToken();
    // try {
    //   setLoading(true);
    //   const response = await DeleteUser(userID);
    //   setLoading(false);
    //   if (AWS_CREDS.STAGE === "qa") {
    //     console.log(response);
    //   }
    //   if (response) {
    //     removeAuthTokens();
    //     AuthService.signOut();
    //     dispatch(setCompletedSteps([]));
    //     dispatch(setStep(1));
    //     dispatch(setUser(null));
    //     dispatch(resetBvnVerified());
    //     navigate("/");
    //   } else {
    //     if (AWS_CREDS.STAGE === "qa") {
    //       console.log(error);
    //     }
    //   }
    // } catch (err: any) {
    //   if (err?.response?.status === 403) {
    //     setError(true);
    //   } else {
    //     toast.error(err.message);
    //   }
    //   setLoading(false);
    // }
  };

  return (
    <>
      <div className="flex py-8 items-center">
        <BackButton />
        <p className="text-lg font-bold ml-3">Delete Account</p>
      </div>

      <div className="py-10 md:w-4/5 xl:w-3/4 md:px-4 xl:px-12 mx-auto">
        <p className="text-xl font-bold text-center">Please read and confirm</p>

        <div className="shadow bg-white mt-8 lg:p-16 p-5">
          <div className="flex justify-center mb-8">
            <img src={deleteIcon} alt="" />
          </div>
          <p className="text-xl mb-8 text-center">
            Are you sure you want to delete your account?
          </p>
          <p className="text-xl mb-10 text-center">
            This action cannot be undone.
          </p>

          <div className="flex items-center justify-between gap-4 mt-8">
            <div>
              <Link
                to="/profile"
                className="bg-cancel text-sm font-bold py-3 md:px-10 px-6 rounded-lg"
              >
                Cancel
              </Link>
            </div>
            <div>
              <button
                onClick={DeleteThisUser}
                className={`bg-cleva-gold text-sm font-bold py-3 md:px-10 px-6 rounded-lg ${
                  loading ? "opacity-50 cursor-not-allowed" : ""
                }`}
                disabled={loading}
              >
                {loading ? "Deleting..." : "Delete"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmDeleteAccount;
