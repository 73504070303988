export const RegisterCheckboxData = [
  {
    value: "A friend told me about Cleva",
    label: "A friend told me about Cleva",
  },
  { value: "From Twitter", label: "From Twitter" },
  { value: "From Linkedin", label: "From Linkedin" },
  { value: "From Google Search", label: "From Google Search" },
  { value: "From Youtube", label: "From Youtube" },
  { value: "From Tiktok", label: " From TikTok" },
  {
    value: "From Meta(Instagram or Facebook)",
    label: "From Meta (Instagram or Facebook)",
  },
  { value: "From Cafe One", label: "From Cafe One" },
  { value: "From an event I attended", label: "From an event I attended" },
];

export const isValidEmail = (email: string): boolean => {
  let regex = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");
  const isEmailFormatValid = regex.test(email);
  return isEmailFormatValid;
};

export const containsCleva = (email: string): boolean => {
  const [localPart] = email.toLowerCase().split("@");

  const containsClevaWord = /cleva/.test(localPart);
  return containsClevaWord;
};

export const containsFiveOrMoreDigits = (email: string): boolean => {
  const containsFiveOrMoreDigits = /\d.*\d.*\d.*\d.*\d/.test(email);
  return containsFiveOrMoreDigits;
};
