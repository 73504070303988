import React, { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import successIcon from "../../images/success.svg";

interface SuccessModalProps {
  onClose: () => void;
  text: string;
  secText?: string;
  btnText: string;
}

const SuccessModal: React.FC<SuccessModalProps> = ({
  onClose,
  text,
  secText,
  btnText,
}) => {
  const [open] = useState(true);

  const cancelButtonRef = useRef(null);
  const mobileStyle =
    "max-[768px]:top-[50%] max-[768px]:left-1/2  max-[768px]:w-full max-[768px]:h-[400px] max-[768px]:transform max-[768px]:-translate-x-1/2 max-[768px]:-translate-y-1/2";

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={onClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className={`fixed inset-0 z-10 overflow-y-auto ${mobileStyle}`}>
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center lg:ml-[256px] sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white py-10 px-16 text-center shadow-xl transition-all sm:my-8 sm:w-full md:max-w-md  w-[30rem]">
                <div className="modal">
                  <div className="flex justify-center mb-4">
                    <img src={successIcon} alt="successful" />
                  </div>
                  <p className="text-base font-semibold text-[#111111] mb-2">
                    {text}{" "}
                  </p>
                  <p className="text-[#494949] text-sm">{secText}</p>
                </div>

                <footer>
                  <div className="flex justify-center pt-3">
                    <button
                      type="submit"
                      className="text-sm 
                  font-bold py-3 md:px-10 px-6
                  ${btn_bg} 
                  float-right 
                  rounded-md mt-4 
                  bg-[#FFBD59]"
                      onClick={onClose}
                      // ref={}
                    >
                      {btnText}
                    </button>
                  </div>
                </footer>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default SuccessModal;
