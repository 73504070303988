import { DiCssTricks } from "react-icons/di";
import errorImg from "../../../asset/svg/material-symbols_warning.svg";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  setAdditionalDetails,
  setIndividualkyc,
  setKYCState,
} from "../../../features/Kyc/kycSlice";
import { SaveAndContinue } from "../../../components/Buttons/KycButtons";
import Loader from "../modals/loader";
import { useEffect, useRef, useState } from "react";
import MismatchModal from "../modals/mismatchModal";
import VerifiedModal from "../modals/verifiedModal";
import InvalidModal from "../modals/invalidModal";
import { createKyc } from "../../../api";
import { ToastContainer } from "react-toastify";
import Button from "../../../components/Layout/buttons/Button";
import { useNavigate } from "react-router-dom";
import BVNInvalidModal from "../modals/BVNInvalidModal";
import {
  handlePublicProfileChange,
  KYCCheckboxData as checkboxes,
  handleDropdownChange,
} from "./utils";
import { CheckboxComponent } from "../../../components/Auth/Register";
import { AWS_CREDS } from "../../../features/services/AmazonService";
import NameMismatchModal from "../modals/NameMismatch";
import BVNFailedModal from "../modals/BVNFailedModal";
import axios from "axios";
interface ISteps {
  currentStep?: number;
  nextStep?: any;
}
interface State {
  name: string;
  blacklisted: boolean;
}

function IndividualDetails(props: ISteps) {
  const [loading, setLoader] = useState(false);
  const [modal, setModal] = useState(false);
  const [bvnModal, setBVNModal] = useState(false);
  const user = useAppSelector((state) => state.user.userData);
  const { IndividualKyc, KYCState, AdditionalDetails } = useAppSelector(
    (state) => state.kycInfo
  );
  const [isBvnValid, setIsBvnValid] = useState(true);
  const [isProfileLinkValid, setIsProfileLinkValid] = useState(true);
  const [selectedBox, setSelectedBox] = useState<string[]>([]);
  const [showOtherText, setShowOtherText] = useState<boolean>(false);
  const [otherText, setOtherText] = useState<string>("");
  const [newText, setNewText] = useState<string>("");
  const [isOtherChecked, setIsOtherChecked] = useState<boolean>(false);
  const [userLessThan18, setUserLessThan18] = useState(false);
  const [errorModal, setErrorModal] = useState<boolean>(false);
  const [providerModal, setProviderModal] = useState<boolean>(false);
  const [states, setStates] = useState<State[]>([]);

  const StateList = AWS_CREDS.STATE_PUBLIC_URL;
  const [fNameErr, setFNameErr] = useState({
    invalidLength: false,
    invalidInput: false,
  });
  const [lNameErr, setLNameErr] = useState({
    invalidLength: false,
    invalidInput: false,
  });
  const [mNameErr, setMNameErr] = useState({
    invalidLength: false,
    invalidInput: false,
  });

  const [bvn, setBvn] = useState("");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isButtonDisabled =
    userLessThan18 ||
    fNameErr.invalidInput ||
    fNameErr.invalidLength ||
    lNameErr.invalidInput ||
    lNameErr.invalidLength ||
    mNameErr.invalidInput ||
    mNameErr.invalidLength ||
    IndividualKyc.Email === "" ||
    IndividualKyc.FirstName === "" ||
    IndividualKyc.LastName === "" ||
    IndividualKyc.DateOfBirth === "" ||
    IndividualKyc.MonthlyIncome === "" ||
    IndividualKyc.ProfileLink === "" ||
    !isProfileLinkValid ||
    (IndividualKyc.FundingSources && IndividualKyc.FundingSources.length < 1) ||
    (showOtherText === true && !otherText) ||
    IndividualKyc.NationalIdentifier === "" ||
    IndividualKyc.Address.StateOrTerritory === "" ||
    IndividualKyc.Address.City === "" ||
    !isBvnValid ||
    IndividualKyc.Address.StreetAddress === "";

  const dateInputRef = useRef<HTMLInputElement | null>(null);

  const validateNameLength = (value: string) => {
    return value.trim().length <= 50;
  };

  const containsLink = (value: string) => {
    const urlPattern = /(?:https?:\/\/|www\.)[^\s]+|[^\s]+\.[a-z]{2,}/i;
    return urlPattern.test(value);
  };

  const handleChange = (event: any) => {
    dispatch(setKYCState(""));
    dispatch(
      setIndividualkyc({
        ...IndividualKyc,
        [event.target.name]: event.target.value.trim(),
      })
    );
  };

  const handleDOBChange = (event: any) => {
    dispatch(setKYCState(""));
    const { value } = event.target;

    const dob = new Date(value);
    const today = new Date();

    const ageYears = today.getFullYear() - dob.getFullYear();
    const ageMonths = today.getMonth() - dob.getMonth();
    const ageDays = today.getDate() - dob.getDate();

    let isLessThan18 = false;
    if (ageYears < 18) {
      isLessThan18 = true;
    } else if (ageYears === 18) {
      if (ageMonths < 0 || (ageMonths === 0 && ageDays < 0)) {
        isLessThan18 = true;
      }
    }

    setUserLessThan18(isLessThan18);

    dispatch(
      setIndividualkyc({
        ...IndividualKyc,
        [event.target.name]: event.target.value.trim(),
      })
    );
  };

  const handleFirstNameChange = (event: any) => {
    const { name, value } = event.target;

    setFNameErr({
      ...fNameErr,
      invalidLength: false,
      invalidInput: false,
    });

    if (name === "FirstName") {
      setFNameErr({
        ...fNameErr,
        invalidLength: !validateNameLength(value),
        invalidInput: containsLink(value),
      });
    }

    dispatch(setKYCState(""));
    dispatch(
      setIndividualkyc({
        ...IndividualKyc,
        [event.target.name]: event.target.value.trim(),
      })
    );
  };
  const handleMiddleNameChange = (event: any) => {
    const { name, value } = event.target;

    setMNameErr({
      ...mNameErr,
      invalidLength: false,
      invalidInput: false,
    });

    if (name === "MiddleName") {
      setMNameErr({
        ...mNameErr,
        invalidLength: !validateNameLength(value),
        invalidInput: containsLink(value),
      });
    }

    dispatch(setKYCState(""));
    dispatch(
      setIndividualkyc({
        ...IndividualKyc,
        [event.target.name]: event.target.value.trim(),
      })
    );
  };
  const handleLastNameChange = (event: any) => {
    const { name, value } = event.target;

    setLNameErr({
      ...lNameErr,
      invalidLength: false,
      invalidInput: false,
    });

    if (name === "LastName") {
      setLNameErr({
        ...lNameErr,
        invalidLength: !validateNameLength(value),
        invalidInput: containsLink(value),
      });
    }

    dispatch(setKYCState(""));
    dispatch(
      setIndividualkyc({
        ...IndividualKyc,
        [event.target.name]: event.target.value.trim(),
      })
    );
  };

  const handleBVNChange = (event: any) => {
    dispatch(setKYCState(""));
    const newBvn = event.target.value.trim();
    setBvn(newBvn);

    if (newBvn.length === 11) {
      setIsBvnValid(true);
    } else {
      setIsBvnValid(false);
    }

    dispatch(
      setIndividualkyc({
        ...IndividualKyc,
        [event.target.name]: newBvn,
      })
    );
  };

  const handleInputChange = (event: any) => {
    handlePublicProfileChange(
      event,
      dispatch,
      setKYCState,
      setIsProfileLinkValid,
      setIndividualkyc,
      IndividualKyc
    );
  };

  const handleDropdown = (event: any) => {
    handleDropdownChange(
      event,
      dispatch,
      setKYCState,
      setIndividualkyc,
      IndividualKyc
    );
  };

  const handleBlur = () => {
    setNewText(otherText);
    // Perform actions when the input field loses focus
  };

  const handleotherText = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOtherText(event.target.value);
    setIsOtherChecked(true);
  };

  // Handle checkbox value
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const id = e.target.id;
    const isChecked = e.target.checked;

    setSelectedBox((prevSelected) => {
      const isSelected =
        prevSelected.includes(value) || prevSelected.includes(id) === isChecked;
      if (prevSelected.length !== 2 && value === "other") {
        setShowOtherText(true);
        setIsOtherChecked(true);
      }

      if (isSelected) {
        if (value === "other") {
          setShowOtherText(false);
          setIsOtherChecked(false);
          setOtherText("");
        }
        return prevSelected.filter(
          (item) => item !== value && item !== otherText
        );
      }
      if (prevSelected.length === 2) {
        return prevSelected;
      }

      return [...prevSelected, value];
    });
  };

  const fetchStates = async () => {
    try {
      const response = await axios.get(StateList);
      setStates(response.data?.states);
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };

  useEffect(() => {
    const modifiedSelectedBox = selectedBox.map((item) =>
      item === "other" ? otherText : item
    );
    setSelectedBox(modifiedSelectedBox);
  }, [newText]);

  useEffect(() => {
    dispatch(setKYCState(""));

    dispatch(
      setIndividualkyc({
        ...IndividualKyc,
        FundingSources: selectedBox,
      })
    );
  }, [selectedBox]);

  const handleFocus = () => {
    if (dateInputRef.current) {
      dateInputRef.current.showPicker();
    }
  };

  const handleAddressChange = (event: any) => {
    dispatch(setKYCState(""));
    const Address: any = {
      ...IndividualKyc?.Address,
      [event.target.name]: event.target.value,
    };
    dispatch(
      setIndividualkyc({
        ...IndividualKyc,
        Address,
      })
    );
  };

  const handleClose = (e: any) => {
    e.preventDefault();
    navigate(-1);
  };

  const proceed = () => {
    if (props.currentStep) {
      props.nextStep(props?.currentStep + 1);
    }
  };

  const validate = async () => {
    setLoader(true);

    const modifiedSelectedBox = selectedBox.map((item) =>
      item === "other" ? otherText : item
    );
    setSelectedBox(modifiedSelectedBox);

    try {
      const response = await createKyc({ IndividualKyc: IndividualKyc });
      if (AWS_CREDS.STAGE === "qa") {
        console.log(response);
      }
      setLoader(false);
      setModal(true);

      if (response?.data?.IndividualKyc) {
        localStorage.setItem("KYCIdentifier", response?.data?.KYCIdentifier);
        dispatch(setKYCState(response?.data?.IndividualKyc.KYCState));
        dispatch(
          setAdditionalDetails(response?.data?.IndividualKyc.AdditionalDetails)
        );
      }
    } catch (error: any) {
      setLoader(false);
      if (error?.response?.data?.Code === "NATIONAL_ID_DUPLICATE") {
        setBVNModal(true);
        if (AWS_CREDS.STAGE === "qa") {
          console.log(error);
        }
      } else if (
        error?.response?.data?.status === 504 ||
        error?.response?.data?.Code === "PROVIDER_DOWN"
      ) {
        setProviderModal(true);
        setModal(true);
      } else {
        setErrorModal(true);
        setModal(true);
      }
    }
  };

  //check for BVN verification status
  const NameMisMatch: boolean =
    KYCState === "BIOMETRICS" && AdditionalDetails.IsNameOnlyMismatch === true;
  // const successfulverification: boolean =
  //   KYCState === "BIOMETRICS" && AdditionalDetails.IsNameOnlyMismatch === false;
  const BVNKYC: boolean =
    KYCState === "MISMATCH" && AdditionalDetails.NoOfRetriesLeft === 0;
  const MismatchKYC: boolean =
    KYCState === "MISMATCH" && AdditionalDetails.NoOfRetriesLeft > 0;

  useEffect(() => {
    dispatch(
      setIndividualkyc({
        ...IndividualKyc,
        PhoneNumber: user?.StandardAttributes?.PhoneNumber ?? "",
        Email: user?.StandardAttributes?.Email ?? "",
      })
    );
  }, []);

  useEffect(() => {
    fetchStates();
  }, []);

  return (
    <>
      <form className="sm-[65%] md:w-[75%] sm:ml-12 min-h-screen pb-7">
        <div className="xl:w-[63%] pb-7 pt-8 sm:pt-0">
          <h3 className="font-semibold text-[18px] -mt-1 ">
            Tell us about yourself
          </h3>
          <p className="text-[13px] mb-7 text-[#747A80]">
            Make sure you enter your information exactly as it appears on your
            government-issued ID.
          </p>

          {/* Field 1 - legal name*/}
          <section>
            <div className="flex mt-5">
              <p className="text-[13px] pb-1 text-black font-normal">
                Legal Name
              </p>
              <p className="text-[6.5px] text-[#D31D1D]">
                <DiCssTricks />
              </p>
            </div>
            {/* first name */}
            <input
              type="text"
              name="FirstName"
              id=""
              value={IndividualKyc.FirstName}
              onChange={handleFirstNameChange}
              className={`text-[13px] border mb-1 w-full py-2 pl-2 outline-none rounded-[10px] ${
                IndividualKyc.FirstName === "" ? "bg-white" : "bg-[#FFF5D9]"
              }`}
              placeholder="First Name"
            />
            {fNameErr.invalidLength && (
              <p className="text-[11px] mb-2 text-[#F15757]">
                Name cannot be more than 50 characters
              </p>
            )}
            {fNameErr.invalidInput && (
              <p className="text-[11px] mb-2 text-[#F15757]">
                Invalid name format
              </p>
            )}
            {/* middle name: optional */}
            <input
              type="text"
              name="MiddleName"
              id=""
              value={IndividualKyc.MiddleName}
              onChange={handleMiddleNameChange}
              className={`text-[13px] border mb-1 w-full py-2 mt-2 pl-2 outline-none rounded-[10px] ${
                IndividualKyc.FirstName === "" ? "bg-white" : "bg-[#FFF5D9]"
              }`}
              placeholder="Middle Name (optional)"
            />
            {mNameErr.invalidLength && (
              <p className="text-[11px] mb-2 text-[#F15757]">
                Name cannot be more than 50 characters
              </p>
            )}
            {mNameErr.invalidInput && (
              <p className="text-[11px] mb-2 text-[#F15757]">
                Invalid name format
              </p>
            )}
            {/* last name */}
            <input
              type="text"
              name="LastName"
              id=""
              value={IndividualKyc.LastName}
              onChange={handleLastNameChange}
              className={`text-[13px] border mb-1 w-full py-2 mt-2 pl-2 outline-none rounded-[10px] ${
                IndividualKyc.LastName === "" ? "bg-white" : "bg-[#FFF5D9]"
              }`}
              placeholder="Last Name"
            />
            {lNameErr.invalidLength && (
              <p className="text-[11px] mb-2 text-[#F15757]">
                Name cannot be more than 50 characters
              </p>
            )}
            {lNameErr.invalidInput && (
              <p className="text-[11px] mb-2 text-[#F15757]">
                Invalid name format
              </p>
            )}
          </section>

          {/* field 2 - DOB */}
          <section>
            <div className="flex mt-4">
              <p className="text-[13px] font-normal pb-1 ">Date of birth</p>
              <p className="text-[6.5px] text-[#D31D1D]">
                <DiCssTricks />
              </p>
            </div>
            <div className="date-input-container">
              <input
                type="date"
                name="DateOfBirth"
                id="dateOfBirthInput"
                ref={dateInputRef}
                value={IndividualKyc?.DateOfBirth}
                onChange={handleDOBChange}
                onFocus={handleFocus}
                className={`text-[13px] border mb-2 w-full py-2 outline-none rounded-[10px] ${
                  IndividualKyc?.DateOfBirth === ""
                    ? "text-[#747A80] bg-white"
                    : "bg-[#FFF5D9]"
                }`}
                placeholder="MM-DD-YYYY"
              />
            </div>
            {userLessThan18 && (
              <p className="text-[11px] mb-2 text-[#F15757]">
                We’re sorry, we can only open USD accounts for people that are
                at least 18 years old.
              </p>
            )}
          </section>

          {/* field 3 - ID */}
          <section>
            <div className="flex mt-2 md:mt-3">
              <p className="text-[13px] md:text-[12px] pb-1 text-black font-normal ">
                ID Type
              </p>
              <p className="text-[6.5px] text-[#D31D1D]">
                <DiCssTricks />
              </p>
            </div>
            <select
              name="NationalIdentifierType"
              id=""
              value={IndividualKyc?.NationalIdentifierType}
              onChange={handleChange}
              className={`text-[13px] text-[#747A80] border mb-1 w-full py-2 pl-2 outline-none rounded-[10px] ${
                IndividualKyc?.NationalIdentifierType === ""
                  ? "bg-white"
                  : "bg-[#FFF5D9] text-black"
              }`}
            >
              <option value="">ID type</option>
              <option value="BVN">Bank Verification Number (BVN)</option>
              {/* <option value="VNIN">
            Virtual National Identification Number (VNIN)
          </option> */}
            </select>

            {IndividualKyc?.NationalIdentifierType === "VNIN" && (
              <>
                <p className="text-[11px] mb-7 text-[#747A80]">
                  Dial <b>*346*3*NIN*1138183#</b> on your phone to generate your
                  vNIN. You will receive an SMS containing your vNIN to the line
                  registered with your NIN. Note that you can only use the
                  generated VNIN once.
                </p>
              </>
            )}
            {IndividualKyc?.NationalIdentifierType === "BVN" && (
              <>
                <div className="flex mt-1 md:mt-3">
                  <p className="text-[13px] md:text-[12px] pb-1 text-black font-normal ">
                    {IndividualKyc?.NationalIdentifierType} Number
                  </p>
                  <p className="text-[6.5px] text-[#D31D1D]">
                    <DiCssTricks />
                  </p>
                </div>
                <input
                  type="text"
                  name="NationalIdentifier"
                  id=""
                  maxLength={11}
                  value={bvn}
                  onChange={handleBVNChange}
                  className={`${
                    KYCState === "MISMATCH" ||
                    KYCState === "INVALID" ||
                    !isBvnValid
                      ? "border-[#F15757]"
                      : ""
                  } text-[13px] border mb-1 w-full py-2 pl-2 outline-none rounded-[10px] ${
                    bvn === "" ? "bg-white" : "bg-[#FFF5D9]"
                  }`}
                  placeholder={`${IndividualKyc?.NationalIdentifierType} Number`}
                />
                {!isBvnValid && (
                  <p className="text-[11px] mb-7 text-[#F15757]">
                    BVN should be exactly 11 characters long.
                  </p>
                )}
                {KYCState === "INVALID" && (
                  <p className="text-[11px] mb-7 text-[#F15757]">
                    {IndividualKyc?.NationalIdentifierType} does not exist.
                    Please enter a valid {IndividualKyc?.NationalIdentifierType}
                  </p>
                )}
                {KYCState === "MISMATCH" && (
                  <p className="text-[11px] mb-7 text-[#F15757]">
                    The details you entered don't match the information on your{" "}
                    {IndividualKyc?.NationalIdentifierType}
                  </p>
                )}
              </>
            )}
          </section>

          {/* Field 5 - address */}
          <section>
            <div className="flex mt-5">
              <p className="text-[13px] md:text-[12px] pb-1 text-black font-normal ">
                Address
              </p>
              <p className="text-[6.5px] text-[#D31D1D]">
                <DiCssTricks />
              </p>
            </div>
            <select
              name="Country"
              id=""
              value={IndividualKyc.Address?.Country}
              onChange={handleAddressChange}
              className={`text-[13px] text-[#747A80] border mb-1 w-full py-2 pl-2 outline-none rounded-[10px] ${
                IndividualKyc.Address?.Country === ""
                  ? "bg-white"
                  : "bg-[#FFF5D9] text-black"
              }`}
            >
              <option value="" disabled>
                Select country
              </option>
              <option value="USA" className=" ">
                United States
              </option>
              <option value="Nigeria" className=" ">
                Nigeria
              </option>
              <option value="UK" className=" ">
                UK
              </option>
            </select>

            {/* StreetAddress */}

            <input
              type="text"
              name="StreetAddress"
              id=""
              value={IndividualKyc?.Address?.StreetAddress}
              onChange={handleAddressChange}
              className={`text-[13px] border mb-1 w-full mt-2 py-2 pl-2 outline-none rounded-[10px] ${
                IndividualKyc?.Address?.StreetAddress === ""
                  ? "bg-white"
                  : "bg-[#FFF5D9]"
              }`}
              placeholder="Address Line 1"
            />
            {/* SecondStreetAddress */}
            <input
              type="text"
              name="SecondStreetAddress"
              id=""
              value={IndividualKyc?.Address?.SecondStreetAddress}
              onChange={handleAddressChange}
              className={`text-[13px] border mb-2 w-full mt-2 py-2 pl-2 outline-none rounded-[10px] ${
                IndividualKyc?.Address?.SecondStreetAddress === ""
                  ? "bg-white"
                  : "bg-[#FFF5D9]"
              }`}
              placeholder="Address Line 2 - (optional)"
            />
            {/* City */}
            <input
              type="text"
              name="City"
              id=""
              value={IndividualKyc?.Address?.City}
              onChange={handleAddressChange}
              className={`text-[13px] border mb-1 w-full mt-2 py-2 pl-2 outline-none rounded-[10px] ${
                IndividualKyc?.Address?.City === ""
                  ? "bg-white"
                  : "bg-[#FFF5D9]"
              }`}
              placeholder="City"
            />
            {/* StateOrTerritory*/}

            {IndividualKyc?.Address?.Country === "Nigeria" ? (
              <select
                name="StateOrTerritory"
                value={IndividualKyc?.Address?.StateOrTerritory || ""}
                onChange={handleAddressChange}
                className={`text-[13px] text-[#747A80] border mt-2 mb-1 w-full py-2 pl-2 outline-none rounded-[10px] ${
                  IndividualKyc?.Address?.StateOrTerritory === ""
                    ? "bg-white"
                    : "bg-[#FFF5D9] text-black"
                }`}
              >
                <option value="" disabled>
                  Select State
                </option>
                {states.map((state, index) => (
                  <option key={index} value={state.name}>
                    {state.name}
                  </option>
                ))}
              </select>
            ) : (
              // Show the input if the country is not Nigeria
              <input
                type="text"
                name="StateOrTerritory"
                value={IndividualKyc?.Address?.StateOrTerritory}
                onChange={handleAddressChange}
                className={`text-[13px] border mb-1 w-full mt-2 py-2 pl-2 outline-none rounded-[10px] ${
                  IndividualKyc?.Address?.StateOrTerritory === ""
                    ? "bg-white"
                    : "bg-[#FFF5D9]"
                }`}
                placeholder="State"
              />
            )}
          </section>

          {/* field 6 - public profile link  */}
          <section>
            <div>
              <div className="flex mt-5">
                <p className="text-[13px] pb-1 text-black font-normal ">
                  Your public profile link
                </p>
                <p className="text-[6.5px] text-[#D31D1D]">
                  <DiCssTricks />
                </p>
              </div>

              <p className="text-sm font-normal text-[#5B5B5B]">
                e.g Linkedin, Fiverr, X(twitter), other freelancer profiles
              </p>
            </div>

            <input
              type="text"
              name="ProfileLink"
              id=""
              value={IndividualKyc?.ProfileLink}
              onChange={handleInputChange}
              className={`text-[13px] border mb-1 w-full mt-2 py-2 pl-2 outline-none rounded-[10px] bg-white`}
              placeholder="Enter URL"
            />
            {!isProfileLinkValid && (
              <p className="text-[11px] mb-7 text-[#F15757]">
                Invalid link. Please ensure it follows the format:
                <strong>https://www.example.com/username</strong>
              </p>
            )}
          </section>

          {/* field 7 - how much do you expect to receive */}
          <section>
            <div className="flex mt-5">
              <p className="text-[13px] md:text-[12px] pb-1 text-black font-normal ">
                How much do you expect to receive each month?
              </p>
              <p className="text-[6.5px] text-[#D31D1D]">
                <DiCssTricks />
              </p>
            </div>

            <p className="text-sm font-normal text-[#5B5B5B]">
              This helps us know what to expect from your account.{" "}
            </p>

            <select
              className={`text-[13px] border mb-1 w-full mt-2 py-2 pl-2 outline-none rounded-[10px] bg-white ${
                IndividualKyc?.MonthlyIncome === ""
                  ? "text-[#747A80] bg-white"
                  : ""
              }`}
              name="MonthlyIncome"
              id="MonthlyIncome"
              value={IndividualKyc?.MonthlyIncome}
              onChange={handleDropdown}
            >
              <option value="" disabled>
                Select Monthly Income
              </option>
              <option value="Less than $1000">Less than $1,000</option>
              <option value="$1,000 - $5,000">$1,000 - $5,000</option>
              <option value="$5,000 - $10,000">$5,000 - $10,000</option>
              <option value="$10,000 - $20,000">$10,000 - $20,000</option>
              <option value="$20,000 - $50,000">$20,000 - $50,000</option>
              <option value="More than $50,000">More than $50,000</option>
            </select>
          </section>

          {/* field 12 - source of funds */}
          <section>
            <div className="flex mt-5">
              <p className="text-[13px] md:text-[12px] pb-1 text-black font-normal ">
                Source of funds
              </p>
              <p className="text-[6.5px] text-[#D31D1D]">
                <DiCssTricks />
              </p>
            </div>

            <p className="text-sm font-normal text-[#5B5B5B]">
              Where do you expect most of your payments into Cleva to come from?
              Please select max 2 sources.{" "}
            </p>

            <div className="flex mt-2">
              <div className="space-y-1">
                {checkboxes.map((checkbox) => (
                  <CheckboxComponent
                    key={checkbox.value}
                    id={checkbox.value}
                    label={checkbox.label}
                    value={checkbox.value}
                    checked={selectedBox.includes(checkbox.value)}
                    onChange={handleCheckboxChange}
                  />
                ))}
                <div className="relative flex items-start">
                  <div className="flex h-6 items-center">
                    <input
                      id="other"
                      value="other"
                      type="checkbox"
                      checked={isOtherChecked}
                      onChange={handleCheckboxChange}
                      aria-describedby="Enter other sources"
                      name="other"
                      className="h-4 w-4 rounded border-gray-300 text-cleva-gold focus:ring-cleva-gold"
                    />
                  </div>
                  <div className="ml-3 text-sm leading-6">
                    <label htmlFor="other" className="text-black-soft">
                      Other
                    </label>
                  </div>
                </div>
                <div>
                  {showOtherText && (
                    <input
                      type="text"
                      name="otherText"
                      id="otherText"
                      value={otherText}
                      onBlur={handleBlur}
                      onChange={handleotherText}
                      className={`text-[13px] border mb-1 w-full mt-2 py-2 pl-2 outline-none rounded-[10px] bg-white`}
                      placeholder="Enter Source"
                    />
                  )}
                </div>
              </div>
            </div>
            {/* {showCheckboxError &&
              <span className="mt-2  inline-flex text-red-500 text-[11px]">
                <img src={errorImg} className="pr-1" alt="error" />
                Maximum of 2 sources can be selected
              </span>
            } */}
          </section>

          <div className="flex justify-end items-center mt-4">
            {userLessThan18 ? (
              <span className="mt-2  inline-flex text-red-500 text-sm">
                <img src={errorImg} className="pr-1" alt="error" />
                You must be at least 18 years old
              </span>
            ) : (
              isButtonDisabled && (
                <span className="mt-2  inline-flex text-red-500 text-sm">
                  <img src={errorImg} className="pr-1" alt="error" />
                  Please fill all required fields
                </span>
              )
            )}
          </div>
          <div className="flex items-center justify-between">
            <div className="mb-1">
              <Button
                status={false}
                fn={handleClose}
                text="Cancel"
                styles={` py-3 px-6 rounded-[10px] mt-2 font-medium border bg-[#FFBD59] `}
              />
            </div>
            <div>
              <SaveAndContinue
                action={validate}
                isButtonDisabled={isButtonDisabled}
              />
            </div>
          </div>
        </div>
        <div />
        <br />
        <br />
        <br />
        <br />
        {loading && <Loader />}
        {KYCState === "INVALID" && (
          <InvalidModal idType="BVN" open={modal} setOpen={setModal} />
        )}
        {MismatchKYC && (
          <MismatchModal
            open={modal}
            setOpen={setModal}
            IdType="BVN"
            retries={AdditionalDetails?.NoOfRetriesLeft}
          />
        )}
        {/* {KYCState === "SUSPENDED" && <SuspendedModal open={modal} setOpen={setModal} />} */}
        {KYCState === "BIOMETRICS" &&
          AdditionalDetails.IsNameOnlyMismatch === false && (
            <VerifiedModal
              idType="BVN"
              open={modal}
              setOpen={setModal}
              action={proceed}
            />
          )}
        {NameMisMatch && (
          <NameMismatchModal open={modal} setOpen={setModal} action={proceed} />
        )}
        {/* {BVNKYC   && (
          <BVNErrorModal open={modal} setOpen={setModal} action={proceed} />
        )} */}

        {providerModal && (
          <BVNInvalidModal
            open={modal}
            setOpen={setModal}
            title=""
            text="We're sorry, our BVN service provider is currently down. Please try again in a few hours time."
            btnText="Done"
          />
        )}
        {errorModal && (
          <BVNInvalidModal
            open={modal}
            setOpen={setModal}
            title=""
            text="An error occurred and we’re sorry about that. Please try again in a few hours."
            btnText="Done"
          />
        )}
        {(KYCState === "FAILED" || BVNKYC) && (
          <BVNFailedModal
            open={modal}
            setOpen={setModal}
            title="BVN verification failed"
            text=" Please contact your bank to view and update your BVN details"
          />
        )}
        {KYCState === "DENIED" && (
          <BVNFailedModal
            open={modal}
            setOpen={setModal}
            title="BVN verification failed"
            text=" Please contact your bank to view and update your BVN details"
          />
        )}
        {bvnModal && (
          <BVNInvalidModal
            open={bvnModal}
            setOpen={setBVNModal}
            title="BVN already in use"
            text="The Bank Verification Number (BVN) you entered has already been associated with another user's account."
          />
        )}
      </form>
      <ToastContainer />
    </>
  );
}
export default IndividualDetails;
