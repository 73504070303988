import { ConfigVariables } from "../../FrontendConfig";

export let AWS_CREDS = {
  CACHED_BUSINESS_USER_POOL_ID: process.env.REACT_APP_BUSINESS_USER_POOL_ID!,
  CACHED_BUSINESS_USER_POOL_CLIENT_ID:
    process.env.REACT_APP_BUSINESS_USER_POOL_CLIENT_ID!,
  APIGATEWAY_URL: process.env.REACT_APP_APIGATEWAY_URL!,
  APIGATEWAY_URL_CARDS: process.env.REACT_APP_APIGATEWAY_URL_CARDS!,
  APIGATEWAY_URL_CARD: process.env.REACT_APP_APIGATEWAY_URL_CARDs!,
  STAGE: process.env.REACT_APP_STAGE!,
  REGION: process.env.REACT_APP_REGION!,
  DOJAH_APP_ID: process.env.REACT_APP_DOJAH_APP_ID!,
  DOJAH_PUBLIC_KEY: process.env.REACT_APP_DOJAH_PUBLIC_KEY!,
  MAX_DEPOSIT_WAIVER: process.env.REACT_APP_MAX_DEPOSIT_WAIVER!,
  BONUS_GIVEN_AT_TOTAL_DEPOSIT_AMOUNT:
    process.env.REACT_APP_BONUS_GIVEN_AT_TOTAL_DEPOSIT_AMOUNT!,
  BONUS_PER_REFERRED_USER: process.env.REACT_APP_BONUS_PER_REFERRED_USER!,
  MAX_REFERRAL_BONUS: process.env.REACT_APP_MAX_REFERRAL_BONUS!,
  DELAYED_TRANSACTION: process.env.REACT_APP_DELAYED_TRANSACTION!,
  MAX_CARD_DECLINE: process.env.REACT_APP_MAX_CARD_DECLINE!,
  CLOSE_ACCOUNT: process.env.REACT_APP_CLOSE_ACCOUNT!,
  TEST_INTERCOM: process.env.REACT_APP_TEST_INTERCOM!,
  INTERCOM_ENV: process.env.REACT_APP_INTERCOM!,
  CARD_CREATION_FEE: process.env.REACT_APP_CARD_CREATION_FEE!,
  INITIAL_FUNDING_FEE: process.env.REACT_APP_INITIAL_FUNDING_FEE!,

  /*==============================================
  frontendconfig variables
  ==============================================*/
  DAILY_LIMIT: ConfigVariables.DAILY_LIMIT,
  MINIMUM_DEPOSIT: ConfigVariables.MINIMUM_DEPOSIT,
  MIN_FUND_FEE: ConfigVariables.MIN_FUND_FEE,
  MAX_FUND_FEE: ConfigVariables.MAX_FUND_FEE,
  SWIFT_CODE: ConfigVariables.SWIFT_CODE,
  STATE_PUBLIC_URL:
    "https://cl-public-docs.s3.eu-west-1.amazonaws.com/states.json",
};

export const FLAGS = {
  VirtualAccountV2: process.env.REACT_APP_VA_V2_USERS
    ? process.env.REACT_APP_VA_V2_USERS.split(",")
    : [],
  VirtualAccountV3: process.env.REACT_APP_VA_V3_USERS
    ? process.env.REACT_APP_VA_V3_USERS.split(",")
    : [],
};
