//import Auth from "@aws-amplify/auth";
import axios from "axios";
import { Auth } from "aws-amplify";
import { AWS_CREDS } from "./AmazonService";

const axiosClient = axios.create();
try {
  axiosClient.interceptors.request.use(async (config: any) => {
    return new Promise((resolve) => {
      // console.log('interceptor is called')
      // console.log(config.url)
      Auth.currentSession()
        .then(async (session) => {
          if (isCallingTokenEndpoint(config.url)) {
            resolve(config);
          }
          var idTokenExpire = session.getIdToken().getExpiration();
          var refreshToken = session.getRefreshToken();
          config!.headers!.Authorization = session.getIdToken().getJwtToken();

          var currentTimeSeconds = Math.round(+new Date() / 1000);
          if (idTokenExpire < currentTimeSeconds) {
            Auth.currentAuthenticatedUser().then((res) => {
              res.refreshSession(refreshToken, (err: any, data: any) => {
                if (err) {
                  delete axiosClient.defaults.headers.common["Otptoken"];
                  Auth.signOut();
                } else {
                  config.headers.Authorization = data
                    .getIdToken()
                    .getJwtToken();
                  resolve(config);
                }
              });
            });
          } else {
            config!.headers!.Authorization = session.getIdToken().getJwtToken();
            resolve(config);
          }
        })
        .catch(async () => {
          // No logged-in user: don't set auth header
          //console.log('user is not logged in')
          resolve(config);
        });
    });
  });
} catch (err) {
  if (AWS_CREDS.STAGE === "qa") {
    console.log(err);
  }
}
export default axiosClient;

const isCallingTokenEndpoint = (url: string): boolean => {
  return url.endsWith("/kyc/verifytoken");
};
