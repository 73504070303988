import { useState, useEffect } from "react";
import BackButton from "../../../components/Buttons/BackButton";
import TabButtons from "../../../components/Tabs/TabButton";
import TabContent from "../../../components/Tabs/TabContent";
import { useDispatch, useSelector } from "react-redux";
import { fetchTransactions } from "../../../features/Transanctions/transactionApi";
import { fetchAccount } from "../../../features/VirtualAccount/VirtualAccountApi";
import { AppDispatch, RootState } from "../../../app/store";
import { ToastContainer } from "react-toastify";
import { setTitle } from "../../../features/Nav/NavSlice";
import CreateLocalTransfer from "./CreateLocalTransfer";
import CreateTagTransfers from "../../Tags/CreateTagTransfer";
import {
  setDescription,
  setReceiveAmount,
  setRecipientFirstName,
  setRecipientLastName,
  setRecipientTag,
  setSendAmount,
} from "../../../features/Transanctions/TransanctionSlice";
//import NigeriaFlag from '../../../asset/nigeriaFlag.svg'
import NigeriaFlag from "../../../images/ngn.svg";
import ClevaTag from "../../../asset/clevatag.png";
import { getLimitResetHour } from "../../../utils/FormatDateString";
//import Tooltip from "../../../components/PopUps/Tooltip";
import { Tooltip } from "react-tooltip";
import QuestionMark from "../../../asset/ri_question-line.svg";
import { useAppSelector } from "../../../app/hooks";
import { getTiers } from "../../../features/User/UserApi";
import { setUser } from "../../../features/User/UserSlice";
import { useLocation } from "react-router-dom";
import { useIsBusinessUser } from "../../../utils";

export default function Transfers() {
  const [activeTab, setActiveTab] = useState<string>("local");
  const dispatch = useDispatch<AppDispatch>();
  const tooltipContent =
    "Limit for money out of your USD account <br> (e.g. transfer and USD conversion).";
  let user = useSelector((state: RootState) => state.user.userData);
  let userTiers = useAppSelector((state: RootState) => state.user.userTier);
  const [progress, setProgress] = useState(0);
  const [userPayoutAmountUsed, setUserPayoutAmountUsed] = useState(0);
  const [userDailyPayoutLimit, setUserDailyPayoutLimit] = useState(0);
  const isBusinessUser = useIsBusinessUser();
  // const [dailyRemainingPayoutBal, setDailyRemainingPayoutBal] = useState(0);

  useEffect(() => {
    //const payoutAmountUsed = user?.PayoutLimit?.daily.amountUsed ?? 0;
    const payoutAmountUsed = userTiers?.Limits?.USD?.daily?.dailyVolume ?? 0;
    const dailyPayoutLimit =
      userTiers?.Limits?.USD?.daily?.dailyLimitValue ?? 0;
    setUserPayoutAmountUsed(payoutAmountUsed);
    setUserDailyPayoutLimit(dailyPayoutLimit);
    // setDailyRemainingPayoutBal(
    //   Number((dailyPayoutLimit - payoutAmountUsed).toFixed(2))
    // );

    let rawProgress;
    let [minAmount, maxAmount] = [0.1, dailyPayoutLimit];
    if (payoutAmountUsed === minAmount) {
      setProgress(100);
    } else {
      rawProgress = (payoutAmountUsed - minAmount) / (maxAmount - minAmount);
      setProgress(Math.min(Math.max(rawProgress, 0), 1) * 100);
    }
  }, [user, userTiers]);
  const location = useLocation();

  useEffect(() => {
    dispatch(setTitle("Transfers"));
    dispatch(fetchAccount());
    dispatch(fetchTransactions());
    dispatch(setDescription(""));
    dispatch(setSendAmount(""));
    dispatch(setRecipientFirstName(""));
    dispatch(setRecipientLastName(""));
    dispatch(setRecipientTag(""));
    dispatch(setReceiveAmount(""));
    dispatch(setUser(user));
  }, [dispatch]);

  useEffect(() => {
    if (isBusinessUser) {
      const businessIdentifier = user?.BusinessIdentifier;
      if (businessIdentifier) {
        dispatch(getTiers(businessIdentifier));
      }
    } else {
      const clevaUserID = user?.ClevaUserID;
      if (clevaUserID) {
        dispatch(getTiers(clevaUserID));
      }
    }
  }, [dispatch, isBusinessUser, user]);

  const handleTabChange = () => {
    dispatch(fetchTransactions());
    dispatch(setDescription(""));
    dispatch(setSendAmount(""));
    dispatch(setRecipientFirstName(""));
    dispatch(setRecipientLastName(""));
    dispatch(setReceiveAmount(""));
  };

  useEffect(() => {
    if (location.state?.activeTab) {
      setActiveTab(location.state.activeTab);
    }
  }, [location]);

  return (
    <>
      <div className="flex items-center pt-6">
        <BackButton />
        <p className="text-lg font-bold ml-3">Make Transfer</p>
      </div>

      <div className="pt-10 mb-10 Tables">
        {/*  w-full md:mb-8 md:w-1/2 order-0 md:order-0 pt-5 mb-5 mx-auto */}
        <div className="md:w-[38rem] mx-auto md:mb-8 ">
          <ul className="grid grid-cols-2 gap-4 bg-[#EBF0F0] rounded-[10px] px-2 py-1.5">
            <TabButtons
              hasImg={true}
              imgSrc={NigeriaFlag}
              name="To bank"
              id="local"
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              onClick={() => handleTabChange()}
            />
            <TabButtons
              hasImg={true}
              imgSrc={ClevaTag}
              name="To @Cleva tag"
              id="tag"
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              onClick={() => handleTabChange()}
            />
          </ul>

          <div className="mt-10 relative flex flex-col justify-center items-center rounded-lg border border-[#F4E8BF] bg-[#FFF9E3] p-3  mb-[2.34rem]">
            <div className=" flex items-center">
              <p className="text-black font-normal  text-sm">
                Daily USD transfer limit
              </p>
              <button
                data-tooltip-id="my-tooltip"
                data-tooltip-html={tooltipContent}
                data-tooltip-place="bottom"
              >
                <img src={QuestionMark} className="ml-1" alt="See more" />
              </button>
            </div>
            <p className="font-semibold text-sm flex gap-1 text-[#2E2100]">
              {/* user limit */}
              <span className="">${userPayoutAmountUsed.toLocaleString()}</span>
              /
              <span className="text-[#047014]">
                ${userDailyPayoutLimit.toLocaleString()}
              </span>
            </p>
            <section className="w-[80%]">
              <div className="overflow-hidden h-[0.5rem] mb-3 mt-2 text-xs flex rounded-lg bg-[#D9D9D9]">
                <div
                  style={{ width: `${progress}%` }}
                  className="shadow-none flex flex-col text-center whitespace-nowrap text-[#3E3E3E]
                   justify-center  bg-[#FFBD59] relative"
                >
                  {/* flex */}
                  <span className="hidden absolute inset-y-0 m-auto left-[40px] right-0  items-center justify-center">{`$${userPayoutAmountUsed}`}</span>
                </div>
              </div>
            </section>
            <div className="text-sm">
              <p className="text-[#747A80] font-normal">
                Resets at <span> {getLimitResetHour()}</span> your time
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-4">
        <TabContent id="local" activeTab={activeTab}>
          <CreateLocalTransfer />
        </TabContent>
        <TabContent id="tag" activeTab={activeTab}>
          <CreateTagTransfers />
        </TabContent>
      </div>

      <ToastContainer />
      <Tooltip id="my-tooltip" />
    </>
  );
}
