import { createAsyncThunk } from "@reduxjs/toolkit";
import DashboardServices from "../services/DashboardServices";
import { AWS_CREDS } from "../services/AmazonService";

interface IAccount {
  FirstName: string;
  LastName: string;
  email: string;
}
export const postAccount = createAsyncThunk(
  "accounts/postAccount",
  async (account: IAccount) => {
    try {
      const response = await DashboardServices.createAccount(account);
      // toast.success("Added Successfully");
      return response.data;
    } catch (error) {
      if (AWS_CREDS.STAGE === "qa") {
        console.log(error);
      }
    }
  }
);

export const fetchAccount = createAsyncThunk(
  "accounts/fetchAccount",
  async () => {
    const response = await DashboardServices.fetchAccount();
    return response.data;
  }
);

export const fetchAccountById = createAsyncThunk(
  "accounts/fetchAccountById",
  async (VirtualAccountIdentifier: string) => {
    try {
      const response = await DashboardServices.fetchAccountByID(
        VirtualAccountIdentifier
      ); // Call your API function to fetch a post by ID
      if (AWS_CREDS.STAGE === "qa") {
        console.log(response);
      }
      return response.data;
    } catch (error) {
      if (AWS_CREDS.STAGE === "qa") {
        console.log(error);
      }
    }
  }
);
