import { Suspense, useEffect } from "react";
import "./App.css";
import { RouterProvider } from "react-router-dom";
import routes from "./routes";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import { Amplify, Auth } from "aws-amplify";

import awsConfig from "./features/services/AwsConfigs";
import Spinner from "./components/PopUps/Spinner";
import { setUser, setUserHash } from "./features/User/UserSlice";
import ReactGA from "react-ga4";
import { GOOGLE_TRACKER_ID } from "./GoogleAnalyticsTracker";
import Intercom from "@intercom/messenger-js-sdk";
import { AWS_CREDS } from "./features/services/AmazonService";
import { getUserHash } from "./features/services/DashboardServices";
import { useIsBusinessUser } from "./utils";

ReactGA.initialize(GOOGLE_TRACKER_ID);

const emitAnalyticsToGoogle = () => {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });
};

function App() {
  const appId = AWS_CREDS.INTERCOM_ENV;
  const AppDispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.userData);
  const userHash = useAppSelector((state) => state.user.userhash);
  const isBusinessUser = useIsBusinessUser();

  // generate user hash

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await getUserHash();
        if (response?.status === 200) {
          AppDispatch(setUserHash(response.data?.SessionID));
        }
      } catch (error) {
        if (AWS_CREDS.STAGE === "qa") {
          console.log(error);
        }
      }
    };
    if (user) {
      fetchUser();
    }
  }, [user]);

  //registerAuth
  useEffect(() => {
    if (user && userHash) {
      Intercom({
        app_id: appId,
        user_id: isBusinessUser ? user?.BusinessIdentifier : user?.ClevaUserID,
        name: isBusinessUser
          ? user?.BusinessName
          : `${user?.FullName?.FirstName} ${user?.FullName?.LastName}`,
        email: user?.StandardAttributes.Email,
        user_hash: userHash,
        // created_at: user.createdAt, // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
      });
    } else {
      Intercom({
        app_id: appId,
      });
    }
  }, [user, userHash, isBusinessUser]);

  useEffect(() => {
    Amplify.configure(awsConfig);
    Auth.configure(awsConfig);
    Amplify.Logger.LOG_LEVEL = "INFO";
    emitAnalyticsToGoogle();

    if (user) {
      AppDispatch(setUser(user));
    } else {
      AppDispatch(setUser(null));
    }
  }, []);

  return (
    <>
      <Suspense
        fallback={
          <div>
            <Spinner />
          </div>
        }
      >
        <RouterProvider router={routes(user)} />
      </Suspense>
    </>
  );
}

export default App;
