import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import logo from "../../asset/images/logo.svg";
import authImg from "../../asset/images/login-img.svg";
import errorImg from "../../asset/svg/material-symbols_warning.svg";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/solid";
import { ToastContainer, toast } from "react-toastify";
import AuthService from "../../features/services/AuthServices";
import { isValidPassword } from "./Register";
import Logo from "../../asset/logo.svg";
import { AWS_CREDS } from "../../features/services/AmazonService";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [formValid, setFormValid] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordError, setShowPasswordError] = useState<boolean>(false);
  const [matchErrr, setMatchErr] = useState<boolean>(false);

  const navigate = useNavigate();
  const { contact, otp } = useParams();

  const handlePasswordFocus = () => {
    setError("");
    setShowPasswordError(!isValidPassword(password));
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handlePasswordBlur = () => {
    setShowPasswordError(!isValidPassword(password));
    // setShowPasswordError(false)
  };

  const handlePasswordChange = (e: any) => {
    setError("");
    setShowPasswordError(!isValidPassword(e.target.value));
    setPassword(e.target.value);
  };

  const handleConfirmPassword = (e: any) => {
    setMatchErr(e.target.value !== password);
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    try {
      if (password !== confirmPassword) {
        setError("New password and confirm password do not match");
      } else {
        await AuthService.resetPassword(contact!, otp!, password);
        if (AWS_CREDS.STAGE === "qa") {
          console.log("Password reset successfully");
        }
        setTimeout(() => {
          navigate("/auth/login");
        }, 2000);
        toast.success("Password reset successfully");
      }
    } catch (error: any) {
      // error = error.toString();

      let errorMessage = "";
      if (typeof error === "object" && error.message) {
        errorMessage = error.message;
      } else if (typeof error === "string") {
        errorMessage = error;
      }

      if (
        errorMessage.includes(
          "CodeMismatchException: Invalid verification code provided, please try again"
        )
      ) {
        setError(
          "Reset password link has expired. Please check your email for the most recent link"
        );
      } else if (
        errorMessage.includes(
          "LimitExceededException: Attempt limit exceeded, please try after some time."
        )
      ) {
        setError("Attempt limit exceeded. Please try again later");
      } else {
        setError("Error resetting password. Please try again later");
      }
    }
    //setLoading(false)
    // console.log("password", password);
  };

  useEffect(() => {
    if (password !== confirmPassword) {
      setFormValid(false);
    } else if (isValidPassword(password) && isValidPassword(confirmPassword)) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  }, [password && confirmPassword]);

  return (
    <>
      <div className="md:flex min-h-full">
        <div className="hidden md:flex md:flex-col justify-center flex-1 px-4 sm:px-6 lg:flex-none xl:px-20 bg-black text-white ">
          <div className="mx-auto w-full max-w-[24rem] 2xl:max-w-[28rem]  xl:w-[28rem]">
            <div className="flex justify-center pt-20 min-h-[100vh] md:items-center md:pt-0">
              <div className="w-full">
                <div>
                  <Link to="/">
                    <img className="w-auto mt-2" src={logo} alt="logo" />
                  </Link>
                  <h1 className="mt-12 text-4xl font-medium text-white">
                    Forgot Password
                  </h1>
                </div>

                <img src={authImg} className="mt-20" alt="login" />
              </div>
            </div>
          </div>
        </div>
        <div className="relative flex-1 bg-[#FBFBFB]">
          <div className="flex justify-center min-h-[100vh] md:items-center md:pt-0">
            <div className="login-card w-full md:w-[32rem] xl:w-[36rem] py-8 px-8 xl:py-16 md:px-20">
              <div className="">
                <div className="flex items-center justify-center md:hidden">
                  <img
                    className="pb-4 w-[10rem]"
                    src={Logo}
                    alt="Cleva banking logo"
                  />
                </div>
                <h2 className="text-center md:text-left text-2xl text-black-soft">
                  Set new password
                </h2>
                {error !== "" && (
                  <div
                    className="w-full px-2 flex my-[2.81rem] text-[#800205] font-[500]
                 rounded-lg py-2
                 justify-center text-sm items-center  bg-[#FFDCE0] "
                  >
                    <img src={errorImg} className="" alt="error" />
                    <span className="text-[#800205] pl-2  font-normal">
                      {error}
                    </span>
                  </div>
                )}
                {/* form section  */}
                <form onSubmit={handleSubmit} className=" mt-10">
                  <div className="mt-6">
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium text-black-soft mb-2"
                    >
                      New Password
                    </label>

                    <div className="flex mt-2">
                      <input
                        type={showPassword ? "text" : "password"}
                        id="password"
                        name="password"
                        value={password}
                        onFocus={handlePasswordFocus}
                        onBlur={handlePasswordBlur}
                        onChange={handlePasswordChange}
                        placeholder="Password (min of 8 characters)"
                        autoComplete="current-password"
                        required
                        className="password-control"
                      />
                      <button
                        type="button"
                        onClick={toggleShowPassword}
                        className="password-button"
                      >
                        {showPassword ? (
                          <EyeSlashIcon className="h-5 w-5 text-gray-700" />
                        ) : (
                          <EyeIcon className="h-5 w-5 text-gray-700" />
                        )}
                      </button>
                    </div>
                    {showPasswordError ? (
                      <span className="mt-4 inline-flex text-red-500 text-sm">
                        <img src={errorImg} className="pr-3" alt="error" />
                        Must be at least 8 characters and use at least 1
                        uppercase, number, and symbol
                      </span>
                    ) : null}
                  </div>

                  <div className="mt-6">
                    <label
                      htmlFor="confirmPassword"
                      className="block text-sm font-medium text-black-soft"
                    >
                      Confirm Password
                    </label>
                    <div className="flex mt-2">
                      <input
                        id="confirmPassword"
                        name="confirmPassword"
                        type={showPassword ? "text" : "password"}
                        autoComplete="password"
                        placeholder="Confirm Password"
                        onFocus={() => setError("")}
                        value={confirmPassword}
                        onChange={handleConfirmPassword}
                        required
                        className="password-control"
                      />
                      <button
                        type="button"
                        onClick={toggleShowPassword}
                        className="password-button"
                      >
                        {showPassword ? (
                          <EyeSlashIcon className="h-5 w-5 text-gray-700" />
                        ) : (
                          <EyeIcon className="h-5 w-5 text-gray-700" />
                        )}
                      </button>
                    </div>
                    {matchErrr && (
                      <span className="mt-4 inline-flex text-red-500 text-sm">
                        <img src={errorImg} className="pr-3" alt="error" />
                        <span>Passwords do not match </span>
                      </span>
                    )}
                  </div>

                  <div className="mt-7">
                    <button
                      type="submit"
                      disabled={!formValid}
                      className={formValid ? "login-active" : "login-disabled"}
                    >
                      {loading ? "Reseting..." : "Reset Password"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
};

export default ResetPassword;
