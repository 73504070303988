import GoBackComponent from "./utils/GoBackComponent";
import CurrencyInput from "../../components/Layout/CurrencyInput";
import USIcon from "../../images/USD.svg";
import { useEffect, useState } from "react";
import CardModal from "./CardModal";
import GreenCheckMark from "../../asset/cards/green-checkmark.svg";
import { useNavigate } from "react-router-dom";

const LowBalance = () => {
  const navigate = useNavigate();
  const [sendAmount, setAmountInput] = useState("");
  const [formValid, setFormValid] = useState(true);
  const [showModal, setShowModal] = useState(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setShowModal(true);
  };

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/,/g, "");
    setAmountInput(value);
  };

  const handleSetAlert = () => {
    setShowModal(false);
    navigate(-1);
  };

  useEffect(() => {
    setFormValid(!sendAmount);
  }, [sendAmount]);

  return (
    <>
      <div className="relative flex-1">
        <div className="pt-16 md:w-[38rem] mx-auto ">
          <GoBackComponent text="Low balance alert" />
          <div className="bg-[#FFF] shadow-md rounded-2xl py-9 px-4 md:px-16">
            <form onSubmit={handleSubmit}>
              <div className="pb-11">
                <CurrencyInput
                  title="Remind me when my balance is less than"
                  value={sendAmount.toLocaleString()}
                  fn={handleAmountChange}
                  type="text"
                  placeholder="0.00"
                  code="USD"
                  flag={USIcon}
                  err=""
                />
              </div>
              <button
                type="submit"
                disabled={formValid}
                className={`bg-cleva-gold ${formValid ? "opacity-60" : "opacity-100"} flex items-center justify-center mb-3 font-medium rounded-lg py-3 px-4 shadow-sm w-full`}
              >
                Create Alert
              </button>
            </form>
          </div>
        </div>
      </div>
      {showModal && (
        <CardModal width="md:w-[33rem]">
          <div className="flex flex-col py-8 px-2 items-center text-center w-4/5 md:w-3/5 mx-auto">
            <img src={GreenCheckMark} alt="Success Icon" />
            <h1 className="text-black font-semibold text-base pt-9 pb-3">
              Balance alert set up successfully!
            </h1>
            <p className="text-black text-xs font-normal pb-6">
              You will receive an email when your USD Virtual Card balance is
              below the specified amount.
            </p>
            <button
              onClick={handleSetAlert}
              className="bg-cleva-gold flex items-center justify-center mb-3 font-medium rounded-lg py-3 px-4 shadow-sm w-4/5 md:w-full"
            >
              Done
            </button>
          </div>
        </CardModal>
      )}
    </>
  );
};

export default LowBalance;
