import React, { useEffect, useState } from "react";
// import errorImg from "../../asset/svg/material-symbols_warning.svg";
import { useAppSelector } from "../../app/hooks";
import { fetchAccount } from "../../features/VirtualAccount/VirtualAccountApi";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../app/store";
import CurrencyInput from "../../components/Layout/CurrencyInput";
import FeeIcon from "../../images/fee-icon.svg";
import USIcon from "../../images/USD.svg";
import infoIcon from "../../asset/svg/infoIcon.svg";
import WithdrawModal from "./Modals/WithdrawModal";
import GoBackComponent from "./utils/GoBackComponent";

const Withdraw = () => {
  const dispatch = useDispatch<AppDispatch>();

  // const [error, setError] = useState("");
  const [formValid, setFormValid] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);

  const allAccount = useAppSelector((state) => state.virtualAccount.allAccount);
  const [withdrawAmount, setAmountInput] = useState("");
  const [BtnError, setBtnError] = useState(false);
  const [amtError, setAmtError] = useState("");
  const fee = 1;
  const [formData, setFormData] = useState({
    withdrawAmount: "",
    fee: fee,
  });

  const accountNumber = allAccount ? (allAccount as any).AccountNumber : " ";

  const getLastFourDigits = (number: string) => number.slice(-4);
  const getMaskedAccountNumber = (number: string | undefined) =>
    number ? " **** " + getLastFourDigits(number) : "";
  const accBalance = allAccount ? (allAccount as any).Balance?.Money : " ";

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    try {
      setShowModal(true);
      setFormData({
        withdrawAmount: withdrawAmount,
        fee: fee,
      });
    } catch {
      setLoading(false);
    }
  };

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/,/g, ""); // Remove existing commas
    const amountFloat = parseFloat(value);
    setAmountInput(value);

    // const parsedValue = parseFloat(value);
    let inputAmount = Number(value);

    if (isNaN(inputAmount) || !Number.isFinite(inputAmount)) {
      setAmtError("Please enter a valid amount");
      setBtnError(true);
      return;
    }

    if (accBalance < inputAmount) {
      setAmtError("Insufficient account balance");
      setBtnError(true);
      return;
    } else {
      setAmtError("");
      setBtnError(false);
    }
    if (!value) {
      setAmtError("Please enter amount");
    }

    if (amountFloat <= 0) {
      setAmtError("Please enter a valid amount");
      setBtnError(true);
      return;
    }

    if (!accBalance) {
      setAmtError("Please create an account");
      return;
    }

    const decimalRegex = /^\d+(\.\d{0,2})?$/;
    if (!decimalRegex.test(value)) {
      setAmtError("Please enter a valid amount of maximum two decimal places");
      setBtnError(true);
      return;
    } else {
      setAmtError("");
      setBtnError(false);
    }
    setAmtError("");
  };

  const handleBlur = () => {
    // const value = e.target.value.replace(/,/g, "");
    // const parsedValue = parseFloat(value);
    // const newValue = isNaN(parsedValue) ? 0 : parsedValue;
  };

  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (withdrawAmount) {
      setFormValid(false);
    } else {
      setFormValid(true);
    }
  }, [withdrawAmount]);

  useEffect(() => {
    dispatch(fetchAccount());
  }, [dispatch]);

  return (
    <>
      <div className="relative flex-1">
        <div className="flex justify-center pt-10 items-center">
          <div>
            <GoBackComponent text="Withdraw Fund" />
            <p className="text-[#676767] text-sm">
              Withdraw funds from your Cleva USD card to your Cleva USD Account
            </p>
            <div className="login-card-md w-full md:w-[32rem] xl:w-[32rem] px-8 py-8 md:px-20 md:py-16 shadow rounded-lg mt-10 md:mt-12">
              <div className="">
                {/* error banner */}
                {/* {error !== "" && (
                  <div
                    className="w-full px-5 flex my-[2.81rem] text-[#800205] font-[500]
       rounded-lg
       justify-center text-sm items-center h-[3.75rem] bg-[#FFDCE0] "
                  >
                    <img src={errorImg} className="" alt="error" />
                    <span className="text-[#800205] pl-2  font-normal">
                      {error}
                    </span>
                  </div>
                )} */}

                {/* form section  */}

                <form onSubmit={handleSubmit} className=" ">
                  <div className="mb-6">
                    <label className="text-sm pb-1 text-left">
                      Source
                      <span className="text-[#D31D1D] font-semibold pl-1">
                        *
                      </span>
                    </label>
                    <div
                      style={{ background: "#FFFFFA" }}
                      className="input-control"
                    >
                      <div className="flex justify-between py-1">
                        <p className="text-[#747A80] text-sm">
                          Cleva Card
                          {getMaskedAccountNumber(accountNumber)}
                        </p>
                        <p className="text-[#747A80]">
                          $
                          {accBalance?.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </p>
                      </div>
                    </div>
                  </div>

                  <CurrencyInput
                    title="Amount"
                    value={withdrawAmount?.toLocaleString()}
                    fn={handleAmountChange}
                    onBlur={handleBlur}
                    type="text"
                    err={amtError ? amtError : ""}
                    placeholder="0.00"
                    code="USD"
                    flag={USIcon}
                  />
                  <div className="flex items-center my-1">
                    <div className="flex items-center">
                      <img src={FeeIcon} alt="fee" />

                      <p className="text-xs text-[#747A80] mr-4 ml-2">
                        Withdraw fee:
                      </p>
                    </div>
                    <p className="text-sm font-bold">
                      $ {formData.fee.toFixed(2)}{" "}
                    </p>

                    <p>
                      <img src={infoIcon} className="ml-1" alt="" />
                    </p>
                  </div>
                  <div className="mt-10">
                    <button
                      type="submit"
                      disabled={
                        formValid || !!amtError || !!BtnError || loading
                      }
                      className={`bg-cleva-gold opacity-${
                        formValid ? "60" : "100"
                      } flex items-center justify-center mb-3 md:mb-0 font-medium rounded-lg md:py-2.5 py-3 px-4 md:px-8 shadow-sm w-[100%]`}
                    >
                      Withdraw funds
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showModal && (
        <WithdrawModal formData={formData} closeModal={closeModal} />
      )}
    </>
  );
};

export default Withdraw;
