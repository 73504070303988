import React from "react";
import { useAppDispatch } from "../../app/hooks";
import { setModalSedtDelete, setModalState } from "../../features/Kyc/kycSlice";
import { useNavigate } from "react-router-dom";
export default function SmallModal({ children }: any) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  function handleClose() {
    navigate("/recipients");
    dispatch(setModalSedtDelete(false));
    dispatch(setModalState(false));
  }

  return (
    <div
      onClick={handleClose}
      className="fixed inset-0 z-10 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="fixed right-0 mr-[5%] mt-[60%] md:mt-[22%] top-4 py-1 border w-32 shadow-lg rounded-md bg-white"
      >
        {children}
      </div>
    </div>
  );
}
