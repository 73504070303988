import RecipientCard from "../../../components/Layout/RecipientCard";
import DetailsCard from "../../../components/Layout/DetailsCard";
import { AppDispatch, RootState } from "../../../app/store";
import { useDispatch, useSelector } from "react-redux";
import BackBtn from "../../../asset/referral/backBtn.svg";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import ValidateUserPin from "../../Security/modals/ValidateUserPin";
import ForgotPin from "../../Security/modals/ForgotPin";
import NewPin from "../../Security/modals/NewPin";
import UpdatedPin from "../../Security/modals/UpdatedPin";
import VerifyUserOTP from "../../Security/modals/ExceededPin";
import { ResetPin } from "../../../api";
import { getUserIdWithAccessToken } from "../../../login";
import PinLoader from "../../Security/modals/PinLoader";
import { createTransaction } from "../../../features/Transanctions/transactionApi";
import axios from "../../../features/services/AxiosInstance";
import { toast, ToastContainer } from "react-toastify";

function MobileReviewTransfer() {
  const [openTransactionPin, setTransactionPin] = useState(false);
  const [openForgotPin, setForgotPin] = useState(false);
  const [openUpdatedPin, setUpdatedPin] = useState(false);
  const [openNewPin, setNewPin] = useState(false);
  const [openOTP, setOTP] = useState(false);
  const [verifying, setVerifying] = useState(false);
  const transactionData = useSelector((state: RootState) => state.transaction);

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const sendAmount = useSelector(
    (state: RootState) => state.transaction.sendAmount
  );

  const description = useSelector(
    (state: RootState) => state.transaction.description
  );
  const fee = useSelector((state: RootState) => state.transaction.fee);
  const RecipientFirstName = useSelector(
    (state: RootState) => state.transaction.RecipientFirstName
  );
  const RecipientLastName = useSelector(
    (state: RootState) => state.transaction.RecipientLastName
  );
  const [loading, setLoading] = useState(false);

  const parseNumber = (value: string): number => {
    const stringValue = String(value);
    const parsedValue = parseFloat(stringValue.replace(/[^0-9.-]+/g, ""));
    return isNaN(parsedValue) ? 0 : parsedValue;
  };

  const allAccount = useSelector(
    (state: RootState) => state.virtualAccount.allAccount
  );

  const parsedAmount = parseNumber(sendAmount);
  const parsedFee = fee;

  const totalAmount = parsedAmount + parsedFee;

  const backToPrevious = () => {
    navigate(-1);
  };

  const VerifyTransaction = async () => {
    try {
      setLoading(true);
      setVerifying(true);

      const response = await dispatch(createTransaction(transactionData));

      setLoading(false);
      setVerifying(false);

      if (response?.payload) {
        navigate("/transfers/view");
      } else {
        toast.error(
          "We could not process your transfer at this time. Please try again later"
        );
      }
    } catch (error: any) {
      setLoading(false);
      setVerifying(false);
      toast.error(error.message);
      delete axios.defaults.headers.common["Otptoken"];
    }
  };

  const sendOTP = async () => {
    const userID = await getUserIdWithAccessToken();
    try {
      await ResetPin(userID);
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  const handleTransactionPin = async () => {
    setTransactionPin(true);
  };

  const accountNumber = allAccount ? (allAccount as any).AccountNumber : " ";

  const getLastFourDigits = (number: string) => number.slice(-4);
  const getMaskedAccountNumber = (number: string | undefined) =>
    number ? " **** " + getLastFourDigits(number) : "";

  return (
    <>
      <div className="pt-4">
        <div
          className="flex gap-2 items-center cursor-pointer pb-1 font-medium text-[#0D0D0D]"
          onClick={backToPrevious}
        >
          <img src={BackBtn} alt="account details" className="mr-2" />
          <button>Review Details</button>
        </div>
        <div className="mt-3">
          <div className="px-4">
            <div className="text-center pt-8 pb-10">
              <p className="text-lg">
                Transfer{" "}
                <span className="font-bold text-xl">
                  $
                  {totalAmount?.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </span>{" "}
                to{" "}
                <span className="text-cleva-gold">{`${RecipientFirstName} ${RecipientLastName}`}</span>
              </p>

              <p className="text-xs italic mt-3">{description}</p>
            </div>

            <RecipientCard title="Recipient Details" />
            <div className="border-dashed border-t border-[#BDBDBD] my-3"></div>
            <DetailsCard
              title="Transaction Details"
              pay={`Cleva USD Account ${getMaskedAccountNumber(accountNumber)}`}
            />
          </div>
        </div>
        <footer>
          <div className="px-4 flex justify-between pt-4">
            <button
              type="button"
              className="text-sm 
                  font-bold py-3 md:px-10 px-6 
                  rounded-md mt-4 
                  bg-[#FFF5D9]"
              onClick={backToPrevious}
            >
              Cancel
            </button>
            <button
              className={`text-[12px]
                      font-bold py-[10px] px-[8%]
                      float-right
                      rounded-md mt-4
                      bg-[#FFBD59]
                       ${loading ? "opacity-50 cursor-not-allowed" : ""}`}
              onClick={(e) => {
                e.preventDefault();
                handleTransactionPin();
              }}
              disabled={loading}

              // ref={}
            >
              {loading ? "Loading ..." : "Confirm Details"}
            </button>
          </div>
        </footer>
      </div>

      {!verifying && openTransactionPin && (
        <ValidateUserPin
          VerifyTransaction={VerifyTransaction}
          openForgotPinModal={() => {
            setTransactionPin(false);
            setForgotPin(true);
            sendOTP();
          }}
          sendOTP={() => {
            setTransactionPin(false);
            setOTP(true);
            sendOTP();
          }}
          closeModal={() => setTransactionPin(false)}
        />
      )}

      {openForgotPin && (
        <ForgotPin
          openNewPinModal={() => {
            setForgotPin(false);
            setNewPin(true);
          }}
          closeModal={() => setForgotPin(false)}
        />
      )}

      {openOTP && (
        <VerifyUserOTP
          openNewPinModal={() => {
            setOTP(false);
            setNewPin(true);
          }}
          closeModal={() => setOTP(false)}
        />
      )}

      {openNewPin && (
        <NewPin
          openUpdatedPinModal={() => {
            setNewPin(false);
            setUpdatedPin(true);
          }}
          closeModal={() => setNewPin(false)}
        />
      )}

      {openUpdatedPin && <UpdatedPin closeModal={() => setUpdatedPin(false)} />}

      {verifying && <PinLoader text="Initiating transfer..." />}

      <ToastContainer />
    </>
  );
}

export default MobileReviewTransfer;
