import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

function ReferralsRedirect() {
  const navigate = useNavigate();
  const location = useLocation();

  //const referralCode = window.location.pathname.split('/').pop();

  useEffect(() => {
    // const queryParams = new URLSearchParams(location.search);
    const pathSegments = location.pathname
      .split("/")
      .filter((segment) => segment !== "");
    const referralCode = pathSegments[pathSegments.length - 1];

    navigate(`/auth/register/${referralCode}`);
  }, [navigate, location.pathname]);

  return null;

  // const referralCode = window.location.pathname.split('/').pop();

  // if (referralCode) {
  //   // Redirect to /auth/register with the referral code
  //   return <Navigate to={`/auth/register?referral=${referralCode}`} />;
  // }
}

export default ReferralsRedirect;
