import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { TransactionListItem } from "../../../../features/Transanctions/TransanctionSlice";

const styles = StyleSheet.create({
  transactionTable: {},
  header: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#F9FAFB",
    height: 25,
    paddingHorizontal: 10,
    textTransform: "uppercase",
    color: "#373737",
    borderBottom: 1,
    borderColor: "#E5E7EB",
    fontWeight: 600,
  },
  tableCell: {
    width: "20%",
  },
  money: {
    width: "10%",
  },
  description: {
    width: "30%",
  },
  tableContent: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    height: 25,
    paddingHorizontal: 10,
    textTransform: "uppercase",
    color: "#373737",
    borderBottom: 1,
    borderColor: "#E5E7EB",
  },
  VirtualAccountSection: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  leftColumn: {
    flexGrow: 0,
    width: "50%",
    gap: "25px",
  },
  rightColumn: {
    flexGrow: 1,
    width: "50%",
    paddingLeft: "25%",
    gap: "25px",
  },

  contactContainer: {
    fontSize: 8,
  },
  accountValue: {
    fontSize: 8,
  },
  accountValueChildren: {
    flexDirection: "row",
  },
  accountValueTopContainer: {
    paddingBottom: 15,
    borderBottomWidth: 1,
    borderBottomColor: "#112131",
    borderBottomStyle: "solid",
    gap: 10,
  },
  accountValueBottomContainer: {
    paddingTop: 15,
    gap: 10,
  },
});
const positiveTransactionTypes = ["USD_CREDIT", "REFERRAL_BONUS", "TAG_CREDIT"];
export const convertMoneyToLocale = (amount: number) =>
  amount?.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

const TransactionList = ({
  transactions,
}: {
  transactions: TransactionListItem[];
}) => (
  <View style={{ paddingTop: 40 }}>
    <View style={styles.header}>
      <View style={styles.tableCell}>
        <Text>Date</Text>
      </View>
      <View style={styles.tableCell}>
        <Text>Transaction Id</Text>
      </View>
      <View style={styles.description}>
        <Text>Description</Text>
      </View>
      <View style={styles.money}>
        <Text>Money in</Text>
      </View>
      <View style={styles.money}>
        <Text>Money out</Text>
      </View>
    </View>
    {transactions?.map((transaction) => {
      const isTagDebit =
        transaction.TransactionType === "TAG_TRANSFER" &&
        transaction.TransactionState === "COMPLETED";
      console.log(
        "isTagDebit",
        transaction.TransactionType,
        transaction.TransactionState,
        isTagDebit
      );
      const isTagCredit =
        transaction.TransactionType === "TAG_CREDIT" &&
        transaction.TransactionState === "COMPLETED";
      const isValidTransactionDebit =
        transaction?.TransactionType === "CARD_CREATION" ||
        transaction?.ToAmount ||
        isTagDebit;
      return (
        <View
          style={styles.tableContent}
          key={transaction?.TransactionIdentifier}
        >
          <View style={styles.tableCell}>
            <Text>{`${transaction?.CreatedAt.split("T")[0]} ${new Date(transaction?.CreatedAt).toLocaleTimeString("en-US")}`}</Text>
          </View>
          <View style={styles.tableCell}>
            <Text>{transaction?.TransactionIdentifier}</Text>
          </View>
          <View style={styles.description}>
            <Text>
              {isValidTransactionDebit
                ? `Transfer to ${transaction?.RecipientName || "CLEVA CARD"}-Description: ${transaction?.Description}`
                : isTagCredit
                  ? `Recieved from ${transaction?.SenderName}`
                  : "Deposit Recieved"}
            </Text>
          </View>
          <View style={styles.money}>
            {positiveTransactionTypes.includes(
              transaction?.TransactionType
            ) && (
              <Text>+${convertMoneyToLocale(transaction?.TotalAmount)}</Text>
            )}
          </View>
          <View style={styles.money}>
            {(transaction?.ToAmount ||
              transaction?.TransactionType === "CARD_CREATION" ||
              isTagDebit) && (
              <Text>-${convertMoneyToLocale(transaction?.TotalAmount)}</Text>
            )}
          </View>
        </View>
      );
    })}
  </View>
);

export default TransactionList;
