const SVG_PATH = "/svg";

export const hand_icon = `${SVG_PATH}/hand_icon.svg`;
export const Lock = `${SVG_PATH}/lock.svg`;
export const WorldCurrency = `${SVG_PATH}/worldCurrency.svg`;
export const AtmCard = `${SVG_PATH}/atmCard.svg`;
export const OpenAccount = `${SVG_PATH}/openAccount.svg`;
export const verifiedUser = `${SVG_PATH}/verifiedUser.svg`;
export const Authenticator = `${SVG_PATH}/authenticator.svg`;
export const pencil = `${SVG_PATH}/pencil.svg`;
export const success = `${SVG_PATH}/success.svg`;
export const successFull = `${SVG_PATH}/successFull.svg`;
export const arrowRight = `${SVG_PATH}/arrowRight.svg`;
export const arrowLeft = `${SVG_PATH}/arrowLeft.svg`;
export const checkSymbol = `${SVG_PATH}/checkSymbol.svg`;
export const downloadIcon = `${SVG_PATH}/downloadIcon.svg`;
export const pendingIcon = `${SVG_PATH}/pendingIcon.svg`;
export const closeIcon = `${SVG_PATH}/closeIcon.svg`;
export const cardImg1 = `${SVG_PATH}/cardImg1.jpg`;
export const line = `${SVG_PATH}/line.svg`;
export const DarkLine = `${SVG_PATH}/DarkLine.svg`;
export const arr_Right = `${SVG_PATH}/arr_right.svg`;
export const failedUpload = `${SVG_PATH}/failedUpload.svg`;
export const clevaLogo = `${SVG_PATH}/clevaLogo.svg`;
export const selfieIcon = `${SVG_PATH}/selfie.svg`;
export const verifiedIcon = `${SVG_PATH}/checkMark.svg`;
export const kycIcon = `${SVG_PATH}/kycIcon.svg`;
export const kycError = `${SVG_PATH}/kycerror.svg`;
export const refreshIcon = `${SVG_PATH}/refreshIcon.svg`;
export const bvnError = `${SVG_PATH}/bvnCancel.svg`;
