import One from "../../asset/svg/one-plain.svg";
import Two from "../../asset/svg/two-plain.svg";
import Three from "../../asset/svg/three-plain.svg";
import { AWS_CREDS } from "../../features/services/AmazonService";

// const maxReferralBonus = AWS_CREDS.MAX_REFERRAL_BONUS || 200;
const bonusPerReferredUser = AWS_CREDS.BONUS_PER_REFERRED_USER || 10;
const maxDepositWaiver = AWS_CREDS.MAX_DEPOSIT_WAIVER || 2;
const bonusGivenAtDeposit =
  AWS_CREDS.BONUS_GIVEN_AT_TOTAL_DEPOSIT_AMOUNT || 300;

export const howReferralsWork = [
  {
    id: 0,
    img: One,
    title: "Share your referral link",
    content:
      "Send via email, text, social media or wherever you chat with your friends",
  },
  {
    id: 1,
    img: Two,
    title: `Earn $${bonusPerReferredUser} for each referral`,
    content: `You earn $${bonusPerReferredUser} when your invited friend receives up to $${bonusGivenAtDeposit} into their Cleva USD account. Your friend also gets a waiver on their first deposit fee (up to $${maxDepositWaiver} is waived).`,
  },
  {
    id: 2,
    img: Three,
    title: `Repeat and keep earning`,
    content: `Keep inviting more friends and continue earning`,
  },
];
