import React, { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import UsIcon from "../../../../images/USD.svg";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { getMaskedAccountNumber } from "../../../../components/Layout/extras/ClevaAccount";
import { RootState } from "../../../../app/store";
import { useSelector } from "react-redux";
import Spinner from "../../../../components/PopUps/Spinner";
import { convertTime } from "../../../Accounts/components/AccountUtils";

type ModalProps = {
  closeModal: () => void;
};

const C2CModal: React.FC<ModalProps> = ({ closeModal }) => {
  const [open] = useState(true);
  const cancelButtonRef = useRef(null);
  const allAccount = useSelector(
    (state: RootState) => state.virtualAccount.allAccount
  );
  const accountNumber = allAccount ? (allAccount as any).AccountNumber : " ";

  const { singleTransfer, loading } = useSelector(
    (state: RootState) => state.transaction
  );

  const transactionID = singleTransfer
    ? (singleTransfer as any).TransactionIdentifier
    : " ";

  const status = singleTransfer
    ? (singleTransfer as any).TransactionState
    : " ";

  const statusResult =
    status === "COMPLETED" ? (
      <span className="px-3 py-1 my-2 rounded-lg capitalize bg-[#DEF7EC] text-[#03543F] font-medium text-xs">
        Completed
      </span>
    ) : status === "IN_TRANSIT" ? (
      <span className="px-3 py-1 my-2 rounded-lg capitalize bg-[#EBFBFE] text-[#1892D7] font-medium text-xs">
        In Transit
      </span>
    ) : status === "PENDING" ? (
      <span className="px-3 py-1 my-2 rounded-lg capitalize bg-[#EBFBFE] text-[#1892D7] font-medium text-xs">
        In Transit
      </span>
    ) : status === "KYC_PENDING" ? (
      <span className="px-3 py-1 my-2 rounded-lg capitalize bg-[#FDF0E7] text-[#FF6600] font-medium text-xs">
        Pending
      </span>
    ) : (
      <span className=" px-3 py-1 my-2 font-medium capitalize bg-[#FDE8E8] rounded-lg text-[#9B1C1C] text-[10px]">
        {singleTransfer ? (singleTransfer as any).TransactionState : " "}
      </span>
    );

  const amount = singleTransfer
    ? (singleTransfer as any).TransactionDetail.FromAmount
    : " ";
  const fee = singleTransfer
    ? (singleTransfer as any).TransactionDetail.Fee
    : " ";

  const description = singleTransfer
    ? (singleTransfer as any)?.TransactionDetail?.Description
    : "";

  const { FirstName: recipientFName = "", LastName: recipientLName = "" } =
    singleTransfer
      ? (singleTransfer as any).TransactionDetail?.Recipient?.FullName || {}
      : {};
  const { FirstName: senderFName = "", LastName: senderLName = "" } =
    singleTransfer
      ? (singleTransfer as any).TransactionDetail?.Sender?.FullName || {}
      : {};
  const TagName = singleTransfer
    ? (singleTransfer as any).TransactionDetail?.Recipient?.NickName
    : "";
  const senderTagName = singleTransfer
    ? (singleTransfer as any).TransactionDetail?.Sender?.Tag
    : "";

  const totalAmount = amount + fee;

  let createdDate = singleTransfer ? (singleTransfer as any).CreatedAt : "";
  const displayDate = convertTime(new Date(createdDate));

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        initialFocus={cancelButtonRef}
        onClose={closeModal}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white pb-8 text-left shadow-xl transition-all sm:my-8 sm:w-full md:max-w-[34rem]  w-[30rem]">
                <header
                  className={`w-full bg-[#EFEFEF] py-4 px-8  flex justify-between`}
                >
                  <Dialog.Title
                    as="h3"
                    className="text-base font-bold leading-6 text-gray-900"
                  >
                    Transaction Details
                  </Dialog.Title>
                  <button
                    type="button"
                    className="rounded-md text-black hover:text-gray-500 focus:outline-none focus:ring-2 font-bold"
                    onClick={closeModal}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-4 w-4" aria-hidden="true" />
                  </button>
                </header>
                {loading ? (
                  <Spinner />
                ) : (
                  <div className="">
                    <div className="mt-2">
                      <div className="flex justify-between px-6 py-9 items-center">
                        <div className="flex gap-4 items-center justify-center">
                          <img src={UsIcon} alt="C2C Transfer" />
                          <p className="text-[#000000] font-bold text-sm">
                            C2C Transfer
                          </p>
                        </div>
                        <div>
                          <p className="pl-10 md:pl-0 text-xs text-right">
                            {displayDate}
                          </p>
                        </div>
                      </div>

                      <div className="bg-[#FCFCFC] w-[90%] mx-auto rounded-lg border border-[#ECECEC]">
                        <div className="text-center py-6">
                          {singleTransfer?.TransactionType ===
                          "TAG_TRANSFER" ? (
                            <p className="text-lg">
                              Transfer{" "}
                              <span className="font-bold text-xl">
                                $
                                {totalAmount?.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </span>{" "}
                              to{" "}
                              <span className="text-[#B16F0B]">
                                {recipientFName} {recipientLName}
                              </span>
                            </p>
                          ) : (
                            <p className="text-lg">
                              <span className="font-bold text-xl">
                                $
                                {totalAmount?.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </span>{" "}
                              from{" "}
                              <span className="text-[#B16F0B]">
                                {senderFName} {senderLName}
                              </span>
                            </p>
                          )}
                          {singleTransfer?.TransactionType ===
                            "TAG_TRANSFER" && (
                            <p className="my-2 italic">{description} </p>
                          )}
                          <div className="mt-2">
                            {singleTransfer ? statusResult : ""}
                          </div>
                        </div>
                      </div>

                      <div className="bg-[#FCFCFC] p-4 border rounded-md w-[90%] mx-auto my-5">
                        {singleTransfer?.TransactionType === "TAG_TRANSFER" ? (
                          <>
                            <h3 className="text-[#3E6488] pb-3 font-medium text-sm">
                              Recipient
                            </h3>
                            <ul className="grid gap-3">
                              <li className="flex justify-between items-center ">
                                <p className="text-[#4E4E4E] text-sm "> Name</p>
                                <p className="text-[#000000] font-medium text-sm">
                                  {recipientFName + " " + recipientLName}
                                </p>
                              </li>
                              <li className="flex justify-between items-center ">
                                <p className="text-[#4E4E4E] text-sm ">
                                  {" "}
                                  Cleva tag
                                </p>
                                <p className="text-[#000000] font-medium text-sm">
                                  {" "}
                                  @{TagName}
                                </p>
                              </li>
                            </ul>
                          </>
                        ) : (
                          <>
                            <ul className="grid gap-3">
                              <li className="flex justify-between items-center ">
                                <p className="text-[#4E4E4E] text-sm ">
                                  {" "}
                                  Sender
                                </p>
                                <p className="text-[#000000] font-medium text-sm">
                                  {senderFName + " " + senderLName}
                                </p>
                              </li>
                              {senderTagName && (
                                <li className="flex justify-between items-center ">
                                  <p className="text-[#4E4E4E] text-sm ">
                                    {" "}
                                    Cleva tag
                                  </p>
                                  <p className="text-[#000000] font-medium text-sm">
                                    {" "}
                                    @{senderTagName}
                                  </p>
                                </li>
                              )}
                            </ul>
                          </>
                        )}
                      </div>

                      <div className="bg-[#FCFCFC] p-4 border rounded-md w-[90%] mx-auto my-5">
                        <h3 className="text-[#3E6488] pb-3 font-medium text-sm">
                          Transaction Details
                        </h3>
                        {singleTransfer?.TransactionType === "TAG_TRANSFER" ? (
                          <ul className="grid gap-3">
                            <li className="flex justify-between items-center">
                              <p className="text-[#4E4E4E] text-sm ">
                                {" "}
                                Pay from
                              </p>
                              <p className="text-[#000000] font-medium text-sm text-right">
                                Cleva USD Account{" "}
                                {getMaskedAccountNumber(accountNumber)}
                              </p>
                            </li>
                            <li className="flex justify-between items-center">
                              <p className="text-[#4E4E4E] text-sm ">
                                You're sending
                              </p>
                              <p className="text-[#000000] font-medium text-sm">
                                {" "}
                                $
                                {amount?.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </p>
                            </li>
                            <li className="flex justify-between items-center">
                              <p className="text-[#4E4E4E] text-sm ">Fee</p>
                              <p className="text-[#000000] font-medium text-sm">
                                {" "}
                                $
                                {fee?.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </p>
                            </li>
                            <li className="flex justify-between items-center">
                              <p className="text-[#4E4E4E] text-sm ">
                                Total Amount
                              </p>
                              <p className="text-[#000000] font-medium text-sm">
                                {" "}
                                $
                                {totalAmount?.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </p>
                            </li>
                          </ul>
                        ) : (
                          <ul className="grid gap-3">
                            <li className="flex justify-between items-center">
                              <p className="text-[#4E4E4E] text-sm ">
                                Amount sent to you
                              </p>
                              <p className="text-[#000000] font-medium text-sm">
                                {" "}
                                $
                                {totalAmount?.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </p>
                            </li>
                            <li className="flex justify-between items-center">
                              <p className="text-[#4E4E4E] text-sm ">Fee</p>
                              <p className="text-[#000000] font-medium text-sm">
                                {" "}
                                $
                                {fee?.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </p>
                            </li>
                            <li className="flex justify-between items-center">
                              <p className="text-[#4E4E4E] text-sm ">
                                You received
                              </p>
                              <p className="text-[#158025] font-medium text-sm">
                                {" "}
                                $
                                {amount?.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </p>
                            </li>
                            <li className="flex justify-between items-center">
                              <p className="text-[#4E4E4E] text-sm ">
                                Description
                              </p>
                              <p className="text-[#000000] font-medium text-sm">
                                {description}
                              </p>
                            </li>
                          </ul>
                        )}
                        <hr className="w-full my-4 px-4" />
                        <div className="">
                          <div className="md:flex justify-between">
                            <p className="text-sm text-[#6F6F6F]">
                              Transaction ID
                            </p>
                            <p className="select-text">
                              {transactionID} <span></span>
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="mt-4 px-6 md:px-8">
                        <div>
                          <footer>
                            <div className="pt-4">
                              <button
                                type="button"
                                className="text-sm 
                          py-3
                            rounded-md mt-4 
                            border border-[#35803F] w-[100%] text-[#35803F] bg-[#F9FFFA]"
                                onClick={closeModal}
                              >
                                Done
                              </button>
                            </div>
                          </footer>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default C2CModal;
