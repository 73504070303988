import BackButton from "../../components/Buttons/BackButton";
import Proof from "../../asset/proof-of-account.png";
import { useEffect, useState } from "react";
import ModalTemplate from "../../components/PopUps/ModalTemplate";
import loader from "../../asset/images/kolici-yukleniyor.gif";
import { useNavigate } from "react-router-dom";
import GreenCheckMark from "../../asset/cards/green-checkmark.svg";
import { sendProofOfAccount } from "../../features/services/DashboardServices";
import { AWS_CREDS } from "../../features/services/AmazonService";
import { MdCancel } from "react-icons/md";
import { useAppDispatch } from "../../app/hooks";
import { setTitle } from "../../features/Nav/NavSlice";

const ProofAccounts = () => {
  const navigate = useNavigate();
  const [process, setProcess] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failedRequest, setFailedRequest] = useState(false);
  const dispatch = useAppDispatch();

  const handleClick = async () => {
    setProcess(true);
    try {
      const response = await sendProofOfAccount();
      if (AWS_CREDS.STAGE === "qa") {
        console.log(response);
      }
      setProcess(false);
      if (response.status === 200) {
        setSuccess(true);
      } else {
        setFailedRequest(true);
      }
    } catch (error) {
      if (AWS_CREDS.STAGE === "qa") {
        console.log(error);
      }
      setProcess(false);
      setFailedRequest(true);
    }
  };
  useEffect(() => {
    dispatch(setTitle("Account"));
  }, []);

  return (
    <>
      <div className="min-w-full  mt-auto ">
        <div className=" min-h-full  gap-4 items-end justify-center p-4 sm:items-center sm:p-0">
          <div className="flex py-8 items-center">
            <BackButton />
            <p className="text-lg font-bold ml-3">Proof of account</p>
          </div>
          <div className="bg-[#FFFFFF] flex items-center justify-center py-4 md:pt-4 md:pb-8 px-7 md:w-[500px] mx-auto mt-8 md:mt-16">
            <div className="text-center">
              <div className="flex items-center justify-center">
                <img src={Proof} alt="Proof of account" />
              </div>
              <h1 className="text-black text-lg font-semibold pt-4">
                Get proof of account
              </h1>
              <p className="text-[#2C2C2C] pt-4 md:w-[70%] mx-auto">
                We'll send the PDF to the email address associated with your
                Cleva account.
              </p>
              <button
                onClick={() => handleClick()}
                className="mt-4 bg-cleva-gold py-4 px-8 rounded-md cursor-pointer text-[#111111] font-bold text-sm"
              >
                Send my proof of account
              </button>
            </div>
          </div>
        </div>
      </div>

      <ModalTemplate
        childStyle="md:w-[300px] h-[200px]"
        extraStyle="flex items-center justify-center h-full"
        modalState={process}
        setModalState={setProcess}
      >
        <div className="flex py-4 px-3 flex-col justify-center items-center">
          <div className="">
            <img src={loader} alt="loading..." className="h-[4rem]" />
          </div>
          <p className="text-[#111111] font-base text-center">Processing...</p>
        </div>
      </ModalTemplate>

      <ModalTemplate
        childStyle="md:w-[400px]"
        modalState={success}
        setModalState={setSuccess}
      >
        <div className="py-5 px-3 flex flex-col gap-5 justify-center items-center">
          <img src={GreenCheckMark} alt="Proof of account sent" />
          <p className="text-center text-[#111111] font-base">
            We've sent your proof of account to the email address associated
            with your Cleva account.
          </p>
          <button
            onClick={() => navigate("/accounts")}
            className=" bg-cleva-gold py-4 px-8 rounded-md cursor-pointer text-[#111111] font-bold text-sm"
          >
            Done
          </button>
        </div>
      </ModalTemplate>

      <ModalTemplate
        childStyle="md:w-[300px]"
        modalState={failedRequest}
        setModalState={setFailedRequest}
      >
        <div className="py-5 px-3 flex flex-col gap-5 justify-center items-center">
          <button>
            <MdCancel className="text-[3rem] text-red-900" />
          </button>
          <p className="text-center text-[#111111] font-base">
            An error occured.
          </p>
          <button
            onClick={() => setFailedRequest(false)}
            className=" bg-cleva-gold py-4 px-8 rounded-md cursor-pointer text-[#111111] font-bold text-sm"
          >
            Try again
          </button>
        </div>
      </ModalTemplate>
    </>
  );
};

export default ProofAccounts;
