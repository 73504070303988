import { createAsyncThunk } from "@reduxjs/toolkit";
import DashboardServices from "../services/DashboardServices";
import { CreateCard, FundCard, FreezeCard } from "../../components/model";
import { AWS_CREDS } from "../services/AmazonService";

export const createCard = createAsyncThunk(
  "cards/createCard",
  async (card: CreateCard) => {
    try {
      const response = await DashboardServices.createCard(card);
      return response.data;
    } catch (error) {
      if (AWS_CREDS.STAGE === "qa") {
        console.log(error);
      }
    }
  }
);

export const fetchCard = createAsyncThunk("cards/fetchCards", async () => {
  try {
    const response = await DashboardServices.fetchCard();
    return response.data?.CardsSummaryList;
  } catch (error) {
    if (AWS_CREDS.STAGE === "qa") {
      console.log(error);
    }
  }
});

export const fetchCardById = createAsyncThunk(
  "cards/fetchCardById",
  async (CardIdentifier: string) => {
    try {
      const response = await DashboardServices.fetchCardByID(CardIdentifier);
      return response.data;
    } catch (error) {
      if (AWS_CREDS.STAGE === "qa") {
        console.log(error);
      }
    }
  }
);

export const fundCard = createAsyncThunk(
  "cards/fundCard",
  async ({
    card,
    CardIdentifier,
  }: {
    card: FundCard;
    CardIdentifier: string;
  }) => {
    try {
      const response = await DashboardServices.fundCard(card, CardIdentifier);
      return response.data;
    } catch (error) {
      if (AWS_CREDS.STAGE === "qa") {
        console.log(error);
      }
    }
  }
);

export const fetchCardTransactions = createAsyncThunk(
  "cards/fetchCardTransactions",
  async (CardIdentifier: string) => {
    try {
      const response = await DashboardServices.fetchCardTransactions(
        CardIdentifier!
      );
      return response?.data?.CardTransactionsSummaryList;
    } catch (error) {
      if (AWS_CREDS.STAGE === "qa") {
        console.log(error);
      }
    }
  }
);

export const verifyCardBVN = createAsyncThunk(
  "cards/verifyCardBVN",
  async (identifier: string) => {
    try {
      const response = await DashboardServices.verifyCardBVN(identifier);
      if (AWS_CREDS.STAGE === "qa") {
        console.log(response);
      }
      return response.data;
    } catch (error) {
      if (AWS_CREDS.STAGE === "qa") {
        console.log("error verifying card bvn", error);
      }
      throw Error;
    }
  }
);

export const freezeCard = createAsyncThunk(
  "cards/freezeCard",
  async ({
    card,
    CardIdentifier,
  }: {
    card: FreezeCard;
    CardIdentifier: string;
  }) => {
    try {
      const response = await DashboardServices.freezeCard(card, CardIdentifier);
      return response.data;
    } catch (error) {
      if (AWS_CREDS.STAGE === "qa") {
        console.log(error);
      }
    }
  }
);

export const verifyUserHasBvn = createAsyncThunk(
  "cards/verifyUserHasBvn",
  async () => {
    try {
      const response = await DashboardServices.verifyUserHasBvn();
      return response.data;
    } catch (error) {
      if (AWS_CREDS.STAGE === "qa") {
        console.log(error);
      }
    }
  }
);
