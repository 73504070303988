import { useState } from "react";
import CurrencyModal from "./CurrencyModal";
import USIcon from "../../../asset/images/USDD.svg";
import ngnIcon from "../../../images/ngn.svg";
import arrowIcon from "../../../images/arrow-down.svg";

type Props = {
  NGNAmount: string;
  USAmount: string;
};

const CurrencySelect: React.FC<Props> = ({ NGNAmount, USAmount }) => {
  const [showCurrencyModal, setShowCurrencyModal] = useState(false);
  const [currency, setCurrency] = useState("USD");
  const [icon, setIcon] = useState(USIcon);

  // const handleCloseModal = () => {
  //   setShowModal(false);
  // };

  const handleMore = () => {
    setShowCurrencyModal(true);
  };

  const handleCloseMoreModal = () => {
    setShowCurrencyModal(false);
  };

  const handleCurrencySelect = (selectedCurrency: string) => {
    if (selectedCurrency === "USD") {
      setCurrency("USD");
      setIcon(USIcon); // Set USD icon
    } else if (selectedCurrency === "NGN") {
      setCurrency("NGN");
      setIcon(ngnIcon); // Set NGN icon
    }
    setShowCurrencyModal(false);
  };
  return (
    <>
      <div className="flex justify-center">
        <button
          className="flex bg-[#FFF4E2] rounded-full px-4 py-2 items-center justify-between md:mb-3 mb-1 text-[#111111]"
          onClick={handleMore}
        >
          <div className="flex items-center">
            <img src={icon} alt="" className="mr-2" />
            {currency}
          </div>
          <div className="ml-4">
            <img src={arrowIcon} alt="" />
          </div>
        </button>
      </div>

      {showCurrencyModal && (
        <CurrencyModal
          closeModal={handleCloseMoreModal}
          USAmount={USAmount}
          NGNAmount={NGNAmount}
          onCurrencySelect={handleCurrencySelect}
        />
      )}
      {/* {showCurrencyModal && <CurrencyModal closeModal={handleCloseMoreModal} />} */}
    </>
  );
};

export default CurrencySelect;
