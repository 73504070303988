import React, { useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import SearchTable from "./SearchTable";
import HeaderTitle from "./HeaderTitle";
import HeaderAction from "./HeaderAction";
import ExportBtn from "../Buttons/ExportButton";
import Spinner from "../PopUps/Spinner";

interface DataItem {
  [key: string]: string | number | boolean; // Add index signature
}
interface IndexProps {
  data: any;
  pending?: boolean;
  title: string;
  searchPlaceholder: string;
  // userCount: number;
  TableColumns: any;
  onClickTable: (row: any) => void;
}

const customStyles = {
  rows: {
    style: {
      minHeight: "56px", // override the row height
      padding: "2px",
      cursor: "pointer",
    },
  },
};

const CardTable: React.FC<IndexProps> = ({
  data,
  pending,
  title,
  searchPlaceholder,
  TableColumns,
  onClickTable,
  // userCount,
}) => {
  const [searchVal, setSearchVal] = useState<string>("");
  const [filteredData, setFilteredData] = useState<any[]>(data);
  const [selectedInterval, setSelectedInterval] = useState<string>("ALL");
  const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(null);
  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(null);

  const intervals = [
    { label: "ALL", days: 0 },
    { label: "1 W", weeks: 1 },
    { label: "1 M", months: 1 },
    { label: "3 M", months: 3 },
    { label: "6 M", months: 6 },
  ];

  const handleIntervalClick = (
    interval: string,
    intervalConfig: { days?: number; weeks?: number; months?: number } = {}
  ) => {
    const { days = 0, weeks = 0, months = 0 } = intervalConfig;

    setSelectedInterval(interval);

    if (months > 0) {
      const today = new Date();
      const startDate = new Date(
        today.getFullYear(),
        today.getMonth() - months,
        today.getDate()
      );
      const endDate = new Date(today);

      setSelectedStartDate(startDate);
      setSelectedEndDate(endDate);
    } else if (weeks > 0) {
      const today = new Date();
      const endDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate()
      );
      const startDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - weeks * 7 + 1
      );

      setSelectedStartDate(startDate);
      setSelectedEndDate(endDate);
    } else if (days > 0) {
      const today = new Date();
      const endDate = new Date(today);
      const startDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - days + 1
      );

      setSelectedStartDate(startDate);
      setSelectedEndDate(endDate);
    } else {
      setSelectedStartDate(null);
      setSelectedEndDate(null);
    }

    const now = new Date();

    function getDatesInPastInterval(intervalConfig: {
      days?: number;
      weeks?: number;
      months?: number;
    }) {
      const pastDate = new Date(now);
      if (intervalConfig.weeks) {
        pastDate.setDate(pastDate.getDate() - intervalConfig.weeks * 7);
      } else if (intervalConfig.months) {
        pastDate.setMonth(pastDate.getMonth() - intervalConfig.months);
      }

      const datesForPastInterval = [];

      const endDate = new Date(now);

      while (pastDate <= endDate) {
        datesForPastInterval.push(new Date(pastDate));
        pastDate.setDate(pastDate.getDate() + 1);
      }

      return datesForPastInterval;
    }

    const intervalDates = getDatesInPastInterval({ days, weeks, months });

    let intervalStart = intervalDates[0];
    let intervalEnd = intervalDates[intervalDates.length - 1];
    if (interval === "ALL") {
      setFilteredData(data); // Reset to the default data
    } else {
      const filteredData = data?.filter((date: any) => {
        const myDate = new Date(date.CreatedAt);

        let newArr =
          myDate >= new Date(intervalStart) && myDate <= new Date(intervalEnd);
        return newArr;
      });
      setFilteredData(filteredData);
    }
  };

  const formatDate = (date: Date | null) => {
    if (date) {
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const month = monthNames[date.getMonth()];
      const day = date.getDate();
      const year = date.getFullYear(); // Get the year
      return `${month} ${day}, ${year}`;
    }
    return "";
  };

  const search = (rows: DataItem[]) => {
    return rows?.filter(
      (row) =>
        JSON.stringify(row).toLowerCase().indexOf(searchVal?.toLowerCase()) !==
        -1
    );
  };

  function downloadCSV(tableArray: DataItem[]) {
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(tableArray);
    if (csv == null) return;

    const filename = `${title}.csv`;

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  function convertArrayOfObjectsToCSV(myArray: DataItem[]) {
    let result: string;

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(myArray[0]);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    myArray.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  const actionsMemo = useMemo(
    () => <ExportBtn onExport={() => downloadCSV(data)} />,
    []
  );

  return (
    <>
      <div className="pt-4">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            marginBottom: "10px",
            flexWrap: "wrap",
          }}
          className="space-y-1"
        >
          <div>
            <p className="font-semibold text-base">
              {selectedInterval === "ALL"
                ? "All Transactions"
                : `${formatDate(selectedStartDate)} - ${formatDate(
                    selectedEndDate
                  )}`}
            </p>
          </div>

          <div className="md:flex items-center">
            <div className="mr-3">
              <SearchTable
                SearchTable={(e) => setSearchVal(e.target.value)}
                filteredText={searchVal}
                placeholder={searchPlaceholder}
              />
            </div>
            <div className="bg-[#EBF0F0] rounded-[10px] px-2 py-1.5">
              {intervals.map((interval, index) => (
                <button
                  key={index}
                  onClick={() =>
                    handleIntervalClick(interval.label, {
                      days: interval.days,
                      weeks: interval.weeks,
                      months: interval.months,
                    })
                  }
                  className={
                    selectedInterval === interval.label
                      ? "selected bg-black text-white rounded-lg py-1 px-2 font-semibold text-xs"
                      : " mx-2 font-medium text-xs"
                  }
                >
                  {interval.label}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="pt-2 outlet">
        <DataTable
          className="border-y table-radius"
          responsive
          highlightOnHover
          title={<HeaderTitle title={title} />}
          actions={<HeaderAction actionMemo={actionsMemo} />}
          columns={TableColumns}
          data={search(filteredData)}
          defaultSortFieldId={1}
          defaultSortAsc={false}
          progressPending={pending}
          progressComponent={<Spinner />}
          // sortIcon={<img src={SortIcon} alt="sortIcon" className="ml-1" />}
          pagination
          selectableRows
          customStyles={customStyles}
          onRowClicked={onClickTable}
        />
      </div>
    </>
  );
};

export default CardTable;
