import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../services/AxiosInstance";
import { AWS_CREDS } from "../services/AmazonService";

export const fetchReferral = createAsyncThunk(
  "referral/fetchReferral",
  async () => {
    try {
      const response = await axios.get(AWS_CREDS.APIGATEWAY_URL + "/referral");

      return response.data;
    } catch (error) {
      if (AWS_CREDS.STAGE === "qa") {
        console.error("Error fetching referrals:", error);
      }
      throw error;
    }
  }
);

export const updateDisplayReferralModal = async () => {
  try {
    const response = await axios.put(
      `${AWS_CREDS.APIGATEWAY_URL}/referral`,
      {}
    );

    return response;
  } catch (error) {
    if (AWS_CREDS.STAGE === "qa") {
      console.error("updateDisplayReferralModal", error);
    }
    throw error;
  }
};
