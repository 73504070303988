import React, { useState } from "react";
import SuccessModal from "../../components/PopUps/SuccessModal";
import { postAccount } from "../../features/VirtualAccount/VirtualAccountApi";
import { RootState, AppDispatch } from "../../app/store";
import { useSelector, useDispatch } from "react-redux";
import { setLoading } from "../../features/VirtualAccount/VirtualAccountSlice";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../components/PopUps/MainLoader";
import { AWS_CREDS } from "../../features/services/AmazonService";

interface AccountProps {
  icon?: string;
  linkText?: string;
  dashIcon?: string;
}

const OpenAccount: React.FC<AccountProps> = ({ icon, linkText, dashIcon }) => {
  const dispatch = useDispatch<AppDispatch>();
  const loading = useSelector(
    (state: RootState) => state.virtualAccount.loading
  );

  let user = useSelector((state: RootState) => state.user.userData);
  if (!user) {
    user = JSON.parse(localStorage.getItem("storageUser")!);
  }
  const FirstName = user?.FullName.FirstName || "";
  const LastName = user?.FullName.LastName || "";
  const email = user?.StandardAttributes.Email || "";
  const myData = {
    FirstName,
    LastName,
    email,
  };

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const handleSubmit = () => {
    // Perform form submission
    dispatch(setLoading(true));
    setLoading(true);
    // setShowSuccessModal(true);

    const action = postAccount(myData);
    dispatch(action)
      .unwrap()
      .then((response: any) => {
        // console.log(response)
        if (response) {
          setLoading(false);
          setShowSuccessModal(true);
        } else {
          setLoading(false);
          dispatch(setLoading(false));
          toast.error("Account Creation Failed, Please try again later");
        }
      })
      .catch((error: any) => {
        dispatch(setLoading(false));
        setLoading(false);
        toast.error("Account Creation Failed, Please contact support");
        if (AWS_CREDS.STAGE === "qa") {
          console.log(error);
        }
        // Prevent navigation if the response returns undefined
        if (error === undefined) {
          return;
        }
        return Promise.reject(error);
      });
  };

  const closeSuccessModal = () => {
    setShowSuccessModal(false);
    window.location.reload();
  };

  return (
    <>
      {loading ? (
        <Loader text="Your account is being generated" />
      ) : (
        <div className="relative flex rounded-2xl items-center text-white  justify-between bg-[#111111] border shadow-xl hover:shadow-xl  h-full">
          <div className="w-[100%] mx-auto z-[10]">
            <div
              className="flex items-center justify-center md:py-[4.5rem] py-[4rem]  px-4 md:px-6 cursor-pointer"
              onClick={handleSubmit}
            >
              <div className="">
                <img src={icon} className="w-[2.5rem]" alt="logo" />
              </div>
              <div className=" ml-3">
                <div className="mb-0">
                  <p className="font-medium underline">{linkText}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="absolute bottom-0 right-0">
            <img src={dashIcon} className="opacity-50" alt="logo" />
          </div>
        </div>
      )}
      {/* {loading && <Loader />} */}

      {showSuccessModal && (
        <SuccessModal
          text="Account created successfully"
          btnText="View Account"
          secText="Congratulations! Your USD account has been created."
          onClose={closeSuccessModal}
        />
      )}
      <ToastContainer />
    </>
  );
};

export default OpenAccount;
