import { createAsyncThunk } from "@reduxjs/toolkit";
import { ITransaction, CreateM2Transactions } from "../../components/model";
import DashboardServices from "../services/DashboardServices";
import axios from "../services/AxiosInstance";
import { AWS_CREDS } from "../services/AmazonService";
import { IUser } from "../../types";
import { PatchUser } from "../../login";

export const postTransaction = createAsyncThunk(
  "transactions/postTransaction",
  async (transaction: ITransaction) => {
    try {
      const response: any =
        await DashboardServices.createTransaction(transaction);
      return response.data;
    } catch {
      localStorage.removeItem("OTPToken");
      delete axios.defaults.headers.common["Otptoken"];
    }
  }
);

//Milestone 2 make transfer
export const createTransaction = createAsyncThunk(
  "transactions/postTransaction",
  async (transaction: CreateM2Transactions) => {
    try {
      const response: any =
        await DashboardServices.postTransaction(transaction);
      return response.data;
    } catch {
      localStorage.removeItem("OTPToken");
      delete axios.defaults.headers.common["Otptoken"];
    }
  }
);

export const fetchRecipients = createAsyncThunk(
  "transactions/fetchRecipients",
  async () => {
    const response = await DashboardServices.fetchRecipients();
    const res = response.data;
    return res.RecipientSummaryList;
  }
);

export const fetchTransactions = createAsyncThunk(
  "transactions/fetchTransactions",
  async () => {
    const response = await DashboardServices.fetchTransfers();
    return response.data;
  }
);

// fetch dummy transactions

export const fetchDummyTransactions = createAsyncThunk(
  "transactions/fetchDummyTransactions",
  async (AccountNumber: number) => {
    const response = await DashboardServices.fetchDummyTransfers(AccountNumber);
    return response.data;
  }
);

export const fetchTransactionById = createAsyncThunk(
  "transactions/fetchTransactionsById",
  async (TransactionIdentifier: string) => {
    const response = await DashboardServices.fetchTransfersByID(
      TransactionIdentifier
    ); // Call your API function to fetch a post by ID

    return response.data; // Assuming your API returns data in the `data` field
  }
);

export const cancelTransfer = createAsyncThunk(
  "transactions/cancelTransfer",
  async ({
    TransactionIdentifier,
    data,
  }: {
    TransactionIdentifier: string;
    data: any;
  }) => {
    try {
      const response = await DashboardServices.cancelTransaction(
        TransactionIdentifier,
        data
      );
      // console.log(response)
      return response.data;
    } catch {
      throw new Error("Failed to cancel transaction.");
    }
  }
);

export const updateTransaction = async (
  id: string,
  TransactionState: string
) => {
  try {
    const response = await axios.put(
      `${AWS_CREDS.APIGATEWAY_URL}/transactions/${id}`,
      { TransactionState }
    );
    // toast.success("Transfer cancelled successfully");
    return response.data;
  } catch {
    throw new Error("Failed to update transaction");
  }
};

export const setPayoutDailyLimit = async (user: IUser) => {
  const res = await PatchUser(user?.ClevaUserID! || user?.BusinessIdentifier, {
    ActionType: "PAYOUT_LIMIT",
  });
  return res;
};

export const fetchRates = createAsyncThunk(
  "transactions/fetchRates",
  async () => {
    const response = await DashboardServices.fetchRate();
    // console.log(response)
    return response.data;
  }
);

export const createTagTransactions = createAsyncThunk(
  "transactions/createTagTransaction",
  async (transaction: CreateM2Transactions) => {
    try {
      const response: any =
        await DashboardServices.createTagTransaction(transaction);
      return response.data;
    } catch {
      localStorage.removeItem("OTPToken");
      delete axios.defaults.headers.common["Otptoken"];
    }
  }
);
