import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getDeals } from "./DealsApi";

export interface DealsData {
  DealID: string;
  Image: string;
  Name: string;
  Code: string;
  Percentage: number;
  Product: string;
  Active?: boolean;
  Title: string;
  Description: string;
  Guide: string[];
  Redeemed?: boolean;
  RedeemedDate: string;
  ExpiryDate: string;
  Link: string;
  SubTitle: string;
}

interface DealsState {
  loading: boolean;
  error: string | null;
  selectedDeal: DealsData;
  data: DealsData[];
}

const initialState: DealsState = {
  loading: false,
  error: null,
  selectedDeal: {
    DealID: "",
    Image: "",
    Name: "",
    Code: "",
    Percentage: 0,
    Product: "",
    Active: false,
    Title: "",
    Description: "",
    Guide: [],
    Redeemed: false,
    RedeemedDate: "",
    ExpiryDate: "",
    Link: "",
    SubTitle: "",
  },
  data: [],
};

const DealsSlice = createSlice({
  name: "deals",
  initialState,
  reducers: {
    setSelectedDeal(state, action: PayloadAction<any>) {
      state.selectedDeal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDeals.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getDeals.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getDeals.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error as string;
      });
  },
});

export const { setSelectedDeal } = DealsSlice.actions;
export default DealsSlice.reducer;
