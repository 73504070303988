import successIcon from "../../../images/checkmark.svg";

interface ITagModal {
  closeModal: () => void;
  tag: string | null;
}

const TagComplete = ({ closeModal, tag }: ITagModal) => {
  return (
    <>
      <div className="flex items-center justify-center mt-6">
        <img
          loading="lazy"
          className="m-4 mt-8 w-[4rem]"
          src={successIcon}
          alt="success"
        />
      </div>
      <div className="mt-1 text-center items-center sm:p-4">
        <p className="text-5md font-semibold text-[#158025]">
          Tag created successfully
        </p>
        <p className="text-md m-auto text-[#484848] py-2 w-[85%] md:w-[70%]">
          Share your Cleva tag <b>(@{tag})</b> to receive instant USD from other
          Cleva users
        </p>
      </div>
      <div className="m-8 items-center">
        <button
          onClick={closeModal}
          className="m-auto login-active w-full md:w-auto md:px-8 py-4 text-sm font-medium text-white bg-[#0772D5] rounded-lg"
        >
          Done
        </button>
      </div>
    </>
  );
};

export default TagComplete;
