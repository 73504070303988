import React, { useCallback, useEffect, useState } from "react";
import { Dialog } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import loadingIcon from "../../../asset/svg/loading.svg";
import { debounce } from "lodash";
import { checkTag, updateTag } from "../../../api";
import { setTag } from "../../../features/Tags/TagsSlice";
import { toast, ToastContainer } from "react-toastify";

interface IProfileModal {
  tag: string | null;
  closeModal: () => void;
  onSuccess: () => void;
}

const EditTag = ({ tag, closeModal, onSuccess }: IProfileModal) => {
  const dispatch = useAppDispatch();
  const [error, setError] = useState("");
  const [Username, setUsername] = useState(tag!);
  const [loading, setLoading] = useState<boolean>(false);
  const [btnLoading, setButtonLoader] = useState<boolean>(false);
  const [success, setSuccess] = useState("");
  const btnDisabled =
    error !== "" || Username === tag || loading || !success || btnLoading;
  const user = useAppSelector((state) => state.user.userData);

  const debouncedSave = useCallback(
    debounce(async (nextValue: string) => {
      setLoading(true);
      try {
        const res = await checkTag({ Tag: nextValue });
        setLoading(false);
        if (res?.data.Available) {
          setSuccess("Tag is available");
          setError("");
        } else if (nextValue === tag) {
          setSuccess("You already own this tag");
          setError("");
        } else {
          setError("Tag already in use by another user");
          setSuccess("");
        }
      } catch {
        setLoading(false);
        setError("An error occurred while checking the tag");
      }
    }, 500),
    []
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let nextValue = e.target.value.toLowerCase();
    nextValue = nextValue.replace(/[^a-z0-9_]/g, "");
    setUsername(nextValue);
    setLoading(false);
    setSuccess("");
    setError("");

    if (!nextValue.match(/^\w{4,12}$/)) {
      if (nextValue.length > 12) {
        setError("Tag cannot be more than 12 characters");
      } else if (nextValue.length < 4) {
        setError("Tag cannot be less than 4 characters");
      } else {
        setError("Tag can only contain letters, numbers, and underscores");
      }
    } else {
      setError("");
      setSuccess("");
      if (nextValue !== "") {
        debouncedSave(nextValue);
      }
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setButtonLoader(true);
    try {
      const res = await updateTag(user?.ClevaUserID!, {
        NewTag: Username,
        OldTag: tag,
      });
      setButtonLoader(false);
      if (res.status === 400) {
        setError("Tag is not available");
      } else {
        dispatch(setTag(Username));
        onSuccess();
      }
    } catch (error: any) {
      setButtonLoader(false);
      toast.error(error.message);
    }
  };

  useEffect(() => {
    if (tag) {
      setUsername(tag);
    }
  }, [tag]);

  return (
    <>
      <ToastContainer />
      <header className={`w-full bg-[#EFEFEF] py-4 px-8  flex justify-between`}>
        <Dialog.Title
          as="h3"
          className="text-base font-bold leading-6 text-gray-900"
        >
          Edit Tag
        </Dialog.Title>
        <button
          type="button"
          className="rounded-md text-black hover:text-gray-500 focus:outline-none focus:ring-2 font-bold"
          onClick={closeModal}
        >
          <span className="sr-only">Close</span>
          <XMarkIcon className="h-4 w-4" aria-hidden="true" />
        </button>
      </header>
      <section className="px-16 pt-[2rem] pb-[1.75rem]">
        <form onSubmit={handleSubmit} autoComplete="off" className=" ">
          <div className="flex mt-2">
            <button type="button" className="username-icon bg-[#F4F4F4]">
              <p className="text-[20px]">@</p>
            </button>
            <input
              type="text"
              id="Tag"
              name="Tag"
              autoComplete="off"
              value={Username}
              onChange={handleInputChange}
              placeholder="yourTag"
              required
              className="username-control"
              aria-label="yourTag"
            />
          </div>

          <div className="mt-2">
            {loading && (
              <div className="text-[#454545] text-sm mb-2 flex">
                <img src={loadingIcon} width={20} alt="loading" /> &nbsp;
                Searching
              </div>
            )}

            {!loading && error && (
              <div className="text-red-500 text-sm mb-2">{error}</div>
            )}

            {!loading && !error && success && (
              <div className="text-[#158025] text-sm mb-2">{success}</div>
            )}
          </div>
          <div className="mt-8">
            <button
              type="submit"
              disabled={Username === "" || btnDisabled}
              className={`bg-cleva-gold opacity-${
                !Username || btnDisabled ? "60" : "100"
              } flex items-center justify-center mb-3 md:mb-0 font-medium rounded-lg md:py-2.5 py-3 px-4 md:px-8 shadow-sm w-[100%]`}
            >
              {!btnLoading ? "Submit" : "Loading"}
            </button>
          </div>
        </form>
      </section>
    </>
  );
};

export default EditTag;
