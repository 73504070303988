import Progress from "../../asset/cards/progress-check.svg";
import { Link } from "react-router-dom";
import Card from "../../asset/cards/card.png";

const PendingCard = () => {
  return (
    <section className="pt-10 xl:ml-20">
      <div className="md:w-[80%] xl:w-[40rem]  mx-auto pb-10">
        <h1 className="text-[#141414] text-xl font-semibold pb-[1.86rem]">
          Cleva Virtual Card
        </h1>
        <img
          className="xl:w-[90%] rounded-[1.25rem]"
          src={Card}
          alt="Cleva virtual  card"
        />
        <div className="bg-white xl:w-[90%] mt-8 border border-[#E4E4E4] shadow-md rounded-[1.25rem] p-[1.5rem] md:p-10">
          <div className="flex flex-col items-center justify-center text-center">
            <img src={Progress} alt="" />
            <h1 className="text-[#111] text-base font-semibold py-4">
              Your card is getting ready
            </h1>
            <p className="text-[#494949]  text-sm font-normal">
              You’ll be notified when your card is ready for use.
            </p>

            <div className="w-[60%] mx-auto flex justify-between items-center pt-[3.04rem]">
              <Link
                to="/"
                className="rounded-md bg-cleva-gold cursor-pointer py-3 text-lg font-medium w-full d-block"
              >
                {" "}
                Go Back Home
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PendingCard;
