import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setTitle } from "../../features/Nav/NavSlice";
import { getKyc } from "../../api";
import {
  setAdditionalDetails,
  setIndividualkyc,
  setKYCState,
} from "../../features/Kyc/kycSlice";
import {
  fetchCard,
  verifyUserHasBvn,
} from "../../features/ClevaCards/ClevaCardsApi";
import CardState from "./CardState";
import NewCard from "./NewCard";
import Spinner from "../../components/PopUps/Spinner";
import { setUserNames } from "../../features/ClevaCards/ClevaCardsSlice";
import { AWS_CREDS } from "../../features/services/AmazonService";
import Announcement from "../../components/Announcement/Announcement";
export interface ICards {
  cards: any;
}

const ClevaCard = () => {
  const [cardExist, setCardExist] = useState(false);
  const dispatch = useAppDispatch();
  const [dataFetched, setdataFetched] = useState(false);
  const cards = useAppSelector((state) => state.clevaCards.cards);
  const [loading, setLoading] = useState(true);
  const KYCIdentifier = localStorage.getItem("KYCIdentifier");
  const kycUser = useAppSelector((state) => state.kycInfo.IndividualKyc);

  useEffect(() => {
    dispatch(setTitle("Card"));
    dispatch(verifyUserHasBvn());
    dispatch(fetchCard())
      .then(() => {
        setdataFetched(true);
      })
      .catch((error) => {
        if (AWS_CREDS.STAGE === "qa") {
          console.error("Error fetching card:", error);
        }
        setdataFetched(false);
      });
  }, [dispatch]);

  useEffect(() => {
    if (KYCIdentifier) {
      getKyc(KYCIdentifier)
        .then((res) => {
          if (res?.data?.IndividualKyc) {
            dispatch(setIndividualkyc(res?.data?.IndividualKyc));
          } else if (res?.data?.BusinessKyc) {
            dispatch(setKYCState(res?.data?.BusinessKyc?.KYCState!));
            dispatch(
              setAdditionalDetails(res?.data?.BusinessKyc?.AdditionalDetails)
            );
          } else {
            dispatch(setKYCState(""));
          }
        })
        .catch(() => {
          dispatch(setKYCState(""));
        });
    }
  }, [dispatch, KYCIdentifier]);

  useEffect(() => {
    if (kycUser) {
      // does user have multiple name object
      const { FirstName, MiddleName, LastName } = kycUser;
      const namesArray = [FirstName, MiddleName, LastName].filter(Boolean);
      const allNames: string[] = [];
      namesArray.forEach((name) => {
        if (typeof name === "string") {
          const splitNames = name.split(" ");
          const lowerName = splitNames.map(
            (name) => name.charAt(0) + name.slice(1).toLowerCase()
          );
          const trimName = lowerName.filter((item) => item.length !== 0);
          allNames.push(...trimName);
        }
      });
      dispatch(setUserNames(allNames));
    }
  }, [kycUser, dispatch]);

  useEffect(() => {
    setLoading(true);

    if (cards && (cards as any)?.length > 0) {
      let cardExistFlag = false;
      const activeCard = (cards as any).find((card: any) =>
        ["ACTIVE", "PENDING", "FROZEN"].includes(card.Status)
      );
      if (activeCard) {
        cardExistFlag = true;
      }

      setCardExist(cardExistFlag);
      setLoading(false);
    }
  }, [cards, dispatch]);

  if (loading && !dataFetched) {
    return <Spinner />;
  }

  return (
    <>
      <div className="pt-4">
        <Announcement Service="Cards" />
      </div>

      {cardExist ? <CardState cards={cards} /> : <NewCard />}
    </>
  );
};

export default ClevaCard;
