import { useEffect, useState } from "react";
import { getKyc } from "../../api";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import Spinner from "../../components/PopUps/Spinner";
import { setKYCState, setAdditionalDetails } from "../../features/Kyc/kycSlice";
import Dashboard from "./Dashboard";
import { getReturningUser, getUserIdWithAccessToken } from "../../login";
import {
  fetchRates,
  fetchTransactions,
} from "../../features/Transanctions/transactionApi";
import { fetchAccount } from "../../features/VirtualAccount/VirtualAccountApi";
import { fetchCard } from "../../features/ClevaCards/ClevaCardsApi";
import { AWS_CREDS } from "../../features/services/AmazonService";
import { setUser } from "../../features/User/UserSlice";

export default function Home() {
  const dispatch = useAppDispatch();

  const KYCIdentifier = localStorage.getItem("KYCIdentifier");
  const [localLoading, setLocalLoading] = useState(true);

  const [cardExist, setCardExist] = useState(false);
  const cards = useAppSelector((state) => state.clevaCards.cards);
  const allAccount = useAppSelector(
    (state) => state.virtualAccount?.allAccount
  );
  const hasAccounts = allAccount && Object.keys(allAccount).length > 0;
  const amount = allAccount ? (allAccount as any).Balance?.Money : " ";

  // Fetch card data
  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(fetchCard());
      } catch (error) {
        if (AWS_CREDS.STAGE === "qa") {
          console.error("Error fetching card:", error);
        }
      }
    };
    fetchData();
  }, [dispatch]);

  // Check if user has an active card
  useEffect(() => {
    if (cards && (cards as any)?.length > 0) {
      const activeCard = (cards as any).find((card: any) =>
        ["ACTIVE", "PENDING", "FROZEN"].includes(card.Status)
      );
      if (activeCard) {
        setCardExist(true);
      }
    }
  }, [cards]);

  // Fetch user data, transactions, rates, account, and KYC state
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        await getUserIdWithAccessToken();
        const user = await getReturningUser();
        if (user) {
          dispatch(setUser(user));
        }
        await Promise.all([
          dispatch(fetchTransactions()),
          dispatch(fetchRates()),
          dispatch(fetchAccount()),
        ]);

        if (KYCIdentifier) {
          const res = await getKyc(KYCIdentifier);
          if (res?.data?.IndividualKyc) {
            dispatch(setKYCState(res?.data?.IndividualKyc?.KYCState!));
            dispatch(
              setAdditionalDetails(res?.data?.IndividualKyc?.AdditionalDetails)
            );
          } else if (res?.data?.BusinessKyc) {
            dispatch(setKYCState(res?.data?.BusinessKyc?.KYCState!));
            dispatch(
              setAdditionalDetails(res?.data?.BusinessKyc?.AdditionalDetails)
            );
          } else {
            dispatch(setKYCState(""));
          }
        }
      } catch {
        dispatch(setKYCState(""));
      } finally {
        setLocalLoading(false);
      }
    };

    fetchUserData();
  }, [dispatch, KYCIdentifier]);

  // Return loading status for the entire component
  if (localLoading) {
    return <Spinner />;
  }

  // Render the dashboard after all loading is complete
  return (
    <Dashboard
      userHasCard={cardExist}
      hasAccounts={hasAccounts}
      amount={amount}
    />
  );
}
