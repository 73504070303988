import { useState, useEffect } from "react";
import TabButtons from "../../../components/Tabs/TabButton";
import TabContent from "../../../components/Tabs/TabContent";
import Table from "../../../components/Table/Index";
import { TransferColumn } from "../../../components/Table/TransferColumn";
import { IncomingTransferColumn } from "../../../components/Table/IncomingTransColumn";
import ViewIncomingTransfer from "./modals/ViewIncomingTransfer";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchTransactions,
  fetchTransactionById,
} from "../../../features/Transanctions/transactionApi";
import { fetchAccount } from "../../../features/VirtualAccount/VirtualAccountApi";
import { AppDispatch } from "../../../app/store";
import { ToastContainer } from "react-toastify";
import Spinner from "../../../components/PopUps/Spinner";
import { setTitle } from "../../../features/Nav/NavSlice";
import ViewOutgoingTransfer from "./modals/ViewOutgoingTransfer";
import RefundModal from "../../Transfers/M2/modals/ViewPayoutRefund";
import ReversalModal from "../../Transfers/M2/modals/ViewReversal";
import ReversalRefundModal from "../../Transfers/M2/modals/ViewReversalRefund";
import CardFundingModal from "../../Transfers/M2/modals/CardFundingModal";
import C2CModal from "./modals/C2CModal";

const MODAL_MAP: Record<string, React.FC<{ closeModal: () => void }>> = {
  MAKE_PAYMENT: ViewOutgoingTransfer,
  MAKE_PAYMENT_REFUND: RefundModal,
  TAG_DEBIT_REVERSAL: RefundModal,
  REVERSED: ReversalModal,
  REVERSAL_REFUNDED: ReversalRefundModal,
  CARD_FUNDING: CardFundingModal,
  CARD_CREATION: CardFundingModal,
  CARD_TERMINATION_REFUND: CardFundingModal,
  CARD_CREATION_REFUND: CardFundingModal,
  CARD_FUNDING_REFUND: CardFundingModal,
  TAG_CREDIT: C2CModal,
  TAG_TRANSFER: C2CModal,
};

export default function Transfers() {
  const { allTransfer, loading } = useSelector(
    (state: any) => state.transaction
  );
  const [activeTab, setActiveTab] = useState<string>("allTransactions");
  const [modal, setModal] = useState(false);
  const [transactionType, setTransactionType] = useState("");
  const [statementModal, setStatementModal] = useState(false);
  const dispatch = useDispatch<AppDispatch>();

  function OutgoingModal(row: any) {
    setTransactionType(row.TransactionType);
    dispatch(fetchTransactionById(row?.TransactionIdentifier));
    setModal(!modal);
  }
  function handleStatementModal() {
    setStatementModal(!statementModal);
  }

  const IncomingModal = (row: any) => {
    setTransactionType(row.TransactionType);

    const transfer = allTransfer.find(
      (transfer: any) =>
        transfer?.TransactionIdentifier === row.TransactionIdentifier
    );
    dispatch(fetchTransactionById(transfer?.TransactionIdentifier));
    setModal(!modal);
  };

  const closeModal = () => {
    setModal(false);
  };

  const OutgoingTrans = allTransfer.filter((transfer: any) => {
    return (
      transfer.TransactionType === "MAKE_PAYMENT" ||
      transfer.TransactionType === "CARD_FUNDING" ||
      transfer.TransactionType === "CARD_CREATION" ||
      transfer.TransactionType === "TAG_TRANSFER" ||
      transfer.TransactionState === "REVERSED"
    );
  });
  const IncomingTrans = allTransfer.filter((transfer: any) => {
    //console.log(allTransfer.filter((transfer:any) => transfer.TransactionType === 'USD_CREDIT'))
    //))
    return (
      (transfer.TransactionType === "USD_CREDIT" &&
        transfer.TransactionState !== "REVERSED") ||
      transfer.TransactionType === "TAG_CREDIT" ||
      transfer.TransactionType === "USD_CREDIT_REVERSAL_REFUND" ||
      transfer.TransactionType === "REFERRAL_BONUS" ||
      transfer.TransactionType === "MAKE_PAYMENT_REFUND" ||
      transfer.TransactionType === "CARD_TERMINATION_REFUND" ||
      transfer.TransactionType === "CARD_CREATION_REFUND" ||
      transfer.TransactionType === "TAG_CREDIT" ||
      transfer.TransactionType === "CARD_FUNDING_REFUND" ||
      transfer.TransactionType === "TAG_DEBIT_REVERSAL"
    );
  });

  useEffect(() => {
    dispatch(setTitle("Transactions"));
    dispatch(fetchTransactions());
    dispatch(fetchAccount());
  }, [dispatch]);

  const renderModal = () => {
    const ModalComponent = MODAL_MAP[transactionType] || ViewIncomingTransfer;
    return <ModalComponent closeModal={closeModal} />;
  };

  return (
    <>
      <div className="pt-10 mb-10 Tables">
        {/* <div className="md:flex items-center justify-end xs:flex-wrap md:flex-nowrap md:float-right">
          <div className="md:w-auto order-1 md:order-3 w-100 mb-3 md:mb-0">
            <div className="flex md:justify-end right">
              <Link
                to="/transfers/create"
                className="btn flex bg-cleva-gold items-center border-[0.5px] border-[#8A8A8A] py-2 px-4 rounded-lg"
              >
                <img src={TransferIcon} alt="" className="mr-1" />
                Make transfer
              </Link>
            </div>
          </div>
        </div> */}

        <div className=" w-full md:mb-8 md:w-1/2 order-0 md:order-0 md:pt-0 pt-5 mb-5">
          <ul className="grid grid-cols-3 gap-4 bg-[#EEEEEE] rounded-[10px] px-2 py-1.5">
            <TabButtons
              name="All"
              id="allTransactions"
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
            <TabButtons
              name="Outgoing"
              id="outgoingTransactions"
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
            <TabButtons
              name="Incoming"
              id="incomingTransactions"
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          </ul>
        </div>
      </div>
      <div className="mt-4">
        <TabContent id="allTransactions" activeTab={activeTab}>
          {loading ? (
            <Spinner /> // Show the spinner when loading is true
          ) : (
            <Table
              data={allTransfer}
              TableColumns={TransferColumn}
              title="All Transactions"
              searchPlaceholder="Search transaction"
              onClickTable={OutgoingModal}
              onclickStatementButton={() => {
                handleStatementModal();
              }}
            />
          )}
        </TabContent>
        <TabContent id="outgoingTransactions" activeTab={activeTab}>
          {loading ? (
            <Spinner /> // Show the spinner when loading is true
          ) : (
            <Table
              data={OutgoingTrans}
              TableColumns={TransferColumn}
              title="Outgoing Transactions"
              searchPlaceholder="Search transaction"
              onClickTable={OutgoingModal}
              onclickStatementButton={() => {
                handleStatementModal();
              }}
            />
          )}
        </TabContent>
        <TabContent id="incomingTransactions" activeTab={activeTab}>
          {loading ? (
            <Spinner /> // Show the spinner when loading is true
          ) : (
            <Table
              data={IncomingTrans}
              TableColumns={IncomingTransferColumn}
              title="Incoming Transactions"
              searchPlaceholder="Search transaction"
              onClickTable={IncomingModal}
              onclickStatementButton={handleStatementModal}
            />
          )}
        </TabContent>
      </div>

      {modal && renderModal()}
      <ToastContainer />
    </>
  );
}
