/* eslint-disable react-hooks/exhaustive-deps */
import Nav from "./Nav";
import SideBar from "./SideBar";
import { Outlet, useNavigate } from "react-router-dom";
import {
  getReturningUser,
  getUserIdWithAccessToken,
  removeAuthTokens,
} from "../../login";
import { useEffect, useState } from "react";
import { setUser } from "../../features/User/UserSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import UseIdleTimer from "../../features/SessionExpiry/IdleTimer";
import { setPayoutDailyLimit } from "../../features/Transanctions/transactionApi";
import { getCurrentDate } from "../../utils/getCurrentDate";
import { AWS_CREDS } from "../../features/services/AmazonService";
import { useIsBusinessUser } from "../../utils";
import { getKyc } from "../../api";
import { setAdditionalDetails, setKYCState } from "../../features/Kyc/kycSlice";
import Spinner from "../PopUps/Spinner";
import RedirectUnverifiedBusinesss from "../Auth/RedirectUnverifiedBusiness";
// import Intercom from "@intercom/messenger-js-sdk";

const FIVE_MINS_IN_MILLISECONDS = 300000;

export default function MainLayout() {
  // const appId = AWS_CREDS.INTERCOM_ENV;
  const KYCIdentifier = localStorage.getItem("KYCIdentifier");
  const [redirectUser, setRedirectUser] = useState<boolean>(false);
  const { KYCState } = useAppSelector((state) => state.kycInfo);
  const isBusinessUser = useIsBusinessUser();
  const [loading, setLoader] = useState(true);
  const navigate = useNavigate();
  const AppDispatch = useAppDispatch();
  const [backButton] = useState(false);

  useEffect(() => {
    let checkUserStatus =
      isBusinessUser === true && KYCState !== "BUSINESS_VERIFIED";
    setRedirectUser(checkUserStatus);
  }, [isBusinessUser, KYCState]);

  useEffect(() => {
    getUserIdWithAccessToken();

    if (KYCIdentifier) {
      getKyc(KYCIdentifier)
        .then((res) => {
          if (res?.data?.IndividualKyc) {
            const individualKyc = res.data.IndividualKyc;
            AppDispatch(setKYCState(individualKyc.KYCState || ""));
            AppDispatch(
              setAdditionalDetails(individualKyc.AdditionalDetails || "")
            );
          } else if (res?.data?.BusinessKyc) {
            const businessKyc = res.data.BusinessKyc;
            AppDispatch(setKYCState(businessKyc.KYCState || ""));
            AppDispatch(
              setAdditionalDetails(businessKyc.AdditionalDetails || "")
            );
          } else {
            AppDispatch(setKYCState(""));
          }
          setLoader(false);
        })
        .catch(() => {
          setLoader(false);
          AppDispatch(setKYCState(""));
        });
    }
  }, [AppDispatch, KYCIdentifier]);

  UseIdleTimer(FIVE_MINS_IN_MILLISECONDS);

  useEffect(() => {
    getReturningUser()
      .then((user: any) => {
        if (
          !user?.PayoutLimit ||
          user.PayoutLimit.daily.currentDay !== getCurrentDate()
        ) {
          return setPayoutDailyLimit(user).then((user) =>
            AppDispatch(setUser(user))
          );
        }
        AppDispatch(setUser(user));
      })
      .catch((err) => {
        if (AWS_CREDS.STAGE === "qa") {
          console.log(err);
        }
        removeAuthTokens();
        AppDispatch(setUser(null));
        navigate("/auth/login");
      });
  }, []);

  // useEffect(() => {
  //   // Store the previous route before navigating to the new one
  //   return () => {
  //     setPreviousPath(location.pathname);
  //   };
  // }, [location.pathname]);

  // useEffect(() => {
  //   if (
  //     (location.pathname === "/profile/tips" ||
  //       location.pathname === "/profile/security" ||
  //       location.pathname === "/deals" ||
  //       location.pathname === "/referrals" ||
  //       location.pathname === "/profile") &&
  //     previousPath === "/profile/settings"
  //   ) {
  //     setBackButton(true);
  //   } else if (location.pathname === "/profile/tips") {
  //     setBackButton(true);
  //   } else {
  //     setBackButton(false);
  //   }
  // }, [location.pathname, previousPath]);

  // Intercom({
  //   app_id: appId,
  //   user_id: user?.ClevaUserID,
  //   name: `${user?.FullName?.FirstName} ${user?.FullName?.LastName}`,
  //   email: user?.StandardAttributes.Email,
  //   // created_at: user.createdAt, // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
  // });

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      {isBusinessUser === null ? (
        <Spinner />
      ) : redirectUser ? (
        <RedirectUnverifiedBusinesss />
      ) : (
        <CentralLayout backButton={backButton} />
      )}
    </>
  );
}

export const CentralLayout = ({ backButton }: { backButton: boolean }) => {
  const [sidebarIsOpen, setSidebarIsOpen] = useState(false);
  const [isScrolled] = useState(false);

  const toggleSidebar = () => {
    setSidebarIsOpen(!sidebarIsOpen);
  };

  const closeSidebar = () => {
    if (sidebarIsOpen) setSidebarIsOpen(false);
  };

  const opaqueBg =
    "max-[768px]:bg-gray-100 max-[768px]:opacity-50 transition-opacity duration-300 ease-in delay-300";

  return (
    <div className="w-12/12 overflow-hidden">
      <section className="flex justify-between m-auto h-screen">
        {/* sideBar */}
        <>
          <SideBar handleClick={toggleSidebar} isOpen={sidebarIsOpen} />
        </>
        {/* Nav With Content */}

        <div
          id="NavWithContent"
          onClick={closeSidebar}
          className={`w-[100%] overflow-y-auto ${
            sidebarIsOpen ? opaqueBg : ""
          }`}
        >
          {/* navBar - w-[90%] min-[2000px]:w-[1440px] m-auto `*/}
          <div id="navwithcontent" className="h-full">
            <div className="bg-[#fff]  py-5 shadow-sm">
              <div
                className="responsive-layout "
                id={`navBar ${isScrolled ? "bg-blue-500" : "bg-transparent"}`}
              >
                <Nav
                  isOpen={sidebarIsOpen}
                  handleClick={() => setSidebarIsOpen(!sidebarIsOpen)}
                  backButton={backButton}
                />
              </div>
            </div>
            {/* main content -  max-h-[90vh] - overflow-y-scroll - */}
            <div
              className=" bg-[#F6f6f6] min-h-[100vh] lg:pb-6 pb-20 scrollBarSettings"
              id="content"
            >
              <div className="responsive-layout">
                <Outlet />
              </div>
            </div>
          </div>
          {/* Footer */}
          {/* <div id="footer">
        <Footer />
      </div> */}
        </div>
      </section>
    </div>
  );
};
