import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "../../asset/images/logo.svg";
import errorImg from "../../asset/svg/material-symbols_warning.svg";
import authImg from "../../asset/images/login-img.svg";
import { Link, useNavigate } from "react-router-dom";
import { getUser, getUserIdWithAccessToken } from "../../login";
import { useAppDispatch } from "../../app/hooks";
import Logo from "../../asset/logo.svg";
import errIcon from "../../images/error-icon.svg";
import { UpdatePin } from "../../api";
import { Auth } from "aws-amplify";
import { setUser } from "../../features/User/UserSlice";
import { AWS_CREDS } from "../../features/services/AmazonService";

const CreatePin = () => {
  const AppDispatch = useAppDispatch();
  const [pin, setPin] = useState("");
  const [confirmPin, setConfirmPin] = useState("");
  const [pinError, setPinError] = useState("");
  const [confirmPinError, setConfirmPinError] = useState("");
  const [formValid, setFormValid] = useState<boolean>(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [showPin, setShowPin] = useState(false);
  const [showConfirmPin, setShowConfirmPin] = useState(false);
  const [isPinValid, setIsPinValid] = useState(true);

  const toggleShowPin = () => {
    setShowPin(!showPin);
  };

  const toggleShowConfirmPin = () => {
    setShowConfirmPin(!showConfirmPin);
  };

  const validatePin = (value: string) => {
    if (value.length !== 4) {
      setPinError("PIN must be 4 digits");
      setIsPinValid(false);
    } else if (!/^[0-9]+$/.test(value)) {
      setPinError("Please enter a valid PIN");
      setIsPinValid(false);
    } else {
      setPinError("");
      setIsPinValid(true);
    }
  };

  const validateConfirmPin = (value: string) => {
    if (value !== pin) {
      setConfirmPinError("PIN and Confirm PIN do not match.");
    } else {
      setConfirmPinError("");
    }
  };

  const handlePinChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPin(value);
    validatePin(value);
  };

  const handleConfirmPinChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    setConfirmPin(value);
    validateConfirmPin(value);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    try {
      const userID = await getUserIdWithAccessToken();
      const session = await Auth.currentSession();
      await UpdatePin(userID, pin).then(async () => {
        setLoading(false);
        navigate("/success-pin");
        const userDetails = await getUser(
          userID,
          session.getIdToken().getJwtToken()
        );
        AppDispatch(setUser(userDetails));
      });
    } catch (error: any) {
      setLoading(false);
      if (AWS_CREDS.STAGE === "qa") {
        console.log(error);
      }
      toast.error(error.message);
    }
  };

  useEffect(() => {
    if (pin && confirmPin) {
      setFormValid(!pinError && !confirmPinError);
    } else {
      setFormValid(false);
    }
  }, [pin, confirmPin, pinError, confirmPinError]);

  return (
    <>
      <div className="md:flex min-h-full">
        <div className="hidden lg:flex md:flex-col justify-center flex-1 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-20 bg-black text-white ">
          <div className="mx-auto w-full max-w-[24rem] 2xl:max-w-[28rem] lg:w-[30rem] xl:w-[28rem]">
            <div className="flex justify-center pt-20 min-h-[100vh] md:items-center md:pt-0">
              <div className="w-full">
                <div>
                  <Link to="/">
                    <img className="w-auto mt-2" src={logo} alt="logo" />
                  </Link>
                  <h1 className="mt-16 text-4xl font-medium text-white">
                    Set Transaction PIN
                  </h1>
                </div>

                <img src={authImg} className="mt-10" alt="login" />
              </div>
            </div>
          </div>
        </div>
        <div className="relative flex-1 bg-[#FBFBFB]">
          <div className="flex justify-center pt-8 min-h-[100vh] items-center md:pt-0">
            <div>
              {localStorage.getItem("KYCIdentifier") !== "placeholder" && (
                <div className="rounded border border-[#7EC9A7] bg-[#E1FFF2] w-full md:w-[32rem] xl:w-[36rem] py-6 px-3 mb-4">
                  <div className="justify-start items-start gap-2 inline-flex">
                    <div className="text-black text-base font-medium font-['Inter'] leading-7">
                      🎉
                    </div>
                    <div className="">
                      <span className="text-green-800 text-base font-semibold font-['Inter'] leading-7">
                        New Feature Alert!
                      </span>
                      <span className="text-black text-base font-medium font-['Inter'] leading-7">
                        {" "}
                        Introducing Transaction PIN, a 4-digit PIN to authorize
                        all of your transactions on Cleva and protect your
                        account.
                      </span>
                    </div>
                  </div>
                </div>
              )}

              <div className="login-card-md w-full md:w-[32rem] xl:w-[36rem] px-8 py-8 md:px-20 md:py-16">
                <div className="">
                  <div className="flex items-center justify-center lg:hidden">
                    <img
                      loading="lazy"
                      className="pb-4 w-[10rem]"
                      src={Logo}
                      alt="Cleva logo"
                    />
                  </div>
                  <h2 className="text-center lg:text-left text-2xl text-black-soft">
                    Set Transaction PIN
                  </h2>
                  <p className="text-black text-opacity-90 mt-3">
                    A 4-digit PIN to authorize your transactions on Cleva
                  </p>

                  {/* form section  */}

                  <form onSubmit={handleSubmit} className="mt-10">
                    <div>
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-black-soft"
                      >
                        Enter 4-digit PIN
                      </label>
                      <div className="flex mt-2">
                        <input
                          id="pin"
                          name="pin"
                          type={showPin ? "text" : "password"}
                          placeholder="4-digit PIN"
                          value={pin}
                          maxLength={4}
                          onChange={handlePinChange}
                          required
                          className={`${
                            !isPinValid ? "border-[#F15757]" : ""
                          } password-control`}
                          aria-label="4-digit PIN"
                          pattern="[0-9]*" // Only accept numeric values
                          inputMode="numeric"
                        />
                        <button
                          type="button"
                          onClick={toggleShowPin}
                          className="password-button"
                        >
                          {showPin ? <p>Hide</p> : <p>Show</p>}
                        </button>
                      </div>
                      {pinError && (
                        <div className="flex items-center mb-2 mt-1">
                          <img src={errIcon} alt="error" />
                          <p className="text-[#D31D1D] text-sm ml-1">
                            {pinError}
                          </p>
                        </div>
                      )}
                    </div>

                    <div className="mt-6">
                      <label
                        htmlFor="password"
                        className="block text-sm font-medium text-black-soft mb-2"
                      >
                        Confirm PIN
                      </label>
                      <div className="flex mt-2">
                        <input
                          type={showConfirmPin ? "text" : "password"}
                          id="confirmPin"
                          name="confirmPin"
                          value={confirmPin}
                          maxLength={4}
                          onChange={handleConfirmPinChange}
                          placeholder="Confirm PIN"
                          required
                          className="password-control"
                          aria-label="4-digit PIN"
                          pattern="[0-9]*" // Only accept numeric values
                          inputMode="numeric"
                        />
                        <button
                          type="button"
                          onClick={toggleShowConfirmPin}
                          className="password-button"
                        >
                          {showConfirmPin ? <p>Hide</p> : <p>Show</p>}
                        </button>
                      </div>

                      {/* banner */}
                      {confirmPinError !== "" && (
                        <div
                          className="w-full px-5 flex my-[2.81rem] text-[#800205] font-[500]
                          rounded-lg
                          justify-center text-sm items-center h-[3.75rem] bg-[#FFDCE0] "
                        >
                          <img src={errorImg} className="" alt="error" />
                          <span className="text-[#800205] pl-2  font-normal">
                            {confirmPinError}
                          </span>
                        </div>
                      )}
                    </div>

                    <div className="mt-7">
                      <button
                        type="submit"
                        disabled={!formValid || loading}
                        className={
                          !loading && formValid
                            ? "login-active"
                            : " login-disabled "
                        }
                      >
                        {loading ? "Loading ..." : "Submit"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ToastContainer />
      </div>
    </>
  );
};

export default CreatePin;
