import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import Spinner from "../../PopUps/smallSpinner";
import { changeBalanceDisplay } from "../../../features/User/UserSlice";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/solid";
import CurrencySelect from "../../../pages/Accounts/components/CurrencySelect";
import BalanceModal from "../../../pages/Accounts/components/BalanceModal";

export const getLastFourDigits = (number: string) => number.slice(-4);

export const getMaskedAccountNumber = (number: string | undefined) =>
  number ? "**** " + getLastFourDigits(number) : "";
const ClevaAccount = () => {
  const allAccount = useAppSelector((state) => state.virtualAccount.allAccount);
  const user = useAppSelector((state) => state.user.userData);

  const accountNumber = allAccount ? (allAccount as any).AccountNumber : " ";
  const showAmount = useAppSelector((state) => state.user.displayBalance);
  const USAmount = allAccount ? (allAccount as any).Balance?.Money : " ";
  const NGNAmount = "0.00";

  const BalanceStatus = user?.HideUsdBalance;
  const [showMenu, setShowMenu] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(changeBalanceDisplay(!BalanceStatus));
  }, [BalanceStatus, dispatch]);

  const toggleAmount = () => {
    dispatch(changeBalanceDisplay(!showAmount));
  };

  const handleEyeIconClick = async () => {
    // Immediate UI update to show or hide the balance

    if (BalanceStatus) {
      toggleAmount();
      // showBalance();
    } else if (!showAmount) {
      toggleAmount();
    } else {
      setShowMenu(true);
    }
  };
  const applyModalChanges = (toggleSwitch: boolean) => {
    if (toggleSwitch) {
      dispatch(changeBalanceDisplay(false));
      setShowMenu(false);
    } else {
      dispatch(changeBalanceDisplay(false));
      setShowMenu(true);
    }
    setShowMenu(false);
  };

  return (
    <>
      {accountNumber ? (
        <div className="">
          <CurrencySelect USAmount={USAmount} NGNAmount={NGNAmount} />
          <div className="flex justify-center items-center">
            <p className="md:text-5xl text-4xl text-center mt-2">
              {showAmount ? (
                <>
                  {Number(USAmount) < 0
                    ? `-$${Math.abs(Number(USAmount)).toLocaleString(
                        undefined,
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      )}`
                    : `$${Number(USAmount).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}`}
                </>
              ) : (
                "****"
              )}
            </p>

            <button type="button" onClick={handleEyeIconClick} className="ml-3">
              {showAmount ? (
                <EyeSlashIcon className="h-5 w-5 text-white font-light" />
              ) : (
                <EyeIcon className="h-5 w-5 text-white font-light" />
              )}
            </button>
          </div>
        </div>
      ) : (
        <Spinner />
      )}

      <BalanceModal
        showMenu={showMenu}
        setShowMenu={setShowMenu}
        balanceChange={applyModalChanges}
      />
    </>
  );
};

export default ClevaAccount;
