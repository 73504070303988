import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { IoCloseOutline } from "react-icons/io5";
import { AiOutlineRight } from "react-icons/ai";
import feeIcon from "../../../asset/dashboard/feeIcon.svg";
import accountIcon from "../../../asset/dashboard/accountIcon.svg";
import proofIcon from "../../../asset/dashboard/proofIcon.svg";
import tipIcon from "../../../asset/dashboard/tips.svg";
import ModalTemplate from "../../../components/PopUps/ModalTemplate";

type ModalProps = {
  showMenu: boolean;
  setShowMenu: React.Dispatch<React.SetStateAction<boolean>>;
};

const MoreAccountModal: React.FC<ModalProps> = ({ showMenu, setShowMenu }) => {
  const navigate = useNavigate();

  return (
    <ModalTemplate
      childStyle="md:w-[450px]"
      modalState={showMenu}
      setModalState={setShowMenu}
    >
      <div>
        <header className="bg-[#EBF0F0] px-8 py-4 rounded-lg flex justify-between items-center">
          <p className="text-lg font-semibold">More</p>
          <IoCloseOutline
            onClick={() => setShowMenu(false)}
            className="cursor-pointer text-[#2C2C2C] w-5 h-5"
          />
        </header>
        <div className="">
          <div className="pb-4 overflow-y-auto">
            <ul className="leading-[3em]">
              <li
                onClick={() => navigate("/accounts/statement")}
                className="cursor-pointer hover:bg-slate-100 border-y flex items-center justify-between py-2 px-6"
              >
                <div className="flex items-center gap-2">
                  <img src={accountIcon} className="h-8 w-8" alt="" />
                  <p className="text-[#111111]">Account statement</p>
                </div>
                <p className="text-[#D5D5D5] h-7 w-7 pt-[4px]">
                  <AiOutlineRight />
                </p>
              </li>
              <li
                onClick={() => navigate("/accounts/proof")}
                className="cursor-pointer hover:bg-slate-100 border-y flex items-center justify-between py-2 px-6"
              >
                <div className="flex items-center gap-2">
                  <img src={proofIcon} className="h-8 w-8" alt="" />
                  <p className="text-[#111111]">Proof of account</p>
                </div>
                <p className="text-[#D5D5D5] h-7 w-7 pt-[4px]">
                  <AiOutlineRight />
                </p>
              </li>
              <li>
                <Link
                  target="_blank"
                  to="https://www.getcleva.com/pricing"
                  className="cursor-pointer hover:bg-slate-100 flex items-center justify-between py-2 px-6"
                >
                  <div className="flex items-center gap-2">
                    <img src={feeIcon} className="h-8 w-8" alt="" />
                    <p className="text-[#111111]">Fees</p>
                  </div>
                  <p className="text-[#D5D5D5] h-7 w-7 pt-[4px]">
                    <AiOutlineRight />
                  </p>
                </Link>
              </li>
              <li
                onClick={() => navigate("/profile/tips")}
                className="cursor-pointer hover:bg-slate-100 border-t flex items-center justify-between py-2 px-6"
              >
                <div className="flex items-center gap-2">
                  <img src={tipIcon} className="h-8 w-8" alt="" />
                  <p className="text-[#111111]">Tips for your account</p>
                </div>
                <p className="text-[#D5D5D5] h-7 w-7 pt-[4px]">
                  <AiOutlineRight />
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </ModalTemplate>
  );
};

export default MoreAccountModal;
