import React from "react";
import { AWS_CREDS } from "../../features/services/AmazonService";
import AccountDetails from "./components/AccountDetails";
import BackBtn from "../../asset/referral/backBtn.svg";
import { Link, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";

const MobileAccountDetails = () => {
  const navigate = useNavigate();
  const allAccount = useAppSelector((state) => state.virtualAccount.allAccount);
  const user = useAppSelector((state) => state.user.userData);

  const minimumDeposit = AWS_CREDS.MINIMUM_DEPOSIT;
  const dailyLimit = allAccount ? (allAccount as any).Limit?.Total : " ";

  const backToAccount = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    navigate("/");
  };

  return (
    <>
      <section className=" py-8">
        <div
          className="flex gap-2 items-center cursor-pointer pb-1 font-medium text-[#0D0D0D]"
          onClick={backToAccount}
        >
          <img src={BackBtn} alt="account details" className="mr-2" />
          <button>Account Details</button>
        </div>
        <div className="text-left">
          <div className="md:pt-2 pt-2">
            <div className="px-4 py-2 bg-[#E8F4FF] rounded mt-4">
              <p className="text-[#303030] text-base font-bold">
                The minimum deposit you can receive is{" "}
                <span className="text-[#278033]">${minimumDeposit}</span>
              </p>
              <p className="text-sm pt-2 text-[#555656]">
                Micro deposits (amounts less than $1) used for account
                confirmation can still be received.
              </p>
            </div>

            <div className="px-4 py-2 bg-[#E8F4FF] rounded mt-4">
              <p className="text-[#303030] text-base font-bold">
                The maximum daily deposit is{" "}
                <span className="text-[#278033]">
                  ${dailyLimit.toLocaleString("en-US")}
                </span>
              </p>
              <p className="text-sm pt-2 text-[#555656]">
                Amounts above this require additional verification before they
                can be processed.
              </p>
            </div>

            {user?.DepositPolicyText ? (
              <div>
                <p className="text-sm text-[#111111] p-[0.5rem] my-[0.5rem] rounded-[0.25rem] bg-[#FFE0A4] border border-[#EAD4B2] font-semibold">
                  {user?.DepositPolicyText}
                </p>
              </div>
            ) : (
              ""
            )}
            <Link
              to="/profile/transaction-limits"
              className="text-sm block
            font-medium py-2 md:px-10 px-6
            w-full
            text-[#065792]
            rounded-md mt-4 text-center
            border border-[#065792]"
            >
              See all limits
            </Link>
            <p className="text-[#6F6F6F] md:text-base text-sm mt-6">
              Use these bank details to receive USD via ACH or domestic wire
              from any U.S. bank. Any money transferred to this account will
              appear in your account balance.
            </p>
            <AccountDetails />
          </div>
        </div>
      </section>
    </>
  );
};

export default MobileAccountDetails;
