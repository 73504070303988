import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import rateIcon from "../../asset/dashboard/rate.svg";
const RateCard = () => {
  const { rates, loading } = useSelector(
    (state: RootState) => state.transaction
  );
  return (
    <>
      {loading ? (
        <p>Loading rate...</p>
      ) : rates ? (
        <>
          {/* <div className="bg-cover bg-center relative h-full w-full shadow-lg rounded-md">
      <img
        className="absolute inset-0 w-full h-full object-cover shadow-lg rounded-md"
        src={bgImg}
        alt="Background"
      />
       
      <div className="relative z-10 px-6 md:py-8 py-4">
        <p className="text-sm text-[#464646] mb-2">Exchange rate</p>
        <p className="text-xl font-semibold mb-4 text-[#158025]"> $1 = {rates? (rates as any).ToCurrencyRate : " "} NGN</p>
      </div>
      
    </div> */}

          <div className=" bg-white shadow-sm rounded-xl px-4 py-3 text-center">
            <div className="flex items-center justify-center">
              <div className="mr-2">
                <div className="flex items-center">
                  <div className="mr-1">
                    <img src={rateIcon} alt="rate icon" />
                  </div>
                  <p className="text-[#111111] text-lg">Rate:</p>
                </div>
              </div>
              <div>
                <p className="text-lg  text-[#111111]">
                  {" "}
                  $1 = {rates ? (rates as any).ToCurrencyRate : " "} NGN
                </p>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default RateCard;
