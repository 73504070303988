import { BsPersonPlusFill, BsPeople } from "react-icons/bs";
interface IRecipients {
  addRecipient: any;
}

export default function NoRecipients(props: IRecipients) {
  return (
    <div className="flex justify-center items-center h-[40vh] text-center">
      <section className="bg-white rounded-lg mt-20 relative p-20 border border-[#E8E8E8]">
        <span className="text-[40px] justify-center items-center flex pb-4">
          <span>
            <BsPeople />
          </span>
        </span>
        <p>You currently have no recipients</p>

        <button className="btn flex bg-cleva-gold items-center text-sm border-[0.5px] border-[#8A8A8A] py-2 px-4 rounded-lg  gap-1 m-auto mt-5">
          <span>
            <BsPersonPlusFill />
          </span>
          <span onClick={() => props.addRecipient()}>Add new recipient</span>
        </button>
      </section>
    </div>
  );
}
