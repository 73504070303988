import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface IRecipient {
  RecipientIdentifier: string;
  Date: number;
  FullName: FullName;
  Country: string;
  LastFourDigits: string;
  AccountNumber: string;
  BankName: string;
  NickName: string;
}

export interface FullName {
  FirstName: string;
  LastName: string;
  MiddleName: string;
}

interface RecipientState {
  recipients: IRecipient[] | null;
  loading: boolean;
  error: string | null;
}

const initialState: RecipientState = {
  recipients: null,
  loading: false,
  error: null,
};

const recipientSlice = createSlice({
  name: "recipients",
  initialState,
  reducers: {
    setRecipients: (state, action: PayloadAction<RecipientState>) => {
      state.recipients = action.payload.recipients;
      state.loading = action.payload.loading;
      state.error = action.payload.error;
      //console.log(action.payload)
    },
    // setLoading: (state, action: PayloadAction<Boolean>) => {
    //   state.loading = action.payload
    // },
    // setError: (state, action: PayloadAction<RecipientState['error']>) => {
    //   state.error = action.payload
    // },
  },
});

export const { setRecipients } = recipientSlice.actions;

export default recipientSlice.reducer;
