import React, { useEffect, useState } from "react";
import Modal from "../../../../components/PopUps/Modal";
import Input from "../../../../components/Layout/inputs/Input";
import Select from "../../../../components/Layout/inputs/Select";
import Button from "../../../../components/Layout/buttons/Button";
import axios from "../../../../features/services/AxiosInstance";
import { setModalState } from "../../../../features/Kyc/kycSlice";
import { useAppDispatch } from "../../../../app/hooks";
import Bank from "../../../../components/Banks/Banks";
import { AWS_CREDS } from "../../../../features/services/AmazonService";
import ConfirmRecipient from "./ConfirmRecipient";

export default function AddRecipient({ closeModal }: any) {
  const [verifiedRecipient, setVerifiedRecipient] = useState("");
  const [validate, setValidate] = useState(false);
  const [validateFailed, setValidateFailed] = useState(false);
  const [modal, setModal] = useState(false);

  const [values, setValues] = useState({
    Country: "",
    BankName: "",
    AccountNumber: "",
  });
  const dispatch = useAppDispatch();

  const country = [
    {
      id: 1,
      value: "Select Country",
      label: "Select Country",
    },
    {
      id: 2,
      value: "Nigeria",
      label: "Nigeria",
    },
  ];

  // const verify = {
  //   BankName: values.BankName,
  //   AccountNumber: values.AccountNumber,
  // }

  const verifyRecipient = (account: any) => {
    if (!values.BankName) {
      setVerifiedRecipient("Please select bank name");
    } else {
      setValidate(false);
      setVerifiedRecipient("Resolving account number...");

      axios
        .post(`${AWS_CREDS.APIGATEWAY_URL}/accountsverifications`, {
          BankName: values.BankName,
          AccountNumber: account,
        })
        .then((response) => {
          setVerifiedRecipient(response.data.BankName);
          setValidateFailed(false);
          setValidate(true);
          localStorage.setItem("verifiedRecipient", response.data.BankName);
        })
        .catch((error) => {
          localStorage.removeItem("verifiedRecipient");
          setVerifiedRecipient("Failed to resolve account number");
          setValidateFailed(true);
          setValidate(false);
          if (AWS_CREDS.STAGE === "qa") {
            console.log(error);
          }
        });
    }
  };

  function handleSubmit() {
    localStorage.setItem("recipients", JSON.stringify(values));
    // navigate('/confirm_recipient')
    setModal(true);

    // dispatch(setModalState(false))
  }

  function handleCancel() {
    localStorage.removeItem("recipients");
    // navigate('/recipients')
    closeModal();
    dispatch(setModalState(false));
  }

  function handleChange(e: any) {
    setValues({ ...values, AccountNumber: e.target.value.slice(0, 10) });
    if (e.target.value.length === 10) {
      verifyRecipient(e.target.value);
    }
  }

  useEffect(() => {
    const isAnyValueEmpty = Object.values(values).some((value) => value === "");
    if (verifiedRecipient.length <= 2) {
      setValidateFailed(false);
      setValidate(false);
    }
    if (values.AccountNumber.length != 10 || isAnyValueEmpty) {
      setValidateFailed(false);
      setValidate(false);
    }
  }, [values, verifiedRecipient]);

  // const closeModal = () => {
  //   setModal(false);
  // };
  return (
    <>
      <Modal
        titlePosition="text-center"
        header="Add new recipient"
        closeModal={handleCancel}
      >
        <div className="px-10">
          <div className=" grid grid-cols-3 gap-4">
            <div className="col-span-1">
              <Select
                title="Country"
                fn={(e: any) =>
                  setValues({ ...values, Country: e.target.value })
                }
                err=""
                arr={country}
                xtstyles=""
              />
            </div>

            <div className="col-span-2">
              <Select
                title="Bank"
                fn={(e: any) =>
                  setValues({ ...values, BankName: e.target.value })
                }
                err=""
                arr={Bank}
                xtstyles=""
              />
            </div>
          </div>

          <Input
            title="Account Number"
            text="Enter account number"
            fn={handleChange}
            type="text"
            err=""
            length={10}
            value={values.AccountNumber}
          />
          {validateFailed ? (
            <span className="mt-6 flex justify-start text-red-500 text-sm">
              {" "}
              Failed to get recipient
            </span>
          ) : (
            <span
              className={`flex justify-start text-sm pt-2 ${values.AccountNumber.length >= 10 ? "block" : "hidden"}`}
            >
              {verifiedRecipient}
            </span>
          )}
        </div>
        <div className="px-10 flex justify-between pt-4">
          <Button
            status={false}
            fn={handleCancel}
            styles="text-[12px]
          font-bold py-[10px] px-[8%]
          ${btn_bg}
          float-right
          rounded-md mt-4
          bg-[#FFF5D9]"
            text="Cancel"
          />

          <Button
            status={validate ? false : true}
            fn={handleSubmit}
            styles={`text-[12px] 
            font-bold py-[10px] px-[8%] 
            float-right 
            rounded-md mt-4 
            ${validate ? "bg-[#FFBD59]" : "bg-[#FFF5D9]"}`}
            text="Save"
          />
        </div>
      </Modal>

      {modal && <ConfirmRecipient />}
    </>
  );
}
