import React, { useEffect, useState } from "react";
import CurrencyInput from "../../../components/Layout/CurrencyInput";
import Input from "../../../components/Layout/inputs/Input";
import TransferFlag from "../../../components/TransferFlag";
import ViewModal from "./modals/ViewModal";
import USIcon from "../../../images/USD.svg";
import NGIcon from "../../../images/ngn.svg";
import FeeIcon from "../../../images/fee-icon.svg";
import { useSelector, useDispatch } from "react-redux";
import { useAppSelector } from "../../../app/hooks";
import errIcon from "../../../images/error-icon.svg";
import Spinner from "../../../components/PopUps/Spinner";
import axios from "../../../features/services/AxiosInstance";
import { toast, ToastContainer } from "react-toastify";
import {
  fetchRecipients,
  fetchRates,
  createTransaction,
  setPayoutDailyLimit,
} from "../../../features/Transanctions/transactionApi";
import { fetchAccount } from "../../../features/VirtualAccount/VirtualAccountApi";
import { setModalState } from "../../../features/Kyc/kycSlice";
import {
  setSenderFirstName,
  setSenderLastName,
  setRecipientFirstName,
  setRecipientLastName,
  setSendAmount,
  setFee,
  setTotalAmount,
  setReceiveAmount,
  setDescription,
  setLoading,
  setBankName,
  setRecipientIdentifier,
  setExchangeRate,
  setAccountNumber,
  setAccountIdentifier,
} from "../../../features/Transanctions/TransanctionSlice";
import { RootState, AppDispatch } from "../../../app/store";
import { Link, useNavigate } from "react-router-dom";
import AddRecipient from "./modals/AddRecipients";
import ValidateUserPin from "../../Security/modals/ValidateUserPin";
import ForgotPin from "../../Security/modals/ForgotPin";
import NewPin from "../../Security/modals/NewPin";
import UpdatedPin from "../../Security/modals/UpdatedPin";
import VerifyUserOTP from "../../Security/modals/ExceededPin";
import { ResetPin } from "../../../api";
import { getReturningUser, getUserIdWithAccessToken } from "../../../login";
import PinLoader from "../../Security/modals/PinLoader";
import { FaExchangeAlt, FaMoneyCheckAlt } from "react-icons/fa";
import Announcement from "../../../components/Announcement/Announcement";
import {
  setDailyLimit,
  setMonthlyLimit,
  setTnxLimit,
  setUser,
  setWeeklyLimit,
} from "../../../features/User/UserSlice";
import { getCurrentDate } from "../../../utils/getCurrentDate";
import { getTiers } from "../../../features/User/UserApi";
import { setTitle } from "../../../features/Nav/NavSlice";
import { useIsBusinessUser } from "../../../utils";

// const delayedDescription: string =
//   "Our banking provider is experiencing a downtime that is impacting transfers and causing them to be delayed. We are actively working with them to fix it. Please kindly postpone any transfers and try again in about 6 hours. Thanks a lot for your patience.";

const CreateLocalTransfer = () => {
  const allAccount = useAppSelector((state) => state.virtualAccount.allAccount);

  const accountNumber = allAccount ? (allAccount as any).AccountNumber : " ";
  const accountIdentifier = allAccount
    ? (allAccount as any).VirtualAccountIdentifier
    : " ";

  const getLastFourDigits = (number: string) => number.slice(-4);
  const getMaskedAccountNumber = (number: string | undefined) =>
    number ? " **** " + getLastFourDigits(number) : "";
  const accBalance = allAccount ? (allAccount as any).Balance?.Money : " ";

  let user = useSelector((state: RootState) => state.user.userData);
  const tnxLimit = useAppSelector((state: RootState) => state.user.tnxLimit);
  const dailyLimit = useAppSelector(
    (state: RootState) => state.user.dailyLimit
  );
  const weeklyLimit = useAppSelector(
    (state: RootState) => state.user.weeklyLimit
  );
  const monthlyLimit = useAppSelector(
    (state: RootState) => state.user.monthlyLimit
  );
  let userTiers = useAppSelector((state: RootState) => state.user.userTier);
  const [seeLimit, setSeeLimit] = useState(false);
  const [modal, setModal] = useState(false);
  const [openTransactionPin, setTransactionPin] = useState(false);
  const [openForgotPin, setForgotPin] = useState(false);
  const [openUpdatedPin, setUpdatedPin] = useState(false);
  const [openNewPin, setNewPin] = useState(false);
  const [openOTP, setOTP] = useState(false);
  const navigate = useNavigate();
  const isBusinessUser = useIsBusinessUser();
  const { allRecipients, rates } = useSelector(
    (state: RootState) => state.transaction
  );

  let reversedRecipients = allRecipients.slice().reverse();

  const SenderFirstName = user?.FullName.FirstName || "";
  const SenderLastName = user?.FullName.LastName || "";

  const transactionData = useSelector((state: RootState) => state.transaction);

  const receiveAmount = useSelector(
    (state: RootState) => state.transaction.receiveAmount
  );

  const sendAmount = useSelector(
    (state: RootState) => state.transaction.sendAmount
  );

  const fee = useSelector((state: RootState) => state.transaction.fee);
  const loading = useSelector((state: RootState) => state.transaction.loading);

  const description = useSelector(
    (state: RootState) => state.transaction.description
  );
  const RecipientFirstName = useSelector(
    (state: RootState) => state.transaction.RecipientFirstName
  );
  const RecipientLastName = useSelector(
    (state: RootState) => state.transaction.RecipientLastName
  );

  const [recipientName, setRecipientName] = useState(
    `${RecipientFirstName} ${RecipientLastName}`
  );

  const exchangeRate = useSelector(
    (state: RootState) => state.transaction.exchangeRate
  );

  const [amtError, setAmtError] = useState("");
  const [verifying, setVerifying] = useState(false);
  const [recipientError, setRecipientError] = useState("");
  const [receiveAmountError, setReceiveAmountError] = useState("");
  // const tooltipContent =
  //   "Limit for money out of your USD account <br> (e.g. transfer and USD conversion).";

  const [BtnError, setBtnError] = useState(false);
  const { modalState } = useAppSelector((state) => state.kycInfo);

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (loading) {
      dispatch(setSendAmount(0));
    }
    if (rates) {
      const priceString = (rates as any)?.Fee;
      if (priceString) {
        const price = parseFloat(priceString.replace("$", ""));
        dispatch(setFee(price));
        dispatch(setExchangeRate((rates as any).ToCurrencyRate));
        setLoading(false);
      }
    }
  }, [rates, dispatch]);

  useEffect(() => {
    getReturningUser().then((user: any) => {
      if (
        !user?.PayoutLimit ||
        user.PayoutLimit.daily.currentDay !== getCurrentDate()
      ) {
        return setPayoutDailyLimit(user).then((user) =>
          dispatch(setUser(user))
        );
      }
      dispatch(setUser(user));
    });
  }, []);

  // useEffect(() => {
  //   const payoutAmountUsed = userTiers?.Limits?.USD?.daily?.dailyVolume ?? 0;
  //   const dailyPayoutLimit =
  //     userTiers?.Limits?.USD?.daily?.dailyLimitValue ?? 0;
  //   dispatch(setDailyLimit(Number((dailyPayoutLimit - payoutAmountUsed).toFixed(2))));

  //   const weeklyAmountUsed = userTiers?.Limits?.USD?.weekly?.weeklyVolume ?? 0;
  //   const weeklyPayoutLimit =
  //     userTiers?.Limits?.USD?.weekly?.weeklyLimitValue ?? 0;
  //   dispatch(setWeeklyLimit(Number((weeklyPayoutLimit - weeklyAmountUsed).toFixed(2))));

  //   const monthlyAmountUsed =
  //     userTiers?.Limits?.USD?.monthly?.monthlyVolume ?? 0;
  //   const monthlyPayoutLimit =
  //     userTiers?.Limits?.USD?.monthly?.monthlyLimitValue ?? 0;
  //   dispatch(setMonthlyLimit(
  //     Number((monthlyPayoutLimit - monthlyAmountUsed).toFixed(2))
  //   ));

  //  dispatch(setTnxLimit(userTiers?.Limits?.USD?.perTransactionLimitValue));

  //   if (isBusinessUser) {
  //     const businessIdentifier = user?.BusinessIdentifier;
  //     if (businessIdentifier) {
  //       dispatch(getTiers(businessIdentifier));
  //     }
  //   } else {
  //     const clevaUserID = user?.ClevaUserID;
  //     if (clevaUserID) {
  //       dispatch(getTiers(clevaUserID));
  //     }
  //   }
  // }, [dispatch ]);

  useEffect(() => {
    const calculateRemainingLimit = (limit: number, used: number) =>
      Number((limit - used).toFixed(2));

    const dailyPayoutLimit = userTiers?.Limits?.USD?.daily ?? {};
    const weeklyPayoutLimit = userTiers?.Limits?.USD?.weekly ?? {};
    const monthlyPayoutLimit = userTiers?.Limits?.USD?.monthly ?? {};

    dispatch(
      setDailyLimit(
        calculateRemainingLimit(
          dailyPayoutLimit.dailyLimitValue ?? 0,
          dailyPayoutLimit.dailyVolume ?? 0
        )
      )
    );
    dispatch(
      setWeeklyLimit(
        calculateRemainingLimit(
          weeklyPayoutLimit.weeklyLimitValue ?? 0,
          weeklyPayoutLimit.weeklyVolume ?? 0
        )
      )
    );
    dispatch(
      setMonthlyLimit(
        calculateRemainingLimit(
          monthlyPayoutLimit.monthlyLimitValue ?? 0,
          monthlyPayoutLimit.monthlyVolume ?? 0
        )
      )
    );

    dispatch(
      setTnxLimit(userTiers?.Limits?.USD?.perTransactionLimitValue ?? 0)
    );

    const identifier = isBusinessUser
      ? user?.BusinessIdentifier
      : user?.ClevaUserID;
    if (identifier) {
      dispatch(getTiers(identifier));
    }
  }, [dispatch, isBusinessUser, user]);

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectValue: string = e.target.value;
    setRecipientName(selectValue);

    if (!selectValue) {
      setRecipientError("Please select a recipient");
      setBtnError(true);
    } else {
      setRecipientError("");
      setBtnError(false);
    }

    const selectedRecipient = allRecipients.find(
      (recipient: any) => recipient.RecipientIdentifier === selectValue
    );

    if (selectedRecipient) {
      dispatch(setBankName((selectedRecipient as any).BankName));
      dispatch(
        setRecipientIdentifier((selectedRecipient as any).RecipientIdentifier)
      );
      dispatch(setAccountNumber((selectedRecipient as any).AccountNumber));
      dispatch(setSenderFirstName(SenderFirstName));
      dispatch(setSenderLastName(SenderLastName));
      dispatch(setAccountIdentifier(accountIdentifier));
    }

    // const [selectedFirstName, selectedLastName] = selectValue.split(" ");
    dispatch(
      setRecipientFirstName((selectedRecipient as any).FullName.FirstName)
    );
    dispatch(
      setRecipientLastName((selectedRecipient as any).FullName.LastName)
    );
  };
  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Number((userDailyPayoutLimit-userPayoutAmountUsed).toFixed(2));
    const value = e.target.value.replace(/,/g, ""); // Remove existing commas

    const amountFloat = parseFloat(value);
    dispatch(setSendAmount(value));

    const parsedValue = parseFloat(value);
    const convertedValue = isNaN(parsedValue) ? 0 : parsedValue * exchangeRate;
    const myRate = parseFloat(convertedValue.toFixed(2));

    dispatch(setTotalAmount());
    dispatch(setReceiveAmount(myRate));

    let inputAmount = Number(value) + Number(fee);

    if (isNaN(inputAmount) || !Number.isFinite(inputAmount)) {
      setAmtError("Please enter a valid amount");
      setBtnError(true);
      return;
    }
    if (myRate < 100) {
      setReceiveAmountError("Minimum payout amount should be 100 NGN");
      setBtnError(true);
    } else {
      setReceiveAmountError("");
      setBtnError(false);
    }

    // if (inputAmount > dailyRemainingPayoutBal) {
    //   setAmtError("The amount you entered is above your daily limit.");
    //   setSeeLimit(true)
    //   setBtnError(true);
    //   return;
    // } else {
    //   setAmtError("");
    //   setBtnError(false);
    //   setSeeLimit(false)
    // }

    if (accBalance < inputAmount) {
      setAmtError("Insufficient account balance");
      setBtnError(true);
      return;
    } else {
      setAmtError("");
      setBtnError(false);
    }
    if (!value) {
      setAmtError("Please enter amount");
    }

    if (amountFloat <= 0) {
      setAmtError("Please enter a valid amount");
      setBtnError(true);
      return;
    }

    if (!accBalance) {
      setAmtError("Please create an account");
      return;
    }

    const decimalRegex = /^\d+(\.\d{0,2})?$/;
    if (!decimalRegex.test(value)) {
      setAmtError("Please enter a valid amount of maximum two decimal places");
      setBtnError(true);
      return;
    } else {
      setAmtError("");
      setBtnError(false);
    }
    if (inputAmount > tnxLimit) {
      setAmtError("This amount is above your transaction limit.");
      setSeeLimit(true);
      setBtnError(true);
      return;
    } else {
      setAmtError("");
      setBtnError(false);
      setSeeLimit(false);
    }

    if (inputAmount > dailyLimit) {
      setAmtError("You've reached your daily transfer limit.");
      setSeeLimit(true);
      setBtnError(true);
      return;
    } else {
      setAmtError("");
      setBtnError(false);
      setSeeLimit(false);
    }

    if (inputAmount > weeklyLimit) {
      setAmtError("You've reached your weekly transfer limit.");
      setSeeLimit(true);
      setBtnError(true);
      return;
    } else {
      setAmtError("");
      setBtnError(false);
      setSeeLimit(false);
    }

    if (inputAmount > monthlyLimit) {
      setAmtError("You've reached your monthly transfer limit.");
      setSeeLimit(true);
      setBtnError(true);
      return;
    } else {
      setAmtError("");
      setBtnError(false);
      setSeeLimit(false);
    }

    setAmtError("");
  };

  const handleNairaChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/[^\d.]/g, ""); // Remove non-numeric characters except decimal point
    const parts = value.split("."); // Split integer and decimal parts

    // Format integer part with commas
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    const amountFloat = parseFloat(value);

    // Join parts back together, allowing only one decimal point
    const formattedValue =
      parts.length > 1 ? `${parts[0]}.${parts[1]}` : parts[0];

    dispatch(setReceiveAmount(formattedValue));

    //function to get decimal number without rounding up or down
    const toFixedNoRound = (num: number, fixed: number) => {
      const factor = Math.pow(10, fixed);
      return Math.floor(num * factor) / factor;
    };
    let calc = amountFloat / exchangeRate;
    let myNairaRate;

    if (amountFloat % exchangeRate === 0) {
      myNairaRate = parseFloat(calc.toFixed(2)) || 0;
    } else {
      myNairaRate = (toFixedNoRound(calc, 2) || 0) + 0.01;
    }
    // const convertedUSDValue = isNaN(amountFloat)
    //   ? 0
    //   : amountFloat * exchangeRate;

    // // const myUSDRate = parseFloat(convertedUSDValue.toFixed(2));

    dispatch(setTotalAmount());
    dispatch(setSendAmount(myNairaRate));

    if (isNaN(amountFloat) || !Number.isFinite(amountFloat)) {
      setReceiveAmountError("Please enter a valid amount");
      setBtnError(true);
      return;
    }
    if (amountFloat < 100) {
      setReceiveAmountError("Minimum payout amount should be 100 NGN");
      setBtnError(true);
    } else {
      setReceiveAmountError("");
      setBtnError(false);
    }

    // if (myNairaRate > dailyRemainingPayoutBal) {
    //   setReceiveAmountError("The amount you entered is above your daily limit");
    //   setAmtError("The amount you entered is above your daily limit");
    //   setSeeLimit(true)
    //   setBtnError(true);
    //   return;
    // } else {
    //   setAmtError("");
    //   setBtnError(false);
    //   setSeeLimit(false)
    // }

    if (accBalance < myNairaRate) {
      setAmtError("Insufficient account balance");
      setBtnError(true);
      return;
    } else {
      setAmtError("");
      setBtnError(false);
    }
    if (!value) {
      setAmtError("Please enter amount");
    }

    if (amountFloat <= 0) {
      setAmtError("Please enter a valid amount");
      setBtnError(true);
      return;
    }

    if (!accBalance) {
      setAmtError("Please create an account");
      return;
    }

    const decimalRegex = /^\d+(\.\d{0,2})?$/;
    if (!decimalRegex.test(value)) {
      setReceiveAmountError(
        "Please enter a valid amount of maximum two decimal places"
      );
      setBtnError(true);
      return;
    } else {
      setAmtError("");
      setBtnError(false);
    }

    if (myNairaRate > tnxLimit) {
      setReceiveAmountError("This amount is above your transaction limit.");
      setAmtError("This amount is above your transaction limit.");
      setSeeLimit(true);
      setBtnError(true);
      return;
    } else {
      setAmtError("");
      setBtnError(false);
      setSeeLimit(false);
    }

    if (myNairaRate > dailyLimit) {
      setReceiveAmountError("You've reached your daily transfer limit.");
      setAmtError("You've reached your daily transfer limit.");
      setSeeLimit(true);
      setBtnError(true);
      return;
    } else {
      setAmtError("");
      setBtnError(false);
      setSeeLimit(false);
    }

    if (myNairaRate > weeklyLimit) {
      setReceiveAmountError("You've reached your weekly transfer limit.");
      setAmtError("You've reached your weekly transfer limit.");
      setSeeLimit(true);
      setBtnError(true);
      return;
    } else {
      setAmtError("");
      setBtnError(false);
      setSeeLimit(false);
    }

    if (myNairaRate > monthlyLimit) {
      setReceiveAmountError("You've reached your monthly transfer limit.");
      setAmtError("You've reached your monthly transfer limit.");
      setSeeLimit(true);
      setBtnError(true);
      return;
    } else {
      setAmtError("");
      setBtnError(false);
      setSeeLimit(false);
    }

    setAmtError("");
  };

  const handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/,/g, "");
    const parsedValue = parseFloat(value);
    const newValue = isNaN(parsedValue) ? 0 : parsedValue;
    dispatch(setSendAmount(newValue));
  };

  const handleNairaBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/,/g, "");
    const parsedValue = parseFloat(value);
    const newValue = isNaN(parsedValue) ? 0 : parsedValue;
    dispatch(setReceiveAmount(newValue));
  };

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setDescription(e.target.value));
  };

  function toggleModal() {
    const amountFloat = parseFloat(sendAmount);

    if (!sendAmount) {
      setAmtError("Please enter amount");
    }

    if (amountFloat <= 0) {
      setAmtError("Please enter a valid amount");
    }

    if (!recipientName || recipientName.trim() === "") {
      setRecipientError("Please select a recipient");
      setBtnError(true);
    } else {
      setRecipientError("");
    }

    if (!accBalance) {
      setAmtError("Please create an account");
    }
    // Check if there are any errors
    if (
      recipientError ||
      amtError ||
      !recipientName ||
      recipientName.trim() === "" ||
      !sendAmount ||
      !accBalance
    ) {
      return;
    }
    setModal(true);
    setTransactionPin(false);
  }

  const closeModal = () => {
    setModal(false);
    setModalState(false);
  };

  const VerifyTransaction = async (): Promise<void> => {
    try {
      setLoading(true);
      setVerifying(true);

      const response = await dispatch(createTransaction(transactionData));

      setLoading(false);
      setVerifying(false);

      if (response?.payload) {
        navigate("/transfers/view");
      } else {
        toast.error(
          "We could not process your transfer at this time. Please try again later"
        );
      }
    } catch (error: any) {
      setLoading(false);
      setVerifying(false);
      toast.error(error.message);
      delete axios.defaults.headers.common["Otptoken"];
    }
  };

  const sendOTP = async () => {
    const userID = await getUserIdWithAccessToken();
    try {
      await ResetPin(userID);
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    dispatch(fetchRecipients());
    dispatch(fetchRates());
    dispatch(fetchAccount());
    getReturningUser();
  }, [dispatch]);

  const navigateMobile = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    const amountFloat = parseFloat(sendAmount);

    if (!sendAmount) {
      setAmtError("Please enter amount");
    }

    if (amountFloat <= 0) {
      setAmtError("Please enter a valid amount");
    }
    if (!recipientName || recipientName.trim() === "") {
      setRecipientError("Please select a recipient");
      setBtnError(true);
    } else {
      setRecipientError("");
    }

    if (!accBalance) {
      setAmtError("Please create an account");
    }
    // Check if there are any errors
    if (
      recipientError ||
      amtError ||
      !recipientName ||
      recipientName.trim() === "" ||
      !sendAmount ||
      !accBalance
    ) {
      return;
    }
    navigate("/transfers/review-transfer");
  };
  useEffect(() => {
    dispatch(setTitle("Transfers"));
  }, []);

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <div className="md:w-[38rem] mx-auto mt-8 md:mb-20 pb-8 mb-12">
        <div className="rounded-md bg-white p-8">
          {/*===========================================
        delay transfer flag commented out incase needed another time
         ============================================== */}

          <Announcement Service="Transfer" />

          <div className="hidden justify-between mb-6">
            <div>
              <p className="text-lg font-bold">International Transfer</p>
            </div>
            <div>
              <TransferFlag />
            </div>
          </div>
          {loading ? (
            <Spinner />
          ) : (
            <div>
              <div className="flex justify-between items-center">
                <label className="text-sm pb-1 font-semibold text-left">
                  Recipient{" "}
                  <span className="hidden text-[#D31D1D] font-semibold">*</span>
                </label>
                <button
                  onClick={() => dispatch(setModalState(!modalState))}
                  className="text-[#0B69AD] "
                >
                  Add new
                </button>
              </div>
              <select
                onChange={handleSelectChange}
                className={`bg-[#F9F9F9] w-full rounded-md text-[14px] border-1 h-12 outline-none border text-[#424242] focus:outline-none focus:ring-cleva-gold focus:border-cleva-gold`}
                name=""
                id=""
                value={recipientName}
              >
                <option value="">Select Recipient</option>

                {reversedRecipients
                  .filter((info: any) => info.RecipientType === "BANK")
                  .map((recipient: any) => (
                    <option
                      key={recipient.RecipientIdentifier}
                      value={`${recipient.RecipientIdentifier}`}
                    >
                      {`${recipient.FullName.FirstName} ${recipient.FullName.LastName} - (${recipient.BankName})`}
                    </option>
                  ))}
              </select>

              <div className="flex justify-between mt-1">
                {recipientError ? (
                  <div className="flex items-center mb-2 mt-1">
                    <img src={errIcon} alt="error" />
                    <p className="text-[#D31D1D] text-sm ml-1">
                      {recipientError}
                    </p>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>

              <div className="hidden mt-4">
                <label className="text-sm pb-1 text-left">
                  Pay from{" "}
                  <span className="hidden text-[#D31D1D] font-semibold">*</span>
                </label>
                <div className="input-control">
                  <div className="flex justify-between">
                    <p className="font-medium text-sm">
                      Cleva USD Account
                      {getMaskedAccountNumber(accountNumber)}
                    </p>
                    <p className="text-[#747A80]">
                      $
                      {accBalance?.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </p>
                  </div>
                </div>
              </div>

              <CurrencyInput
                title="You will send"
                value={sendAmount?.toLocaleString()}
                fn={handleAmountChange}
                onBlur={handleBlur}
                type="text"
                err={amtError ? amtError : ""}
                placeholder="0.00"
                code="USD"
                flag={USIcon}
                isBal={true}
                accBalance={accBalance}
                seeLimit={seeLimit}
              />
              <div className="relative mt-4 flex flex-col gap-4">
                <div className="left-4 absolute h-4 border-l-[#BDBDBD] border-l"></div>
                {/* Rate Section */}
                <div className="relative mt-4 flex items-center gap-3">
                  <div className="border-[#BDBDBD] border rounded-full p-2">
                    <FaExchangeAlt className="text-yellow-600" />{" "}
                    {/* Arrow Icon */}
                  </div>
                  <div className="text-lg">
                    <span className="text-sm">Rate: </span>
                    <span className="text-base">$1.00 = ₦{exchangeRate}</span>
                  </div>
                </div>
                {/* Fee Section */}
                <div className="relative flex items-center gap-3">
                  <div className="-top-4 left-4 absolute h-4 border-l-[#BDBDBD] border-l"></div>
                  <div className="border-[#BDBDBD] border rounded-full p-2">
                    <FaMoneyCheckAlt className="text-yellow-600" />{" "}
                    {/* Money Icon */}
                  </div>
                  <div className="text-lg">
                    <span className="text-sm">Fee: </span>
                    <span className="text-base">$0.00</span>
                  </div>
                  <div className="-bottom-4 left-4 absolute h-4 border-l-[#BDBDBD] border-l"></div>
                </div>
              </div>
              <p className="hidden font-bold text-base mb-1">
                1 USD = {exchangeRate} NGN
              </p>
              <CurrencyInput
                title="Recipient will get"
                value={receiveAmount?.toLocaleString()}
                fn={handleNairaChange}
                onBlur={handleNairaBlur}
                type="text"
                placeholder="0.00"
                err={receiveAmountError ? receiveAmountError : ""}
                code="NGN"
                flag={NGIcon}
              />

              <div className="hidden items-center my-1">
                <div className="flex items-center">
                  <img src={FeeIcon} alt="fee" />
                  <p className="text-xs text-[#747A80] mr-4 ml-2">
                    Transfer fee
                  </p>
                </div>
                <p className="text-xs font-bold">{fee.toFixed(2)} USD </p>
              </div>
              <Input
                //yatitle="Description"
                value={description}
                fn={handleDescriptionChange}
                type="text"
                err=""
                text="Add a description (Optional)"
              />
              <div className="flex items-center justify-between gap-4 my-6">
                <div>
                  <Link
                    to="/transfers"
                    className="bg-cancel text-sm font-bold py-3 md:px-10 px-6 rounded-lg"
                  >
                    Cancel
                  </Link>
                </div>
                <div>
                  <button
                    onClick={() => toggleModal()}
                    className={`bg-cleva-gold text-sm font-bold py-3 md:px-10 px-6 rounded-lg hidden md:block`}
                    disabled={
                      !sendAmount ||
                      !recipientName ||
                      !!BtnError ||
                      !accBalance ||
                      !!amtError ||
                      !!receiveAmountError
                    }
                    style={{
                      opacity:
                        !sendAmount ||
                        !recipientName ||
                        BtnError ||
                        !accBalance ||
                        !!amtError ||
                        !!receiveAmountError
                          ? 0.6
                          : 1,
                    }}
                  >
                    Continue
                  </button>
                  {/* ====================
              mobile button for review transfer
              ================== */}
                  <button
                    onClick={() => navigateMobile()}
                    className={`bg-cleva-gold text-sm font-bold py-3 md:px-10 px-6 rounded-lg md:hidden`}
                    disabled={
                      !sendAmount ||
                      !recipientName ||
                      !!BtnError ||
                      !accBalance ||
                      !!amtError ||
                      !!receiveAmountError
                    }
                    style={{
                      opacity:
                        !sendAmount ||
                        !recipientName ||
                        BtnError ||
                        !accBalance ||
                        !!amtError ||
                        !!receiveAmountError
                          ? 0.6
                          : 1,
                    }}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          )}

          <ToastContainer />
        </div>

        {modalState && <AddRecipient closeModal={closeModal} />}

        {modal && (
          <ViewModal
            closeModal={closeModal}
            openTransactionPin={() => {
              closeModal();
              setTransactionPin(true);
            }}
          />
        )}

        {!verifying && openTransactionPin && (
          <ValidateUserPin
            VerifyTransaction={VerifyTransaction}
            openForgotPinModal={() => {
              setTransactionPin(false);
              setForgotPin(true);
              sendOTP();
            }}
            sendOTP={() => {
              setTransactionPin(false);
              setOTP(true);
              sendOTP();
            }}
            closeModal={() => setTransactionPin(false)}
          />
        )}

        {openForgotPin && (
          <ForgotPin
            openNewPinModal={() => {
              setForgotPin(false);
              setNewPin(true);
            }}
            closeModal={() => setForgotPin(false)}
          />
        )}

        {openOTP && (
          <VerifyUserOTP
            openNewPinModal={() => {
              setOTP(false);
              setNewPin(true);
            }}
            closeModal={() => setOTP(false)}
          />
        )}

        {openNewPin && (
          <NewPin
            openUpdatedPinModal={() => {
              setNewPin(false);
              setUpdatedPin(true);
            }}
            closeModal={() => setNewPin(false)}
          />
        )}

        {openUpdatedPin && (
          <UpdatedPin closeModal={() => setUpdatedPin(false)} />
        )}

        {verifying && <PinLoader text="Initiating transfer..." />}

        <ToastContainer />
      </div>
    </>
  );
};

export default CreateLocalTransfer;
