import { useAppSelector } from "../../../app/hooks";
import { useState } from "react";
import OptionButton from "../components/OptionButton";

export default function AllTags() {
  const [delDropdown, setDelDropdown] = useState(false);
  const { recipients } = useAppSelector((state) => state.recipient);
  // console.log("recipinet", recipients);
  const reverseRecipients = recipients ?? [];
  const getRecipients = [...reverseRecipients].reverse();

  return (
    <div
      className="pr-4 py-10"
      onClick={() => {
        if (delDropdown) {
          setDelDropdown(false);
        }
      }}
    >
      <div className="overflow-x-auto">
        <table className="w-full mt-6">
          <thead className="text-left text-[14px] bg-gray-100 mb-2">
            <tr>
              <th className="py-3 pl-2">Name</th>
              <th className="py-3"> </th>
              <th className="py-1 pl-4"> Tag</th>
              <th className="py-3"> </th>
              <th className="py-3"> </th>
            </tr>
          </thead>
          {getRecipients
            .filter((info: any) => info.RecipientType === "TAG")
            .map((info: any) => (
              <tbody
                className="bg-white text-left
      text-[13px] border
      odd:bg-gray-100"
                key={info.RecipientIdentifier}
              >
                <tr>
                  <td
                    // onClick={() => navigate("/recipient_details")}
                    className="py-3 pl-4 border-b"
                  >
                    <p className="flex space-x-1">
                      <span>{info.FullName.FirstName}</span>
                      <span>{info.FullName.LastName}</span>
                    </p>
                  </td>
                  <td className="py-1 pl-4 border-b"></td>
                  <td className="py-1 pl-4 border-b">@{info.NickName}</td>
                  <td className="py-1 pl-4 border-b px-4"></td>
                  <td className="py-3 max-[600px]:pr-4 pl-4 border-b cursor-pointer">
                    <OptionButton
                      key={info.RecipientIdentifier}
                      RecipientIdentifier={info.RecipientIdentifier}
                      RecipientName={`${info.FullName.FirstName} ${info.FullName.LastName}`}
                      RecipientTag={info.NickName}
                    />
                  </td>
                </tr>
              </tbody>
            ))}
        </table>
      </div>
    </div>
  );
}
