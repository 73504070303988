import React, { useCallback, useState } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import loadingIcon from "../../../asset/svg/loading.svg";
import TagsList from "./TagsList";
import { debounce } from "lodash";
import { searchTag } from "../../../api";

interface ISearchInput {
  selectTag: (tag: any) => void;
  removeTag: () => void;
  setList?: (list: any) => void;
  tag: any;
}
export default function SearchInput({
  tag,
  selectTag,
  removeTag,
  setList,
}: ISearchInput) {
  const [error, setError] = useState("");
  const [tags, setTags] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [username, setUsername] = useState<string>("");
  const [message, setMessage] = useState<string>("");

  const debouncedSave = useCallback(
    debounce(async (nextValue: any) => {
      setLoading(true);
      try {
        const res = await searchTag({ query: nextValue });
        setLoading(false);
        if (res.data.TagsList.length === 0) {
          setError("No user found");
        } else {
          setError("");
          setTags(res.data.TagsList);
          if (setList) {
            setList(res.data.TagsList);
          }
        }
      } catch {
        setLoading(false);
        setTags([]);
        if (setList) {
          setList([]);
        }
        setError("Network error");
      }
    }, 500),
    []
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let nextValue = e.target.value.toLowerCase();
    nextValue = nextValue.replace(/[^a-z0-9_]/g, "");
    setUsername(nextValue);
    setLoading(false);
    setError("");
    setMessage("");
    if (nextValue.length === 0) {
      setTags([]);
      if (setList) {
        setList([]);
      }
    } else if (nextValue.length < 4) {
      setMessage("Enter at least 4 characters");
    } else if (nextValue.length >= 4 && nextValue.length < 12) {
      debouncedSave(nextValue);
    } else if (nextValue.length > 12) {
      setMessage("Enter at most 12 characters");
    }
  };

  const select = (tag: any) => {
    selectTag(tag);
    setTags([]);
    if (setList) {
      setList([]);
    }
  };

  const remove = () => {
    removeTag();
    setUsername("");
    setTags([]);
    if (setList) {
      setList([]);
    }
  };

  return (
    <>
      {!tag.Tag && (
        <div className="flex mt-2">
          <input
            type="text"
            id="Tag"
            name="Tag"
            autoComplete="off"
            value={username}
            onChange={handleInputChange}
            placeholder="Search for @Cleva tag, name, email"
            required
            className="password-control"
            aria-label="yourTag"
          />
          <button type="button" onClick={remove} className="password-button">
            <AiFillCloseCircle />
          </button>
        </div>
      )}
      {tag.Tag && (
        <div className="flex mt-2">
          <input
            type="text"
            id="taggedUser"
            name="taggedUser"
            value={`${tag.FullName?.FirstName} ${tag?.FullName?.MiddleName!} ${
              tag.FullName?.LastName
            } -  @${tag.Tag}`}
            placeholder="Search for @Cleva tag"
            required
            className="password-control"
            aria-label="clevaTag"
            disabled
          />
          <button type="button" onClick={remove} className="password-button">
            <AiFillCloseCircle />
          </button>
        </div>
      )}

      <div className="mt-2 text-left">
        {loading && (
          <div className="text-[#454545] text-sm mb-2 flex">
            <img src={loadingIcon} width={20} alt="loading" /> &nbsp; Searching
          </div>
        )}
        {!loading && error && !message && (
          <div className="text-red-500 text-sm mb-2">{error}</div>
        )}

        {!loading && message && (
          <div className="text-red-500 text-sm mb-2">{message}</div>
        )}
      </div>

      {tags?.length > 0 ? <TagsList tags={tags} onTagClick={select} /> : null}
    </>
  );
}
