import React from "react";
import { shutdown } from "@intercom/messenger-js-sdk";
import { IoPersonOutline } from "react-icons/io5";
import { GrSettingsOption } from "react-icons/gr";
import { removeAuthTokens } from "../../login";
import { useNavigate } from "react-router";
import { useAppDispatch } from "../../app/hooks";
import { setUser, setUserHash } from "../../features/User/UserSlice";
import { AuthService } from "../../features/services/AuthServices";
import {
  setCompletedSteps,
  setModalState,
  setStep,
} from "../../features/Kyc/kycSlice";
import { resetBvnVerified } from "../../features/ClevaCards/ClevaCardsSlice";
import { MdLogout } from "react-icons/md";
import { setTitle } from "../../features/Nav/NavSlice";
//import { setUser } from "../../features/Accounts/AccountSlice";

export default function ProfilePop({ handleModal, closeModal }: any) {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  function handleClose() {
    closeModal();
    dispatch(setModalState(false));
  }

  // const handleSecurity = () => {
  //   navigate("/profile/security/confirm-change");
  //   handleModal();
  // };

  return (
    <div
      onClick={handleClose}
      className="fixed inset-0 z-20 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
    >
      <div className="fixed right-0 mr-[5%] mt-[3%] w-[16rem] top-6 py-5 border w-68 shadow-lg rounded-xl bg-white">
        <ul className="leading-[3em]">
          <li
            onClick={() => {
              navigate("/profile");
              handleModal();
            }}
            className=" cursor-pointer hover:bg-slate-100 flex items-center gap-3 px-8"
          >
            <span className="text-[20px]">
              <IoPersonOutline className="h-6 w-6" />
            </span>{" "}
            Profile
          </li>
          <li
            onClick={() => {
              navigate("/profile/settings");
              handleModal();
            }}
            className=" cursor-pointer hover:bg-slate-100 flex items-center gap-3 px-8"
          >
            <span className="text-[20px]">
              <GrSettingsOption className="h-6 w-6" />
            </span>
            Settings
          </li>
          {/* <li
            onClick={() => {
              navigate("/profile/tips");
              handleModal();
            }}
            className=" cursor-pointer hover:bg-slate-100 flex items-center gap-3 px-8"
          >
            <span className="text-[20px]">
              <MdOutlinePrivacyTip className="h-6 w-6" />
            </span>
            Tips for your account
          </li>

          <li>
            <Link
              target="_blank"
              to="https://www.getcleva.com/pricing"
              className=" cursor-pointer hover:bg-slate-100 flex items-center gap-3 px-8 pb-2"
            >
              {" "}
              <span className="text-[20px]">
                <LiaMoneyBillWaveSolid className="h-6 w-6" />
              </span>{" "}
              Our fees
            </Link>
          </li> */}

          <li
            className=" cursor-pointer hover:bg-slate-100 flex items-center gap-3 border-t pt-2 px-8 text-[#FF2E00]"
            onClick={() => {
              shutdown();
              localStorage.removeItem("modalDisplayed");
              removeAuthTokens();
              AuthService.signOut();
              dispatch(setCompletedSteps([]));
              dispatch(setTitle(""));
              dispatch(setStep(1));
              dispatch(setUser(null));
              dispatch(resetBvnVerified());
              dispatch(setUserHash(""));
              navigate("/");
            }}
          >
            {" "}
            <span className="text-[20px]">
              <MdLogout className="h-6 w-6" />
            </span>{" "}
            Logout
          </li>
        </ul>
      </div>
    </div>
  );
}
