import React from "react";
import loader from "../../asset/images/kolici-yukleniyor.gif";

interface LoaderProps {
  text: string;
}

const Loader: React.FC<LoaderProps> = ({ text }) => {
  return (
    <div className="fixed flex justify-center items-center inset-0 z-10 bg-[#000000cf] bg-opacity-70  h-full w-full">
      <div className="border shadow-sm py-12 px-16 m-auto bg-white rounded-xl">
        <div className="flex justify-center mb-6">
          <img src={loader} alt="loading..." className="w-[3rem]" />
          {/* <DotLoader
            size={25}
            color="orange"
            loading={true}
            aria-label="loading"
            className=""
          /> */}
        </div>
        <p className="text-base text-[#111111] font-semibold">{text}</p>
      </div>
    </div>
  );
};

export default Loader;
