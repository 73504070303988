import React, { useEffect, useState } from "react";
import ModalTemplate from "../../../components/PopUps/ModalTemplate";
import { IoCloseOutline, IoEyeOffOutline } from "react-icons/io5";
import { Switch } from "@headlessui/react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { hideBalance } from "../../../api";
import { getReturningUser } from "../../../login";
import { setUser } from "../../../features/User/UserSlice";
import { toast } from "react-toastify";

type ModalProps = {
  showMenu: boolean;
  setShowMenu: React.Dispatch<React.SetStateAction<boolean>>;
  balanceChange: (toggleSwitch: boolean) => void;
};

const BalanceModal: React.FC<ModalProps> = ({
  showMenu,
  setShowMenu,
  balanceChange,
}) => {
  const dispatch = useAppDispatch();

  const user = useAppSelector((state) => state.user.userData);
  const BalanceStatus = user?.HideUsdBalance;

  const [enabled, setEnabled] = useState<boolean>(BalanceStatus || false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleToggle = async (newValue: boolean) => {
    setEnabled(newValue);

    setIsLoading(true);

    try {
      await hideBalance(newValue);

      const updatedUser = await getReturningUser();
      if (updatedUser) {
        dispatch(setUser(updatedUser));
      }
    } catch (error) {
      setEnabled(!newValue);
      toast.error("An error occurred. Please try again later.");
      console.error("Failed to update balance visibility", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleApply = () => {
    balanceChange(enabled);
  };
  useEffect(() => {
    if (BalanceStatus !== undefined) {
      setEnabled(BalanceStatus);
    }
  }, [BalanceStatus]);

  return (
    <ModalTemplate
      childStyle="md:w-[450px]"
      modalState={showMenu}
      setModalState={setShowMenu}
    >
      <div>
        <header className="bg-[#F6F6F6] px-8 py-4 rounded-lg flex justify-between items-center">
          <p className="text-lg font-semibold text-[#111111]">Hide Balance</p>
          <IoCloseOutline
            onClick={() => setShowMenu(false)}
            className="cursor-pointer text-[#2C2C2C] w-5 h-5"
          />
        </header>
        <div className="">
          <div className="py-8 overflow-y-auto px-6">
            <div className="flex justify-between  py-3 px-4 border border-[#F1F1F1] rounded-lg">
              <div className="flex">
                <IoEyeOffOutline className="w-6 h-6 text-[#111111]" />
                <div className="mx-[0.75rem] ">
                  <p className="text-base text-[#111111]">
                    Hide Balance by default
                  </p>
                  <p className="text-[#5C5C5C] text-sm mt-3">
                    By turning this on, your balance will be hidden whenever you
                    log in.
                  </p>
                </div>
              </div>

              <div>
                <Switch
                  checked={enabled}
                  onChange={handleToggle}
                  disabled={isLoading} // Disable switch while loading
                  className={`${
                    enabled ? "bg-[#077843]" : "bg-gray-200"
                  } group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-green-700 focus:ring-offset-2`}
                >
                  <span className="sr-only">Toggle balance visibility</span>
                  <span
                    aria-hidden="true"
                    className={`${
                      enabled ? "translate-x-5" : "translate-x-0"
                    } pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out`}
                  />
                </Switch>
              </div>
            </div>
            <div className="mt-6">
              <button
                className="bg-cleva-gold py-3 px-20 w-[100%] rounded-lg font-semibold shadow text-[#111111] text-sm"
                onClick={handleApply}
              >
                {" "}
                Apply{" "}
              </button>
            </div>
          </div>
        </div>
      </div>
    </ModalTemplate>
  );
};

export default BalanceModal;
