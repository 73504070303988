import React, { ReactElement, useState } from "react";
import AccountCard from "../../components/Layout/AccountCard";
import TabButtons from "../../components/Tabs/RoundedTabButton";
import TabContent from "../../components/Tabs/TabContent";
import { AccountStatus } from "./components/AccountStatus";
import RecentTransaction from "./components/RecentTransaction";
import usdIcon from "../../asset/dashboard/US-Icon.svg";
import ngnIcon from "../../asset/dashboard/NGN-Icon.svg";

const ViewAccount: React.FC = (): ReactElement => {
  const [activeTab, setActiveTab] = useState<string>("usdTab");

  const handleTabChange = () => {};

  return (
    <>
      <div className="pt-10 mb-20">
        <div className="flex justify-center items-center min-h-[75vh]">
          <div className="md:w-3/4 mx-auto">
            <div className="mb-4">
              <div className=" w-full md:mb-1 md:w-[30%] order-0 md:order-0 pt-5 mb-1 mx-auto">
                <ul className="grid grid-cols-2 gap-2 bg-white rounded-full px-2 py-1.5">
                  <TabButtons
                    icon={usdIcon}
                    name="USD"
                    id="usdTab"
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    onClick={() => handleTabChange()}
                  />
                  <TabButtons
                    icon={ngnIcon}
                    disabled={true}
                    name="NGN"
                    id="ngnTab"
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    ngnText="Coming soon"
                    onClick={() => handleTabChange()}
                  />
                </ul>
              </div>
            </div>
            <div className="mt-4">
              <TabContent id="usdTab" activeTab={activeTab}>
                <>
                  <AccountCard />
                  <AccountStatus />
                  <RecentTransaction />
                </>
              </TabContent>
              <TabContent id="ngnTab" activeTab={activeTab}>
                <></>
              </TabContent>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewAccount;
