import { MdArrowForwardIos } from "react-icons/md";
import { useNavigate } from "react-router-dom";

import tag from "../../../images/transfer/tag.png";
import bank from "../../../images/transfer/bank.png";
import { useAppDispatch } from "../../../app/hooks";
import { fetchRecipients } from "../../../features/Recipients/RecipientsApi";
import { useEffect } from "react";
import { fetchAccount } from "../../../features/VirtualAccount/VirtualAccountApi";
import { Auth } from "aws-amplify";
import { setUser } from "../../../features/User/UserSlice";
import { getUserIdWithAccessToken, getUser } from "../../../login";
import { setTag, updateTagModalStatus } from "../../../features/Tags/TagsSlice";
import { getTag } from "../../../api";
import BackButton from "../../../components/Buttons/BackButton";

export default function TransferOption() {
  const fetchUser = async () => {
    const userID = await getUserIdWithAccessToken();
    const session = await Auth.currentSession();
    const userDetails = await getUser(
      userID,
      session.getIdToken().getJwtToken()
    );
    dispatch(setUser(userDetails));
    const TagData = await getTag(userID).catch(() => null);
    if (TagData?.data) {
      dispatch(updateTagModalStatus(false));
      dispatch(setTag(TagData?.data?.Tag || ""));
    } else {
      dispatch(updateTagModalStatus(true));
      dispatch(setTag(""));
    }
  };

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    fetchUser();
    dispatch(fetchRecipients());
    dispatch(fetchAccount());
  }, [dispatch]);

  return (
    <>
      <div className="relative flex-1">
        <div className="flex items-center pt-6">
          <BackButton />
          <p className="text-lg font-bold ml-3">Make Transfer</p>
        </div>

        <div className="flex justify-center flex-col md:h-[60vh] text-center">
          <div className="top-4 mt-4 left-0 right-0 bottom-0 bg-cleva-white flex items-center justify-center">
            <div className="login-card-md w-full md:w-[32rem] xl:w-[32rem] md:px-10 md:py-8 md:shadow rounded-lg">
              <div
                className="flex bg-white w-full mb-2 px-3 py-8 cursor-pointer border rounded-lg border-1 border-[#EFEFEF]"
                onClick={() => navigate("/tags/transfers/create")}
              >
                <img
                  title="tag"
                  alt="tag"
                  className="w-[42px] h-[42px] mr-4"
                  src={tag}
                />
                <div className="text-left w-full">
                  <h2 className="text-[15px] font-semibold">To @Cleva tag</h2>
                  <p className="text-gray-500 text-[15px]">
                    Transfer to any cleva user
                  </p>
                </div>
                <div className="flex justify-end float-right mt-2">
                  <MdArrowForwardIos
                    size={15}
                    className="float-right"
                    color="#B0B0B0"
                  />
                </div>
              </div>

              <div
                className="flex bg-white w-full px-3 py-8 cursor-pointer border rounded-lg border-1 border-[#EFEFEF]"
                onClick={() => navigate("/transfers/create")}
              >
                <img
                  title="bank"
                  alt="bank"
                  className="w-[42px] h-[42px] mr-4"
                  src={bank}
                />
                <div className="text-left w-full">
                  <h2 className="text-[15px] font-semibold">To bank account</h2>
                  <p className="text-gray-500 text-[15px]">
                    Transfer to NGN bank account
                  </p>
                </div>
                <div className="flex justify-end float-right mt-2">
                  <MdArrowForwardIos
                    size={15}
                    className="float-right"
                    color="#B0B0B0"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
