import React, { useState } from "react";
import PendingIcon from "../../../images/pending-trans.svg";
import { Link } from "react-router-dom";
import RefundModal from "../../Transfers/M2/modals/ViewPayoutRefund";
import RefundRefundModal from "../../Transfers/M2/modals/ViewReversalRefund";
import ReversalModal from "../../Transfers/M2/modals/ViewReversal";
import ReceivedModal from "../../Transfers/M2/modals/ViewIncomingTransfer";
import TransferModal from "../../Transfers/M2/modals/ViewOutgoingTransfer";
import CardFundingModal from "../../Transfers/M2/modals/CardFundingModal";
import { fetchTransactionById } from "../../../features/Transanctions/transactionApi";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch } from "../../../app/store";
import { RootState } from "../../../app/store";
import {
  getTransactionIcon,
  getTransferStatus,
  convertTime,
  parseToDateTimeString,
  getTransactionType,
} from "./AccountUtils";
import { Tooltip } from "react-tooltip";
import C2CModal from "../../Transfers/M2/modals/C2CModal";

const RecentTransaction: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { allTransfer } = useSelector((state: RootState) => state?.transaction);

  const [selectedTransfer, setSelectedTransfer] = useState<any>(null);

  const handleTransferClick = (transferId: string) => {
    const transfer = allTransfer.find(
      (transfer: any) => transfer.TransactionIdentifier === transferId
    );

    if (transfer) {
      setSelectedTransfer(transfer);
      dispatch(fetchTransactionById(transfer?.TransactionIdentifier));
    }
  };
  const OutgoingModal = (transferId: string) => {
    const transfer = allTransfer.find(
      (transfer: any) => transfer?.TransactionIdentifier === transferId
    );

    if (transfer) {
      setSelectedTransfer(transfer);
      dispatch(fetchTransactionById(transfer?.TransactionIdentifier));
    }
  };

  const closeModal = () => {
    setSelectedTransfer(null);
  };

  const sortedTransactions = [...allTransfer].sort(
    (a, b) => new Date(b.CreatedAt).getTime() - new Date(a.CreatedAt).getTime()
  );
  const recentTransaction = sortedTransactions?.slice(0, 3); // Limit the recent transactions to 6

  const kycPendingTransfers = sortedTransactions?.filter(
    (transfer: any) => transfer?.TransactionState === "KYC_PENDING"
  );

  return (
    <>
      <div className="bg-white rounded-2xl px-3 py-10 md:py-10 md:px-8 xl:py-10 xl:px-12">
        {allTransfer.length < 1 ? (
          <div className="xl:w-[90%] mx-auto">
            <p className="font-medium text-lg  text-center text-[#3E3E3E] md:py-10 py-4">
              No Recent Transactions
            </p>
          </div>
        ) : (
          <div>
            <div className="">
              <div className="flex justify-between mb-2 md:mb-6 items-center">
                <div className="grow-0 text-neutral-700 text-xl font-medium leading-tight">
                  Recent Transactions
                </div>

                <div className="">
                  <Link
                    to="/transfers"
                    className="text-[#0B69AD] text-lg font-normal"
                  >
                    See All
                  </Link>
                </div>
              </div>
            </div>

            <div className="">
              {kycPendingTransfers.length > 0 ? (
                kycPendingTransfers.map((transfer: any, index: number) => (
                  <div
                    className={`md:gap-14 gap-6 flex justify-between py-3 border-y border-[#CFDBD5] hover:cursor-pointer ${
                      index === 0 ? "border-t-0" : ""
                    } ${
                      index === kycPendingTransfers.length - 1
                        ? "border-b-0 border-t-0"
                        : ""
                    }`}
                    key={transfer.TransactionIdentifier}
                    onClick={() =>
                      handleTransferClick(transfer.TransactionIdentifier)
                    }
                  >
                    <div className="flex justify-between py-1">
                      <div className="justify-center items-center md:gap-[18px] gap-[8px] flex">
                        <div className="">
                          <img src={PendingIcon} alt="complete" />
                        </div>
                        <div className="">
                          <p className="text-[#111111] text-sm font-medium leading-none mb-1">
                            Money Received
                          </p>
                          <p className="text-[#747A80] text-sm font-normal leading-tight">
                            {new Date(
                              transfer?.CreatedAt
                            )?.toLocaleDateString()}
                          </p>
                        </div>
                      </div>
                      <div className="">
                        {transfer?.FromAmount && (
                          <p className="text-right text-black text-[14px] font-semibold leading-tight mb-1">
                            +$
                            {transfer.FromAmount?.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </p>
                        )}

                        {transfer?.TransactionState === "KYC_PENDING" ? (
                          <p className="text-right text-[#303030] text-[14px] font-normal leading-tight">
                            Pending
                          </p>
                        ) : transfer?.TransactionState ===
                          "DAILY_LIMIT_EXCEEDED" ? (
                          <p className="text-right text-[#303030] text-[14px] font-normal leading-tight">
                            Pending
                          </p>
                        ) : transfer?.TransactionState === "REJECTED" ? (
                          <p className="text-[#FA624D] text-right text-[14px] font-normal leading-tight">
                            Rejected
                          </p>
                        ) : transfer?.TransactionState === "REFUNDED" ? (
                          <p className="text-[#FA624D] text-right text-[14px] font-normal leading-tight">
                            Refunded
                          </p>
                        ) : transfer?.TransactionState === "RETURNED" ? (
                          <p className="text-[#FA624D] text-right text-[14px] font-normal leading-tight">
                            Returned
                          </p>
                        ) : transfer?.TransactionState === "FAILED" ? (
                          <p className="text-[#FA624D] text-right text-[14px] font-normal leading-tight">
                            Failed
                          </p>
                        ) : (
                          <p className="text-[#FA624D] text-right text-[14px] font-normal leading-tight">
                            {transfer.TransactionState}
                          </p>
                        )}
                      </div>
                      {/* {selectedTransfer === transfer && (
                          <ReceivedModal
                            transfer={selectedTransfer}
                            closeModal={closeModal}
                          />
                        )} */}
                      {selectedTransfer &&
                        selectedTransfer?.TransactionIdentifier ===
                          transfer?.TransactionIdentifier && (
                          <ReceivedModal closeModal={closeModal} />
                        )}
                    </div>
                    <div className="flex justify-end">
                      {transfer?.TransactionState === "KYC_PENDING" ? (
                        <Link
                          to="/kyc"
                          className="text-red-500 text-[14px] font-medium leading-tight mt-1"
                        >
                          KYC Required
                        </Link>
                      ) : transfer?.TransactionState ===
                        "DAILY_LIMIT_EXCEEDED" ? (
                        <p className="text-[#FA624D] text-right text-[14px] font-normal leading-tight">
                          Limit Exceeded
                        </p>
                      ) : (
                        " "
                      )}
                    </div>
                  </div>
                ))
              ) : (
                <div className="mt-3">
                  {recentTransaction?.map((transfer: any, index: number) => {
                    return (
                      <div
                        className={`md:gap-14 gap-6 flex justify-between py-3 border-y border-[#CFDBD5] hover:cursor-pointer ${
                          index === 0 ? "border-t-0" : ""
                        } ${
                          index === transfer.length - 1
                            ? "border-y-0"
                            : "border-t-0"
                        }`}
                        key={transfer.TransactionIdentifier}
                        onClick={() =>
                          OutgoingModal(transfer.TransactionIdentifier)
                        }
                      >
                        <div className="justify-center items-center md:gap-[18px] gap-[8px] flex">
                          <div className="">
                            {/* transaction icon */}
                            {getTransactionIcon(transfer)}
                          </div>
                          <div className="">
                            {/* transaction header */}
                            <p className="text-[#111111] text-sm font-medium leading-none mb-1">
                              {transfer?.TransactionType === "USD_CREDIT" &&
                              transfer?.SenderName !== ""
                                ? `From ${transfer?.SenderName}`
                                : transfer?.TransactionType === "USD_CREDIT" &&
                                    transfer?.TransactionState !== "REVERSED"
                                  ? "Money Received"
                                  : transfer?.TransactionState === "REVERSED"
                                    ? "Reversal from Sender"
                                    : transfer?.TransactionState ===
                                        "REVERSAL_REFUNDED"
                                      ? "Reversal refund from you"
                                      : transfer?.TransactionType ===
                                          "REFERRAL_BONUS"
                                        ? "Referral Bonus"
                                        : transfer?.TransactionType ===
                                            "CARD_FUNDING"
                                          ? "Card Funding"
                                          : transfer?.TransactionType ===
                                                "MAKE_PAYMENT_REFUND" ||
                                              transfer?.TransactionType ===
                                                "TAG_DEBIT_REVERSAL"
                                            ? "Transfer Refunded"
                                            : transfer?.TransactionType ===
                                                "CARD_CREATION"
                                              ? "Card Creation"
                                              : transfer?.TransactionType ===
                                                  "CARD_CREATION_REFUND"
                                                ? "Card creation Refund"
                                                : transfer?.TransactionType ===
                                                    "CARD_FUNDING_REFUND"
                                                  ? "Card funding Refund"
                                                  : transfer?.TransactionType ===
                                                      "CARD_TERMINATION_REFUND"
                                                    ? "Card Termination Refund"
                                                    : transfer?.TransactionType ===
                                                        "TAG_CREDIT"
                                                      ? `From ${transfer?.SenderName}`
                                                      : transfer?.TransactionType ===
                                                          "TAG_TRANSFER"
                                                        ? `Transfer to ${transfer?.RecipientName}`
                                                        : `Transfer to ${transfer?.RecipientName}`}
                            </p>
                            <p className="text-[#747A80] text-sm font-normal leading-tight">
                              {convertTime(new Date(transfer?.CreatedAt))}
                            </p>
                            {transfer?.TransactionState === "IN_REVIEW" && (
                              <div className="flex items-center mt-2">
                                <p className="text-[#0B69AD] text-xs">
                                  Completed by{" "}
                                  {parseToDateTimeString(
                                    new Date(transfer?.ToBeCompletedBy)
                                  )}{" "}
                                  WAT
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="min-w-[5rem]">
                          {transfer && (
                            <>
                              <p className="text-right text-black text-[14px] font-semibold leading-tight mb-1">
                                {/* display transaction amount */}
                                {getTransactionType(
                                  transfer.TransactionType,
                                  transfer.TransactionState,
                                  transfer.FromAmount,
                                  transfer.TotalAmount
                                )}
                              </p>
                            </>
                          )}
                          {/* transfer status */}
                          {getTransferStatus(transfer)}
                          <div className="flex justify-end">
                            {transfer?.TransactionState === "KYC_PENDING" ? (
                              <Link
                                to="/kyc"
                                className="text-red-500 text-[14px] font-medium leading-tight mt-1"
                              >
                                KYC Required
                              </Link>
                            ) : transfer?.TransactionState ===
                              "DAILY_LIMIT_EXCEEDED" ? (
                              <p className="text-[#FA624D] text-right text-[14px] font-normal leading-tight">
                                Limit Exceeded
                              </p>
                            ) : (
                              " "
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  <Tooltip
                    id="my-tooltip"
                    style={{
                      width: "250px",
                      fontSize: "12px",
                      textAlign: "center",
                    }}
                  />

                  {selectedTransfer && (
                    <>
                      {selectedTransfer.TransactionType === "MAKE_PAYMENT" && (
                        // Show the TransferModal for MAKE_PAYMENT transaction type
                        <TransferModal closeModal={closeModal} />
                      )}

                      {(selectedTransfer.TransactionType === "TAG_TRANSFER" ||
                        selectedTransfer.TransactionType === "TAG_CREDIT") && (
                        <C2CModal closeModal={closeModal} />
                      )}

                      {(selectedTransfer.TransactionType === "USD_CREDIT" ||
                        selectedTransfer.TransactionType ===
                          "REFERRAL_BONUS") &&
                        selectedTransfer.TransactionState !== "REVERSED" && (
                          // Show the ReceivedModal for USD_CREDIT transaction type
                          <ReceivedModal closeModal={closeModal} />
                        )}

                      {selectedTransfer.TransactionState === "REVERSED" && (
                        // Show the ReversalModal for Reversed transaction state
                        <ReversalModal closeModal={closeModal} />
                      )}

                      {selectedTransfer.TransactionState ===
                        "REVERSAL_REFUNDED" && (
                        // Show the ReversalModal for Reversed transaction state
                        <RefundRefundModal closeModal={closeModal} />
                      )}

                      {(selectedTransfer.TransactionType ===
                        "CARD_FUNDING_REFUND" ||
                        selectedTransfer.TransactionType ===
                          "CARD_TERMINATION_REFUND" ||
                        selectedTransfer.TransactionType ===
                          "CARD_CREATION_REFUND") && (
                        <CardFundingModal closeModal={closeModal} />
                      )}

                      {(selectedTransfer.TransactionType === "CARD_FUNDING" ||
                        selectedTransfer.TransactionType ===
                          "CARD_CREATION") && (
                        <CardFundingModal closeModal={closeModal} />
                      )}
                      {(selectedTransfer.TransactionType ===
                        "MAKE_PAYMENT_REFUND" ||
                        selectedTransfer.TransactionType ===
                          "TAG_DEBIT_REVERSAL") && (
                        <RefundModal closeModal={closeModal} />
                      )}
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default RecentTransaction;
