import React, { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import UsIcon from "../../../../images/USD.svg";
import { RootState } from "../../../../app/store";
import { useSelector } from "react-redux";
import Spinner from "../../../../components/PopUps/smallSpinner";
import { convertTime } from "../../../Accounts/components/AccountUtils";
import CardTransactionDetail from "./CardTransactionDetail";

type ModalAProps = {
  closeModal: () => void;
};

const CardFundingModal: React.FC<ModalAProps> = ({ closeModal }) => {
  const [open, setOpen] = useState(true);
  const cancelButtonRef = useRef(null);

  const { singleTransfer, loading } = useSelector(
    (state: RootState) => state.transaction
  );

  const status = singleTransfer
    ? (singleTransfer as any).TransactionState
    : " ";
  const transactionType = singleTransfer
    ? (singleTransfer as any).TransactionType
    : " ";

  const transactionID = singleTransfer?.TransactionIdentifier;

  // const Description = singleTransfer
  //   ? (singleTransfer as any).TransactionDetail.Description
  //   : " ";

  const totalAmount = singleTransfer
    ? (singleTransfer as any).TotalAmount
    : " ";

  const topupAmount = singleTransfer
    ? (singleTransfer as any).TransactionDetail.FromAmount
    : " ";

  const fee = singleTransfer
    ? (singleTransfer as any).TransactionDetail.Fee
    : " ";

  let createdDate = singleTransfer ? (singleTransfer as any).CreatedAt : "";

  const displayDate = convertTime(new Date(createdDate));

  const statusResult =
    status === "COMPLETED" ? (
      <span className="px-3 py-1 my-2 rounded-full capitalize bg-[#DEF7EC] text-[#03543F] font-medium text-xs">
        Completed
      </span>
    ) : status === "IN_TRANSIT" ? (
      <span className="px-3 py-1 my-2 rounded-full capitalize bg-[#EBFBFE] text-[#1892D7] font-medium text-xs">
        In Progress
      </span>
    ) : status === "PENDING" ? (
      <span className="px-3 py-1 my-2 rounded-full capitalize bg-[#EBFBFE] text-[#1892D7] font-medium text-xs">
        In Progress
      </span>
    ) : status === "REFUNDED" ? (
      <span className="px-3 py-1 my-2 rounded-[0.375rem] capitalize bg-[#F9F1E7] text-[#B8730A] font-medium text-xs">
        Refunded
      </span>
    ) : status === "RETURNED" ? (
      <span className="px-3 py-1 my-2 rounded-full capitalize bg-[#FDE8E8]  text-[#9B1C1C] font-medium text-xs">
        Returned
      </span>
    ) : status === "REJECTED" ? (
      <span className="px-3 py-1 my-2 rounded-full capitalize bg-[#FDE8E8]  text-[#9B1C1C] font-medium text-xs">
        Rejected
      </span>
    ) : (
      <span className=" px-3 py-1 my-2 font-medium capitalize bg-[#FDE8E8] rounded-full text-[#9B1C1C] text-[10px]">
        {singleTransfer ? (singleTransfer as any).TransactionState : " "}
      </span>
    );

  const renderTransactionDetails = () => {
    return (
      <CardTransactionDetail
        transactionType={transactionType}
        displayDate={displayDate}
        topupAmount={topupAmount}
        fee={fee}
        totalAmount={totalAmount}
        singleTransfer={singleTransfer}
        statusResult={statusResult}
        transactionID={transactionID}
      />
    );
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white pb-8 text-left shadow-xl transition-all sm:my-8 sm:w-full md:max-w-[28rem]  w-[28rem]">
                <header
                  className={`w-full bg-[#EFEFEF] py-4 px-8  flex justify-between`}
                >
                  <Dialog.Title
                    as="h3"
                    className="text-base font-bold leading-6 text-gray-900"
                  >
                    Transaction Details
                  </Dialog.Title>
                  <button
                    type="button"
                    className="rounded-md text-black hover:text-gray-500 focus:outline-none focus:ring-2 font-bold"
                    onClick={closeModal}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-4 w-4" aria-hidden="true" />
                  </button>
                </header>
                {loading ? (
                  <Spinner />
                ) : (
                  <div className="">
                    <div className="mt-3">
                      <div className="">
                        <div className="flex justify-between px-6 py-7 items-center gap-4 md:gap-0">
                          <div className="flex items-center">
                            <img src={UsIcon} alt="icon" />
                            {transactionType === "CARD_CREATION" ? (
                              <p className="font-bold ml-2">Card creation</p>
                            ) : transactionType === "CARD_FUNDING" ? (
                              <p className="font-bold ml-2">Card funding</p>
                            ) : (
                              <p className="font-bold ml-2">USD Refund</p>
                            )}
                          </div>
                          <div>
                            <p className="text-sm">{displayDate}</p>
                          </div>
                        </div>
                      </div>

                      {(transactionType === "CARD_CREATION_REFUND" ||
                        transactionType === "CARD_FUNDING_REFUND" ||
                        transactionType === "CARD_TERMINATION_REFUND") && (
                        <div className="bg-[#F6F6F6] flex flex-col gap-[0.8rem] items-center justify-center py-5">
                          <>
                            <h1 className="text-lg text-black font-normal">
                              Refund of{" "}
                              <span className="font-extrabold">
                                {" "}
                                $
                                {topupAmount?.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </span>
                            </h1>

                            <button className=""> {statusResult} </button>
                          </>
                        </div>
                      )}

                      <div className="mt-4 px-6 md:px-8">
                        <div className="bg-[#FCFCFC] border border-[#F1F1F1] md:px-4 py-3 px-2 rounded-xl mb-3">
                          {renderTransactionDetails()}
                        </div>
                        <div className="mt-8 md:mt-8">
                          <div className="flex justify-between items-center">
                            <p className="text-sm text-[#6F6F6F] w-1/3">
                              Transaction ID
                            </p>
                            <p className="text-sm ml-2 text-right">
                              {transactionID}
                            </p>
                          </div>
                        </div>
                        <footer>
                          <div className="pt-4">
                            <button
                              type="button"
                              className="text-sm py-3 rounded-md mt-4  border border-[#35803F] w-[100%] text-[#35803F]"
                              onClick={closeModal}
                            >
                              Done
                            </button>
                          </div>
                        </footer>
                      </div>
                    </div>
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default CardFundingModal;
