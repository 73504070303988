import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ContactDetails {
  PhoneNumber: string;
  Email: string;
}

export interface RegisteredAddress {
  StreetAddress: string;
  SecondStreetAddress?: string;
  City: string;
  Country: string;
  StateOrTerritory: string;
  Zipcode?: string;
  LGA?: string;
}

export interface IdentificationDocument {
  DocumentNumber?: string;
  DocumentType: string;
  IssuingCountry?: string;
  IssueDate?: string;
  ExpirationDate?: string;
}

export interface BeneficiaryOwner {
  Email: string;
  FirstName: string;
  LastName: string;
  DateOfBirth: string;
  NationalIdentifier?: string;
  IdentificationDocument: IdentificationDocument;
  Address?: RegisteredAddress;
  PercentageOwnership?: number;
  Document?: Document;
}

export interface Document {
  DocumentType: string;
  data?: string;
  contentType?: string;
  filename: string;
  status?: string;
  message?: string;
  size?: number;
}

export interface BusinessKyc {
  BusinessName: string;
  BusinessRegistrationNumber?: string;
  Classification: string;
  CountryOfIncorporation: string;
  ContactDetails: ContactDetails;
  NationalIdentifier?: string;
  Type: string;
  RegisteredAddress: RegisteredAddress;
  Website?: string;
  BeneficialOwners: BeneficiaryOwner[];
  BusinessDocuments: Document[];
}

export interface AdditionalDetails {
  SelfieUploadUrl?: {
    url: string;
  };
  IDUploadUrl?: {
    url: string;
  };
}

export interface IndividualKyc {
  FirstName: string;
  MiddleName?: string;
  LastName: string;
  DateOfBirth: string;
  NationalIdentifierType: string;
  Address: RegisteredAddress;
  PhoneNumber: any;
  Email: string;
  NationalIdentifier: string;
  IdentificationDocument: IdentificationDocument;
  AdditionalDetails?: AdditionalDetails;
  ProfileLink: string;
  MonthlyIncome?: string;
  FundingSources: string[];
}

interface IKycState {
  modalState: boolean;
  modalSedtDelete: boolean;
  BusinessKyc: BusinessKyc;
  IndividualKyc: IndividualKyc;
  closeEditModal: boolean;
  KYCState: any;
  accountType: any;
  index: any;
  step: any;
  newOrEdit: boolean;
  body: any;
  incrementalKyc: boolean;
  AdditionalDetails: any;
  completedSteps: any[];
  ninIdentifier: string;
}

const initialState: IKycState = {
  IndividualKyc: {
    FirstName: "",
    MiddleName: "",
    LastName: "",
    DateOfBirth: "",
    NationalIdentifierType: "",
    Address: {
      StreetAddress: "",
      City: "",
      Country: "",
      StateOrTerritory: "",
    },
    PhoneNumber: "",
    Email: "",
    ProfileLink: "",
    MonthlyIncome: "",
    FundingSources: [],
    NationalIdentifier: "",
    IdentificationDocument: {
      DocumentNumber: "",
      DocumentType: "",
      IssuingCountry: "",
    },
    AdditionalDetails: {
      SelfieUploadUrl: { url: "" },
      IDUploadUrl: { url: "" },
    },
  },
  BusinessKyc: {
    Type: "",
    BusinessName: "",
    Classification: "",
    RegisteredAddress: {
      StreetAddress: "",
      City: "",
      Country: "",
      StateOrTerritory: "",
      Zipcode: "",
    },
    Website: "",
    BusinessRegistrationNumber: "",
    CountryOfIncorporation: "",
    ContactDetails: {
      PhoneNumber: "",
      Email: "",
    },
    NationalIdentifier: "0000",
    BeneficialOwners: [
      {
        FirstName: "",
        LastName: "",
        Email: "",
        DateOfBirth: "",
        IdentificationDocument: {
          DocumentType: "",
        },
        Document: {
          DocumentType: "",
          filename: "",
        },
      },
    ],
    BusinessDocuments: [
      {
        DocumentType: "",
        data: "",
        contentType: "",
        filename: "",
        status: "",
        message: "",
        size: 0,
      },
    ],
  },
  modalState: false,
  modalSedtDelete: false,
  closeEditModal: false,
  accountType: localStorage.getItem("accountType"),
  index: 0,
  step: 1,
  newOrEdit: false,
  body: {},
  incrementalKyc: false,
  KYCState: null,
  completedSteps: [],
  AdditionalDetails: { VerificationPending: false },
  ninIdentifier: "",
};

export const KycSlice = createSlice({
  name: "kycInfo",
  initialState,
  reducers: {
    setBusinesskyc(state, action: PayloadAction<BusinessKyc>) {
      state.BusinessKyc = action.payload;
      if (state.BusinessKyc.Type === "Sole Proprietorship") {
        let owner = {
          FirstName: "",
          LastName: "",
          Email: "",
          DateOfBirth: "",
          IdentificationDocument: {
            DocumentType: "",
          },
          Document: {
            DocumentType: "",
            filename: "",
          },
        };
        if (state.BusinessKyc.BeneficialOwners.length < 1) {
          state.BusinessKyc.BeneficialOwners.push(owner);
        }
      }
    },
    setIndividualkyc(state, action: PayloadAction<IndividualKyc>) {
      state.IndividualKyc = action.payload;
    },
    setIncrementalKyc(state, action: PayloadAction<boolean>) {
      state.incrementalKyc = action.payload;
    },
    updateBeneficiaryOwner(state, action: PayloadAction<any>) {
      const options: any = action.payload;
      const owner: any = {
        ...state.BusinessKyc.BeneficialOwners[options.index],
      };
      state.BusinessKyc.BeneficialOwners[options.index] = {
        ...owner,
        ...options.body,
      };
    },
    updateBusinessDocument(state, action: PayloadAction<any>) {
      const options: any = action.payload;
      const document: any = {
        ...state.BusinessKyc.BusinessDocuments[options.index],
      };
      state.BusinessKyc.BusinessDocuments[options.index] = {
        ...document,
        ...options.body,
      };
    },
    setKYCState(state, action: PayloadAction<any>) {
      state.KYCState = action.payload;
    },
    setStep(state, action: PayloadAction<any>) {
      state.step = action.payload;
    },
    setCompletedSteps(state, action: PayloadAction<any>) {
      state.completedSteps = [...state.completedSteps, action.payload];
    },
    setAdditionalDetails(state, action: PayloadAction<any>) {
      state.AdditionalDetails = action.payload;
    },
    setAccountType(state, action: PayloadAction<string>) {
      state.accountType = action.payload;
    },
    setModalState(state, action: PayloadAction<any>) {
      state.modalState = action.payload;
    },
    setIndex(state, action: PayloadAction<any>) {
      state.index = action.payload;
    },
    openForm(state, action: PayloadAction<boolean>) {
      state.newOrEdit = action.payload;
    },
    setModalSedtDelete(state, action: PayloadAction<boolean>) {
      state.modalSedtDelete = action.payload;
    },
    setCloseEditModal(state, action: PayloadAction<boolean>) {
      state.closeEditModal = action.payload;
    },
    updateIDAndSelfieDocuments: (
      state,
      action: PayloadAction<{ IDDocument: Document; selfieDoc: Document }>
    ) => {
      const { IDDocument, selfieDoc } = action.payload;
      state.IndividualKyc.AdditionalDetails = {
        IDUploadUrl: { url: IDDocument.data || "" },
        SelfieUploadUrl: { url: selfieDoc.data || "" },
      };
    },
    setNINIdentifier(state, action: PayloadAction<any>) {
      state.ninIdentifier = action.payload;
    },
  },
});

export const {
  setBusinesskyc,
  setIndividualkyc,
  updateBeneficiaryOwner,
  updateBusinessDocument,
  setModalState,
  setModalSedtDelete,
  setCloseEditModal,
  setAccountType,
  setStep,
  setIncrementalKyc,
  setIndex,
  setCompletedSteps,
  setAdditionalDetails,
  setKYCState,
  openForm,
  updateIDAndSelfieDocuments,
  setNINIdentifier,
} = KycSlice.actions;
export default KycSlice.reducer;
