import { useNavigate } from "react-router-dom";
import { sendOTP } from "../Index";
import { useState } from "react";
import Spinner from "../../../../../components/PopUps/Spinner";

const SendPinConfirmation = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleSendOTP = async () => {
    setLoading(true);
    try {
      await sendOTP();
      navigate("/profile/security/confirm-otp");
      setLoading(false);
    } catch (error) {
      console.error("Failed to send OTP:", error);
      setLoading(false);
    }
  };

  if (loading) {
    return <Spinner />;
  }
  return (
    <section className="flex justify-center items-center text-center h-[50vh]  md:h-[70vh] xl:h-[55vh]">
      <div className="text-[#111111] bg-white  p-5 md:py-[6rem] md:px-[4rem]">
        <h1 className="text-[#111111] font-bold text-2xl">
          Change Transaction PIN
        </h1>
        <p className="text-base font-normal py-5">
          We’ll send a code to your email address to confirm it’s you.
        </p>
        <button
          className="bg-[#FFBD59] rounded-md text-sm font-bold py-4 px-8 md:px-16"
          onClick={() => handleSendOTP()}
        >
          Send OTP to confirm
        </button>
      </div>
    </section>
  );
};

export default SendPinConfirmation;
