import React from "react";

interface ExportButtonProps {
  onExport: (value: string) => void;
}

const ExportButton: React.FC<ExportButtonProps> = ({ onExport }) => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const target = e.target as HTMLButtonElement;
    const value = target.value;
    onExport(value);
  };

  return (
    <button
      className="flex items-center py-1.5 rounded-lg border border-black px-5 mb-3 text-sm"
      onClick={handleClick}
    >
      {/* <img src={DownloadIcon} className="mr-2 text-primary700 w-4" alt="export" /> */}
      Export
    </button>
  );
};

export default ExportButton;
