import React from "react";
import { RootState } from "../../app/store";
import { useSelector } from "react-redux";
import nairaIcon from "../../images/naira.svg";

interface DetailProps {
  title?: string;
  send?: string;
}

const DetailsCard: React.FC<DetailProps> = () => {
  const { singleTransfer } = useSelector(
    (state: RootState) => state.transaction
  );
  const amount = singleTransfer
    ? (singleTransfer as any).TransactionDetail.FromAmount
    : " ";
  const fee = singleTransfer
    ? (singleTransfer as any).TransactionDetail.Fee
    : " ";
  const exchangeRate = singleTransfer
    ? (singleTransfer as any).TransactionDetail.PromisedRate.ToCurrency
    : " ";

  const parseNumber = (value: string): number => {
    const stringValue = String(value);
    const parsedValue = parseFloat(stringValue.replace(/[^0-9.-]+/g, ""));
    return isNaN(parsedValue) ? 0 : parsedValue;
  };

  const parsedAmount = parseNumber(amount);
  const parsedFee = parseNumber(fee);

  const totalAmount = parsedAmount + parsedFee;

  return (
    <>
      <div className="bg-[#FCFCFC] border border-[#F1F1F1] md:px-4 px-2 py-3 rounded-xl mb-3">
        {/* <p className="font-medium">{title}</p> */}
        <div className="md:flex justify-between my-3">
          <p className="text-sm text-[#747A80]">Amount to send</p>
          <p className="text-sm font-medium">
            $
            {amount.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </p>
        </div>
        <div className="md:flex justify-between my-3">
          <p className="text-sm text-[#747A80]">Transfer fee</p>
          <p className="text-sm font-medium">
            $
            {fee.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </p>
        </div>
        <div className="md:flex justify-between my-3">
          <p className="text-sm text-[#747A80]">Total amount</p>
          <p className="text-sm font-semibold">
            $
            {totalAmount.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </p>
        </div>
        <div className="md:flex justify-between mt-3">
          <p className="text-sm text-[#747A80]">Exchange rate</p>
          <p className="text-sm font-medium flex">
            $1.00 -
            <img src={nairaIcon} alt="" srcSet="" className="ml-1 mr-[1px]" />
            {exchangeRate.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </p>
        </div>
      </div>
    </>
  );
};

export default DetailsCard;
