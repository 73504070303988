import React from "react";
import progressIcon from "../../../images/progress-check.svg";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../app/store";
import nairaIcon from "../../../images/naira.svg";
import BackIcon from "../../../images/leftArrow.svg";
import { useNavigate } from "react-router-dom";
import {
  setDescription,
  setReceiveAmount,
  setRecipientFirstName,
  setRecipientLastName,
  setSendAmount,
} from "../../../features/Transanctions/TransanctionSlice";
import { AppDispatch } from "../../../app/store";

const ViewTransfer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const RecipientFirstName = useSelector(
    (state: RootState) => state.transaction?.RecipientFirstName
  );
  const RecipientLastName = useSelector(
    (state: RootState) => state.transaction?.RecipientLastName
  );

  const AcctName = RecipientFirstName + " " + RecipientLastName;
  const receiveAmount = useSelector(
    (state: RootState) => state.transaction?.receiveAmount
  );

  const goBack = () => {
    dispatch(setDescription(""));
    dispatch(setSendAmount(""));
    dispatch(setRecipientFirstName(""));
    dispatch(setRecipientLastName(""));
    dispatch(setReceiveAmount(0));
    navigate("/transfers/create");
  };
  return (
    <>
      <div className="flex items-center pt-6">
        <div
          className="bg-white border border-[#CFDBD5B2] py-3 px-3.5 rounded-lg"
          onClick={goBack}
        >
          <img src={BackIcon} alt="back" />
        </div>
        <p className="text-lg font-bold ml-3">View Transfer</p>
      </div>

      <div className="flex justify-center items-center h-[75vh]">
        <div className="md:w-1/2 mx-auto mt-16 md:mb-20 mb-12 text-center md:p-4 p-2">
          <div className="flex justify-center">
            <img src={progressIcon} alt="progress" className="w-16 h-16" />
          </div>
          <div>
            <p className="text-base font-medium mt-8 mb-4 text-[#138D24]">
              Transfer in Transit!
            </p>
            <p className="text-[#535353] mb-8 ">
              Your transfer is now in transit, we’ll send{" "}
              <span className="text-[#242424] font-medium">
                <img
                  src={nairaIcon}
                  alt=""
                  srcSet=""
                  className="mr-[1px] inline mb-[2px]"
                />
                {receiveAmount?.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </span>{" "}
              to {AcctName} within 1 hour.
            </p>

            <div className="mt-12">
              <Link
                to="/transfers"
                className="bg-cleva-gold py-4 px-12 rounded-lg font-medium"
              >
                View Transfers
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewTransfer;
