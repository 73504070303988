import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TagTransaction } from "../../types";

interface TagState {
  displayCreateTagModal: boolean;
  userTag: string | null;
  tagTransaction: TagTransaction | null;
}

const initialState: TagState = {
  displayCreateTagModal: true,
  userTag: null,
  tagTransaction: {
    TagItem: {
      Tag: "",
      FullName: {
        FirstName: "",
        MiddleName: "",
        LastName: "",
      },
      Email: "",
    },
    RecipientIdentifier: "",
    Amount: 0,
    Fee: 0,
    Description: "",
  },
};

const tagSlice = createSlice({
  name: "tags",
  initialState,
  reducers: {
    setTag: (state, action) => {
      state.userTag = action.payload;
    },
    updateTagModalStatus: (state, action) => {
      state.displayCreateTagModal = action.payload;
    },
    setTagTransaction: (
      state,
      action: PayloadAction<TagTransaction | null>
    ) => {
      state.tagTransaction = action.payload;
    },
  },
});

export const { setTag, updateTagModalStatus, setTagTransaction } =
  tagSlice.actions;

export default tagSlice.reducer;
