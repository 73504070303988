import React, { useState, useEffect } from "react";
import { androidIcon, appleIcon } from "../../../asset/landing_photos";

const AppDownloadBanner = () => {
  const [isStandalone, setIsStandalone] = useState(false);

  useEffect(() => {
    const checkStandalone = () => {
      if (window.matchMedia("(display-mode: standalone)").matches) {
        setIsStandalone(true);
      } else {
        setIsStandalone(false);
      }
    };

    checkStandalone();

    window
      .matchMedia("(display-mode: standalone)")
      .addEventListener("change", checkStandalone);

    return () => {
      window
        .matchMedia("(display-mode: standalone)")
        .removeEventListener("change", checkStandalone);
    };
  }, []);

  if (!isStandalone) return null;

  return (
    <div
      style={{
        // position: "fixed",
        backgroundColor: "#333",
        color: "#fff",
        textAlign: "center",
        padding: "1rem",
        borderRadius: "15px",
        marginBottom: "1rem",
      }}
    >
      <p className="mb-3">
        For an up-to-date experience, download the Cleva official app.
      </p>
      <div className="md:w-1/2 w-[80%] mx-auto">
        <div className="flex gap-[0.75rem] justify-center items-center">
          <a
            href="https://play.google.com/store/apps/details?id=com.getcleva.cleva_mobile_app"
            target="_blank"
            rel="noreferrer"
            className=""
          >
            <img src={androidIcon} alt="Get it on google play" />
          </a>
          <a
            href="https://apps.apple.com/app/cleva-app/id6473619732"
            target="_blank"
            rel="noreferrer"
          >
            <img src={appleIcon} alt="Get it on apple store" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default AppDownloadBanner;
