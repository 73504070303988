import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { kycError } from "../../../Image";
import { MdRefresh } from "react-icons/md";

interface ModalProps {
  open: boolean;
  setOpen: any;
  IdType: string;
  retries: number;
}
const MismatchModal = ({ setOpen, open, IdType, retries }: ModalProps) => {
  const cancelButtonRef = useRef(null);

  const close = () => {
    setOpen(false);
  };

  return (
    <div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={close}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 md:max-w-lg  w-[23rem]">
                  <header
                    className={`w-full text-left bg-[#FFFFFF] py-4 px-8  flex justify-between`}
                  >
                    <Dialog.Title
                      as="h3"
                      className="text-base font-bold leading-6 text-gray-900"
                    >
                      {" "}
                    </Dialog.Title>
                    <button
                      type="button"
                      className="rounded-md text-black float-right justify-end hover:text-gray-500 focus:outline-none focus:ring-2 font-bold"
                      onClick={() => close()}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-4 w-4" aria-hidden="true" />
                    </button>
                  </header>

                  <div className="flex justify-center  w-full items-center ">
                    <div className="p-2 sm:w-[80%] md:w-[80%] pb-10 sm:pb-8 rounded-lg ">
                      <center className="">
                        <img
                          className="sm:w-[80px] md:w-[120px]"
                          src={kycError}
                          alt=""
                        />
                        <h2 className="text-[#000] font-semibold pt-4 md:text-[17px]">
                          {IdType} doesn't match
                        </h2>
                        <p className="py-2 text-[11px] md:text-[13px] text-[#444444] font-medium">
                          The details you entered don't match the information on
                          your {IdType}
                        </p>

                        <button
                          onClick={() => close()}
                          className="w-full text-[15px] font-semibold p-3 rounded-lg mt-4 border bg-[#FFBD59]"
                        >
                          Enter Valid {IdType}
                        </button>

                        <p className="w-full mt-4 text-[13px] text-[#0A7E39] flex font-medium  justify-center items-center">
                          <MdRefresh size={18} /> &nbsp; {retries || 0} retry
                          left
                        </p>
                        {/* additionalDetails?.NoOfRetriesLeft */}
                      </center>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

export default MismatchModal;
