/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { setStep, setCompletedSteps } from "../../features/Kyc/kycSlice";
import { Timeline2, UploadGuide } from "./components/Features";
import IndividualDetails from "./pages/individualDetails";
import { ToastContainer } from "react-toastify";
import { setTitle } from "../../features/Nav/NavSlice";
import { useNavigate } from "react-router-dom";
import { IDDocument } from "./components/IDDocument";
import NINDocumnent from "./components/NINDocumnent";
import { AWS_CREDS } from "../../features/services/AmazonService";

function IndividualKYC() {
  const { step, completedSteps, KYCState, AdditionalDetails, index } =
    useAppSelector((state) => state.kycInfo);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // const [loading, setLoading] = useState(true);

  const changeStep = (next: number) => {
    if (next > step) {
      dispatch(setCompletedSteps(step));
      dispatch(setStep(next));
    } else {
      setCompletedSteps(
        completedSteps.filter((completedStep) => completedStep !== step)
      );
      dispatch(setStep(next));
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(setTitle("KYC Verification - Individual"));
    // setLoading(true);

    // Check if KYCState is loaded
    if (KYCState) {
      // setLoading(false);

      // Handle the different states
      if (KYCState === "VERIFIED") {
        navigate("/");
      } else if (KYCState === "BIOMETRICS") {
        if (AdditionalDetails?.VerificationPending) {
          navigate("/");
        } else {
          dispatch(setStep(2));
        }
      } else if (KYCState === "DOCUMENT_RETRY" || KYCState === "ID_UPLOAD") {
        dispatch(setStep(2));
      } else if (KYCState === "RETRY") {
        dispatch(setStep(1));
        dispatch(setCompletedSteps([]));
      } else if (KYCState === "DENIED" || KYCState === "SUSPENDED") {
        navigate("/");
      }
    } else {
      if (AWS_CREDS.STAGE === "qa") {
        console.log("KYCState not loaded yet");
      }
      // Log to see if KYCState is missing
    }
  }, []);

  return (
    <div className="sm:flex md:py-20 py-10">
      <div className=" xl:w-[35%] sm:w-[50%] pb-2 md:pb-0">
        <Timeline2 step={step} completed={completedSteps} />
        {/* {step === 2 && <UploadGuide />} */}
        <div className="md:block hidden">{step === 2 && <UploadGuide />}</div>
      </div>

      {step === 1 && (
        <IndividualDetails currentStep={step} nextStep={changeStep} />
      )}

      {KYCState === "ID_UPLOAD"
        ? step === 2 && <NINDocumnent index={index} key={1} />
        : step === 2 && <IDDocument index={index} key={2} />}

      {/* {step === 2 && (
        <>
          {loading ? (
            ""
          ) : (
            <>
              {KYCState === "ID_UPLOAD" ? (
                <NINDocumnent index={index} key={1} />
              ) : (
                <IDDocument index={index} key={1} />
              )}
            </>
          )}
        </>
      )} */}

      <div className="md:hidden block">{step === 2 && <UploadGuide />}</div>

      <ToastContainer />
    </div>
  );
}

export default IndividualKYC;
