interface iProgress {
  spent: number;
  total: number;
}

const ProgressBar = ({ spent, total }: iProgress) => {
  const percentage = Math.min((spent / total) * 100, 100);

  let rawProgress;
  let progress = 0;
  let [minAmount, maxAmount] = [0.1, total];
  if (spent === minAmount) {
    progress = 100;
  } else {
    rawProgress = (spent - minAmount) / (maxAmount - minAmount);
    progress = Math.min(Math.max(rawProgress, 0), 1) * 100;
  }
  // console.log({spent, total, percentage})
  return (
    <>
      <div className="hidden w-full bg-[#D9D9D9] rounded-lg h-2">
        <div
          className="bg-[#FFBD59] h-2 rounded-lg"
          style={{ width: `${percentage}%` }}
        ></div>
        <div className="hidden text-center mt-2">
          {spent} / {total} ({Math.floor(percentage)}%)
        </div>
      </div>

      <section className="w-full">
        <div className="overflow-hidden h-[0.5rem] text-xs flex rounded-lg bg-[#D9D9D9]">
          <div
            style={{ width: `${progress}%` }}
            className="shadow-none flex flex-col text-center whitespace-nowrap text-[#3E3E3E]
          justify-center  bg-[#FFBD59] relative"
          >
            {/* flex */}
            <span className="hidden absolute inset-y-0 m-auto left-[40px] right-0  items-center justify-center">{`$${spent}`}</span>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProgressBar;
