import React, { useEffect, useState } from "react";
import ClevaAccount from "./extras/ClevaAccount";
import transferIcon from "../../images/make-transfer.svg";
import { useNavigate } from "react-router-dom";
import DetailsModal from "../../pages/Accounts/components/DetailsModal";
import { useAppSelector } from "../../app/hooks";
import Spinner from "../PopUps/smallSpinner";
import { getKyc } from "../../api";
import { setAdditionalDetails, setKYCState } from "../../features/Kyc/kycSlice";
import { AppDispatch } from "../../app/store";
import { useDispatch } from "react-redux";
import dashIcon from "../../asset/dashboard/usdIcon.svg";
import accountIcon from "../../asset/dashboard/account.svg";
import moreIcon from "../../asset/dashboard/more.svg";
import convertIcon from "../../asset/dashboard/convert.svg";
import MoreAccountModal from "../../pages/Accounts/components/MoreAccountModal";

const AccountCard: React.FC = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  const [showMenu, setShowMenu] = useState(false);
  const allAccount = useAppSelector((state) => state.virtualAccount.allAccount);

  const status = allAccount ? (allAccount as any).VirtualAccountState : "";

  const { allTransfer } = useAppSelector((state) => state.transaction);
  const { KYCState } = useAppSelector((state) => state.kycInfo);
  const KYCIdentifier = localStorage.getItem("KYCIdentifier");
  const [loading, setLoader] = useState(true);

  const kycPendingTransfers = allTransfer.filter(
    (transfer: any) => transfer?.TransactionState === "KYC_PENDING"
  );

  const [nairaAccount] = useState(true);

  const isButtonDisabled = kycPendingTransfers.length > 0;
  const BVNVerified =
    KYCState !== "VERIFIED" && KYCState !== "BUSINESS_VERIFIED";
  const disabledBtn = status === "DEACTIVATED" || BVNVerified;

  const dispatch = useDispatch<AppDispatch>();

  const handleDetails = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  // const [modalPosition, setModalPosition] = useState<{
  //   top: number;
  //   left: number;
  // }>({ top: 0, left: 0 });

  useEffect(() => {
    if (KYCIdentifier) {
      getKyc(KYCIdentifier)
        .then((res) => {
          if (res?.data?.IndividualKyc) {
            const { KYCState, AdditionalDetails } = res.data.IndividualKyc;
            dispatch(setKYCState(KYCState || ""));
            dispatch(setAdditionalDetails(AdditionalDetails || ""));
          } else if (res?.data?.BusinessKyc) {
            const { KYCState, AdditionalDetails } = res.data.BusinessKyc;
            dispatch(setKYCState(KYCState || ""));
            dispatch(setAdditionalDetails(AdditionalDetails || ""));
          } else {
            dispatch(setKYCState(""));
          }
          setLoader(false);
        })
        .catch(() => {
          setLoader(false);
          dispatch(setKYCState(""));
        });
    } else {
      setLoader(false);
    }
  }, []);

  return (
    <>
      {allAccount ? (
        <>
          <div className="bg-white rounded-xl shadow mb-6">
            <div className="relative flex rounded-xl items-center text-white  justify-between bg-[#111111] border h-full md:py-10 py-6">
              <div className="w-[100%] mx-auto z-[10]">
                <ClevaAccount />
              </div>
              <div className="absolute bottom-0 right-0">
                <img src={dashIcon} className="opacity-50" alt="logo" />
              </div>
            </div>
            {/* navigations  */}
            <div className="w-[100%] flex justify-center py-4 md:py-6">
              <div className="flex md:gap-12 gap-4">
                <div className="flex flex-col items-center">
                  <div className="">
                    {/* desktop button  */}
                    <button
                      className={`bg-cleva-gold opacity-${
                        isButtonDisabled || disabledBtn ? "60" : "100"
                      } md:flex items-center justify-center mb-2 md:mb-1 font-medium h-12 w-12 rounded-full shadow hover:shadow-xl hidden`}
                      disabled={isButtonDisabled || disabledBtn}
                      onClick={() => handleDetails()}
                    >
                      <img src={accountIcon} alt="" className="" />
                    </button>

                    {/* //mobile button  */}
                    <button
                      className={`bg-cleva-gold opacity-${
                        isButtonDisabled || disabledBtn || loading
                          ? "60"
                          : "100"
                      } flex items-center justify-center mb-2 md:mb-1 font-medium h-12 w-12 rounded-full shadow hover:shadow-xl md:hidden`}
                      disabled={isButtonDisabled || disabledBtn}
                      onClick={() => navigate("/accounts/account-details")}
                    >
                      <img src={accountIcon} alt="" className="" />
                    </button>
                  </div>

                  <p className="text-center">Account</p>
                </div>

                <div className="flex flex-col items-center">
                  <div className="">
                    <button
                      className={`bg-cleva-gold opacity-${
                        isButtonDisabled || disabledBtn ? "60" : "100"
                      } flex items-center justify-center mb-2 md:mb-1 font-medium h-12 w-12 rounded-full shadow hover:shadow-xl`}
                      disabled={isButtonDisabled || disabledBtn}
                      onClick={() => navigate("/transfers/create")}
                      // reloadDocument
                    >
                      <img src={transferIcon} alt="" className="" />
                    </button>
                  </div>

                  <p className="text-center">Transfer</p>
                </div>

                <div className="flex flex-col items-center">
                  <div className="">
                    <button
                      className={`bg-cleva-gold opacity-30
                      flex items-center justify-center mb-2 md:mb-1 font-medium h-12 w-12 rounded-full shadow hover:shadow-xl`}
                      disabled={isButtonDisabled || disabledBtn || nairaAccount}
                      onClick={() => navigate("/transfers/create")}
                      // reloadDocument
                    >
                      <img src={convertIcon} alt="" className="" />
                    </button>
                  </div>

                  <p className="text-center">Convert</p>
                </div>

                <div className="flex flex-col items-center">
                  <div className="">
                    <button
                      className={`bg-cleva-gold opacity-${
                        isButtonDisabled || disabledBtn ? "60" : "100"
                      } flex items-center justify-center mb-2 md:mb-1 font-medium h-12 w-12 rounded-full shadow hover:shadow-xl`}
                      disabled={isButtonDisabled || disabledBtn}
                      onClick={() => setShowMenu(true)}
                      // reloadDocument
                    >
                      <img src={moreIcon} alt="" className="" />
                    </button>
                  </div>

                  <p className="text-center">More</p>
                </div>

                {/* see more menu */}
                {/* <div className="">
                  <button onClick ={() => setShowMenu(true)} className={`border border-[#8A8A8A] flex items-center justify-center mb-3 md:mb-0 font-medium rounded-lg h-full px-4 shadow-sm w-[100%]`}>
                <BsThreeDotsVertical />
                  </button>
                </div> */}
              </div>
            </div>
          </div>

          {showModal && <DetailsModal closeModal={handleCloseModal} />}

          <MoreAccountModal showMenu={showMenu} setShowMenu={setShowMenu} />
        </>
      ) : (
        <Spinner />
      )}
    </>
  );
};
export default React.memo(AccountCard);
