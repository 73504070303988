const handlePublicProfileChange = (
  event: any,
  dispatch: any,
  setKYCState: any,
  setIsProfileLinkValid: any,
  setIndividualkyc: any,
  IndividualKyc: any
) => {
  dispatch(setKYCState(""));
  const profileLink = event.target.value.trim();
  const urlRegex = /^(ftp|http|https):\/\/(www\.)?[^ "]+$/;
  const isValidUrl = urlRegex.test(profileLink);

  if (!isValidUrl) {
    setIsProfileLinkValid(false);
  } else {
    setIsProfileLinkValid(true);
  }

  dispatch(
    setIndividualkyc({
      ...IndividualKyc,
      [event.target.name]: profileLink,
    })
  );
};

const handleDropdownChange = (
  event: any,
  dispatch: any,
  setKYCState: any,
  setIndividualkyc: any,
  IndividualKyc: any
) => {
  dispatch(setKYCState(""));

  dispatch(
    setIndividualkyc({
      ...IndividualKyc,
      [event.target.name]: event.target.value,
    })
  );
};

export const KYCCheckboxData = [
  {
    value: "Deel, Rippling, or Remote.com",
    label: "Deel, Rippling, or Remote.com",
  },
  { value: "Payoneer", label: "Payoneer" },
  { value: "Fiverr or Upwork", label: "Fiverr or Upwork" },
  { value: "PayPal", label: "PayPal" },
  { value: "Telus International AI", label: "Telus International AI" },
  { value: "DoorDash", label: "DoorDash" },
  { value: "UberEats", label: "UberEats" },
  {
    value: "Survey Platforms (e.g.Verily, Cliqly, e.t.c)",
    label: "Survey Platforms (e.g.Verily, Cliqly, e.t.c)",
  },
  {
    value: "Other platforms (e.g Airbnb, Amazon, e.t.c)",
    label: "Other platforms (e.g Airbnb, Amazon, e.t.c)",
  },
  { value: "Mercury or Brex", label: "Mercury or Brex" },
  {
    value: "Other US banks (e.g  Bank of America, Chase e.t.c)",
    label: "Other US banks (e.g  Bank of America, Chase e.t.c)",
  },
  { value: "Non-US countries", label: "Non-US countries" },
];

export const DeleteAccountData = [
  {
    value: "No longer receiving USD payments",
    label: "No longer receiving USD payments",
  },
  {
    value: "Poor experience with customer support ",
    label: "Poor experience with customer support ",
  },
  {
    value: "Sender was unable to send payments to my Cleva account ",
    label: "Sender was unable to send payments to my Cleva account ",
  },
  {
    value: "Could not link my Cleva account to my freelancer account ",
    label: "Could not link my Cleva account to my freelancer account ",
  },
  {
    value: "The feature I need is not available on Cleva ",
    label: "The feature I need is not available on Cleva ",
  },
  {
    value: "Poor experience with my Cleva card ",
    label: "Poor experience with my Cleva card ",
  },
  {
    value: "Poor experience with transfers from my Cleva account ",
    label: "Poor experience with transfers from my Cleva account ",
  },
  {
    value: "Poor experience with funds I received into my Cleva account",
    label: "Poor experience with funds I received into my Cleva account ",
  },
  { value: "I have security concerns", label: "I have security concerns" },
  { value: "My KYC was not approved ", label: "My KYC was not approved " },
];

export { handlePublicProfileChange, handleDropdownChange };
