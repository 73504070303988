import React from "react";

interface DetailProps {
  details: any;
}
const CardTransferDetails: React.FC<DetailProps> = ({ details }) => {
  const amount = details ? (details as any).NetAmount : " ";

  const fee = details ? (details as any).Fee : "";

  const totalAmount = amount + fee;

  const status = details ? (details as any).Status : " ";

  const type = details ? (details as any).Type : " ";

  const transactionID = details ? (details as any).TransactionID : " ";

  const Merchant = details ? (details as any).MerchantName : " ";

  const Description = details ? (details as any).Description : " ";

  const CreditCard = details ? (details as any).Entry === "CREDIT" : " ";

  const FundingCard = details
    ? (details as any).Type === "FUNDING" && (details as any).Entry === "CREDIT"
    : " ";

  const statusResult =
    status === "COMPLETED" && type === "REFUND" ? (
      <span className="px-3 py-1 my-2 rounded-lg capitalize bg-[#F9F1E7] text-[#B8730A] font-medium text-xs">
        Refunded
      </span>
    ) : status === "COMPLETED" && type === "REVERSAL" ? (
      <span className="px-3 py-1 my-2 rounded-lg capitalize bg-[#F9F1E7] text-[#B8730A] font-medium text-xs">
        Reversed
      </span>
    ) : status === "COMPLETED" ? (
      <span className="px-3 py-1 my-2 rounded-lg capitalize bg-[#DEF7EC] text-[#03543F] font-medium text-xs">
        Completed
      </span>
    ) : status === "PENDING" ? (
      <span className="px-3 py-1 my-2 rounded-lg capitalize bg-[#EBFBFE] text-[#1892D7] font-medium text-xs">
        In Progress
      </span>
    ) : status === "FAILED" && type === "DECLINE" ? (
      <span className="px-3 py-1 my-2 rounded-lg capitalize bg-[#FDE8E8] text-[#9B1C1C] font-medium text-xs">
        Declined
      </span>
    ) : status === "FAILED" ? (
      <span className="px-3 py-1 my-2 rounded-lg capitalize bg-[#FDE8E8] text-[#9B1C1C] font-medium text-xs">
        Failed
      </span>
    ) : (
      <span className=" px-3 py-1 my-2 font-medium capitalize bg-[#FDE8E8] rounded-lg text-[#9B1C1C] text-[10px]">
        {details ? (details as any).Status : " "}
      </span>
    );
  return (
    <>
      {FundingCard ? (
        <div className="mb-3">
          <div className="flex justify-between my-4">
            <p className="text-sm text-[#747A80]">Transaction type</p>
            <p className="text-sm font-medium ml-2 text-[#158025]">Credit</p>
          </div>

          <div className="flex justify-between my-4">
            <p className="text-sm text-[#747A80]">Top up amount</p>
            <p className="text-sm font-medium ml-2">
              ${" "}
              {amount?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </p>
          </div>
          <div className="flex justify-between my-4">
            <p className="text-sm text-[#747A80]">Fee</p>
            <p className="text-sm font-medium ml-2">
              ${" "}
              {fee?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </p>
          </div>
          <div className="flex justify-between my-4">
            <p className="text-sm text-[#747A80]">Total amount</p>
            <p className="text-sm font-medium ml-2">
              {" "}
              ${" "}
              {totalAmount?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </p>
          </div>
          <div className="flex justify-between my-4">
            <p className="text-sm text-[#747A80]">Status</p>
            <p className="text-sm font-medium ml-2">{statusResult}</p>
          </div>
          <div className="flex justify-between items-center">
            <p className="text-sm text-[#6F6F6F] w-1/3">Transaction ID</p>
            <p className="text-sm ml-2 text-right">{transactionID}</p>
          </div>
        </div>
      ) : CreditCard ? (
        <div className="mb-3">
          <div className="flex justify-between my-4">
            <p className="text-sm text-[#747A80]">Transaction type</p>
            <p className="text-sm font-medium ml-2 text-[#158025]">Credit</p>
          </div>

          <div className="flex justify-between my-4">
            <p className="text-sm text-[#747A80]">Top up amount</p>
            <p className="text-sm font-medium ml-2">
              ${" "}
              {amount?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </p>
          </div>
          <div className="flex justify-between my-4">
            <p className="text-sm text-[#747A80]">Fee</p>
            <p className="text-sm font-medium ml-2">
              ${" "}
              {fee?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </p>
          </div>
          <div className="flex justify-between my-4">
            <p className="text-sm text-[#747A80]">Total amount</p>
            <p className="text-sm font-medium ml-2">
              {" "}
              ${" "}
              {totalAmount?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </p>
          </div>
          <div className="flex justify-between my-4">
            <p className="text-sm text-[#747A80]">Status</p>
            <p className="text-sm font-medium ml-2">{statusResult}</p>
          </div>
          <div className="flex justify-between items-center">
            <p className="text-sm text-[#6F6F6F] w-1/3">Transaction ID</p>
            <p className="text-sm ml-2 text-right">{transactionID}</p>
          </div>
        </div>
      ) : (
        <div className="mb-3">
          <div className="flex justify-between my-4">
            <p className="text-sm text-[#747A80]">Transaction type</p>
            <p className="text-sm font-medium ml-2 text-[#D31D1D]">Debit</p>
          </div>
          <div className="flex justify-between my-4">
            <p className="text-sm text-[#747A80]">Merchant</p>
            <p className="text-sm font-medium ml-2">{Merchant}</p>
          </div>
          <div className="flex justify-between my-4">
            <p className="text-sm text-[#747A80]">Amount</p>
            <p className="text-sm font-medium ml-2">
              ${" "}
              {amount?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </p>
          </div>
          <div className="flex justify-between my-4">
            <p className="text-sm text-[#747A80]">Fee</p>
            <p className="text-sm font-medium ml-2">
              ${" "}
              {fee?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </p>
          </div>
          <div className="flex justify-between my-4">
            <p className="text-sm text-[#747A80]">Total amount</p>
            <p className="text-sm font-medium ml-2">
              {" "}
              ${" "}
              {totalAmount?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </p>
          </div>
          <div className="flex justify-between my-4">
            <p className="text-sm text-[#747A80]">Status</p>
            <p className="text-sm font-medium ml-2">{statusResult}</p>
          </div>
          <div className="flex justify-between my-4">
            <p className="text-sm text-[#747A80]">Description</p>
            {/* <p className="text-sm font-medium ml-2 text-right"> {status ===  "COMPLETED" ? "Approved Successfully" : Description }</p> */}
            <p className="text-sm font-medium ml-2 text-right">
              {" "}
              {Description}
            </p>
          </div>
          <div className="flex justify-between items-center">
            <p className="text-sm text-[#6F6F6F] w-1/3">Transaction ID</p>
            <p className="text-sm ml-2 text-right">{transactionID}</p>
          </div>
        </div>
      )}
    </>
  );
};

export default CardTransferDetails;
