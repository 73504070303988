import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import Spinner from "../../components/PopUps/Spinner";
import { setTitle } from "../../features/Nav/NavSlice";
import { fetchAccount } from "../../features/VirtualAccount/VirtualAccountApi";
import CreateAccount from "./CreateAccount";
import ViewAccount from "./ViewAccount";
import { getKyc } from "../../api";
import { setKYCState, setAdditionalDetails } from "../../features/Kyc/kycSlice";
import { AWS_CREDS } from "../../features/services/AmazonService";

export default function Accounts() {
  const dispatch = useAppDispatch();
  const allAccount = useAppSelector(
    (state) => state.virtualAccount?.allAccount
  );
  const loading = useAppSelector((state) => state.virtualAccount.loading);
  const KYCIdentifier = localStorage.getItem("KYCIdentifier");

  const [dataFetched, setDataFetched] = useState(false);

  useEffect(() => {
    dispatch(setTitle("Accounts"));
    // Fetch account data from the API
    dispatch(fetchAccount())
      .then(() => {
        setDataFetched(true);
      })
      .catch((error) => {
        if (AWS_CREDS.STAGE === "qa") {
          console.error("Error fetching account data:", error);
        }
        setDataFetched(true);
      });
    if (KYCIdentifier) {
      getKyc(KYCIdentifier)
        .then((res) => {
          if (res?.data?.IndividualKyc) {
            dispatch(setKYCState(res?.data?.IndividualKyc?.KYCState!));
            dispatch(
              setAdditionalDetails(res?.data?.IndividualKyc?.AdditionalDetails)
            );
          } else if (res?.data?.BusinessKyc) {
            dispatch(setKYCState(res?.data?.BusinessKyc?.KYCState!));
            dispatch(
              setAdditionalDetails(res?.data?.BusinessKyc?.AdditionalDetails)
            );
          } else {
            dispatch(setKYCState(""));
          }
        })
        .catch(() => {
          dispatch(setKYCState(""));
        });
    }
  }, [dispatch]);

  const hasAccounts = allAccount && Object.keys(allAccount).length > 0;
  if (loading && !dataFetched) {
    return <Spinner />;
  }

  return <div>{hasAccounts ? <ViewAccount /> : <CreateAccount />}</div>;
}
