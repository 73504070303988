import React, { useEffect, useState } from "react";
import errorImg from "../../../../asset/svg/material-symbols_warning.svg";
import errIcon from "../../../../images/error-icon.svg";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { UpdatePin } from "../../../../api";
import { getUser, getUserIdWithAccessToken } from "../../../../login";
import { useAppDispatch } from "../../../../app/hooks";
import { Auth } from "aws-amplify";
import { setUser } from "../../../../features/User/UserSlice";
import { AWS_CREDS } from "../../../../features/services/AmazonService";

const ChangePIN = () => {
  const [pin, setPin] = useState("");
  const [confirmPin, setConfirmPin] = useState("");
  const [pinError, setPinError] = useState("");
  const [confirmPinError, setConfirmPinError] = useState("");
  const [formValid, setFormValid] = useState<boolean>(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [showPin, setShowPin] = useState(false);
  const [showConfirmPin, setShowConfirmPin] = useState(false);
  const [isPinValid, setIsPinValid] = useState(true);
  const AppDispatch = useAppDispatch();

  const toggleShowPin = () => {
    setShowPin(!showPin);
  };

  const toggleShowConfirmPin = () => {
    setShowConfirmPin(!showConfirmPin);
  };

  const validatePin = (value: string) => {
    if (value.length !== 4) {
      setPinError("PIN must be 4 digits");
      setIsPinValid(false);
    } else if (!/^[0-9]+$/.test(value)) {
      setPinError("Please enter a valid PIN");
      setIsPinValid(false);
    } else {
      setPinError("");
      setIsPinValid(true);
    }
  };

  const validateConfirmPin = (value: string) => {
    if (value !== pin) {
      setConfirmPinError("PIN and Confirm PIN do not match.");
    } else {
      setConfirmPinError("");
    }
  };

  const handlePinChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPin(value);
    validatePin(value);
  };

  const handleConfirmPinChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    setConfirmPin(value);
    validateConfirmPin(value);
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setLoading(true);
    const userID = await getUserIdWithAccessToken();
    const session = await Auth.currentSession();
    try {
      await UpdatePin(userID, pin)
        .then(() => {
          navigate("/profile/security/success-pin");
        })
        .then(async () => {
          const userDetails = await getUser(
            userID,
            session.getIdToken().getJwtToken()
          );
          AppDispatch(setUser(userDetails));
        });
    } catch (error: any) {
      const userDetails = await getUser(
        userID,
        session.getIdToken().getJwtToken()
      );
      AppDispatch(setUser(userDetails));
      setLoading(false);
      if (AWS_CREDS.STAGE === "qa") {
        console.log(error);
      }
      toast.error(error.message);
    }
  };

  useEffect(() => {
    if (pin && confirmPin) {
      setFormValid(!pinError && !confirmPinError);
    } else {
      setFormValid(false);
    }
  }, [pin, confirmPin, pinError, confirmPinError]);

  return (
    <div className="relative flex-1">
      <div className="flex justify-center pt-8 min-h-[100vh] items-center md:pt-0">
        <div>
          <div className="login-card-md w-full md:w-[32rem] xl:w-[36rem] px-8 py-8 md:px-20 md:py-16">
            <div className="">
              <h2 className="text-center lg:text-left text-2xl text-black-soft">
                Set Transaction PIN
              </h2>
              <p className="text-black text-opacity-90 mt-3">
                A 4-digit PIN to authorize your transactions on Cleva
              </p>

              {/* form section  */}

              <form onSubmit={handleSubmit} className="mt-10">
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-black-soft"
                  >
                    Enter 4-digit PIN
                  </label>
                  <div className="flex mt-2">
                    <input
                      id="pin"
                      name="pin"
                      type={showPin ? "text" : "password"}
                      placeholder="4-digit PIN"
                      value={pin}
                      onChange={handlePinChange}
                      required
                      maxLength={4}
                      className={`${
                        !isPinValid ? "border-[#F15757]" : ""
                      } password-control`}
                      aria-label="4-digit PIN"
                      pattern="[0-9]*" // Only accept numeric values
                      inputMode="numeric"
                    />
                    <button
                      type="button"
                      onClick={toggleShowPin}
                      className="password-button"
                    >
                      {showPin ? <p>Hide</p> : <p>Show</p>}
                    </button>
                  </div>
                  {pinError && (
                    <div className="flex items-center mb-2 mt-1">
                      <img src={errIcon} alt="error" />
                      <p className="text-[#D31D1D] text-sm ml-1">{pinError}</p>
                    </div>
                  )}
                </div>

                <div className="mt-6">
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium text-black-soft mb-2"
                  >
                    Confirm PIN
                  </label>
                  <div className="flex mt-2">
                    <input
                      type={showConfirmPin ? "text" : "password"}
                      id="confirmPin"
                      name="confirmPin"
                      value={confirmPin}
                      maxLength={4}
                      onChange={handleConfirmPinChange}
                      placeholder="Confirm PIN"
                      required
                      className="password-control"
                      aria-label="4-digit PIN"
                      pattern="[0-9]*" // Only accept numeric values
                      inputMode="numeric"
                    />
                    <button
                      type="button"
                      onClick={toggleShowConfirmPin}
                      className="password-button"
                    >
                      {showConfirmPin ? <p>Hide</p> : <p>Show</p>}
                    </button>
                  </div>

                  {/* banner */}
                  {confirmPinError !== "" && (
                    <div
                      className="w-full px-5 flex my-[2.81rem] text-[#800205] font-[500]
                          rounded-lg
                          justify-center text-sm items-center h-[3.75rem] bg-[#FFDCE0] "
                    >
                      <img src={errorImg} className="" alt="error" />
                      <span className="text-[#800205] pl-2  font-normal">
                        {confirmPinError}
                      </span>
                    </div>
                  )}
                </div>

                <div className="mt-7">
                  <button
                    onClick={(e) => handleSubmit(e)}
                    disabled={loading || !formValid}
                    className={
                      !loading && formValid
                        ? "login-active"
                        : " login-disabled "
                    }
                  >
                    {loading ? "Loading ..." : "Submit"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePIN;
