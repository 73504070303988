import React from "react";

interface cardProps {
  number: number;
  mainText: string;
  secText: string;
}

const kycCard: React.FC<cardProps> = ({ number, mainText, secText }) => {
  return (
    <div className="border border-[#DCDCDC] py-4 px-4 rounded-lg mb-3 bg-white">
      <div className="flex">
        <p className="w-[28px] h-[28px] flex items-center justify-center bg-cleva-gold rounded-full border border-neutral-200 text-base mr-5">
          {number}
        </p>
        <div className="w-3/4 xl:w-[90%]">
          <p className="font-bold text-[#212121] mb-1 text-base">{mainText}</p>
          <p className="text-[#747A80] text-sm">{secText}</p>
        </div>
      </div>
    </div>
  );
};

export default kycCard;
