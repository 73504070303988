import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchTiers } from "../services/DashboardServices";
import { AWS_CREDS } from "../services/AmazonService";

export const getTiers = createAsyncThunk(
  "tiers/fetchTiers",
  async (userId: string) => {
    try {
      const response = await fetchTiers(userId);
      return response.data;
    } catch (error) {
      if (AWS_CREDS.STAGE === "qa") {
        console.log(error);
      }
    }
  }
);
