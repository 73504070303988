import { createSlice } from "@reduxjs/toolkit";
import {
  fetchCard,
  fundCard,
  verifyCardBVN,
  fetchCardTransactions,
  fetchCardById,
  createCard,
  freezeCard,
  verifyUserHasBvn,
} from "./ClevaCardsApi";

interface Card {
  Name: string;
  MaskedPan: string;
  Expiry: string;
  Status: string;
  TypeOfCard: string;
  Issuer: string;
  Currency: string;
  Balance: number;
  Address: AddressInterface;
  CreatedAt: string;
  UpdatedAt: string;
  CardIdentifier?: string;
  CardNumber: string;
  CVV: string;
  NoOfDeclines: string;
}

interface CardsInterface {
  CardsSummaryList: Card[];
  Message: string;
  UserIdentifier: string;
}

interface BVNstatus {
  IsBVNRetrievable: boolean;
  Match?: boolean;
  NationalIdentifier?: string;
}
export interface AddressInterface {
  StreetAddress: string;
  City: string;
  StateOrTerritory: string;
  ZipCode: string;
  Country: string;
}

interface CardTransactionInterface {
  allTransfer: any[];
  TransactionID: string;
  status: string;
}
interface CardsState {
  card: Card | null;
  cards: CardsInterface | null;
  loading: boolean;
  error: string | null;
  bvnVerified: BVNstatus | null;
  cardTransaction: CardTransactionInterface | null;
  cardIdentifier: string;
  userNames: string[];
}

const initialState: CardsState = {
  cards: null,
  card: null,
  loading: false,
  error: null,
  bvnVerified: null,
  cardTransaction: null,
  cardIdentifier: "",
  userNames: [],
};

const clevaCardsSlice = createSlice({
  name: "cards",
  initialState,
  reducers: {
    setCardIdentifier(state, action) {
      state.cardIdentifier = action.payload;
    },
    resetBvnVerified(state) {
      state.bvnVerified = null;
      state.cards = null;
    },
    setUserNames(state, action) {
      state.userNames = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(verifyCardBVN.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(verifyCardBVN.fulfilled, (state) => {
        state.loading = false;
        //state.bvnVerified = action.payload
      })
      .addCase(verifyCardBVN.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "cannot create card";
      })
      // check if user's bvn is verified
      .addCase(verifyUserHasBvn.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(verifyUserHasBvn.fulfilled, (state, action) => {
        state.loading = false;
        state.bvnVerified = action.payload;
      })
      .addCase(verifyUserHasBvn.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Error";
      })
      // create card
      .addCase(createCard.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createCard.fulfilled, (state, action) => {
        state.loading = false;
        state.card = action.payload;
      })
      .addCase(createCard.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to verify BVN";
      })
      // fund  card
      .addCase(fundCard.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fundCard.fulfilled, (state, action) => {
        state.loading = false;
        state.cards = action.payload;
      })
      .addCase(fundCard.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to verify BVN";
      })
      // fetch card  associated with user
      .addCase(fetchCard.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCard.fulfilled, (state, action) => {
        state.loading = false;
        state.cards = action.payload;
      })
      .addCase(fetchCard.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to verify BVN";
      })

      // fetch card by Id

      .addCase(fetchCardById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCardById.fulfilled, (state, action) => {
        state.loading = false;
        state.card = action.payload;
      })
      .addCase(fetchCardById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to verify BVN";
      })

      // fetch transactions
      .addCase(fetchCardTransactions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCardTransactions.fulfilled, (state, action) => {
        state.loading = false;
        state.cardTransaction = action.payload;
      })
      .addCase(fetchCardTransactions.rejected, (state, action) => {
        state.loading = false;

        state.error = action.payload as string;
      })

      // freeze card
      .addCase(freezeCard.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(freezeCard.fulfilled, (state) => {
        state.loading = false;
        // state.cards = action.payload
      })
      .addCase(freezeCard.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export const { setCardIdentifier, resetBvnVerified, setUserNames } =
  clevaCardsSlice.actions;
export default clevaCardsSlice.reducer;
