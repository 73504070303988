import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import errIcon from "../../../../images/error-icon.svg";
import errorImg from "../../../../asset/svg/material-symbols_warning.svg";
import { Auth } from "aws-amplify";
import { getUserIdWithAccessToken, getUser } from "../../../../login";
import { ResetPin, UpdatePin, VerifyPin } from "../../../../api";
import { ToastContainer, toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { setUser } from "../../../../features/User/UserSlice";

export const sendOTP = async () => {
  const userID = await getUserIdWithAccessToken();
  try {
    await ResetPin(userID);
  } catch (error: any) {
    toast.error(error.message);
  }
};

const Index = () => {
  const [pin, setPin] = useState("");
  const [newPin, setNewPin] = useState("");
  const [confirmPin, setConfirmPin] = useState("");
  const [pinError, setPinError] = useState("");
  const [newPinError, setNewPinError] = useState("");
  const [invalid, setInvalid] = useState(false);
  const [confirmPinError, setConfirmPinError] = useState("");
  const [formValid, setFormValid] = useState<boolean>(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [showNewPin, setShowNewPin] = useState(false);
  const [showPin, setShowPin] = useState(false);
  const [showConfirmPin, setShowConfirmPin] = useState(false);
  const [isPinValid, setIsPinValid] = useState(true);
  const user = useAppSelector((state) => state.user.userData);
  const AppDispatch = useAppDispatch();

  const toggleShowPin = () => {
    setShowPin(!showPin);
  };

  const toggleShowNewPin = () => {
    setShowNewPin(!showNewPin);
  };

  const toggleShowConfirmPin = () => {
    setShowConfirmPin(!showConfirmPin);
  };

  const validatePin = (value: string) => {
    if (value.length !== 4) {
      setPinError("PIN must be 4 digits");
      setIsPinValid(false);
    } else if (!/^[0-9]+$/.test(value)) {
      setPinError("Please enter a valid 4-digit PIN");
      setIsPinValid(false);
    } else {
      setPinError("");
      setIsPinValid(true);
    }
  };

  const validateNewPin = (value: string) => {
    if (value.length !== 4) {
      setNewPinError("PIN must be 4 digits");
      setIsPinValid(false);
    } else if (!/^[0-9]+$/.test(value)) {
      setNewPinError("Please enter a valid 4-digit PIN");
      setIsPinValid(false);
    } else {
      setNewPinError("");
      setIsPinValid(true);
    }
  };

  const validateConfirmPin = (value: string) => {
    if (value !== newPin) {
      setConfirmPinError("PIN and Confirm PIN do not match.");
    } else {
      setConfirmPinError("");
    }
  };

  const handlePinChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = target;
    setPin(value);
    validatePin(value);
    setInvalid(false);
  };

  const handleNewPinChange = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = target;
    setNewPin(value);
    validateNewPin(value);
  };

  const handleConfirmPinChange = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = target;
    setConfirmPin(value);
    validateConfirmPin(value);
  };

  useEffect(() => {
    if (pin && newPin && confirmPin) {
      setFormValid(!pinError && !newPinError && !confirmPinError);
    } else {
      setFormValid(false);
    }
  }, [pin, newPin, confirmPin, pinError, newPinError, confirmPinError]);

  const handleSubmit = async (event: any) => {
    setLoading(true);
    event.preventDefault();
    const userID = await getUserIdWithAccessToken();
    const session = await Auth.currentSession();
    try {
      await VerifyPin(userID, pin)
        .then(async () => {
          await UpdatePin(userID, newPin).then(async () => {
            navigate("/profile/security/success-pin");
            const userDetails = await getUser(
              userID,
              session.getIdToken().getJwtToken()
            );
            AppDispatch(setUser(userDetails));
          });
        })
        .catch(async (err: any) => {
          setLoading(false);
          if (err?.response?.status === 403) {
            setInvalid(true);
            const userDetails = await getUser(
              userID,
              session.getIdToken().getJwtToken()
            );
            AppDispatch(setUser(userDetails));
            if (!userDetails?.PinRetriesLeft) {
              navigate("/profile/security/verify-otp");
              sendOTP();
            }
          } else {
            toast.error(err.message);
          }
        });
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  return (
    <div className="relative flex-1">
      <div className="flex justify-center pt-8 min-h-[100vh] items-center md:pt-0">
        <div>
          <div className="login-card-md w-full px-8 py-8 md:px-20 md:py-16">
            <div className="">
              <h2 className="text-center lg:text-left text-2xl text-black-soft">
                Change Transaction PIN
              </h2>
              <p className="text-black text-opacity-90 mt-3">
                A 4-digit PIN to authorize your transactions on Cleva
              </p>

              {/* form section  */}

              <form onSubmit={handleSubmit} className="mt-10">
                <div>
                  <div className="flex justify-between">
                    <div>
                      <label
                        htmlFor="pin"
                        className="block text-sm text-black-soft"
                      >
                        Old 4-digit PIN
                      </label>
                    </div>
                    <div>
                      <Link
                        onClick={() => sendOTP()}
                        to="/profile/security/verify-otp"
                        className="text-[#606060] text-sm underline underline-offset-2"
                      >
                        {" "}
                        Forgot PIN?
                      </Link>
                    </div>
                  </div>

                  <div className="flex mt-2">
                    <input
                      id="pin"
                      name="pin"
                      type={showPin ? "text" : "password"}
                      placeholder="4-digit PIN"
                      value={pin}
                      maxLength={4}
                      onChange={handlePinChange}
                      required
                      className={`${
                        !isPinValid ? "border-[#F15757]" : ""
                      } password-control`}
                      aria-label="4-digit PIN"
                      pattern="[0-9]*" // Only accept numeric values
                      inputMode="numeric"
                    />
                    <button
                      type="button"
                      onClick={toggleShowPin}
                      className="password-button"
                    >
                      {showNewPin ? <p>Hide</p> : <p>Show</p>}
                    </button>
                  </div>
                  {pinError && (
                    <div className="flex items-center mb-2 mt-1">
                      <img src={errIcon} alt="error" />
                      <p className="text-[#D31D1D] text-sm ml-1">{pinError}</p>
                    </div>
                  )}

                  {invalid && (
                    <div
                      className="w-full flex font-[500]
                      rounded-lg mt-2 text-sm items-left"
                    >
                      <img src={errorImg} className="" alt="error" />
                      <span className="text-[#000] pl-2  font-normal">
                        Incorrect PIN &nbsp;
                        <span className="text-[#027429] font-semibold">
                          {user?.PinRetriesLeft || 0} retries left
                        </span>
                      </span>
                    </div>
                  )}
                </div>

                <div className="mt-6">
                  <label
                    htmlFor="password"
                    className="block text-sm text-black-soft mb-2"
                  >
                    New PIN
                  </label>
                  <div className="flex mt-2">
                    <input
                      type={showNewPin ? "text" : "password"}
                      id="newPin"
                      name="newPin"
                      value={newPin}
                      maxLength={4}
                      onChange={handleNewPinChange}
                      placeholder="New PIN"
                      required
                      className="password-control"
                      aria-label="4-digit PIN"
                      pattern="[0-9]*" // Only accept numeric values
                      inputMode="numeric"
                    />
                    <button
                      type="button"
                      onClick={toggleShowNewPin}
                      className="password-button"
                    >
                      {showNewPin ? <p>Hide</p> : <p>Show</p>}
                    </button>
                  </div>
                  {newPinError && (
                    <div className="flex items-center mb-2 mt-1">
                      <img src={errIcon} alt="error" />
                      <p className="text-[#D31D1D] text-sm ml-1">
                        {newPinError}
                      </p>
                    </div>
                  )}
                </div>

                <div className="mt-6">
                  <label
                    htmlFor="password"
                    className="block text-sm text-black-soft mb-2"
                  >
                    Confirm PIN
                  </label>
                  <div className="flex mt-2">
                    <input
                      type={showConfirmPin ? "text" : "password"}
                      id="confirmPin"
                      name="confirmPin"
                      value={confirmPin}
                      maxLength={4}
                      onChange={handleConfirmPinChange}
                      placeholder="Confirm PIN"
                      required
                      className="password-control"
                      aria-label="4-digit PIN"
                      pattern="[0-9]*" // Only accept numeric values
                      inputMode="numeric"
                    />
                    <button
                      type="button"
                      onClick={toggleShowConfirmPin}
                      className="password-button"
                    >
                      {showConfirmPin ? <p>Hide</p> : <p>Show</p>}
                    </button>
                  </div>

                  {/* banner */}
                  {confirmPinError !== "" && (
                    <div
                      className="w-full px-5 flex my-[2.81rem] text-[#800205] font-[500]
                          rounded-lg
                          justify-center text-sm items-center h-[3.75rem] bg-[#FFDCE0] "
                    >
                      <img src={errorImg} className="" alt="error" />
                      <span className="text-[#800205] pl-2  font-normal">
                        {confirmPinError}
                      </span>
                    </div>
                  )}
                </div>

                <div className="mt-7">
                  <button
                    onClick={(e) => handleSubmit(e)}
                    disabled={loading || !formValid}
                    className={
                      !loading && formValid
                        ? "login-active"
                        : " login-disabled "
                    }
                  >
                    {loading ? "Loading ..." : "Submit"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
};

export default Index;
