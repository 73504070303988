interface IButton {
  text?: string;
  style?: string;
  fn: () => void;
}

const Button = (props: IButton) => {
  const { text, fn, style } = props;
  return (
    <button
      onClick={fn}
      className={`${style} bg-cleva-gold text-black text-[.8rem] lg:text-[1.125rem] border-cleva-gold font-[700] border-2 py-[1rem] rounded-[0.5rem] transition-all duration-300 hover:bg-[#000] hover:text-cleva-gold hover:border-cleva-gold`}
    >
      {text}
    </button>
  );
};

export default Button;
