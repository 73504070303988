import GoBackComponent from "./utils/GoBackComponent";

const CardDeactivation = () => {
  return (
    <>
      <div className="relative flex-1">
        <div className="flex justify-center pt-10 items-center pb-10">
          <div>
            {/* back btn */}
            <GoBackComponent text="More info" />

            <div className="login-card-md bg-[#FFFFFF] w-full md:w-[38.8125rem] px-8 py-8 md:px-12 md:py-12 shadow rounded-lg mt-10 md:mt-16">
              <div className="text-[#000] text-sm leading-6">
                <h1 className="font-semibold text-base pb-[1.06rem]">
                  Why Card Deactivation Happens:
                </h1>
                <p>
                  When your Cleva Card balance is low, it may lead to declined
                  transactions. To protect you and ensure the security of your
                  account, we would terminate your card after multiple declined
                  transactions.{" "}
                </p>

                <p className="font-semibold py-4 text-base">
                  How to Avoid Card Deactivation:
                </p>

                <ul>
                  <li>
                    1.{" "}
                    <span className="font-medium">
                      Regularly Monitor Your Balance:
                    </span>{" "}
                    Make it a habit to check your card balance regularly. This
                    way, you'll know when it's time to add funds and can prevent
                    any disruptions in your transactions.
                  </li>
                  <br />
                  <li>
                    2.{" "}
                    <span className="font-medium">Frequently Add Funds:</span>{" "}
                    Rather than waiting until your balance is critically low,
                    consider adding funds regularly. This approach keeps your
                    card balance healthy and minimizes the risk of deactivation.
                  </li>
                </ul>

                <p className="pt-4">
                  By following these guidelines and taking proactive steps, you
                  can maintain a sufficient balance on your Cleva Card and enjoy
                  uninterrupted use for your transactions. We're here to support
                  you in managing your account effectively.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardDeactivation;
