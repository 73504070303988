import { ReactNode, useState } from "react";
import logo from "../../asset/landing_photos/cleva-logo.svg";
import { Link, useNavigate } from "react-router-dom";
import Menu from "../../asset/landing_photos/hamburger.svg";
import CloseMenu from "../../asset/landing_photos/close-menu.svg";
import Button from "./Button";
import { usdBuild, cardIcon } from "../../asset/landing_photos";
interface INavbar {
  onLinkClick?: () => void;
  scrollToProducts?: () => void;
  scrollToFAQ?: () => void;
  scrollToBlog?: () => void;
  scrollToRefer?: () => void;
  children?: ReactNode;
  handleMobileNav?: () => void;
  closeProducts?: () => void;
  closeCompany: () => void;
  showProductsMobile?: (event: React.MouseEvent) => void;
  showCompanyMobile: (event: React.MouseEvent) => void;
  showProducts?: (event: React.MouseEvent) => void;
  showCompany?: (event: React.MouseEvent) => void;
  removeDropdowns: (event: React.MouseEvent) => void;
  isMobile?: boolean;
  displayProducts?: boolean;
  displayCompany: boolean;
  productsMobile?: boolean;
  companyMobile: boolean;
}

const Navbar = (props: INavbar) => {
  const {
    isMobile,
    removeDropdowns,
    handleMobileNav,
    closeCompany,
    scrollToFAQ,
    scrollToRefer,
    scrollToBlog,
  } = props;
  const navigate = useNavigate();
  const [activeProduct, setActiveProduct] = useState(false);
  const [activeCompany, setActiveCompany] = useState(false);

  const goToHomepage = () => {
    navigate("/");
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const btnLoginStyle =
    "px-[.5rem] lg:px-[2.25rem] py-[0.5rem] lg:py-[1rem] mr-[.5rem] lg:mr-[1rem] text-white border lg:border-2 border-cleva-gold font-[600] rounded-[0.5rem] text-[.8rem] lg:text-[1.125rem]  transition-all duration-300 hover:text-black hover:bg-cleva-gold";
  const linkStyle =
    "text-white text-base font-normal font-medium leading-6 transition-all duration-300 hover:text-cleva-gold hover:underline";
  const sublinkStyle = "transition-all duration-300 hover:text-cleva-gold ";
  const sublinkColor = "hover:text-cleva-gold ";
  const mobileLink =
    "text-[1.125rem] font-[500] transition-all duration-300 hover:decoration-cleva-gold hover:underline";

  return (
    //  min-h-[20.12813rem]"
    <div className="bg-[#000000]">
      <nav className="fixed top-0 z-50 bg-[#000000] w-full ">
        <div className="flex justify-between items-center h-[100%] mb-[2rem] bg-[#000000] pt-[1.82rem] w-[90%] xl:w-[80%] mx-auto 2xl:w-[1280px]">
          <Link
            onClick={goToHomepage}
            to="#"
            className="font-bold text-2xl lg:text-4xl"
          >
            <img src={logo} alt="logo" />
          </Link>
          <div className="">
            <div className="hidden md:block">
              <ul className="text-[12px] flex flex-row items-center relative">
                <div className="relative">
                  <Link
                    onMouseOver={() => setActiveProduct(true)}
                    onMouseLeave={() => setActiveProduct(false)}
                    className={`${linkStyle} xl:pr-[3.06rem] md:pr-[1rem]`}
                    to=""
                  >
                    Products
                    {activeProduct && (
                      <div className="absolute -left-7 top-7 w-[25rem] xl:w-[27rem] rounded-[1.125rem] bg-white  border-[4px] border-[#ECECEC] p-8">
                        <div className="grid gap-8">
                          <Link
                            to="/usd-account"
                            className={`${sublinkStyle} grid grid-cols-[60px,1fr] gap-4`}
                          >
                            <div>
                              <img src={usdBuild} alt="USD cards" />
                            </div>
                            <div>
                              <p
                                className={`text-lg font-semibold text-black pb-1 ${sublinkColor}`}
                              >
                                USD account{" "}
                              </p>
                              <p className="text-[#262626] font-base text-sm">
                                Get a USD account in your name even as non-us
                                resident.
                              </p>
                            </div>
                          </Link>
                          <Link
                            to="/usd-card"
                            className={`${sublinkStyle}  grid grid-cols-[60px,1fr] gap-4`}
                          >
                            <div>
                              <img src={cardIcon} alt="USD cards" />
                            </div>
                            <div>
                              <p
                                className={`text-lg font-semibold text-black pb-1   ${sublinkColor}`}
                              >
                                {" "}
                                Virtual card
                              </p>
                              <p className="text-[#262626] font-base text-sm">
                                Shop online anywhere, anytime
                              </p>
                            </div>
                          </Link>
                        </div>
                      </div>
                    )}
                  </Link>
                </div>
                <Link
                  onMouseOver={removeDropdowns}
                  onClick={scrollToFAQ}
                  className={`${linkStyle} xl:pr-[2.81rem] md:pr-[1rem]`}
                  to=""
                >
                  FAQs
                </Link>
                <Link
                  onMouseOver={removeDropdowns}
                  onClick={scrollToRefer}
                  className={`${linkStyle} xl:pr-[2.81rem] md:pr-[1rem]`}
                  to=""
                >
                  Referral
                </Link>
                <div className="relative">
                  <div
                    onMouseOver={() => setActiveCompany(true)}
                    onMouseLeave={() => setActiveCompany(false)}
                    className={`${linkStyle} cursor-pointer xl:pr-[3.06rem] md:pr-[1rem]`}
                  >
                    Company
                    {activeCompany && (
                      <div className="absolute -left-7 top-7 rounded-[1.125rem] bg-white  border-[4px] border-[#ECECEC] py-4 px-8">
                        <div className="grid gap-8">
                          <Link
                            to=""
                            onClick={scrollToBlog}
                            className={`${sublinkStyle} text-lg font-semibold text-black`}
                          >
                            Blog
                          </Link>
                          <Link
                            to="/careers"
                            className={`${sublinkStyle}  text-lg font-semibold text-black`}
                          >
                            Careers
                          </Link>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <button
                  data-testid="login-button"
                  className={`${btnLoginStyle} `}
                  onClick={() => navigate("/auth/login")}
                >
                  Login
                </button>
                <Button
                  text="Create an account"
                  fn={() => navigate("/auth/register")}
                  style="px-[.5rem] py-[.5rem] lg:py-[1rem] lg:px-[1.1255rem]"
                />
              </ul>
            </div>
            <div onClick={handleMobileNav} className="">
              <img
                className="cursor-pointer block md:hidden"
                src={isMobile ? CloseMenu : Menu}
                alt={isMobile ? CloseMenu : Menu}
              />
            </div>
          </div>
        </div>
      </nav>
      {isMobile && (
        <div className="md:hidden bg-[#181818] fixed top-20 w-full h-[100%] z-50">
          <ul
            onClick={handleMobileNav}
            className="text-white py-4 px-4 flex flex-col gap-[1.5rem]"
          >
            <div className="relative cursor-pointer">
              <div
                onMouseOver={() => setActiveProduct(true)}
                onMouseLeave={() => setActiveProduct(false)}
                className={`${mobileLink} cursor-pointer`}
              >
                Products
                {activeProduct && (
                  <div className="z-50 absolute rounded-[1.125rem] bg-white  border-[4px] border-[#ECECEC] p-8">
                    <div className="grid gap-8">
                      <Link
                        to="/usd-account"
                        className={`${sublinkStyle} grid grid-cols-[60px,1fr] gap-4`}
                      >
                        <div>
                          <img src={usdBuild} alt="USD cards" />
                        </div>
                        <div>
                          <p
                            className={`text-lg font-semibold text-black pb-1 ${sublinkColor}`}
                          >
                            USD account{" "}
                          </p>
                          <p className="text-[#262626] font-base text-sm">
                            Get a USD account in your name even as non-us
                            resident.
                          </p>
                        </div>
                      </Link>
                      <Link
                        to="/usd-card"
                        className={`${sublinkStyle}  grid grid-cols-[60px,1fr] gap-4`}
                      >
                        <div>
                          <img src={cardIcon} alt="USD cards" />
                        </div>
                        <div>
                          <p
                            className={`text-lg font-semibold text-black pb-1   ${sublinkColor}`}
                          >
                            {" "}
                            Virtual card
                          </p>
                          <p className="text-[#262626] font-base text-sm">
                            Shop online anywhere, anytime
                          </p>
                        </div>
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <Link onClick={scrollToFAQ} to="" className={`${mobileLink}`}>
              FAQs
            </Link>
            <Link onClick={scrollToRefer} to="" className={`${mobileLink}`}>
              Referral
            </Link>
            <div className="relative">
              <Link
                onMouseOver={() => setActiveCompany(true)}
                onMouseLeave={() => setActiveCompany(false)}
                className={`${mobileLink} xl:pr-[3.06rem] md:pr-[1rem]`}
                to=""
              >
                Company
                {activeCompany && (
                  <div className="absolute top-6 rounded-[1.125rem] bg-white  border-[4px] border-[#ECECEC] py-4 px-8">
                    <div className="grid gap-8">
                      <a
                        onClick={closeCompany}
                        target="_blank"
                        rel="no-refferer noreferrer"
                        href="https://www.blog.getcleva.com/"
                        className={`${sublinkStyle} text-lg font-semibold text-black`}
                      >
                        Blog
                      </a>
                      <Link
                        to="/careers"
                        className={`${sublinkStyle}  text-lg font-semibold text-black`}
                      >
                        Careers
                      </Link>
                    </div>
                  </div>
                )}
              </Link>
            </div>
            <li onClick={() => navigate("/auth/login")}>
              <button className="rounded-xl border-2 border-[#787878] py-4 w-full duration-300 hover:text-black hover:bg-[#D8D8D8]">
                Login
              </button>
            </li>
            <li onClick={() => navigate("/auth/register")}>
              <button className="rounded-xl border-2 bg-cleva-gold text-black border-cleva-gold py-4 w-full transition-all duration-300 hover:bg-cleva-gold">
                Create Account
              </button>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default Navbar;
