// ProfileLayout.js

import React from "react";
import { Outlet } from "react-router-dom";
// import ProfileNav from "./ProfileNav";

const ProfileLayout = () => {
  return (
    <div className="">
      {/* Sidebar */}
      {/* <ProfileNav/> */}

      {/* Main content */}
      <main className="">
        <Outlet />
      </main>
    </div>
  );
};

export default ProfileLayout;
