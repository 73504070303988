import { useState, useEffect, ReactNode } from "react";
import { updateDisplayReferralModal } from "../../features/Referrals/ReferralAPI";

interface ICopyToClipboardAlert {
  isOpen: boolean;
  // onClose: () => void;
  children: ReactNode;
  bg: string;
  topS?: string;
  leftS?: string;
  shadow?: string;
}

const InfoModal = ({
  isOpen,
  children,
  bg,
  topS,
  leftS,
  shadow,
}: ICopyToClipboardAlert) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(() => {
    // Initialize modal state from local storage or use the prop value
    const storedModalState = localStorage.getItem("modalState");

    return storedModalState ? JSON.parse(storedModalState) : isOpen;
  });

  useEffect(() => {
    // Save modal state to local storage whenever it changes
    localStorage.setItem("modalState", JSON.stringify(isModalOpen));
  }, [isModalOpen]);

  const closeModal = () => {
    updateDisplayReferralModal();
    setIsModalOpen(false);
  };

  return (
    <>
      {isModalOpen && (
        <div
          onClick={closeModal}
          className={`fixed inset-0 z-50 ${topS} ${leftS} flex items-center justify-center backdrop-filter bg-black bg-opacity-70`}
        >
          <div
            className={`${bg}  ${shadow} rounded-xl w-[90%] mx-auto md:w-[33rem] absolute `}
          >
            <div className="">{children}</div>
          </div>
        </div>
      )}
    </>
  );
};

export default InfoModal;
