import { AWS_CREDS } from "./AmazonService";

const awsConfig = {
  aws_app_analytics: "enable",
  aws_user_pools: "enable",
  aws_user_pools_id: AWS_CREDS.CACHED_BUSINESS_USER_POOL_ID,
  aws_user_pools_mfa_type: "OFF",
  aws_user_pools_web_client_id: AWS_CREDS.CACHED_BUSINESS_USER_POOL_CLIENT_ID,
  aws_user_settings: "enable",
  authenticationFlowType: "USER_PASSWORD_AUTH",
  aws_cognito_region: AWS_CREDS.REGION,
};

export default awsConfig;
