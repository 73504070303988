import React, { useEffect, useState } from "react";
import { setTitle } from "../../../features/Nav/NavSlice";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { ChevronRightIcon } from "@heroicons/react/24/solid";
import ProfileImage from "../../../asset/dashboard/profileImg.svg";
import { GrShieldSecurity } from "react-icons/gr";
import { MdLogout, MdOutlinePrivacyTip } from "react-icons/md";
import { LiaMoneyBillWaveSolid } from "react-icons/lia";
import referIcon from "../../../asset/dashboard/refer.svg";
import dealsIcon from "../../../asset/dashboard/deal.svg";
import limitsIcon from "../../../asset/dashboard/limits.svg";
import { ScaleIcon } from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";
import { getReturningUser } from "../../../login";
import { setUser } from "../../../features/User/UserSlice";
import { AWS_CREDS } from "../../../features/services/AmazonService";
import { Switch } from "@headlessui/react";
import { hideBalance } from "../../../api";
import { IoEyeOffOutline } from "react-icons/io5";

const NewSetting = () => {
  const user = useAppSelector((state) => state.user.userData);
  const BalanceStatus = user?.HideUsdBalance; // Pick from Redux state

  const dispatch = useAppDispatch();
  const bonusPerReferredUser = AWS_CREDS.BONUS_PER_REFERRED_USER || 10;

  const [enabled, setEnabled] = useState<boolean>(BalanceStatus || false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleToggle = async (newValue: boolean) => {
    setEnabled(newValue);

    setIsLoading(true);

    try {
      await hideBalance(newValue);

      const updatedUser = await getReturningUser();
      if (updatedUser) {
        dispatch(setUser(updatedUser));
      }
    } catch (error) {
      setEnabled(!newValue);
      console.error("Failed to update balance visibility", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (BalanceStatus !== undefined) {
      setEnabled(BalanceStatus);
    }
  }, [BalanceStatus]);

  useEffect(() => {
    dispatch(setTitle("Settings"));
  }, [dispatch]);

  return (
    <>
      <section className="md:w-4/5 xl:w-3/4 md:px-4 xl:px-12 mx-auto pt-8">
        <Link
          to="/profile"
          className="border bg-white rounded-xl py-3 md:py-4 md:px-4 flex justify-between items-center px-2 hover:bg-slate-100"
        >
          <div className="flex items-center">
            <div>
              <img src={ProfileImage} alt="" />
            </div>
            <div className="m-[0.75rem]">
              <p className="font-bold text-base">
                {user?.FullName?.FirstName} {user?.FullName?.LastName}
              </p>
              <p className="text-base text-[#676767]">
                {user?.StandardAttributes.Email}
              </p>
            </div>
          </div>
          <div>
            <ChevronRightIcon className="w-6 h-6 text-[#A0A0A0]" />
          </div>
        </Link>

        <section className="my-5">
          <p className="text-base text-[#5F5F5F] mb-1">Security</p>

          <div className="border bg-white rounded-xl py-3 md:py-4 md:px-4 px-2">
            <Link
              to="/profile/security"
              className=" flex justify-between items-center py-4 hover:bg-slate-100 px-1"
            >
              <div className="flex items-center">
                <GrShieldSecurity className="w-5 h-5" />
                <div className="ml-[0.75rem] ">
                  <p className="text-base">Security PIN</p>
                </div>
              </div>
              <ChevronRightIcon className="w-6 h-6 text-[#A0A0A0]" />
            </Link>

            <Link
              to="/profile/tips"
              className="flex justify-between items-center border-y py-4 hover:bg-slate-100 px-1"
            >
              <div className="flex items-center">
                <MdOutlinePrivacyTip className="w-5 h-5" />
                <div className="ml-[0.75rem] ">
                  <p className="text-base">Tips for your account</p>
                </div>
              </div>
              <ChevronRightIcon className="w-6 h-6 text-[#A0A0A0]" />
            </Link>

            <div className="flex justify-between items-center py-4 px-1">
              <div className="flex items-center">
                <IoEyeOffOutline className="w-5 h-5" />
                <div className="ml-[0.75rem] ">
                  <p className="text-base">Hide Balance by default</p>
                </div>
              </div>

              <div>
                <Switch
                  checked={enabled}
                  onChange={handleToggle}
                  disabled={isLoading} // Disable switch while loading
                  className={`${
                    enabled ? "bg-[#077843]" : "bg-gray-200"
                  } group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-green-700 focus:ring-offset-2`}
                >
                  <span className="sr-only">Toggle balance visibility</span>
                  <span
                    aria-hidden="true"
                    className={`${
                      enabled ? "translate-x-5" : "translate-x-0"
                    } pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out`}
                  />
                </Switch>
              </div>
            </div>
          </div>
        </section>

        <section className="my-5">
          <p className="text-base text-[#5F5F5F] mb-1">Finances</p>

          <div className="border bg-white rounded-xl py-3 md:py-4 md:px-4 px-2">
            <Link
              to="/referrals"
              className=" flex justify-between items-center py-4 hover:bg-slate-100 px-1"
            >
              <div className="flex items-center">
                <img src={referIcon} alt="Refer & Earn" />
                <div className="ml-[0.75rem]">
                  <p className="text-base">Refer & Earn</p>
                </div>
              </div>
              <div className="flex items-center">
                <span className="text-[#158025] bg-[#EBFFEE] rounded-full py-1 px-3 font-medium text-sm">
                  ${bonusPerReferredUser} per referral
                </span>
                <ChevronRightIcon className="w-6 h-6 text-[#A0A0A0]" />
              </div>
            </Link>

            <Link
              target="_blank"
              to="https://www.getcleva.com/pricing"
              className="flex justify-between items-center border-y py-4 hover:bg-slate-100 px-1"
            >
              <div className="flex items-center">
                <LiaMoneyBillWaveSolid className="w-5 h-5" />
                <div className="ml-[0.75rem]">
                  <p className="text-base">Our fees</p>
                </div>
              </div>
              <ChevronRightIcon className="w-6 h-6 text-[#A0A0A0]" />
            </Link>

            <Link
              to="/deals"
              className=" flex justify-between border-b items-center py-4 hover:bg-slate-100 px-1"
            >
              <div className="flex items-center">
                <img src={dealsIcon} alt="Deals" />
                <div className="ml-[0.75rem]">
                  <p className="text-base">Deals for you</p>
                </div>
              </div>
              <ChevronRightIcon className="w-6 h-6 text-[#A0A0A0]" />
            </Link>
            <Link
              to="/profile/transaction-limits"
              className=" flex justify-between items-center py-4 hover:bg-slate-100 px-1"
            >
              <div className="flex items-center">
                <img src={limitsIcon} alt="Deals" />
                <div className="ml-[0.75rem]">
                  <p className="text-base">Trasaction Limits</p>
                </div>
              </div>
              <ChevronRightIcon className="w-6 h-6 text-[#A0A0A0]" />
            </Link>
          </div>
        </section>

        <section className="my-5">
          <p className="text-base text-[#5F5F5F] mb-1">Others</p>

          <div className="border bg-white rounded-xl py-3 md:py-4 md:px-4 px-2">
            <Link
              target="_blank"
              to="https://www.getcleva.com/terms-of-service"
              className=" flex justify-between border-b items-center py-4 hover:bg-slate-100 px-1"
            >
              <div className="flex items-center">
                <ScaleIcon className="w-5 h-5" />
                <div className="ml-[0.75rem]">
                  <p className="text-base">Terms of service</p>
                </div>
              </div>
              <ChevronRightIcon className="w-6 h-6 text-[#A0A0A0]" />
            </Link>

            <Link
              target="_blank"
              to="https://www.getcleva.com/privacy-policy"
              className=" flex justify-between border-b items-center py-4 hover:bg-slate-100 px-1"
            >
              <div className="flex items-center">
                <MdOutlinePrivacyTip className="w-5 h-5" />
                <div className="ml-[0.75rem]">
                  <p className="text-base">Privacy Policy</p>
                </div>
              </div>
              <ChevronRightIcon className="w-6 h-6 text-[#A0A0A0]" />
            </Link>

            <div className="border-top py-4 flex justify-between items-center hover:bg-slate-100 px-1 text-[#FF2E00]">
              <div className="flex items-center">
                <MdLogout className="w-5 h-5" />
                <div className="ml-[0.75rem]">
                  <p className="text-base">Logout</p>
                </div>
              </div>

              <ChevronRightIcon className="w-6 h-6 text-[#A0A0A0]" />
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default NewSetting;
