export default function formatDateString(inputDateStr: string) {
  const inputDate = new Date(inputDateStr);

  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const formattedDate = inputDate.toLocaleDateString(undefined, options);

  return formattedDate;
}

export function formatDate(dateString: string) {
  const date = new Date(dateString);

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  return `${month} ${day}, ${year}`;
}

export const getLimitResetHour = () =>
  new Date(
    new Date().setHours((new Date().getTimezoneOffset() / 60) * -1, 0, 0, 0)
  ).toLocaleString("en-US", {
    hour: "numeric",
    hour12: true,
  });
