import React, { useState } from "react";
import { AWS_CREDS } from "../../features/services/AmazonService";

const CloseAccountForm = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    reason: "",
  });
  const [error, setError] = useState("");
  const [isSubmittedSuccessfully, setIsSubmittedSuccessfully] = useState(false);

  const handleChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setError(""); // Reset error message when the user starts typing
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const { firstName, lastName, email, reason } = formData;

    // Basic validation
    if (!firstName || !lastName || !email || !reason) {
      setError("Please fill in all fields.");
      return;
    }
    if (AWS_CREDS.STAGE === "qa") {
      console.log(formData);
    }
    try {
      const response = await fetch(`${AWS_CREDS.CLOSE_ACCOUNT}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      if (AWS_CREDS.STAGE === "qa") {
        console.log(response);
        console.log(formData);
      }
      if (!response.ok) {
        throw new Error("Something went wrong with the submission.");
      }
      setIsSubmittedSuccessfully(true);
      setError("");
    } catch (error: any) {
      setError(error.message);
      setIsSubmittedSuccessfully(false);
    }
  };

  return (
    <div className="bg-white p-4 max-w-md mx-auto my-10 rounded-md shadow">
      {isSubmittedSuccessfully ? (
        <div
          className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4"
          role="alert"
        >
          <p className="font-bold">Success</p>
          <p>Your account deletion request has been submitted successfully.</p>
        </div>
      ) : (
        <div className="p-6 max-w-4xl mx-auto bg-white rounded-lg shadow-md mt-6 font-sans">
          <p className="text-gray-700 text-lg">
            Submit your names and account's email. Cleva Technology Inc. will
            process your request for data deletion. Personal data such as name,
            address, and email will be removed. However, we do not delete your
            transactions data due to auditing and financial compliance
            requirements.
          </p>
          <form onSubmit={handleSubmit} noValidate className="mt-6">
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <input
                  type="text"
                  name="firstName"
                  placeholder="First Name"
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="w-full md:w-1/2 px-3">
                <input
                  type="text"
                  name="lastName"
                  placeholder="Last Name"
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="mb-6">
              <input
                type="email"
                name="email"
                placeholder="Email"
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-6">
              <textarea
                name="reason"
                placeholder="Reason for closing account"
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                value={formData.reason}
                onChange={handleChange}
                required
              ></textarea>
            </div>
            {error && (
              <p className="text-red-500 text-xs italic">
                There was an error. Please try again.
              </p>
            )}
            <button
              type="submit"
              className="bg-black text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Submit
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default CloseAccountForm;
