import axios from "./AxiosInstance";
import { AWS_CREDS } from "./AmazonService";
import { Auth } from "aws-amplify";
import { toast } from "react-toastify";

export type UserTypes = "IndividualUser" | "BusinessUser";
interface RegisterUsers {
  firstName: string;
  lastName: string;
  businessName?: string; //for business users
  email: string;
  password: string;
  country: string;
  phone_number: any;
  referralCode?: string;
  selectedBox: string[];
  userType: UserTypes;
  gender: "MALE" | "FEMALE" | "OTHER";
}

Auth.configure({
  userPoolId: `${AWS_CREDS.CACHED_BUSINESS_USER_POOL_ID}`,
  userPoolWebClientId: `${AWS_CREDS.CACHED_BUSINESS_USER_POOL_CLIENT_ID}`,
});
export class AuthService {
  static CHANNEL = "auth_channel";

  static AUTH_EVENTS = {
    LOGIN: "login",
    SIGN_OUT: "sign_out",
  };

  async createUser(userObj: RegisterUsers) {
    const isBusinessUser = userObj.userType === "BusinessUser";

    try {
      const response = await axios.post(`${AWS_CREDS.APIGATEWAY_URL}/users`, {
        [userObj.userType]: {
          //for business users
          BusinessName: isBusinessUser ? userObj.businessName : null,
          CustomAttributes: {
            BusinessToUsersMappingList: [],
            roles: isBusinessUser
              ? ["BusinessReader", "BusinessAdmin"]
              : ["Personal"],
          },
          FullName: {
            FirstName: userObj.firstName,
            LastName: userObj.lastName,
          },
          Gender: userObj.gender,
          ReferralCode: userObj.referralCode,
          StandardAttributes: {
            Email: userObj.email,
            Password: userObj.password,
            PhoneNumber: userObj.phone_number,
            Address: {
              Country: userObj.country,
            },
            PIN: "0000",
            Birthdate: "0000-00-00",
          },
          ReferralSource: userObj.selectedBox,
        },
      });
      if (AWS_CREDS.STAGE === "qa") {
        console.log(response);
      }
      return false;
    } catch (error) {
      if (AWS_CREDS.STAGE === "qa") {
        console.log(error);
      }

      throw error;
    }
  }

  //testing

  editUser(userObj: any, id: string) {
    return axios.put(`${AWS_CREDS.APIGATEWAY_URL}/users/${id}`, userObj);
  }

  async resendOTP(username: string) {
    try {
      await Auth.resendSignUp(username);
    } catch (error) {
      if (AWS_CREDS.STAGE === "qa") {
        console.error("Error resending OTP:", error);
      }
      toast.error("Failed to resend token");
    }
  }

  async forgotPassword(contact: string) {
    return Auth.forgotPassword(contact);
  }

  async resetPassword(contact: string, otp: string, password: string) {
    return Auth.forgotPasswordSubmit(contact, otp, password);
  }

  async confirmSignUp(contact: string, otp: string) {
    return Auth.confirmSignUp(contact, otp);
  }

  async login(username: string, password: string) {
    const user = await Auth.signIn(username, password);
    return user;
  }

  static loginWithToken = async (token: string) => {
    try {
      const user = await Auth.signIn(token);
      return user;
    } catch (error: any) {
      if (AWS_CREDS.STAGE === "qa") {
        console.error("error signing in with token", error);
      }
    }
  };

  static signOut = async () => {
    try {
      const data = await Auth.signOut();
      if (AWS_CREDS.STAGE === "qa") {
        console.log(data);
      }
    } catch {
      if (AWS_CREDS.STAGE === "qa") {
        console.error("Failed to sign out");
      }
    }
  };

  async getCurrentAccessToken() {
    const session = await Auth.currentSession();
    return session.getAccessToken().getJwtToken();
  }
}

export default new AuthService();
