import React, { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import USIcon from "../../../asset/images/USDD.svg";
import ngnIcon from "../../../images/ngn.svg";
import { XMarkIcon } from "@heroicons/react/24/solid";

type ModalProps = {
  closeModal: () => void;
  onCurrencySelect: (currency: string) => void; // New prop to handle currency selection
  USAmount?: any;
  NGNAmount?: any;
};

const CurrencyModal: React.FC<ModalProps> = ({
  closeModal,
  onCurrencySelect,
  USAmount,
  NGNAmount,
}) => {
  const [open] = useState(true);
  const cancelButtonRef = useRef(null);

  const handleCurrencyClick = (currency: string) => {
    onCurrencySelect(currency); // Pass the selected currency to the parent
  };

  const isNGNDisabled = true;

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={closeModal}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex md:min-h-full min-h-[50vh] items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-2xl bg-white text-center shadow-xl transition-all sm:my-8 sm:w-full w-[28rem] md:max-w-[28rem]">
                <header
                  className={`sticky top-0 bg-[#F6F6F6] z-20  flex justify-between py-3 px-6`}
                >
                  <Dialog.Title
                    as="h3"
                    className="font-semibold text-left text-lg"
                  >
                    Accounts
                  </Dialog.Title>

                  <button
                    type="button"
                    className="rounded-md text-black hover:text-gray-500"
                    onClick={closeModal}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon
                      className="h-6 w-6 font-semibold"
                      aria-hidden="true"
                    />
                  </button>
                </header>

                <div className="text-left">
                  <div className="p-4 overflow-y-auto ">
                    <ul className="">
                      <li
                        onClick={() => handleCurrencyClick("USD")} // USD click handler
                        className=" cursor-pointer hover:bg-slate-100 hover:border-cleva-gold hover:rounded-lg hover:border flex items-center justify-between py-5 px-6"
                      >
                        <div className="flex items-center gap-2">
                          <img src={USIcon} className="h-8 w-8" alt="" />
                          <p className="text-[#111111]">USD account</p>
                        </div>

                        <p className="text-[#171717] text-base font-semibold">
                          $
                          {USAmount.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </p>
                      </li>

                      <li
                        onClick={
                          !isNGNDisabled
                            ? () => handleCurrencyClick("NGN")
                            : undefined
                        } // Disable click if NGN is disabled
                        className={`flex items-center justify-between py-5 px-6 ${
                          isNGNDisabled
                            ? "cursor-not-allowed opacity-50"
                            : "cursor-pointer hover:bg-slate-100"
                        }`}
                      >
                        <div className="flex gap-2 items-center">
                          <img src={ngnIcon} className="h-8 w-8" alt="NGN" />
                          <div>
                            <p className="text-[#111111] mb-0">NGN account</p>
                            <span className=" bg-[#4F8B64] text-white rounded-full px-[0.5rem] py-[0.2rem] text-[.6rem]">
                              Coming Soon
                            </span>
                          </div>
                        </div>

                        <p className="text-[#171717] text-base font-semibold">
                          NGN
                          {NGNAmount.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default CurrencyModal;
