import React from "react";
interface TabButtonProps {
  id: string;
  name: string;
  activeTab: string;
  disabled?: boolean;
  setActiveTab: (id: string) => void;
  onClick?: () => void;
  hasImg?: boolean;
  imgSrc?: string;
}

export function classNames(
  ...classes: Array<string | boolean | undefined | null>
): string {
  return classes.filter(Boolean).join(" ");
}

const TabButtons: React.FC<TabButtonProps> = ({
  id,
  name,
  activeTab,
  setActiveTab,
  disabled,
  onClick,
  hasImg,
  imgSrc,
}) => {
  const handleClick = () => {
    setActiveTab(id);
    if (onClick) {
      onClick();
    }
  };

  return (
    <>
      <div className="">
        <nav className="flex -mb-px space-x-8" aria-label="Tabs">
          <button
            key={id}
            onClick={handleClick}
            disabled={disabled}
            className={classNames(
              activeTab === id
                ? "rounded-lg bg-black  text-white"
                : "tab-child border-transparent text-gray500 hover:text-gray-300 hover:border-gray-300 ",
              "whitespace-nowrap py-2  w-full font-bold text-sm bg-[##EBF0F0]",
              disabled ? "rounded-lg  text-gray-400" : ""
            )}
          >
            <div className="flex justify-center items-center min-[375px]:gap-2">
              {hasImg && <img src={imgSrc} alt="Flag" />}
              {name}
            </div>
          </button>
        </nav>
      </div>
    </>
  );
};

export default TabButtons;
