/* eslint-disable react-hooks/rules-of-hooks */
import axios from "./features/services/AxiosInstance";
import { AWS_CREDS } from "./features/services/AmazonService";

interface IDetails {
  DisplayName?: string;
  KYCIdentifier: string;
}

export const createKyc = async (Payload: any) => {
  return await axios
    .post(`${AWS_CREDS.APIGATEWAY_URL}/kyc`, JSON.stringify(Payload))
    .then((response: any) => {
      return response;
    });
};

export const getKyc = async (KYCIdentifier: any) => {
  if (KYCIdentifier !== null) {
    return await axios
      .get(`${AWS_CREDS.APIGATEWAY_URL}/kyc/${KYCIdentifier}`)
      .then((response: any) => {
        return response;
      });
  }
};

export const retryKYC = async () => {
  try {
    const response = await axios.patch(`${AWS_CREDS.APIGATEWAY_URL}/kyc/retry`);
    console.log(response);
    return response;
  } catch (error) {
    console.error("Error retrying KYC:", error);
    throw error;
  }
};

export const createController = async (Payload: any) => {
  return await axios
    .patch(
      `${AWS_CREDS.APIGATEWAY_URL}/business-controllers/`,
      JSON.stringify(Payload)
    )
    .then((response: any) => {
      return response;
    });
};

export const createOwner = async (Payload: any) => {
  return await axios
    .patch(
      `${AWS_CREDS.APIGATEWAY_URL}/business-owners/`,
      JSON.stringify(Payload)
    )
    .then((response: any) => {
      return response;
    });
};

export const getOwners = async () => {
  return await axios
    .get(`${AWS_CREDS.APIGATEWAY_URL}/business-owners/`)
    .then((response: any) => {
      return response;
    });
};

export const getOwner = async (KYCIdentifier: any) => {
  if (KYCIdentifier !== null) {
    return await axios
      .get(`${AWS_CREDS.APIGATEWAY_URL}/business-owners/${KYCIdentifier}`)
      .then((response: any) => {
        return response;
      });
  }
};

export const getControllers = async () => {
  return await axios
    .get(`${AWS_CREDS.APIGATEWAY_URL}/business-controllers`)
    .then((response: any) => {
      return response;
    });
};

export const getController = async (KYCIdentifier: any) => {
  if (KYCIdentifier !== null) {
    return await axios
      .get(`${AWS_CREDS.APIGATEWAY_URL}/business-controllers/${KYCIdentifier}`)
      .then((response: any) => {
        return response;
      });
  }
};

export const updateKyc = async (KYCIdentifier: string, Payload: any) => {
  if (KYCIdentifier) {
    return await axios
      .put(
        `${AWS_CREDS.APIGATEWAY_URL}/kyc/${KYCIdentifier}`,
        JSON.stringify(Payload)
      )
      .then((response: any) => {
        return response;
      });
  }
};

export const deleteOwner = async (KYCIdentifier: string) => {
  if (KYCIdentifier) {
    return await axios
      .delete(`${AWS_CREDS.APIGATEWAY_URL}/business-owners/${KYCIdentifier}`)
      .then((response: any) => {
        return response;
      });
  }
};

export const completeIDCheck = async (Payload: any) => {
  //{{baseUrl}}/api/v1/business-owners/complete-id-check
  return await axios
    .patch(
      `${AWS_CREDS.APIGATEWAY_URL}/business-owners/complete-id-check`,
      JSON.stringify(Payload)
    )
    .then((response: any) => {
      return response;
    });
};

export const controllerIDCheck = async (Payload: any) => {
  return await axios
    .patch(
      `${AWS_CREDS.APIGATEWAY_URL}/business-controllers/complete-id-check`,
      JSON.stringify(Payload)
    )
    .then((response: any) => {
      return response;
    });
};
export const PeopleUploads = async (Payload: any) => {
  return await axios
    .patch(
      `${AWS_CREDS.APIGATEWAY_URL}/kyc/complete-people-uploads/`,
      JSON.stringify(Payload)
    )
    .then((response: any) => {
      return response;
    });
};

export const SendNotificationRequest = async ({
  KYCIdentifier,
  DisplayName,
}: IDetails) => {
  if (KYCIdentifier) {
    const payload = {
      Url: `https://${window.location.hostname}/selfie/`,
      KYCIdentifier,
      ServiceType: "KYC",
      TriggerSource: "kyc_selfie",
      Details: {
        DisplayName,
      },
    };
    return await axios.post(
      `${AWS_CREDS.APIGATEWAY_URL}/notifications`,
      JSON.stringify(payload)
    );
  }
};

export const GetAnnouncementsRequest = async () => {
  return await axios.get(`${AWS_CREDS.APIGATEWAY_URL}/announcements`);
};

export const UpdatePin = async (Identifier: string, SecurityKey: string) => {
  if (Identifier) {
    const payload = {
      Identifier,
      ActionType: "TRANSACTION_PIN",
      SecurityKey,
    };
    return await axios.patch(
      `${AWS_CREDS.APIGATEWAY_URL}/users/${Identifier}`,
      JSON.stringify(payload)
    );
  }
};

export const VerifyPin = async (Identifier: string, SecurityKey: string) => {
  if (Identifier) {
    const payload = {
      Identifier,
      ActionType: "VERIFY_PIN",
      SecurityKey,
    };
    return await axios.post(
      `${AWS_CREDS.APIGATEWAY_URL}/security`,
      JSON.stringify(payload)
    );
  }
};

export const ValidatePin = async (Identifier: string, SecurityKey: string) => {
  if (Identifier) {
    const payload = {
      Identifier,
      ActionType: "VALIDATE_PIN",
      SecurityKey,
    };
    return await axios.post(
      `${AWS_CREDS.APIGATEWAY_URL}/security`,
      JSON.stringify(payload)
    );
  }
};

export const VerifyOTP = async (Identifier: string, SecurityKey: string) => {
  if (Identifier) {
    const payload = {
      Identifier,
      ActionType: "VERIFY_OTP",
      SecurityKey,
    };
    return await axios.post(
      `${AWS_CREDS.APIGATEWAY_URL}/security`,
      JSON.stringify(payload)
    );
  }
};

export const ValidateOTP = async (Identifier: string, SecurityKey: string) => {
  if (Identifier) {
    const payload = {
      Identifier,
      ActionType: "VALIDATE_OTP",
      SecurityKey,
    };
    return await axios.post(
      `${AWS_CREDS.APIGATEWAY_URL}/security`,
      JSON.stringify(payload)
    );
  }
};

export const ResetPin = async (Identifier: string) => {
  if (Identifier) {
    const payload = {
      Identifier,
      ActionType: "RESET_PIN",
    };
    return await axios.post(
      `${AWS_CREDS.APIGATEWAY_URL}/security`,
      JSON.stringify(payload)
    );
  }
};

export const DeleteAccount = async (Identifier: string) => {
  if (Identifier) {
    const payload = {
      Identifier,
      ActionType: "DELETE_ACCOUNT",
    };
    return await axios.post(
      `${AWS_CREDS.APIGATEWAY_URL}/security`,
      JSON.stringify(payload)
    );
  }
};

//using this file instead of dashboard services just to keep user related api calls together ...

export const DeleteUser = async (Identifier: string, reasons: string[]) => {
  return await axios.delete(`${AWS_CREDS.APIGATEWAY_URL}/data/${Identifier}`, {
    params: { DeletionReasons: reasons.join(",") }, // send reasons as a comma-separated string
  });
};

export const createTag = async (Payload: any) => {
  return await axios
    .post(`${AWS_CREDS.APIGATEWAY_URL}/tags`, JSON.stringify(Payload))
    .then((response: any) => {
      return response;
    });
};

export const TagRecipient = async (Payload: any) => {
  return await axios
    .post(`${AWS_CREDS.APIGATEWAY_URL}/recipients`, JSON.stringify(Payload))
    .then((response: any) => {
      return response;
    });
};

export const CreateTagTransfer = async (Payload: any) => {
  return await axios
    .post(`${AWS_CREDS.APIGATEWAY_URL}/transactions`, JSON.stringify(Payload))
    .then((response: any) => {
      return response;
    });
};

export const updateTag = async (userIdentifier: string, Payload: any) => {
  if (userIdentifier) {
    return await axios
      .put(
        `${AWS_CREDS.APIGATEWAY_URL}/tags/${userIdentifier}`,
        JSON.stringify(Payload)
      )
      .then((response: any) => {
        return response;
      });
  }
};

export const getTag = async (userIdentifier: string) => {
  if (userIdentifier) {
    return await axios
      .get(`${AWS_CREDS.APIGATEWAY_URL}/tags`)
      .then((response: any) => {
        return response;
      });
  }
};

export const searchTag = async (params: { query?: string }) => {
  return await axios
    .get(`${AWS_CREDS.APIGATEWAY_URL}/tags/search`, { params })
    .then((response: any) => {
      return response;
    });
};

export const checkTag = async (Payload: any) => {
  return await axios
    .post(`${AWS_CREDS.APIGATEWAY_URL}/tags/check`, JSON.stringify(Payload))
    .then((response: any) => {
      return response;
    });
};

export const GetVeriffURL = async (Payload: any) => {
  return await axios
    .post(`${AWS_CREDS.APIGATEWAY_URL}/sessions`, JSON.stringify(Payload))
    .then((response: any) => {
      return response;
    });
};

export const checkNIN = async (kycIdentifier: string, ninNumber: string) => {
  if (kycIdentifier) {
    const payload = {
      IdType: "NIN",
      IdNumber: ninNumber,
      IdCountry: "Nigeria",
      KycIdentifier: kycIdentifier,
      // "PersonType": "" // optional, for business persons
    };
    return await axios
      .patch(
        AWS_CREDS.APIGATEWAY_URL + `/kyc/id-check`,
        JSON.stringify(payload)
      )
      .then((response: any) => {
        return response;
      });
  }
};

export const resetNIN = async (kycIdentifier: string) => {
  if (kycIdentifier) {
    const payload = {
      DocType: "NIN",
      KycIdentifier: kycIdentifier,
      // "PersonType": "" // optional, for business persons
    };
    return await axios
      .patch(AWS_CREDS.APIGATEWAY_URL + `/kyc`, JSON.stringify(payload))
      .then((response: any) => {
        return response;
      });
  }
};

export const checkNIINDocument = async (
  imageString: string | ArrayBuffer | null,
  extensionType: string
) => {
  const payload = {
    ExtensionType: extensionType,
    DocType: "NIN",
    ImageAsString: imageString,
  };
  return await axios
    .post(AWS_CREDS.APIGATEWAY_URL + `/doc-types`, JSON.stringify(payload))
    .then((response: any) => {
      return response;
    });
};

export const hideBalance = async (hideBalance: boolean) => {
  return await axios.put(AWS_CREDS.APIGATEWAY_URL + `/user-app-settings`, {
    HideUSDBalance: hideBalance,
  });
};
