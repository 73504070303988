import { useState, useEffect, ReactNode } from "react";
import classNames from "classnames"; // Import the classNames library

interface ICopyToClipboardAlert {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  bg: string;
  topS?: string;
  leftS?: string;
  shadow?: string;
}

const AlertModal = ({
  isOpen,
  onClose,
  children,
  bg,
  topS,
  leftS,
  shadow,
}: ICopyToClipboardAlert) => {
  const [isModalOpen, setIsModalOpen] = useState(isOpen);

  useEffect(() => {
    setIsModalOpen(isOpen);

    if (isOpen) {
      const timer = setTimeout(() => {
        setIsModalOpen(false);
        onClose();
      }, 10000); // 2 seconds

      return () => {
        clearTimeout(timer);
      };
    }
  }, [isOpen, onClose]);

  const closeModal = () => {
    setIsModalOpen(false);
    if (onClose) {
      onClose();
    }
  };

  const progressClass = classNames("progress-bar h-[2rem]", {
    "progress-bar-active": isModalOpen,
  });

  return (
    <>
      {isModalOpen && (
        // backdrop-filter bg-black bg-opacity-70
        <div
          onClick={closeModal}
          className={`fixed inset-0 z-50 ${topS} ${leftS} flex items-center justify-center`}
        >
          <div
            className={`${bg}  ${shadow} mx-auto w-max-content md:w-[28rem] border-[0.5px] border-[#E3E3E3] rounded-2xl`}
          >
            <div className="p-4">{children}</div>
            <div className={progressClass}></div>
          </div>
        </div>
      )}
    </>
  );
};

export default AlertModal;
