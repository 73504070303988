import Modal from "../../../components/PopUps/Modal";
import Button from "../../../components/Layout/buttons/Button";
import { useAppDispatch } from "../../../app/hooks";
import { setModalState } from "../../../features/Kyc/kycSlice";
import axios from "../../../features/services/AxiosInstance";
import { toast } from "react-toastify";
import { AWS_CREDS } from "../../../features/services/AmazonService";
import { fetchRecipients } from "../../../features/Recipients/RecipientsApi";

export default function DeleteRecipientModal({
  RecipientIdentifier,
  RecipientName,
  RecipientTag,
  closeModal,
}: any) {
  const dispatch = useAppDispatch();
  const deletionText = `Are you sure you want to remove ${RecipientName} ${RecipientTag && `(@${RecipientTag}) as recipient`}?`;

  function handleClose() {
    closeModal();
    dispatch(setModalState(false));
  }

  const handleDeleteRecipients = () => {
    axios
      .delete(`${AWS_CREDS.APIGATEWAY_URL}/recipients/${RecipientIdentifier}`)
      .then(() => toast.success(`Recipient deleted successfully`))
      .catch((error) => {
        if (AWS_CREDS.STAGE === "qa") {
          console.log(error);
        }
        toast.error(`Operation failed!`);
      });
  };

  function deteleRecipient() {
    handleDeleteRecipients();
    setTimeout(() => {
      dispatch(setModalState(false));
      dispatch(fetchRecipients());
    }, 1000);
  }

  return (
    <Modal
      height="h-64"
      width=""
      header="Remove Recipient"
      closeModal={handleClose}
    >
      <div className="text-sm pt-10 ps-10 w-[67%]">{deletionText}</div>

      <div className="px-10 flex justify-end gap-3 mt-2">
        <Button
          fn={() => {
            handleClose();
          }}
          status={false}
          styles="text-[12px]
          font-bold py-[10px] px-[8%]
          ${btn_bg}
          float-right
          rounded-md mt-4
          bg-[#FFF5D9]"
          text="Cancel"
        />

        <Button
          fn={deteleRecipient}
          status={false}
          styles="text-[12px]
          font-bold py-[10px] px-[8%]
          ${btn_bg}
          float-right
          rounded-md mt-4
          bg-[#FFBD59]"
          text="Delete"
        />
      </div>
    </Modal>
  );
}
