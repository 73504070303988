import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import Spinner from "../../../components/PopUps/Spinner";
import ProgressBar from "../../../utils/ProgressBar";
import { getTiers } from "../../../features/User/UserApi";
import {
  formatNumberThousands,
  TierType,
  TierTypeImage,
  useIsBusinessUser,
} from "../../../utils";
import { FaAngleDown, FaAngleUp } from "react-icons/fa6";
import { setTitle } from "../../../features/Nav/NavSlice";
import BackButton from "../../../components/Buttons/BackButton";
import { fetchAccount } from "../../../features/VirtualAccount/VirtualAccountApi";

const ProfileTransactionLimits = () => {
  const { KYCState } = useAppSelector((state) => state.kycInfo);
  const [activeTab, setActiveTab] = useState("transfer");
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user);
  const [accordionActive, setAccordionActive] = useState("");
  const allAccount = useAppSelector((state) => state.virtualAccount.allAccount);
  const depositLimit = allAccount ? (allAccount as any).Limit?.Total : " ";
  const componentRef = useRef<HTMLElement | null>(null);
  const isBusinessUser = useIsBusinessUser();

  useEffect(() => {
    dispatch(setTitle("Finances"));
    dispatch(fetchAccount());
    if (isBusinessUser) {
      const businessIdentifier = user?.userData?.BusinessIdentifier;
      if (businessIdentifier) {
        dispatch(getTiers(businessIdentifier));
      }
    } else {
      const clevaUserID = user?.userData?.ClevaUserID;
      if (clevaUserID) {
        dispatch(getTiers(clevaUserID));
      }
    }
  }, [
    dispatch,
    isBusinessUser,
    user?.userData?.BusinessIdentifier,
    user?.userData?.ClevaUserID,
  ]);

  useEffect(() => {
    if (componentRef.current) {
      componentRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, []);

  const showTransfer = () => {
    setActiveTab("transfer");
  };

  const showDeposit = () => {
    setActiveTab("deposit");
  };

  const showCard = () => {
    setActiveTab("card");
  };

  const toggleAccordion = (key: string) => {
    if (accordionActive === key) {
      setAccordionActive("");
    } else {
      setAccordionActive(key);
    }
  };

  if (isBusinessUser === null || KYCState === null || user.userTier === null) {
    return <Spinner />;
  }
  const limits = user.userTier?.Limits?.USD || null;

  if (KYCState !== "VERIFIED" && KYCState !== "BUSINESS_VERIFIED") {
    return (
      <section className="mx-auto md:w-11/12 xl:w-9/12">
        <div className="flex pt-10 pb-14 items-center">
          <BackButton />
          <p className="text-lg font-bold ml-3">Transaction limits</p>
        </div>
        <p className="text-[#000000] text-center font-bas md:w-[539px] mx-auto">
          Impacts all transfers from your USD account including bank transfers,
          C2C transfers, conversions, and card funding.
        </p>

        <div className="mt-8 md:mt-20 flex justify-center items-center text-center rounded-2xl md:w-[539px] mx-auto">
          <div className="bg-white p-8 md:py-16 md:px-8">
            <p className="text-lg text-[#000000] font-semibold">
              Your KYC needs to be verified before you can view your transaction
              limits.
            </p>
          </div>
        </div>
      </section>
    );
  }

  return (
    <section ref={componentRef} className=" mx-auto md:w-11/12 xl:w-9/12">
      <div className="flex pt-10 pb-14 items-center">
        <BackButton />
        <p className="text-lg font-bold ml-3">Transaction limits</p>
      </div>
      <>
        <section className="flex items-center justify-center">
          <div className="md:w-[539px]">
            {/* min-[375px]:w-[20rem] md:w-[25rem] */}
            <div className="bg-[#fff] rounded-md text-[#000000] flex items-center justify-between md:w-[21rem] mx-auto p-2 ">
              <>
                <button
                  onClick={() => showTransfer()}
                  className={`${
                    activeTab === "transfer"
                      ? "bg-black text-white"
                      : "bg-transparent text-[#000000]"
                  } md:px-2 py-2 w-2/4 text-sm rounded`}
                >
                  Transfers
                </button>
              </>
              <button
                onClick={() => showDeposit()}
                className={`${
                  activeTab === "deposit"
                    ? "bg-black text-white"
                    : "bg-transparent text-[#000000]"
                } md:px-2 py-2 w-2/4 text-sm rounded`}
              >
                Deposits
              </button>
              <button
                onClick={() => showCard()}
                className={`${
                  activeTab === "card"
                    ? "bg-black text-white"
                    : "bg-transparent text-[#000000]"
                } md:px-2 py-2 w-2/4 text-sm rounded`}
              >
                Card funding
              </button>
            </div>

            <div className="pt-6 pb-9">
              {activeTab === "transfer" ? (
                <p className="text-[#000000] text-center text-sm">
                  Impacts all transfers from your USD account including bank
                  transfers, C2C transfers, conversions, and card funding.
                </p>
              ) : activeTab === "deposit" ? (
                <p className="text-[#000000] text-center text-sm">
                  The amount you can receive into your Cleva USD account.
                </p>
              ) : activeTab === "card" ? (
                <p className="text-[#000000] text-center text-sm">
                  The amount you can top-up on your card.
                </p>
              ) : null}
            </div>

            {user.userTier.Tier === "CUSTOM" && (
              <div className="mt-5 p-4 bg-[#fff] rounded-md text-[#000000] ">
                <div className="flex flex-col p-2 gap-2">
                  <div className="flex items-center justify-start gap-2">
                    {/* <img src={TierTypeImage(user.userTier.Tier)} alt="Account tier" /> */}
                    <p className={`font-medium}`}>
                      {TierType(user.userTier.Tier)}
                    </p>
                    <button className="bg-[#FFF5EA] border border-[#FFE9D0] px-2 text-xs text-[#D07F03] rounded-2xl">
                      current
                    </button>
                  </div>
                  <>
                    {activeTab !== "deposit" && (
                      <div className="border p-3 flex rounded-md items-center justify-between ">
                        <p className="text-[#000000] text-sm ">
                          Per transaction limit
                        </p>
                        <p className="text-sm">
                          $
                          {formatNumberThousands(
                            limits?.perTransactionLimitValue
                          )}
                        </p>
                      </div>
                    )}
                    {/* card funding daily limit */}
                    {activeTab === "card" && (
                      <LimitsFrequence
                        frequency="Daily limit"
                        amtSpent={
                          limits?.cardFunding?.cardFundingDailyVolume || 0
                        }
                        totalAmt={
                          limits?.cardFunding?.cardFundingDailyLimitValue || 0
                        }
                        resetInfo="Resets at 1 AM your time"
                        current={true}
                      />
                    )}
                    {activeTab === "transfer" && (
                      <>
                        <LimitsFrequence
                          frequency="Daily limit"
                          amtSpent={limits?.daily?.dailyVolume || 0}
                          totalAmt={limits?.daily?.dailyLimitValue || 0}
                          resetInfo="Resets at 1 AM your time"
                          current={true}
                        />
                        {/* weekly limit */}
                        <LimitsFrequence
                          frequency="Weekly limit"
                          amtSpent={limits?.weekly?.weeklyVolume || 0}
                          totalAmt={limits?.weekly?.weeklyLimitValue || 0}
                          resetInfo="Resets on Monday at 1 AM your time"
                          current={true}
                        />
                        {/* monthly limit */}
                        <LimitsFrequence
                          frequency="Monthly limit"
                          amtSpent={limits?.monthly?.monthlyVolume || 0}
                          totalAmt={limits?.monthly?.monthlyLimitValue || 0}
                          resetInfo="Resets on the 1st of each month"
                          current={true}
                        />
                      </>
                    )}
                    {activeTab === "deposit" && (
                      <>
                        <LimitsFrequence
                          frequency="Maximum deposit"
                          amtSpent={0}
                          totalAmt={depositLimit || 0}
                          resetInfo={null}
                          text="Amounts above this require additional verification before they can be processed."
                          current={false}
                        />
                      </>
                    )}
                  </>
                </div>
              </div>
            )}

            {/* display tiers min-[375px]:w-[20rem] md:w-[25rem]*/}
            {Object.entries(user?.userTier?.TiersInfo).map(([key, value]) => (
              <div
                key={key}
                className={`${
                  key === user?.userTier.Tier ||
                  (accordionActive === key && activeTab !== "deposit")
                    ? "py-4"
                    : ""
                } px-4 mt-5 bg-[#fff] rounded-md text-[#000000]  `}
              >
                {key === user?.userTier.Tier ? (
                  <div
                    key={user?.userTier.Tier}
                    className="flex flex-col p-2 gap-2"
                  >
                    <div className="flex items-center justify-start gap-2">
                      <img src={TierTypeImage(key)} alt="Account tier" />
                      <p
                        className={`${
                          key === user?.userTier.Tier
                            ? "font-medium"
                            : "font-normal"
                        }`}
                      >
                        {TierType(key)}
                      </p>
                      <button className="bg-[#FFF5EA] border border-[#FFE9D0] px-2 text-xs text-[#D07F03] rounded-2xl">
                        current
                      </button>
                    </div>
                    <>
                      {activeTab !== "deposit" && (
                        <div className="border p-3 flex rounded-md items-center justify-between ">
                          <p className="text-[#000000] text-sm ">
                            Per transaction limit
                          </p>
                          <p className="text-sm">
                            $
                            {formatNumberThousands(
                              limits?.perTransactionLimitValue
                            )}
                          </p>
                        </div>
                      )}
                      {/* card funding daily limit */}
                      {activeTab === "card" && (
                        <LimitsFrequence
                          frequency="Daily limit"
                          amtSpent={
                            limits?.cardFunding?.cardFundingDailyVolume || 0
                          }
                          totalAmt={
                            limits?.cardFunding?.cardFundingDailyLimitValue || 0
                          }
                          resetInfo="Resets at 1 AM your time"
                          current={true}
                        />
                      )}
                      {activeTab === "transfer" && (
                        <>
                          <LimitsFrequence
                            frequency="Daily limit"
                            amtSpent={limits?.daily?.dailyVolume || 0}
                            totalAmt={limits?.daily?.dailyLimitValue || 0}
                            resetInfo="Resets at 1 AM your time"
                            current={true}
                          />
                          {/* weekly limit */}
                          <LimitsFrequence
                            frequency="Weekly limit"
                            amtSpent={limits?.weekly?.weeklyVolume || 0}
                            totalAmt={limits?.weekly?.weeklyLimitValue || 0}
                            resetInfo="Resets on Monday at 1 AM your time"
                            current={true}
                          />
                          {/* monthly limit */}
                          <LimitsFrequence
                            frequency="Monthly limit"
                            amtSpent={limits?.monthly?.monthlyVolume || 0}
                            totalAmt={limits?.monthly?.monthlyLimitValue || 0}
                            resetInfo="Resets on the 1st of each month"
                            current={true}
                          />
                        </>
                      )}
                      {activeTab === "deposit" && (
                        <>
                          <LimitsFrequence
                            frequency="Maximum deposit"
                            amtSpent={0}
                            totalAmt={depositLimit || 0}
                            resetInfo={null}
                            text="Amounts above this require additional verification before they can be processed."
                            current={false}
                          />
                        </>
                      )}
                    </>
                  </div>
                ) : (
                  activeTab !== "deposit" && (
                    <div
                      key={user?.userTier.Tier}
                      className="flex flex-col p-2 gap-2"
                    >
                      <div
                        onClick={() => toggleAccordion(key)}
                        className="cursor-pointer flex items-center justify-between"
                      >
                        <div className="flex items-center justify-start gap-2">
                          <img src={TierTypeImage(key)} alt="Account tier" />
                          <p>{TierType(key)}</p>
                        </div>
                        <button onClick={() => toggleAccordion(key)}>
                          {accordionActive === key ? (
                            <FaAngleUp className="text-[#959595]" />
                          ) : (
                            <FaAngleDown className="text-[#959595]" />
                          )}
                        </button>
                      </div>
                      {accordionActive === key && (
                        <>
                          <div className="border p-3 flex rounded-md items-center justify-between ">
                            <p className="text-[#000000] text-sm ">
                              Per transaction limit
                            </p>
                            <p className="text-sm">
                              $
                              {formatNumberThousands(
                                value?.Limits?.USD?.perTransaction
                              )}
                            </p>
                          </div>

                          {/* card funding daily limit */}
                          {activeTab === "card" && (
                            <LimitsFrequence
                              frequency="Daily limit"
                              amtSpent={
                                value?.Limits?.USD
                                  ?.cardFundingDailyLimitValue || 0
                              }
                              totalAmt={
                                value?.Limits?.USD
                                  ?.cardFundingDailyLimitValue || 0
                              }
                              resetInfo={null}
                              current={false}
                            />
                          )}
                          {activeTab === "transfer" && (
                            <>
                              {/* daily limit */}
                              <LimitsFrequence
                                frequency="Daily limit"
                                amtSpent={
                                  value?.Limits?.USD?.dailyLimitValue || 0
                                }
                                totalAmt={
                                  value?.Limits?.USD?.dailyLimitValue || 0
                                }
                                resetInfo={null}
                                current={false}
                              />
                              {/* weekly limit */}
                              <LimitsFrequence
                                frequency="Weekly limit"
                                amtSpent={
                                  value?.Limits?.USD?.weeklyLimitValue || 0
                                }
                                totalAmt={
                                  value?.Limits?.USD?.weeklyLimitValue || 0
                                }
                                resetInfo={null}
                                current={false}
                              />
                              {/* monthly limit */}
                              <LimitsFrequence
                                frequency="Monthly limit"
                                amtSpent={
                                  value?.Limits?.USD?.monthlyLimitValue || 0
                                }
                                totalAmt={
                                  value?.Limits?.USD?.monthlyLimitValue || 0
                                }
                                resetInfo={null}
                                current={false}
                              />
                            </>
                          )}
                        </>
                      )}
                    </div>
                  )
                )}
              </div>
            ))}
            <div className="hidden mt-12 text-center rounded-md bg-[#EDEDED] px-12 py-4 text-[#000000] font-medium">
              Ability to upgrade your tier is coming soon
            </div>
          </div>
        </section>
      </>
    </section>
  );
};

export default ProfileTransactionLimits;

interface ILimitsFrequence {
  frequency: string;
  amtSpent: number;
  totalAmt: number;
  resetInfo: string | null;
  current?: boolean;
  text?: string;
}

export const LimitsFrequence = ({
  frequency,
  amtSpent,
  totalAmt,
  resetInfo,
  current,
  text,
}: ILimitsFrequence) => {
  // let amtSpentPercent = Number(amtSpent);
  // let totalAmtPercent = Number(totalAmt);

  return (
    <div className="border p-3 rounded-md items-center ">
      <div className="flex justify-between">
        <p className="text-[#000000] text-sm ">{frequency}</p>
        {current ? (
          <p className="text-[#000000] text-sm">
            ${formatNumberThousands(amtSpent)} /{" "}
            <span className="text-[#026A32]">
              ${formatNumberThousands(totalAmt)}
            </span>
          </p>
        ) : (
          <p className="text-[#000000] text-sm">
            ${formatNumberThousands(totalAmt)}
          </p>
        )}
      </div>
      {current && (
        <>
          <div className="py-3">
            <ProgressBar spent={amtSpent} total={totalAmt} />
          </div>
          <p className="text-xs text-[#555555]">{resetInfo}</p>
        </>
      )}

      {text && (
        <p className="text-xs pt-3 md:w-[300px] text-[#111111]">{text}</p>
      )}
    </div>
  );
};
