// IDDocument.js
import React, { useState } from "react";
import "react-dropzone-uploader/dist/styles.css";
import Loader from "../../../components/PopUps/MainLoader";

import { ToastContainer } from "react-toastify";
import VeriffComponent from "./VeriffComponent";
import { RadioGroup } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { useNavigate } from "react-router-dom";
interface IDoc {
  key: any;
  index?: any;
  currentStep?: number;
  nextStep?: any;
  saveForLater?: any;
}

const plans = [
  {
    id: 1,
    name: "International Passport",
  },
  {
    id: 2,
    name: "Driver’s License",
  },
  {
    id: 3,
    name: "Voter’s Card",
  },
  {
    id: 4,
    name: "NIN Card or Slip",
    hint: "Additional verification required",
  },
];
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function IDDocument(_props: IDoc) {
  const [loading] = useState(false);
  const [selected, setSelected] = useState<any>(null);
  const navigate = useNavigate();

  const ninPage = () => {
    navigate("/kyc/verify-NIN");
  };
  return (
    <>
      {loading ? (
        <Loader text="Uploading documents" />
      ) : (
        <div className="md:w-[50%] sm:w-[50%] mb-2 bg-white rounded-xl mt-4 px-4 py-6 md:px-12 md:py-12 mx-auto">
          <p className="text-lg font-bold md:mb-4 mb-3">
            Document Verification
          </p>
          <p className="text-sm">
            Please ensure that you have one of the following documents below. We
            are unable to accept other documents at this time:
          </p>

          <div className="w-full mt-6">
            <div className="mx-auto w-full max-w-md">
              <RadioGroup value={selected} onChange={setSelected}>
                <RadioGroup.Label className="sr-only">
                  Server size
                </RadioGroup.Label>
                <div className="space-y-2">
                  {plans.map((plan) => (
                    <RadioGroup.Option
                      key={plan.id}
                      value={plan}
                      className={({ active, checked }) =>
                        `${
                          active
                            ? "ring-2 ring-white/60 ring-offset-2 ring-offset-cleva-gold"
                            : ""
                        }
                  ${checked ? "ring-offset-cleva-gold" : "bg-white"}
                    relative flex cursor-pointer border rounded-lg px-5 py-4 focus:outline-none`
                      }
                    >
                      {({ checked }) => (
                        <>
                          <div className="flex w-full items-center justify-between">
                            <div className="flex items-center">
                              <div className="text-sm">
                                <RadioGroup.Label
                                  as="p"
                                  className={`font-medium  text[#0C0C0C]
                                  }`}
                                >
                                  {plan.name}
                                </RadioGroup.Label>
                                <RadioGroup.Description
                                  as="span"
                                  className={`inline text-[#0B62A1]
                                  }`}
                                >
                                  <span>{plan.hint}</span>{" "}
                                </RadioGroup.Description>
                              </div>
                            </div>
                            {checked ? (
                              <div className="shrink-0 text-cleva-gold">
                                <CheckCircleIcon className="h-6 w-6" />
                              </div>
                            ) : (
                              <div>
                                <div className="ml-3 flex h-6 items-center">
                                  <input
                                    defaultChecked={plan.name === null}
                                    id={`side-${plan.name}`}
                                    name="plan"
                                    type="radio"
                                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </RadioGroup.Option>
                  ))}
                </div>
              </RadioGroup>
            </div>
          </div>
          <div className="mt-8">
            {selected?.name === "NIN Card or Slip" ? (
              <button
                onClick={ninPage}
                className="bg-cleva-gold py-4 w-[100%] rounded-lg font-semibold shadow-lg"
              >
                Enter NIN Number
              </button>
            ) : selected ? (
              <VeriffComponent />
            ) : null}
          </div>
        </div>
      )}

      <ToastContainer />
    </>
  );
}
