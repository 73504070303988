import React from "react";
// import { Link } from "react-router-dom";
interface CardProps {
  active?: boolean;
  title: string;
  body?: string;
  text?: string;
  icon?: string;
  handleClick?: () => void;
  disabled?: boolean;
  cardClass?: boolean;
  width?: string;
  additionalStyle?: string;
  backgroundImage?: string;
}

const DashboardCard: React.FC<CardProps> = ({
  active,
  title,
  body,
  text,
  handleClick,
  disabled,
  width,
  additionalStyle,
  backgroundImage,
}) => {
  const cardClasses = active ? "card-active" : "card-inactive";

  if (disabled) {
    return (
      <div className={`card`}>
        <div
          className="flex rounded-xl items-center justify-between h-[110px] py-1 pl-3 text-[#111111] border-2 border-black shadow-xl"
          style={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: "cover",
          }}
        >
          <div className="w-[60%] md:w-[66%] 2xl:w-[60%]">
            <div className="mb-0">
              <button
                disabled={true}
                className={
                  text
                    ? "text-[8px] leading-4 opacity-80  text-[#EBF0F0] font-semibold  mb-3 flex items-center rounded-full bg-zinc-700 px-2 py-1 "
                    : "hidden"
                }
              >
                {text}
              </button>
              <div
                style={{ width }}
                className={`${additionalStyle} ${cardClasses}`}
              >
                {title}
              </div>
              <p className="text-[16px] opacity-60 text-[#111111] pt-[8px]">
                {body}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="card">
      <div
        onClick={handleClick}
        className="flex rounded-xl cursor-pointer items-center justify-between  h-[110px] py-1 pl-3 text-[#111111] border-2 border-black shadow-xl"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
        }}
      >
        <div className="w-[60%] md:w-[66%] 2xl:w-[60%]">
          <div className="mb-0">
            <button
              className={
                text
                  ? "border border-[#F8B755] bg-white text-[#FF6D59] text-[8px] mb-3 leading-4 py-[.2rem] px-[1.5rem] font-semibold flex items-center rounded-full"
                  : "hidden"
              }
            >
              {text}
            </button>
            <div
              style={{ width }}
              className={`${additionalStyle} ${cardClasses}`}
            >
              {title}
            </div>
            <p className="text-[16px] text-[#111111] pt-[8px]">{body}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardCard;
