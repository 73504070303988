import "react-toastify/dist/ReactToastify.css";
import logo from "../../asset/images/logo.svg";
import authImg from "../../asset/images/login-img.svg";
import { Link } from "react-router-dom";

const RedirectUnverifiedBusinesss = () => {
  return (
    <>
      <div className="md:flex min-h-full">
        <div className="hidden lg:flex md:flex-col justify-center flex-1 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-20 bg-black text-white ">
          <div className="mx-auto w-full max-w-[24rem] 2xl:max-w-[28rem] lg:w-[30rem] xl:w-[28rem]">
            <div className="flex justify-center pt-20 min-h-[100vh] md:items-center md:pt-0">
              <div className="w-full">
                <div>
                  {/* <img className="w-auto mt-2" src={logo} alt="logo" /> */}
                  <Link to="https://app.getcleva.com/">
                    <img className="w-auto mt-2" src={logo} alt="logo" />
                  </Link>
                  <h1 className="mt-16 text-4xl font-medium text-white">
                    Welcome to Cleva
                  </h1>
                  <p className="text-[#EBF0F0] mt-6">
                    Login to access your account
                  </p>
                </div>

                <img src={authImg} className="mt-10" alt="login" />
              </div>
            </div>
          </div>
        </div>
        <div className="relative flex-1 bg-[#F8f8f8]">
          <div className="flex justify-center pt-8 min-h-[100vh] items-center md:pt-0">
            <div>
              <div className="flex justify-center items-end pb-5">
                <img className="w-auto mt-2 lg:hidden" src={logo} alt="logo" />
              </div>

              <h2 className="font-bold mx-auto w-[85%] md:w-[30rem] md:mb-8 text-center text-2xl text-[#111111]">
                We noticed you’re trying to log into your Cleva business account
              </h2>
              <div className="login-card-md w-full md:w-[32rem] xl:w-[36rem] px-10 py-8">
                <div className="">
                  <div className="bg-white">
                    <ul className="list-disc font-medium flex flex-col gap-4 px-6 py-4">
                      <li>
                        To access your Cleva business account, please use this
                        link instead:{" "}
                        <Link
                          className="text-blue-600"
                          to="https://business-beta.getcleva.com/"
                        >
                          https://business-beta.getcleva.com/
                        </Link>
                      </li>
                      <li>
                        Log in here, complete the KYB verification for your
                        business, and open your Business USD account
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RedirectUnverifiedBusinesss;
