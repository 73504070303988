import React from "react";

interface TabButtonProps {
  id: string;
  name: string;
  icon?: string;
  activeTab: string;
  disabled?: boolean;
  setActiveTab: (id: string) => void;
  onClick?: () => void;
  ngnText?: string;
}

export function classNames(
  ...classes: Array<string | boolean | undefined | null>
): string {
  return classes.filter(Boolean).join(" ");
}

const TabButtons: React.FC<TabButtonProps> = ({
  id,
  name,
  activeTab,
  setActiveTab,
  disabled,
  onClick,
  icon,
  ngnText,
}) => {
  const handleClick = () => {
    setActiveTab(id);
    if (onClick) {
      onClick();
    }
  };

  return (
    <>
      <div className="">
        <nav className="flex -mb-px relative" aria-label="Tabs">
          {ngnText ? (
            <div className="">
              <span className=" bg-[#4F8B64] absolute text-white rounded-full px-[0.5rem] py-[0.1rem] text-[.6rem] bottom-10 md:right-[0.8rem] right-[2rem]">
                {ngnText}
              </span>
            </div>
          ) : (
            ""
          )}
          <button
            key={id}
            onClick={handleClick}
            disabled={disabled}
            className={classNames(
              activeTab === id
                ? "rounded-full border-2 border-[#111111] bg-[#EBF0F0]"
                : "tab-child border-transparent text-gray500 ",
              "whitespace-nowrap py-2  w-full font-bold text-xl bg-white flex items-center gap-1 justify-center",
              disabled ? "rounded-lg  text-gray-400" : ""
            )}
          >
            <img src={icon} className="w-6 h-6" alt="" />
            {name}
          </button>
        </nav>
      </div>
    </>
  );
};

export default TabButtons;
