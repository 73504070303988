import { AddressInterface } from "../../../features/ClevaCards/ClevaCardsSlice";
import { AWS_CREDS } from "../../../features/services/AmazonService";

type SetCopyState = (value: boolean) => void;

export const copyAddress = async (
  StreetAddress: string,
  City: string,
  StateOrTerritory: string,
  Country: string,
  ZipCode: string,
  CARD_ADDRESS: AddressInterface,
  setcopyState: SetCopyState
) => {
  const addressToBeCopied = `
    ${StreetAddress}, ${City},
    ${StateOrTerritory}, ${Country},
    ${ZipCode}

 
    `;
  try {
    if (CARD_ADDRESS) {
      await navigator.clipboard.writeText(addressToBeCopied);
      setcopyState(true);
    }
  } catch (error) {
    if (AWS_CREDS.STAGE === "qa") {
      console.error("Copy failed:", error);
    }
  }
};
