import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

export const GOOGLE_TRACKER_ID = "G-W93QFLH40G";
ReactGA.initialize(GOOGLE_TRACKER_ID);

export function withGoogleAnalyticsTracking(Component: any) {
  return function WithGoogleAnalyticsTracking(props: any) {
    const location = useLocation();

    useEffect(() => {
      // Send a pageview event to Google Analytics on route change
      ReactGA.send({
        hitType: "pageview",
        page: window.location.pathname,
      });
    }, [location]);

    return <Component {...props} />;
  };
}
