import { useState } from "react";
import { TagRecipient } from "../../api";
import RecipientAdded from "./Modals/RecipientAdded";
import SearchInput from "./components/SearchInput";
import { toast, ToastContainer } from "react-toastify";
import BackIcon from "../../images/leftArrow.svg";

import { useNavigate } from "react-router-dom";

export default function TagAddRecipent() {
  const [tag, setTag] = useState<any>({});
  const [tags, setTags] = useState<any[]>([]);
  const [modalState, setModalState] = useState(false);
  const [btnLoading, setButtonLoader] = useState<boolean>(false);
  const btnDisabled = !tag?.Tag || btnLoading;
  const navigate = useNavigate();

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setButtonLoader(true);
    try {
      await TagRecipient({ Tag: tag?.Tag });
      setModalState(true);
      setButtonLoader(false);
    } catch (error: any) {
      setModalState(false);
      console.error(error);
      toast.error("We are sorry, Please try again later");
      setButtonLoader(false);
    }
  };

  const returnBack = () => {
    navigate("/recipients", { state: { activeTab: "TAG" } });
    setModalState(false);
  };

  const goBack = () => {
    navigate("/recipients", { state: { activeTab: "TAG" } });
  };

  const removeTag = () => {
    setTag({});
  };

  const selectTag = (tag: any) => {
    setTag(tag);
  };

  return (
    <>
      <ToastContainer />
      <div className="relative flex-1">
        <div className="flex items-center pt-6">
          <button
            className="bg-white border border-[#CFDBD5B2] py-3 px-3.5 rounded-lg"
            onClick={goBack}
          >
            <img src={BackIcon} alt="back" />
          </button>
          <p className="text-lg font-bold ml-3">Add recipient</p>
        </div>

        <div className="flex justify-center flex-col md:h-[60vh] text-center">
          <div className="top-4 md:mt-4 left-0 right-0 bottom-0 bg-cleva-white flex items-center justify-center">
            <div className="login-card-md w-full md:w-[32rem] xl:w-[32rem] px-6 py-10 md:px-10 md:py-16 md:shadow rounded-lg">
              <div className="">
                <form onSubmit={handleSubmit} autoComplete="off" className=" ">
                  <SearchInput
                    tag={tag}
                    selectTag={selectTag}
                    removeTag={removeTag}
                    setList={setTags}
                  />

                  {tags.length === 0 && (
                    <div className="mt-8">
                      <button
                        type="submit"
                        disabled={btnDisabled}
                        className={`bg-cleva-gold opacity-${
                          btnDisabled ? "60" : "100"
                        } flex items-center justify-center mb-3 md:mb-0 font-medium rounded-lg md:py-2.5 py-3 px-4 md:px-8 shadow-sm w-[100%]`}
                      >
                        {!btnLoading ? "Add" : "Loading .."}
                      </button>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {modalState && (
        <RecipientAdded
          open={modalState}
          tag={tag?.Tag}
          fullName={tag?.FullName}
          closeModal={returnBack}
        />
      )}
    </>
  );
}
