import React, { memo, useEffect, useState } from "react";
import waveIcon from "../../images/wave-hand.svg";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { KycStatus } from "../Kyc/components/KycStatus";
import { setTitle } from "../../features/Nav/NavSlice";
import InfoModal from "../../components/PopUps/InfoModal";
import Win from "../../asset/referral/win.svg";
// import DecemberRefer from "../../asset/referral/december-referral.png";
import { Link, useNavigate } from "react-router-dom";
import RateCard from "../../components/Layout/RateCard";
import { IoCloseOutline } from "react-icons/io5";
import Yellow from "../../asset/referral/yellowimg.png";
import { AWS_CREDS } from "../../features/services/AmazonService";
import { KycStatusFallbackComponent, useIsBusinessUser } from "../../utils";
import { updateTagModalStatus } from "../../features/Tags/TagsSlice";
import tagImg from "../../images/tags.png";
import { ErrorBoundary } from "react-error-boundary";
// import AnniversaryBanner from "./components/AnniversaryBanner";
import usdBg from "../../asset/dashboard/USD-coin.svg";
import USIcon from "../../asset/dashboard/US-Icon.svg";
import TagBG from "../../asset/dashboard/tagImage.jpg";
import CardBG from "../../asset/dashboard/cardImage.jpg";
import ReferralBG from "../../asset/dashboard/referralImage.jpg";
import UsdBenefit from "./components/UsdBenefit";
import Carousel from "./components/Carousel";
import DealBG from "../../asset/dashboard/dealsBg.jpg";
import AccountCard from "../../components/Layout/AccountCard";
import RecentTransaction from "../Accounts/components/RecentTransaction";
import OpenAccount from "../Accounts/OpenAccount";
import { ToastContainer } from "react-toastify";
import AppDownloadBanner from "../Accounts/components/AppDownload";

const Dashboard = (props: any) => {
  const MemoizedRecentTransaction = memo(RecentTransaction);

  const { hasAccounts } = props;

  const maxDepositWaiver = AWS_CREDS.MAX_DEPOSIT_WAIVER || 3;
  const maxReferralBonus = AWS_CREDS.MAX_REFERRAL_BONUS || 200;
  const referralBonusEarned = AWS_CREDS.BONUS_PER_REFERRED_USER || 3;
  // const bonusPerReferral = AWS_CREDS.BONUS_PER_REFERRED_USER || 5;

  const [userHasKycBonus, setUserHasKycBonus] = useState(false);
  const [preReferralUser, setPreReferralUser] = useState(false);
  // const [decemberReferral, setDecemberReferral] = useState(false);
  const { KYCState } = useAppSelector((state: any) => state.kycInfo);
  const user = useAppSelector((state) => state.user.userData);
  const isBusinessUser = useIsBusinessUser();
  const userTag = useAppSelector((state) => state.tags.userTag);
  const showCreateTagModal = useAppSelector(
    (state) => state.tags.displayCreateTagModal
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setTitle("Home"));
  }, [dispatch]);

  useEffect(() => {
    if (user?.ReferredByReferralCode === "") {
      setPreReferralUser(true);
    } else {
      setUserHasKycBonus(true);
    }
    if (userTag) {
      dispatch(updateTagModalStatus(false));
    }
  }, [user]);

  const showModal = user?.DisplayReferralModal === true;

  const navigateToReferrals = () => {
    localStorage.setItem("modalState", JSON.stringify(false));
  };

  const GoToTagTransfer = () => {
    navigate("/transfers/create", { state: { activeTab: "tag" } });
  };

  return (
    <div className="xl:w-[80%] md:w-[90%] mx-auto pt-5">
      <AppDownloadBanner />

      <header>
        <section className="my-6">
          <div className="flex items-center">
            <p className="text-black text-[16px] font-normal leading-normal ">
              Welcome,{" "}
              {isBusinessUser ? (
                <span className="text-black text-[16px] font-bold leading-normal ">
                  {user?.BusinessName || ""}
                </span>
              ) : (
                <span className="text-black text-[16px] font-bold leading-normal ">
                  {user?.FullName?.FirstName || ""}
                </span>
              )}
            </p>
            <p className=" ml-3">
              <span className="">
                <img src={waveIcon} alt="wave" />
              </span>
            </p>
          </div>
          {/* <p className=" text-[14px] text-[#696969] pt-2 ">
            What would you like to do today?
          </p> */}
        </section>
      </header>

      <ErrorBoundary fallback={<KycStatusFallbackComponent />}>
        <KycStatus />
      </ErrorBoundary>
      {/* remove anniversary banner  */}
      {/* {KYCState === "VERIFIED" && <AnniversaryBanner />} */}

      {hasAccounts ? (
        <InfoModal isOpen={showCreateTagModal} bg="bg-white">
          <div data-test="promo-modal" className="">
            <div
              className="relative top-[-1rem] rounded-t-2xl bg-no-repeat bg-cover bg-center h-56"
              style={{
                backgroundImage: `url(${tagImg})`,
              }}
            >
              <div className="flex justify-end pr-2 pt-2 cursor-pointer">
                <IoCloseOutline className="text-4xl text-[#fff]" />
              </div>
            </div>
            <div className="text-center flex flex-col items-center px-2 md:px-8 pb-4">
              <h1 className="text-[#111] text-lg font-semibold pt-[.5rem]">
                Introducing the Cleva tag for C2C transfers{" "}
              </h1>
              <p className="py-[0.88rem] text-[#373737] text-[0.8rem] text-center font-normal">
                Create your Cleva tag to receive instant USD transfers from
                other Cleva users. You can also send to other people’s Cleva
                tag.
              </p>
              <Link
                to="/create-tag"
                type="submit"
                className="m-2 py-3 px-10 font-normal text-[0.8rem] bg-[#FFBD59] rounded-lg"
              >
                Create Tag
              </Link>
            </div>
          </div>
        </InfoModal>
      ) : null}

      {showModal ? (
        <div>
          {user?.DisplayReferralModal && userHasKycBonus && (
            <InfoModal isOpen={userHasKycBonus} bg="bg-white">
              <div className="">
                <div className="flex justify-end pr-8 pt-4 cursor-pointer">
                  <IoCloseOutline className="text-4xl text-[#747474]" />
                </div>
                <div className="text-center flex flex-col items-center px-8 py-4">
                  <img src={Win} alt="Deposit Bonus" />
                  <h1 className="text-[#158025] text-xl font-medium pt-[2.25rem]">
                    You'll receive a waiver on your first deposit fee
                  </h1>
                  <p className="py-[1.12rem] text-[#686868] text-sm font-normal">
                    When you receive your first USD deposit, we'll waive up to $
                    {maxDepositWaiver} from your deposit fee. This means that in
                    most cases,{" "}
                    <b>
                      you won't pay a fee. You can also refer friends to Cleva
                      and earn up to ${maxReferralBonus}!{" "}
                    </b>
                  </p>
                  <Link
                    onClick={navigateToReferrals}
                    className="rounded-lg border border-[#0772D5] bg-[#F8F8F8] py-4 px-8 text-[#0772D5] font-normal text-base"
                    to="/referrals"
                  >
                    Learn More
                  </Link>
                </div>
              </div>
            </InfoModal>
          )}
          {user?.DisplayReferralModal && preReferralUser && (
            <InfoModal isOpen={preReferralUser} bg="bg-white">
              <div className="text-center flex flex-col items-center">
                <img
                  className="cursor-pointer"
                  src={Yellow}
                  alt="Deposit Bonus"
                />
                <div className="px-8 mb-10">
                  <h1 className="text-[#158025] text-xl font-medium pt-[2.25rem]">
                    Introducing Our New Referral Program!
                  </h1>
                  <p className="pt-[1.12rem] pb-[2.12rem] text-[#686868] text-sm font-normal">
                    You can now earn a cash bonus when you refer friends to
                    Cleva. Plus, your friends also get a waiver on their first
                    deposit fee. It's a win-win! Start referring today.
                  </p>
                  <Link
                    onClick={navigateToReferrals}
                    className="rounded-lg border border-[#0772D5] bg-[#F8F8F8] py-4 px-8 text-[#0772D5] font-normal text-base"
                    to="/referrals"
                  >
                    Learn More
                  </Link>
                </div>
              </div>
            </InfoModal>
          )}
        </div>
      ) : null}

      {/* {allTransfer.length > 0 && <ToDoContents kycState={KYCState} />} */}

      <>
        <section>
          {(KYCState === "VERIFIED" || KYCState === "BUSINESS_VERIFIED") && (
            <div className="mt-6 md:mt-8">
              <div>
                {hasAccounts ? (
                  <AccountCard />
                ) : (
                  <OpenAccount
                    linkText="Tap to open a Cleva USD account"
                    icon={USIcon}
                    dashIcon={usdBg}
                  />
                )}
              </div>

              <div className="mt-10">
                <RateCard />
              </div>

              {hasAccounts && (
                <div className="mt-10">
                  <MemoizedRecentTransaction />
                </div>
              )}
            </div>
          )}
        </section>

        <section className="mt-6 md:mt-10">
          <h1 className=" text-base mb-1">Special features for you</h1>

          <Carousel
            KYCState={KYCState}
            referralBonusEarned={referralBonusEarned}
            hasAccounts={hasAccounts}
            navigate={navigate}
            GoToTagTransfer={GoToTagTransfer}
            ReferralBG={ReferralBG}
            CardBG={CardBG}
            TagBG={TagBG}
            DealBG={DealBG}
          />
        </section>

        {/* USD Account benefit section  */}
        <section className="mt-8 md:mt-12">
          {KYCState !== "VERIFIED" && KYCState !== "BUSINESS_VERIFIED" ? (
            <UsdBenefit />
          ) : null}
        </section>
      </>
      <ToastContainer />
    </div>
  );
};

export default Dashboard;
