const CardTransactionDetail = (transaction: any) => {
  const {
    transactionType,
    fee,
    singleTransfer,
    topupAmount,
    totalAmount,
    statusResult,
  } = transaction;
  return (
    <div>
      <>
        {transactionType === "CARD_CREATION" ? (
          <div className="mb-3">
            <div className="flex justify-between my-4">
              <p className="text-sm text-[#747A80]">Transaction type</p>
              <p className="text-sm font-medium ml-2 text-[#158025]">
                Card Creation
              </p>
            </div>

            <div className="flex justify-between my-4">
              <p className="text-sm text-[#747A80]">Fee</p>
              <p className="text-sm font-medium ml-2">
                ${" "}
                {fee?.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </p>
            </div>
            <div className="flex justify-between my-4">
              <p className="text-sm text-[#747A80]">Status</p>
              <p className="text-sm font-medium ml-2">
                {" "}
                {singleTransfer ? statusResult : ""}
              </p>
            </div>
          </div>
        ) : transactionType === "CARD_FUNDING" ? (
          <div className="mb-3">
            <div className="flex justify-between my-4">
              <p className="text-sm text-[#747A80]">Transaction type</p>
              <p className="text-sm font-medium ml-2 text-[#158025]">
                Card Funding
              </p>
            </div>

            <div className="flex justify-between my-4">
              <p className="text-sm text-[#747A80]">Top up amount</p>
              <p className="text-sm font-medium ml-2">
                ${" "}
                {topupAmount?.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </p>
            </div>
            <div className="flex justify-between my-4">
              <p className="text-sm text-[#747A80]">Fee</p>
              <p className="text-sm font-medium ml-2">
                ${" "}
                {fee?.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </p>
            </div>
            <div className="flex justify-between my-4">
              <p className="text-sm text-[#747A80]">Total amount</p>
              <p className="text-sm font-medium ml-2">
                {" "}
                ${" "}
                {totalAmount?.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </p>
            </div>
            <div className="flex justify-between my-4">
              <p className="text-sm text-[#747A80]">Status</p>
              <p className="text-sm font-medium ml-2">
                {" "}
                {singleTransfer ? statusResult : ""}
              </p>
            </div>
          </div>
        ) : transactionType === "CARD_CREATION_REFUND" ? (
          <div className="mb-3">
            <div className="flex justify-between my-4">
              <p className="text-sm text-[#747A80]">Amount refunded</p>
              <p className="text-base font-medium ml-2 text-[#158025]">
                $
                {topupAmount?.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </p>
            </div>
            <div className="flex justify-between my-4">
              <p className="text-sm text-[#747A80]">Status</p>
              <p className="text-sm font-medium ml-2">
                {singleTransfer ? statusResult : ""}
              </p>
            </div>
            <div className="flex justify-between my-4">
              <p className="text-sm text-[#747A80]">Description</p>
              <p className="text-sm font-medium text-[#000]">
                Card Creation Feee Refund
              </p>
            </div>
          </div>
        ) : transactionType === "CARD_TERMINATION_REFUND" ? (
          <div className="mb-3">
            <div className="flex justify-between my-4">
              <p className="text-sm text-[#747A80]">Amount refunded</p>
              <p className="text-base font-medium ml-2 text-[#158025]">
                $
                {totalAmount?.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </p>
            </div>
            <div className="flex justify-between my-4">
              <p className="text-sm text-[#747A80]">Status</p>
              <p className="text-sm font-medium ml-2">
                {singleTransfer ? statusResult : ""}
              </p>
            </div>
            <div className="flex justify-between my-4">
              <p className="text-sm text-[#747A80]">Description</p>
              <p className="text-sm font-medium text-[#000]">
                Card Termination Fee Refund
              </p>
            </div>
          </div>
        ) : (
          <div className="mb-3">
            <div className="flex justify-between my-4">
              <p className="text-sm text-[#747A80]">Amount refunded</p>
              <p className="text-base font-medium ml-2 text-[#158025]">
                $
                {topupAmount?.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </p>
            </div>
            <div className="flex justify-between my-4">
              <p className="text-sm text-[#747A80]">Status</p>
              <p className="text-sm font-medium ml-2">
                {singleTransfer ? statusResult : ""}
              </p>
            </div>
            <div className="flex justify-between my-4">
              <p className="text-sm text-[#747A80]">Description</p>
              <p className="text-sm font-medium  text-[#000]">
                Card Funding Refund
              </p>
            </div>
          </div>
        )}
      </>
    </div>
  );
};

export default CardTransactionDetail;
