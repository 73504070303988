import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { verifyNIN, resetNINStatus } from "../../../features/Kyc/KycApi";
import { AWS_CREDS } from "../../../features/services/AmazonService";
import CardModal from "../../ClevaCards/CardModal";
import SpinnerTwo from "../../../components/PopUps/SpinnerTwo";
import InvalidModal from "../modals/invalidModal";
import MismatchModal from "../modals/mismatchModal";
import VerifiedModal from "../modals/verifiedModal";
import BVNFailedModal from "../modals/BVNFailedModal";
import BVNInvalidModal from "../modals/BVNInvalidModal";

const VerifyNINPage = () => {
  const [NINvalid, setNINvalid] = useState(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [NINvalue, setNINvalue] = useState<string>("");
  const [disableForm, setDisableForm] = useState(true);
  const [currentModal, setCurrentModal] = useState<string | null>(null);
  const { KYCState } = useAppSelector((state) => state.kycInfo);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  // const [NewKYCState, setNewKYCState] = useState("");
  const [retries, setRetries] = useState(0);

  const KYCIdentifier = localStorage.getItem("KYCIdentifier");

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim();
    setNINvalue(value);
    setNINvalid(value.length === 11);
    setDisableForm(value.length !== 11);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!KYCIdentifier) {
      console.error("KYC Identifier not found in localStorage");
      return;
    }

    setIsLoading(true);

    try {
      const result = await dispatch(
        verifyNIN({
          kycIdentifier: KYCIdentifier,
          ninNumber: NINvalue,
        })
      ).unwrap();

      // setNewKYCState(result?.KYCState);
      setRetries(result?.IdRetriesLeft);

      if (result?.KYCState === "ID_INVALID") {
        setCurrentModal("InvalidModal");
      } else if (result?.KYCState === "ID_MISMATCH") {
        setCurrentModal("MismatchModal");
      } else if (result?.KYCState === "ID_UPLOAD") {
        setCurrentModal("VerifiedModal");
      } else if (result?.KYCState === "FAILED") {
        setCurrentModal("FailedModal");
      }
    } catch (error: any) {
      if (AWS_CREDS.STAGE === "qa") {
        console.error("Verification error:", error);
      }

      if (
        error?.response?.data?.Code === "NATIONAL_ID_DUPLICATE" ||
        error?.response?.data?.Code === "ID_DUPLICATE"
      ) {
        setCurrentModal("BVNModal");
      } else if (
        error?.response?.data?.status === 504 ||
        error?.response?.data?.Code === "PROVIDER_DOWN"
      ) {
        setCurrentModal("ProviderDownModal");
      } else {
        setCurrentModal("ErrorModal");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const proceed = () => {
    navigate("/kyc-info");
  };

  const resetNIN = async () => {
    if (!KYCIdentifier) {
      console.error("KYC Identifier not found in localStorage");
      return;
    }
    try {
      const result = await dispatch(
        resetNINStatus({
          kycIdentifier: KYCIdentifier,
        })
      ).unwrap();
      if (result?.KycState === "BIOMETRICS") {
        navigate("/kyc-info");
      }
    } catch (error) {
      console.error(error);
    } finally {
      navigate("/kyc-info");
    }
  };

  useEffect(() => {
    if (KYCState === "VERIFIED") {
      navigate("/");
    } else if (KYCState === "ID_UPLOAD") {
      navigate("/kyc-info");
    } else if (KYCState === "DENIED") {
      navigate("/");
    } else if (KYCState === "SUSPENDED") {
      navigate("/");
    }
  }, [navigate]);

  return (
    <div className="flex flex-col justify-center items-center py-20 xl:ml-20">
      <div className="text-center">
        <p className="text-xl text-[#172323] font-semibold mb-2">
          National Identification Number
        </p>
        <p className="font-medium text-[#333333]">
          Please enter your National Identification Number (NIN) to confirm your
          identity.
        </p>
      </div>

      <section className="rounded-[1.25rem] border border-[#EFEFEF] bg-[#FFF] shadow-lg mt-[4.28rem] p-10 w-full md:w-[35rem]">
        <p className="text-[#515151] pb-2 font-normal text-[0.875rem] leading-4">
          National Identification Number (NIN)
        </p>
        <form onSubmit={handleSubmit}>
          <div>
            <input
              data-test="NIN-input"
              maxLength={11}
              onChange={handleInputChange}
              value={NINvalue}
              className="w-full rounded-lg border border-[#555] placeholder:text-[#ACACAC] p-[.4rem]"
              placeholder="Enter 11 digits number"
            />
            {!NINvalid && (
              <div className="flex items-center pt-1">
                <p className="text-[11px] text-[#F15757]">
                  NIN should be exactly 11 characters long.
                </p>
              </div>
            )}
          </div>
          <button
            type="submit"
            disabled={disableForm}
            className={`bg-[#FFBD59] rounded-xl py-4 px-[0.75rem] w-full mt-[2.12rem] ${
              disableForm ? "opacity-60" : ""
            }`}
          >
            Submit
          </button>
        </form>
      </section>

      <div className="text-center mt-12">
        <button className="text-[#0B62A1]" onClick={resetNIN}>
          Use a different ID type for verification
        </button>
      </div>

      {/* Modals */}
      {currentModal === "InvalidModal" && (
        <InvalidModal
          idType="NIN"
          open={true}
          setOpen={() => setCurrentModal(null)}
        />
      )}
      {currentModal === "MismatchModal" && (
        <MismatchModal
          open={true}
          setOpen={() => setCurrentModal(null)}
          IdType="NIN"
          retries={retries}
        />
      )}
      {currentModal === "VerifiedModal" && (
        <VerifiedModal
          idType="NIN"
          open={true}
          setOpen={() => setCurrentModal(null)}
          action={proceed}
        />
      )}
      {currentModal === "FailedModal" && (
        <BVNFailedModal
          open={true}
          setOpen={() => setCurrentModal(null)}
          title="NIN verification failed"
          text="Please contact NIMC to view and update your NIN details"
        />
      )}
      {currentModal === "BVNModal" && (
        <BVNInvalidModal
          open={true}
          setOpen={() => setCurrentModal(null)}
          title="NIN already in use"
          text="The NIN number you entered has already been associated with another user's account."
        />
      )}
      {currentModal === "ProviderDownModal" && (
        <BVNInvalidModal
          open={true}
          setOpen={() => setCurrentModal(null)}
          title=""
          text="We're sorry, our NIN service provider is currently down. Please try again in a few hours time."
        />
      )}
      {currentModal === "ErrorModal" && (
        <BVNInvalidModal
          open={true}
          setOpen={() => setCurrentModal(null)}
          title=""
          text="An error occurred and we’re sorry about that. Please try again in a few hours."
        />
      )}

      {isLoading && (
        <CardModal width="md:w-[27rem]">
          <div className="flex flex-col items-center justify-center p-16 gap-10 lg:gap-20">
            <SpinnerTwo />
            <h1 className="text-[#111] text-center font-semibold text-xl md:text-2xl">
              Loading...
            </h1>
          </div>
        </CardModal>
      )}
    </div>
  );
};

export default VerifyNINPage;
