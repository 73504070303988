import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import errIcon from "../../images/error-icon.svg";
import ConfirmNameModal from "../Cards/Modals/ConfirmNameModal";
import { setTitle } from "../../features/Nav/NavSlice";
import { getKyc } from "../../api";
import { setIndividualkyc, setKYCState } from "../../features/Kyc/kycSlice";
//import errIcon2 from "../../images"

const CardName = () => {
  const dispatch = useAppDispatch();
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [btnError, setBtnError] = useState<boolean>(true);
  const [formValid, setFormValid] = useState<boolean>(false);
  const [nameErr, setNameErr] = useState<boolean>(false);
  const [confirmCard, setConfirmCard] = useState<boolean>(false);
  const KYCIdentifier = localStorage.getItem("KYCIdentifier");
  const allNames = useAppSelector((state) => state.clevaCards.userNames);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
  });

  //  console.log(allNames)

  const handleFirstName = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectValue: string = e.target.value;
    setFirstName(selectValue.trim());

    if (selectValue) {
      //setFirstNameError("");
      setBtnError(false);
    } else {
      setNameErr(true);
      setBtnError(true);
    }

    // const selectedRecipient = allNames.find(
    //   (recipient: any) => recipient.RecipientIdentifier === selectValue
    // );
  };

  const handleLastName = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectValue: string = e.target.value;
    setLastName(selectValue.trim());

    if (selectValue) {
      //setFirstNameError("");
      setBtnError(false);
    } else {
      //setFirstNameError("Please select a first name");
      setBtnError(true);
    }

    // const selectedRecipient = allNames.find(
    //   (recipient: any) => recipient.RecipientIdentifier === selectValue
    // );
  };

  const confirmCardName = () => {
    setFormData({
      firstName,
      lastName,
    });
    setConfirmCard(true);
  };
  const closeModal = () => {
    setConfirmCard(false);
  };

  useEffect(() => {
    dispatch(setTitle("Card"));

    if (KYCIdentifier) {
      getKyc(KYCIdentifier)
        .then((res) => {
          if (res?.data?.IndividualKyc) {
            dispatch(setIndividualkyc(res?.data?.IndividualKyc));
          }
        })
        .catch(() => {
          dispatch(setKYCState(""));
        });
    }
  }, [dispatch, KYCIdentifier]);

  useEffect(() => {
    if (lastName && firstName) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  }, [lastName, firstName]);

  useEffect(() => {
    dispatch(setTitle("Card"));
  }, [dispatch]);

  return (
    // flex flex-col justify-center items-center
    <>
      <div className=" py-20 xl:ml-20">
        <div className="md:w-[80%] xl:w-[40rem]  mx-auto pb-10">
          <section className="text-center">
            <h1 className="text-[#172323] text-lg font-medium pb-[0.75rem]">
              Select Cardholder Names
            </h1>
            <p className="text-[#474747] text-sm font-normal">
              We noticed you have multiple names on your BVN. Please select two
              names to appear on your card.
            </p>
          </section>

          <section className="py-[2.75rem]">
            {/* first name */}
            <div>
              <label className="text-[#282828] font-normal text-sm pb-1 text-left">
                First Name
              </label>
              <select
                onChange={handleFirstName}
                className={`bg-[#F9F9F9] w-full rounded-md text-[14px] border-1 h-12 outline-none border text-sm text-[#424242] focus:outline-none focus:ring-cleva-gold focus:border-cleva-gold`}
                name=""
                id=""
                value={firstName}
              >
                <option value="" disabled>
                  Select First Name
                </option>

                {lastName
                  ? allNames
                      .filter((word) => word.trim() !== lastName)
                      .map((name: any) => (
                        <option key={name} value={`${name}`}>
                          {`${name} `}
                        </option>
                      ))
                  : allNames.map((name: any) => (
                      <option key={name} value={`${name}`}>
                        {`${name} `}
                      </option>
                    ))}
              </select>
              {nameErr && (
                <div className="flex items-center mb-2 mt-1">
                  <img src={errIcon} alt="error" />
                  <p className="text-[#D31D1D] text-sm ml-1">
                    Please select a First Name
                  </p>
                </div>
              )}
            </div>

            {/* last name */}
            <div className="pt-[1.16rem]">
              <label className="text-sm pb-1 text-left">
                Last Name <span className="text-[#D31D1D] font-semibold"></span>
              </label>
              <select
                onChange={handleLastName}
                className={`bg-[#F9F9F9] w-full rounded-md text-[14px] border-1 h-12 outline-none border text-[#424242] focus:outline-none focus:ring-cleva-gold focus:border-cleva-gold`}
                name=""
                id=""
                value={lastName}
              >
                <option value="" disabled>
                  Select Last Name
                </option>
                {firstName
                  ? allNames
                      .filter((word) => word.trim() !== firstName)
                      .map((name: any) => (
                        <option key={name} value={`${name}`}>
                          {`${name} `}
                        </option>
                      ))
                  : allNames.map((name: any) => (
                      <option key={name} value={`${name}`}>
                        {`${name} `}
                      </option>
                    ))}
              </select>
            </div>
          </section>

          <button
            data-test="submit-cardName"
            disabled={!formValid || btnError}
            onClick={confirmCardName}
            className={`rounded-xl bg-[#FFBD59] cursor-pointer py-4 px-[0.75rem] text-xl font-semibold w-full ${
              !formValid || btnError ? "opacity-60" : "opacity-100"
            }`}
          >
            Submit
          </button>
        </div>
      </div>

      {confirmCard && (
        <ConfirmNameModal formData={formData} closeModal={closeModal} />
      )}
    </>
  );
};

export default CardName;

// const kycUser = useAppSelector((state)=> state.kycInfo.IndividualKyc)
// const { FirstName, MiddleName, LastName } = kycUser;

// const namesArray = [FirstName, MiddleName, LastName].filter(Boolean);
// const allNames: string[] = [];
// namesArray.forEach((name) => {
// if (typeof name === 'string') {
//   const splitNames = name.split(' ');
//   const lowerName = splitNames.map(name =>
//   name.charAt(0) + name.slice(1).toLowerCase()
//   );
//   allNames.push(...lowerName);
// }
// });
