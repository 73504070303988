import WebLayout from "../landing_page/components/WebLayout";

import FAQ from "../../asset/landing_photos/faq.svg";
import CloseAccountForm from "./CloseAccount";

const DeletionHero = () => {
  return (
    <section className="bg-[#000] relative mt-[7rem] md:pt-[2rem] ">
      <div className="w-[90%] xl:w-[80%] mx-auto 2xl:w-[1280px]">
        <div className="text-white grid place-items-center text-center pb-[4.5rem]">
          <img className="hidden md:block animate-bounce" src={FAQ} alt={FAQ} />
          <h1 className="text-[1.375rem] lg:text-[2rem] font-[700] leading-normal">
            Need to delete your Cleva App account?
          </h1>
          <p className="text-[0.875rem] lg:text-[1.25rem] pt-[.5rem] font-normal leading-normal">
            Please submit your name and account email in the form below to
            delete your account{" "}
          </p>
        </div>
      </div>
    </section>
  );
};

const DeleteAccount = () => {
  return (
    <WebLayout>
      <div className="">
        <DeletionHero />
        <div className="h-full pb-[5rem]">
          <CloseAccountForm />
        </div>
      </div>
    </WebLayout>
  );
};

// function DeleteAccountFrame() {
//   return (
//     <div>
//       <section id="contact-us-forms">
//         <iframe
//           src="https://form.jotform.com/230696744115156"
//           title="Delete Account Form"
//           width="100%"
//           height="600"
//           // frameborder="0"
//           // marginheight="0"
//           // marginwidth="0"
//         >
//           Loading…
//         </iframe>
//       </section>
//     </div>
//   );
// }

export default DeleteAccount;
