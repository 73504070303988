import React, { useEffect, useState } from "react";
import ExistingCard from "./ExistingCard";
import PendingCard from "./PendingCard";
import { useAppDispatch } from "../../app/hooks";
import { setCardIdentifier } from "../../features/ClevaCards/ClevaCardsSlice";
import Spinner from "../../components/PopUps/smallSpinner";
import { ICards } from ".";
//interface ICard:

const CardState = ({ cards }: ICards) => {
  const [state, setState] = useState("");
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (cards && (cards as any)?.length > 0) {
      const activeCard = (cards as any).find((card: any) =>
        ["ACTIVE", "PENDING", "FROZEN"].includes(card.Status)
      );
      if (activeCard) {
        const { Status, CardIdentifier } = activeCard;
        dispatch(setCardIdentifier(CardIdentifier));
        setState(Status || "");
        setLoading(false);
      }
    }
  }, [cards, dispatch, setState]);

  if (loading) {
    return <Spinner />;
  }

  return <>{state !== "PENDING" ? <ExistingCard /> : <PendingCard />}</>;
};

export default CardState;
