import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../app/hooks";
import { getUserIdWithAccessToken, removeAuthTokens } from "../../../login";
import { DeleteUser } from "../../../api";
import { AWS_CREDS } from "../../../features/services/AmazonService";
import { AuthService } from "../../../features/services/AuthServices";
import { setCompletedSteps, setStep } from "../../../features/Kyc/kycSlice";
import { setUser, setUserHash } from "../../../features/User/UserSlice";
import { resetBvnVerified } from "../../../features/ClevaCards/ClevaCardsSlice";
import { toast } from "react-toastify";
import BackButton from "../../../components/Buttons/BackButton";
import { CheckboxComponent } from "../../../components/Auth/Register";
import { DeleteAccountData } from "../../Kyc/pages/utils";
import ModalTemplate from "../../../components/PopUps/ModalTemplate";
import { IoCloseOutline } from "react-icons/io5";
import deleteIcon from "../../../asset/svg/deleteIcon.svg";

const DeleteAccountCheck = () => {
  const [loading, setLoading] = useState(false);
  const [selectedBox, setSelectedBox] = useState<string[]>([]);
  const [otherText, setOtherText] = useState<string>("");
  const [showModal, setShowModal] = useState<boolean>(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const reasons = selectedBox.includes("other")
    ? [...selectedBox.filter((reason) => reason !== "other"), otherText]
    : selectedBox;

  const DeleteThisUser = async () => {
    try {
      setLoading(true);
      const userID = await getUserIdWithAccessToken();
      // URL encode reasons before sending
      const encodedReasons = reasons.map((reason) =>
        encodeURIComponent(reason)
      );

      const response = await DeleteUser(userID, encodedReasons);
      setLoading(false);

      setLoading(false);

      if (AWS_CREDS.STAGE === "qa") console.log(response);

      if (response) {
        removeAuthTokens();
        AuthService.signOut();
        dispatch(setCompletedSteps([]));
        dispatch(setStep(1));
        dispatch(setUser(null));
        dispatch(resetBvnVerified());
        dispatch(setUserHash(""));
        navigate("/");
      }
    } catch (err: any) {
      setLoading(false);
      toast.error(err.message);
    }
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;

    setSelectedBox((prevSelected) => {
      if (checked) {
        return [...prevSelected, value];
      } else {
        if (value === "other") setOtherText("");
        return prevSelected.filter((item) => item !== value);
      }
    });
  };

  const handleOtherTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOtherText(e.target.value);
  };

  // Derived state to disable button
  const isButtonDisabled =
    !selectedBox.length || (selectedBox.includes("other") && !otherText);

  return (
    <>
      <div className="flex py-8 items-center">
        <BackButton />
        <p className="text-lg font-bold ml-3">Delete Account</p>
      </div>

      <div className="py-10 md:w-4/5 xl:w-[68%] md:px-4 xl:px-12 mx-auto">
        <p className="text-2xl font-bold text-center">
          We’re sorry that you’re leaving!
        </p>
        <p className="text-base text-center mt-3">
          Before you delete your account, please tell us why you’re leaving
        </p>

        <div className="bg-white mt-8 lg:p-16 p-5 rounded-lg">
          <div className="space-y-4">
            {DeleteAccountData.map((checkbox: any) => (
              <CheckboxComponent
                key={checkbox.value}
                id={checkbox.value}
                label={checkbox.label}
                value={checkbox.value}
                checked={selectedBox.includes(checkbox.value)}
                onChange={handleCheckboxChange}
              />
            ))}
            <div className="relative flex items-start">
              <input
                id="other"
                value="other"
                type="checkbox"
                checked={selectedBox.includes("other")}
                onChange={handleCheckboxChange}
                aria-describedby="Enter other sources"
                className="h-4 w-4 rounded border-gray-300 text-cleva-gold focus:ring-cleva-gold"
              />
              <label htmlFor="other" className="ml-3 text-black-soft">
                Other
              </label>
            </div>
            {selectedBox.includes("other") && (
              <input
                type="text"
                value={otherText}
                onChange={handleOtherTextChange}
                className="text-[13px] border mb-1 w-full mt-2 py-2 pl-2 outline-none rounded-[10px] bg-white input-control"
                placeholder="Enter other reason"
              />
            )}
          </div>

          <div className="flex items-center justify-between gap-4 mt-8">
            <Link
              to="/profile"
              className="bg-cancel text-sm font-bold py-3 md:px-10 px-6 rounded-lg"
            >
              Cancel
            </Link>
            <button
              onClick={() => setShowModal(true)}
              className={`bg-cleva-gold text-sm font-bold py-3 md:px-10 px-6 rounded-lg ${
                isButtonDisabled ? "opacity-50 cursor-not-allowed" : ""
              }`}
              disabled={isButtonDisabled}
            >
              {"Delete"}
            </button>
          </div>
        </div>
      </div>

      <ModalTemplate
        childStyle="md:w-[450px]"
        modalState={showModal}
        setModalState={setShowModal}
      >
        <div>
          <header className="px-8 py-4 rounded-lg flex justify-between items-center">
            <p className="text-lg font-semibold text-[#111111]"></p>
            <IoCloseOutline
              onClick={() => setShowModal(false)}
              className="cursor-pointer text-[#2C2C2C] w-5 h-5"
            />
          </header>
          <div className="py-10 overflow-y-auto px-6 md:px-12">
            <div className="text-center">
              <div className="flex justify-center">
                <img src={deleteIcon} alt="" />
              </div>

              <p className="my-8">
                Are you sure you want to delete your account? This action cannot
                be undone.
              </p>
            </div>
            <div className="flex items-center justify-between gap-4 mt-8">
              <button
                onClick={() => setShowModal(false)}
                className="bg-cancel text-sm font-bold py-3 md:px-10 px-6 rounded-lg"
              >
                Cancel
              </button>
              <button
                onClick={DeleteThisUser}
                className={`bg-cleva-gold text-sm font-bold py-3 md:px-10 px-6 rounded-lg ${
                  loading ? "opacity-50 cursor-not-allowed" : ""
                }`}
                disabled={loading}
              >
                {loading ? "Deleting..." : "Delete"}
              </button>
            </div>
          </div>
        </div>
      </ModalTemplate>
    </>
  );
};

export default DeleteAccountCheck;
