import {
  createTransaction,
  fetchTransactions,
  fetchRecipients,
  fetchTransactionById,
  cancelTransfer,
  fetchRates,
  createTagTransactions,
} from "./transactionApi";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface TransactionState {
  SenderFirstName: string;
  SenderLastName: string;
  RecipientFirstName: string;
  RecipientLastName: string;
  AccountIdentifier?: string;
  transactionDetails?: string;
  fee: any;
  totalAmount: number;
  description: string;
  AccountNumber: string;
  bankName: string;
  RecipientIdentifier: string;
  loading: boolean;
  error: string | null;
  allTransfer: TransactionListItem[];
  allRecipients: any[];
  singleTransfer: any | null;
  exchangeRate: number;
  TransactionID: string;
  status: string;
  rates: any[];
  receiveAmount: any;
  sendAmount: any;
  SenderTag?: string;
  RecipientTag?: string;
}

const initialState: TransactionState = {
  SenderFirstName: "",
  SenderLastName: "",
  RecipientFirstName: "",
  RecipientLastName: "",
  AccountIdentifier: "",
  transactionDetails: "",
  fee: 0,
  totalAmount: 0,
  description: "",
  AccountNumber: "",
  bankName: "",
  RecipientIdentifier: "",
  loading: false,
  error: null,
  allTransfer: [],
  allRecipients: [],
  singleTransfer: null,
  exchangeRate: 0,
  TransactionID: "",
  status: "",
  rates: [],
  sendAmount: "",
  receiveAmount: "",
  SenderTag: "",
  RecipientTag: "",
};
export interface TransactionListItem {
  UserID: string;
  TransactionIdentifier: string;
  TransactionType: string;
  TransactionState: string;
  ToAmount: number;
  FromAmount: number;
  TotalAmount: number;
  RecipientName: string;
  AccountNumber: string;
  Description: string;
  CreatedAt: string;
  UpdatedAt: string;
  RecipientTag: string;
  SenderName?: string;
}

const transactionSlice = createSlice({
  name: "transfer",
  initialState,
  reducers: {
    setSenderFirstName: (state, action: PayloadAction<string>) => {
      state.SenderFirstName = action.payload;
    },
    setSenderLastName: (state, action: PayloadAction<string>) => {
      state.SenderLastName = action.payload;
    },
    setRecipientFirstName: (state, action: PayloadAction<string>) => {
      state.RecipientFirstName = action.payload;
    },
    setRecipientLastName: (state, action: PayloadAction<string>) => {
      state.RecipientLastName = action.payload;
    },
    setAccountIdentifier: (state, action: PayloadAction<string>) => {
      state.AccountIdentifier = action.payload;
    },
    setTransactionDetails: (state, action: PayloadAction<string>) => {
      state.transactionDetails = action.payload;
    },
    setSendAmount: (state, action: PayloadAction<number | string>) => {
      state.sendAmount = action.payload;
    },
    setReceiveAmount: (state, action: PayloadAction<number | string>) => {
      state.receiveAmount = action.payload;
    },
    setFee: (state, action: PayloadAction<number>) => {
      state.fee = action.payload;
    },
    setDescription: (state, action: PayloadAction<string>) => {
      state.description = action.payload;
    },
    setTotalAmount: (state) => {
      state.totalAmount = state.sendAmount + state.fee;
    },
    setAccountNumber: (state, action: PayloadAction<string>) => {
      state.AccountNumber = action.payload;
    },
    setBankName: (state, action: PayloadAction<string>) => {
      state.bankName = action.payload;
    },
    setRecipientIdentifier: (state, action: PayloadAction<string>) => {
      state.RecipientIdentifier = action.payload;
    },
    setExchangeRate: (state, action: PayloadAction<number>) => {
      state.exchangeRate = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setSingleTransfer: (state, action: PayloadAction<any | null>) => {
      state.singleTransfer = action.payload;
    },
    setAllTransfer: (state, action: PayloadAction<any[]>) => {
      state.allTransfer = action.payload;
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
    setSenderTag: (state, action: PayloadAction<string>) => {
      state.SenderTag = action.payload;
    },
    setRecipientTag: (state, action: PayloadAction<string>) => {
      state.RecipientTag = action.payload;
    },

    updateTransactionStatus: (
      state,
      action: PayloadAction<{ transactionID: string; TransactionState: string }>
    ) => {
      const { transactionID, TransactionState } = action.payload;
      const checkTransfer = state.singleTransfer;
      console.log(checkTransfer);
      const transaction = Array.isArray(checkTransfer)
        ? checkTransfer.find(
            (t: any) => t.TransactionIdentifier === transactionID
          )
        : null;

      if (transaction) {
        transaction.TransactionState = TransactionState;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      // .addCase(postTransaction.pending, (state) => {
      //   state.loading = true
      //   state.error = null
      // })
      // .addCase(postTransaction.fulfilled, (state) => {
      //   state.loading = false
      //   state.error = null
      // })
      // .addCase(postTransaction.rejected, (state, action) => {
      //   state.loading = false
      //   state.error = action.payload as string
      // })

      //M2 transaction
      .addCase(createTransaction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createTransaction.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(createTransaction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      // fetch transactions
      .addCase(fetchTransactions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTransactions.fulfilled, (state, action) => {
        state.loading = false;
        state.allTransfer = action.payload;
      })
      .addCase(fetchTransactions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      // fetch dummy transfers
      // .addCase(fetchDummyTransactions.pending, (state) => {
      //   state.loading = true
      //   state.error = null
      // })
      // .addCase(fetchDummyTransactions.fulfilled, (state, action) => {
      //   state.loading = false
      //   state.allTransfer = action.payload
      // })
      // .addCase(fetchDummyTransactions.rejected, (state, action) => {
      //   state.loading = false
      //   state.error = action.payload as string
      // })

      // fetch single transaction
      .addCase(fetchTransactionById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTransactionById.fulfilled, (state, action) => {
        state.loading = false;
        state.singleTransfer = action.payload;
        // console.log('my transfer', state.singleTransfer)
        // Update the state with the fetched data
      })
      .addCase(fetchTransactionById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      // fetch Recipients
      .addCase(fetchRecipients.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchRecipients.fulfilled, (state, action) => {
        state.loading = false;
        state.allRecipients = action.payload;
      })
      .addCase(fetchRecipients.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });

    // Handle the cancelTransaction action
    builder.addCase(cancelTransfer.pending, () => {
      // Handle pending state
    });

    builder.addCase(cancelTransfer.fulfilled, (state, action) => {
      const { TransactionIdentifier } = action.meta.arg;
      console.log("trans", TransactionIdentifier);
      const checkTransfer = state.singleTransfer;
      console.log(checkTransfer);
      const transaction = Array.isArray(checkTransfer)
        ? checkTransfer.find(
            (t: any) => t.TransactionIdentifier === TransactionIdentifier
          )
        : null;

      if (transaction) {
        transaction.TransactionState = "Cancelled";
      }
    });

    builder
      .addCase(cancelTransfer.rejected, () => {
        // Handle rejected state
      })

      // fetch Rates
      .addCase(fetchRates.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchRates.fulfilled, (state, action) => {
        state.loading = false;
        state.rates = action.payload;
      })
      .addCase(fetchRates.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      // Tag transaction
      .addCase(createTagTransactions.pending, (state) => {
        console.log(state);
        state.loading = true;
        state.error = null;
      })
      .addCase(createTagTransactions.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(createTagTransactions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export const {
  setSenderFirstName,
  setSenderLastName,
  setRecipientFirstName,
  setRecipientLastName,
  setAccountIdentifier,
  setTransactionDetails,
  setSendAmount,
  setFee,
  setTotalAmount,
  setReceiveAmount,
  setDescription,
  setAccountNumber,
  setBankName,
  setRecipientIdentifier,
  setExchangeRate,
  setSingleTransfer,
  setAllTransfer,
  setError,
  setLoading,
  updateTransactionStatus,
  setSenderTag,
  setRecipientTag,
} = transactionSlice.actions;

export default transactionSlice.reducer;
