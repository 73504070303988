/* eslint-disable react-hooks/exhaustive-deps */
import { MdOutlineErrorOutline } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import "react-placeholder/lib/reactPlaceholder.css";
import { useSelector } from "react-redux";
// import { useIsBusinessUser } from "../../../utils";
import { retryKYC } from "../../../api";
import Spinner from "../../../components/PopUps/Spinner";
import { useState } from "react";
import { toast } from "react-toastify";

export const KycStatus = () => {
  const [loading, setLoading] = useState(false);
  const { allTransfer } = useSelector((state: any) => state.transaction);
  // const isBusinessUser = useIsBusinessUser();
  const { KYCState, AdditionalDetails } = useAppSelector(
    (state: any) => state.kycInfo
  );

  const depositTrans = allTransfer.filter((transfer: any) => {
    return transfer.TransactionType === "USD_CREDIT";
  });

  const checkStatus = (KYCState: string) => {
    switch (KYCState) {
      case "VERIFIED":
      case "BUSINESS_VERIFIED":
        return <></>;
      case "FAILED":
        return (
          <DeniedWithRetryKyc
            setLoading={setLoading}
            AdditionalDetails={AdditionalDetails}
          />
        );
      case "PENDING":
      case "BUSINESS_PENDING":
      case "BUSINESS_DUPLICATE":
      case "BUSINESS_MISMATCH":
      case "BUSINESS_INVALID":
      case "BUSINESS_RETRY":
        return <PreKyc />;
      case "DOCUMENT_IN_REVIEW":
      case "BUSINESS_DOCUMENT_IN_REVIEW":
        return <PendingKyc />;
      case "DENIED":
        return <DeniedKyc />;
      case "SUSPENDED":
        return <SuspendedKyc />;
      case "BIOMETRICS":
      case "BIOMETRICS_MISMATCH":
        if (AdditionalDetails?.VerificationPending) {
          return <PendingKyc />;
        }
        return <NoDocumentKyc />;
      case "DOCUMENT_FAILED":
        return <FailedKyc />;
      case "MISMATCH":
      case "INVALID":
      case "RETRY":
        if (AdditionalDetails.NoOfRetriesLeft === 0) {
          return <PreKyc />;
        }
        return <PreKyc />;
      // case "RETRY":
      //   return <RetryKyc />;
      case "DOCUMENT":
        return <NoDocumentKyc />;
      case "DOCUMENT_RETRY":
        return <DocumentKyc AdditionalDetails={AdditionalDetails} />;
      case "CONTROLLER_PENDING":
      case "CONTROLLER_RETRY":
      case "CONTROLLER_INVALID":
      case "CONTROLLER_MISMATCH":
      case "CONTROLLER_DUPLICATE":
        return <ControllerDetailsKyb />;
      case "CONTROLLER_BVN_FAILED":
      case "CONTROLLER_DENIED":
        return <ControllerBvnFailed />;
      case "OWNER_PENDING":
      case "OWNER_RETRY":
      case "OWNER_INVALID":
      case "OWNER_MISMATCH":
      case "OWNER_DUPLICATE":
        return <OwnerDetailsKyb />;
      case "OWNER_DENIED":
      case "OWNER_BVN_FAILED":
        return <OwnerFailedKyb />;
      case "BUSINESS_DOCUMENT_PENDING":
        return <BusinessDocument />;
      case "PEOPLE_DOCS":
        return <PeopleDocument />;
      case "PEOPLE_DOCS_RETRY":
      case "BUSINESS_DOCS_RETRY":
        return <BusinessDocumentRetry />;
      case "ID_UPLOAD":
        return <VerifyNIN />;
      case "ID_INVALID":
      case "ID_DUPLICATE":
      case "ID_MISMATCH":
        return <VerifyNINNumber />;
      // case "DISCLOSURES":
      //   return <AcceptDisclosure />;
      case "":
        if (depositTrans.length > 0) {
          return <PreKyc2 />;
        } else {
          return <PreKyc />;
        }
      default:
        return <></>;
      //return <PreKyc2 />;
    }
  };

  if (loading) {
    return (
      <div className="h-screen">
        <Spinner />
      </div>
    );
  }

  return <>{checkStatus(KYCState)}</>;
};

function PreKyc() {
  const navigate = useNavigate();

  return (
    <>
      <div
        className={`bg-[#F0FFFB] px-3  mb-4 py-3  mt-2 text-base text-[#012F2C] rounded-md border border-[#7EC9A7]`}
      >
        <div className=" justify-start items-start gap-2 flex">
          <span className="me-3 text-[20px]">
            <MdOutlineErrorOutline />
          </span>
          <p className="text-base">
            <span className="font-bold">
              Your identity needs to be verified.
            </span>
            <span
              className="underline text-[#108E86] cursor-pointer pl-2 font-medium"
              onClick={() => navigate("/kyc")}
            >
              Click here to complete KYC
            </span>
          </p>
        </div>
      </div>
      {/* <div className="px-4 py-2.5 mb-6 bg-[#D6EEE3] rounded-lg border border-[#7EC9A7] justify-start items-center gap-[42px] inline-flex">
        <div className=" justify-start items-start gap-2 flex">
          <img title="img" className="" src={kycIcon} />
          <div className=" text-[#0F5133] text-[16px] font-semibold leading-tight">
            Cleva does incremental verification. This means we let you try out
            some of our features before you need to share your detailed
            information to complete KYC.
          </div>
        </div>
      </div> */}
    </>
  );
}

function PreKyc2() {
  const navigate = useNavigate();
  return (
    <>
      <div
        className={`bg-[#FFF5D9] px-3  mt-2 mb-4 py-3 text-base text-[#012F2C] rounded-md border border-[#F0D180]`}
      >
        <div className=" justify-start items-start gap-2 flex">
          <span className="me-3 text-[20px]">
            <MdOutlineErrorOutline />
          </span>
          <p className="text-base">
            <span className="font-bold">KYC verification pending.</span>
            <span
              className="underline font-medium text-[#108E86] cursor-pointer pl-2"
              onClick={() => navigate("/kyc-info")}
            >
              Click here to re-upload your ID for verification
            </span>
          </p>
        </div>
      </div>
    </>
  );
}

// function UndoneKyc() {
//   const navigate = useNavigate();
//   return (
//     <>
//       <div
//         className={`bg-[#F2F2F2] px-3 flex items-center mb-4 py-3 text-[13px] text-[#111111] rounded-md`}
//       >
//         <span className="me-3 text-[20px]">
//           <MdOutlineErrorOutline />
//         </span>
//         <p>
//           Your account needs to be verified.
//           <span
//             className="underline text-[#A06202] font-semibold cursor-pointer pl-2"
//             onClick={() => navigate("/kyc")}
//           >
//             Verify your account now
//           </span>
//         </p>
//       </div>
//     </>
//   );
// }

function NoDocumentKyc() {
  const navigate = useNavigate();
  return (
    <>
      <div
        className={`bg-[#fff] w-full px-3 flex items-center mb-4 py-4 md:py-6 text-base text-[#111111] rounded-md border`}
      >
        <span className="me-3 text-[20px]">
          <MdOutlineErrorOutline />
        </span>
        <p>
          Document verification pending
          <span
            className="underline text-[#A06202] font-semibold cursor-pointer pl-2"
            onClick={() => navigate("/kyc-info")}
          >
            Click to upload your documents.
          </span>
        </p>
      </div>
    </>
  );
}

function BusinessDocument() {
  const navigate = useNavigate();
  return (
    <div className="">
      <div
        className={`bg-[#fff] w-full px-3 flex items-center mb-4 py-3 text-base text-[#111111] rounded-md`}
      >
        <span className="me-3 text-[20px]">
          <MdOutlineErrorOutline />
        </span>
        <p>
          Document upload pending.
          <span
            className="underline text-[#A06202] font-semibold cursor-pointer pl-2"
            onClick={() => navigate("/kyb-info")}
          >
            Click to upload your documents
          </span>
        </p>
      </div>
    </div>
  );
}

function PeopleDocument() {
  const navigate = useNavigate();
  return (
    <div className="">
      <div
        className={`bg-[#fff] w-full px-3 flex items-center mb-4 py-3 text-base text-[#111111] rounded-md`}
      >
        <span className="me-3 text-[20px]">
          <MdOutlineErrorOutline />
        </span>
        <p>
          Document upload pending.
          <span
            className="underline text-[#A06202] font-semibold cursor-pointer pl-2"
            onClick={() => navigate("/kyb-info")}
          >
            Click to upload your Controller & Owner(s) documents
          </span>
        </p>
      </div>
    </div>
  );
}

function ControllerDetailsKyb() {
  const navigate = useNavigate();

  return (
    <>
      <div
        className={`bg-[#eae6e6] w-full px-3  mb-4 py-3  mt-2 text-base text-[#111111] rounded-md border border-[F2F2F2]`}
      >
        <div className=" justify-start items-start gap-2 flex">
          <span className="me-3 text-[20px]">
            <MdOutlineErrorOutline />
          </span>
          <p className="text-base">
            <span className="font-base">
              Your Controller's identity needs to be verified.
            </span>
            <span
              className="underline text-[#A06202] cursor-pointer pl-2 font-medium"
              onClick={() => navigate("/kyb-info")}
            >
              Click to verify your controller
            </span>
          </p>
        </div>
      </div>
    </>
  );
}

//FFE6E6
function OwnerDetailsKyb() {
  const navigate = useNavigate();

  return (
    <div className="">
      <div
        className={`bg-[#eae6e6] px-3  mb-4 py-3 w-full  mt-2 text-base text-[#111111] rounded-md border border-[#F2F2F2]`}
      >
        <div className=" justify-start items-start gap-2 flex">
          <span className="me-3 text-[20px]">
            <MdOutlineErrorOutline />
          </span>
          <p className="text-base">
            <span className="font-base">
              Your Owners' identity needs to be verified.
            </span>
            <span
              className="underline text-[#A06202] cursor-pointer pl-2 font-medium"
              onClick={() => navigate("/kyb-info")}
            >
              Click here to verify your owners
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}

function DocumentKyc({ AdditionalDetails }: any) {
  const navigate = useNavigate();

  return (
    <>
      <div
        className={` bg-[#FFEEE6] border border-[#9C2B07] mt-2 p-4 mb-4 text-base text-[#111111] rounded-md`}
      >
        <div className="flex items-center">
          <span className="hidden me-3 text-[20px]">
            <MdOutlineErrorOutline />
          </span>
          <p className="font-bold  text-base md:text-lg">
            Sadly, we could not verify your ID.
          </p>
        </div>
        <p className="underline text-base font-bold pt-2">Reason:</p>
        <div className="md:flex grid gap-2 md:justify-between items-center">
          <ol className="list-decimal ml-7 md:w-[60%]">
            {AdditionalDetails && AdditionalDetails?.StateChangeReasons
              ? AdditionalDetails?.StateChangeReasons.map((item: string) => (
                  <li key={item}>{item}</li>
                ))
              : null}
          </ol>
          <button
            onClick={() => navigate("/kyc-info")}
            className="p-2 md:p-4 min-[375px]:w-[70%] md:w-[45%] bg-[#FFBD59] rounded-lg "
          >
            Re-upload your ID
          </button>
        </div>
      </div>
    </>
  );
}

function PendingKyc() {
  return (
    <>
      <div
        className={`font-semibold bg-[#FFF5D9] mt-2 px-3 flex items-center mb-4 py-3 text-[18px] text-[#111111] rounded-md border border-[#F0D180]`}
      >
        <span className="me-3 text-[22px]">
          <MdOutlineErrorOutline />
        </span>
        <p>KYC verification pending, please check back in 2-5 days</p>
      </div>
    </>
  );
}

function DeniedKyc() {
  // const StateChangeReasons = [
  //   "You are not holding your passport in your selfie",
  //   "You are uploading an unacceptable Identification Document",
  // ];
  return (
    <>
      <div
        className={`font-semibold bg-[#FFE5E5]  mt-2 px-3 flex items-center mb-4 py-3 text-base text-[#111111] rounded-md`}
      >
        <p className="">
          Unfortunately, we could not verify your identity after multiple tries.
          As a result, we cannot proceed to offer our services at this time.{" "}
        </p>
      </div>
    </>
  );
}

function DeniedWithRetryKyc({ AdditionalDetails, setLoading }: any) {
  const navigate = useNavigate();

  const handleRetry = async () => {
    setLoading(true);

    try {
      const response = await retryKYC();
      if (response.status === 200) {
        setLoading(false);
        navigate("/kyc-info");
      }
    } catch (error) {
      console.error(error);
      toast.error("Try again later");
    }
  };

  return (
    // {/* mt-2 mb-4 */}
    <div
      className={` bg-[#FFEEE6] border border-[#9C2B07] p-4 text-[#111111] rounded-md`}
    >
      <p className="font-bold text-lg">
        Sadly, we could not verify your identity.
      </p>
      <p className="underline text-base font-bold pt-2">Reason:</p>
      <div className="">
        {AdditionalDetails?.StateChangeReasons &&
        AdditionalDetails?.StateChangeReasons.length > 0 ? (
          <ol className="list-decimal ml-4 md:ml-7 md:w-[60%]">
            {AdditionalDetails && AdditionalDetails?.StateChangeReasons
              ? AdditionalDetails?.StateChangeReasons.map((item: string) => (
                  <li key={item}>{item}</li>
                ))
              : null}
          </ol>
        ) : AdditionalDetails?.NationalIDFailureReasons.includes(
            "DOB_MISMATCH"
          ) ? (
          <p className="md:w-[60%]">
            The details you entered don’t match what is on your BVN. Please
            contact your bank to update your BVN.
          </p>
        ) : null}
        <button
          onClick={() => handleRetry()}
          className="mt-4 p-2 md:p-3  bg-[#FFBD59] rounded-lg "
        >
          <span className="font-bold">Try again </span> (
          {AdditionalDetails?.NoOfUserResetsLeft} resets left)
        </button>
      </div>
    </div>
  );
}

function ControllerBvnFailed() {
  return (
    <>
      <div
        className={`font-semibold bg-[#FFE5E5]  mt-2 px-3 flex items-center mb-4 py-3 text-base text-[#111111] rounded-md`}
      >
        <p>
          Unfortunately, we were unable to verify your Controller's information.
          Please email contact@getcleva.com if you think we made a mistake
        </p>
      </div>
    </>
  );
}

// function FailedKyb() {
//   return (
//     <>
//       <div
//         className={`font-semibold bg-[#FFE5E5]  mt-2 px-3 flex items-center mb-4 py-3 text-base text-[#111111] rounded-md`}
//       >
//         <p>
//           Unfortunately, we were unable to verify your KYB information. Please
//           email contact@getcleva.com if you think we made a mistake
//         </p>
//       </div>
//     </>
//   );
// }

function SuspendedKyc() {
  return (
    <>
      <div
        className={`font-semibold bg-[#FFE5E5] px-3  mt-2 flex items-center mb-4 py-3 text-base text-[#111111] rounded-md`}
      >
        <p>
          Your BVN could not be verified therefore your account has been
          blocked. If you believe this is incorrect, email us at
          <a
            href="mailto:contact@getcleva.com"
            className="underline text-[#0E82D6] font-bold cursor-pointer pl-2"
          >
            contact@getcleva.com
          </a>
        </p>
      </div>
    </>
  );
}

function FailedKyc() {
  const navigate = useNavigate();
  return (
    <>
      <div
        className={`font-semibold bg-[#FFE5E5] mt-2 px-3 flex items-center mb-4 py-3 text-[18px] text-[#111111] rounded-md`}
      >
        <span className="me-3 text-[22px] text-[#D31D1D]">
          <MdOutlineErrorOutline />
        </span>
        <p>
          KYC verification failed.
          <span
            className="underline text-[#096215] text-base font-semibold cursor-pointer pl-2"
            onClick={() => navigate("/kyc-info")}
          >
            Click here to review and update your information
          </span>
        </p>
      </div>
    </>
  );
}

// function RetryKyc() {
//   const navigate = useNavigate();

//   return (
//     <>
//       <div
//         className={`font-semibold bg-[#FFE6E6]  mt-2 px-3 flex items-center mb-4 py-3 text-base text-[#111111] rounded-md`}
//       >
//         <span className="me-3 text-[20px]">
//           <MdOutlineErrorOutline />
//         </span>
//         <p>
//           KYC verification failed please retry by
//           <span
//             className="underline text-[#A06202] font-semibold cursor-pointer pl-2"
//             onClick={() => navigate("/kyc-info")}
//           >
//             clicking here to review and update your information for
//             re-verification
//           </span>
//         </p>
//       </div>
//     </>
//   );
// }

function BusinessDocumentRetry() {
  const navigate = useNavigate();
  return (
    <>
      <div
        className={`font-semibold bg-[#FFE6E6]  mt-2 px-3 flex items-center mb-4 py-3 text-base text-[#111111] rounded-md`}
      >
        <span className="me-3 text-[20px]">
          <MdOutlineErrorOutline />
        </span>
        <p>
          KYB Verification Failed.
          <span
            className="underline text-[#A06202] font-semibold cursor-pointer pl-2"
            onClick={() => navigate("/kyb-info")}
          >
            Click here to upload your document for re-verification
          </span>
        </p>
      </div>
    </>
  );
}

function OwnerFailedKyb() {
  return (
    <>
      <div
        className={`font-semibold bg-[#FFE6E6]  mt-2 px-3 flex items-center mb-4 py-3 text-base text-[#111111] rounded-md`}
      >
        <span className="me-3 text-[20px]">
          <MdOutlineErrorOutline />
        </span>
        <p>
          Unfortunately, we were unable to verify your Owners' information.
          Please email contact@getcleva.com if you think we made a mistake.
          {/* <span
            className="underline text-[#A06202] font-semibold cursor-pointer pl-2"
            onClick={() => navigate("/kyb-info")}
          >
            clicking here to review and update your information for
            re-verification
          </span> */}
        </p>
      </div>
    </>
  );
}

function VerifyNIN() {
  const navigate = useNavigate();
  return (
    <>
      <div
        className={`font-semibold bg-[#FFFBEF] mt-2 px-3 flex items-center mb-4 py-4 text-[18px] text-[#111111] rounded-md border border-[#F0D180]`}
      >
        <span className="me-3 text-[22px]">
          <MdOutlineErrorOutline />
        </span>
        <p>
          <span
            className="underline text-[#A06202] font-semibold cursor-pointer pl-2"
            onClick={() => navigate("/kyc-info")}
          >
            Click to verify your NIN
          </span>
        </p>
      </div>
    </>
  );
}
function VerifyNINNumber() {
  const navigate = useNavigate();
  return (
    <>
      <div
        className={`font-semibold bg-[#FFFBEF] mt-2 px-3 flex items-center mb-4 py-4 text-[18px] text-[#111111] rounded-md border border-[#F0D180]`}
      >
        <span className="me-3 text-[22px]">
          <MdOutlineErrorOutline />
        </span>
        <p>
          <span
            className="underline text-[#A06202] font-semibold cursor-pointer pl-2"
            onClick={() => navigate("/kyc/verify-NIN")}
          >
            Click to verify your NIN
          </span>
        </p>
      </div>
    </>
  );
}
