import React from "react";

const UsdBenefit = () => {
  return (
    <div className="mt-8 md:mt-12 bg-white rounded-xl py-8 px-4">
      <p className="font-semibold">Benefits of a Cleva USD account</p>
      <p className="xl:w-[55%]">
        Complete your KYC, then open your USD account to enjoy the following
        benefits:
      </p>

      <ol className="list-decimal space-y-2 ml-6 mt-4">
        <li>Receive ACH & Wire transfers from abroad</li>
        <li>Withdraw from Upwork, Wise, e.t.c</li>
        <li>Competitive exchange rates</li>
        <li>Free Naira transfers</li>
        <li>USD virtual card for online payments</li>
      </ol>
    </div>
  );
};

export default UsdBenefit;
